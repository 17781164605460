import PaginationContext from "../contexts/PaginationContext";
import React, {Suspense} from "react";
import Loading from "../atoms/Loading";
import usePagination from "../hooks/usePagination";


const WithPagination = ({children, pagination: initialPagination}) => {
    const value = usePagination(initialPagination);
    return (
        <PaginationContext.Provider value={value}>
            <Suspense fallback={<Loading/>}>
                {children}
            </Suspense>
        </PaginationContext.Provider>
    );
};


export default WithPagination;