import {FlexRow, WhiteBox} from "../../atoms/style";
import React from "react";
import {Col, Row} from "reactstrap";
import {RegRow} from "./style";
import {formatDate} from "../../utils/formatDate";

const Membership = ({t, membership, isSelf = false, isManager = false}) => {
    const hideSettings = membership.is_manager;
    return membership ? (
        <div>
            <RegRow leftSize={6} left={t('choir_member.singer_status')} hide={!membership.is_singer}>
                {membership.active_singer ? t('choir_member.status_active') : t('choir_member.status_inactive')}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.singer_joined')} hide={!membership.joined_choir || !isManager}>
                {membership.joined_choir ? formatDate(membership.joined_choir) : null}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.singer_resigned')} hide={!membership.resigned_choir || !isManager}>
                {membership.resigned_choir ? formatDate(membership.resigned_choir) : null}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.singer_resigned_reason')} hide={!membership.resignation_reason || !isManager}>
                {membership.resignation_reason}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.singer_notes')} hide={!membership.notes || !isManager}>
                {membership.notes}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.singer_voice')} hide={!membership.is_singer}>
                {membership.voice ? t(`voice.name_${membership.voice.abbr}`) : t("N/A")}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.manager_status')} hide={!membership.is_manager}>
                {membership.is_manager ? t('choir_member.status_active') : t('choir_member.status_inactive')}
            </RegRow>

            <RegRow leftSize={6} left={t('choir_member.allow_profile_edit')} hide={hideSettings}>
                {membership.allow_profile_edit ? t('boolean_yes') : t('boolean_no')}
            </RegRow>
            <RegRow leftSize={6} left={t('choir_member.numbers_visible_to_singers')} hide={hideSettings}>
                {membership.numbers_visible_to_singers ? t('boolean_yes') : t('boolean_no')}
            </RegRow>
            <RegRow leftSize={6} left={t('choir_member.email_visible_to_singers')} hide={hideSettings}>
                {membership.email_visible_to_singers ? t('boolean_yes') : t('boolean_no')}
            </RegRow>
            <RegRow leftSize={6} left={t('choir_member.address_visible_to_singers')} hide={hideSettings}>
                {membership.address_visible_to_singers ? t('boolean_yes') : t('boolean_no')}
            </RegRow>
            <RegRow leftSize={6} left={t('choir_member.birthdate_visible_to_singers')} hide={hideSettings}>
                {membership.birthdate_visible_to_singers ? t('boolean_yes') : t('boolean_no')}
            </RegRow>
        </div>
    ) : null;
};


const MembershipProfile = ({t, member, isSelf, canEdit, onEdit, isManager}) => (
    <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
        <FlexRow justify={'between'}>
            <div>
                <h2>{t('choir_member.membership')}</h2>
            </div>
        </FlexRow>
        <Row>
            <Col>
                <Membership t={t} membership={member.membership} isSelf={isSelf} canEdit={canEdit} isManager={isManager} />
            </Col>
        </Row>
    </WhiteBox>
);

export default MembershipProfile;