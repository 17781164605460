import React, {useContext} from "react";
import {
    Div,
    GreyHeader,
    H5,
    Icon,
    ILink,
    SmallUpper,
    SrOnly,
    SubmitButton,
    WrappedCol,
    WrappedRow
} from "../../atoms/style";
import {ChoirSingersBoxWrapper, VoiceTitle} from "./style";
import {getLeafVoices} from "../../../utils/choirUtils";
import ChoirContext from "../../contexts/ChoirContext";
import groupBy from "lodash/groupBy";
import UserWithAvatar from "../UserWithAvatar";
import useTrans from "../../hooks/useTrans";
import {EventFormSpacer} from "../../pages/EventEditPage/style";
import useAuthUser from "../../hooks/useAuthUser";

const VoiceBox = ({title, singers}) => (
    <WrappedCol md={4} spacing={{pb: 4, pr: 1}}>
        <VoiceTitle>{title}</VoiceTitle>

        {singers ? singers.map(singer => (
            <UserWithAvatar src={singer.user}
                            inactive={!singer.is_active}
                            name={singer.user.full_name}
                            to={['choir_member', {choir_id: singer.choir_id, id: singer.user.id}]}/>
        )) : null}
    </WrappedCol>
);

const ChoirSingersBox = ({t}) => {
    const {choir, voices, isManager} = useContext(ChoirContext);
    const user = useAuthUser();
    const singers = choir.singers && choir.singers.length ? choir.singers.filter(singer => (isManager || singer.is_active)) : [];
    const groupedSingers = groupBy(singers, 'voice.id');
    const leafVoices = getLeafVoices(voices);
    const edit_membership = ['edit_choir_membership', {choir_id: choir.id, id: user.id, action: 'edit-membership'}];
    return (leafVoices && leafVoices.length > 0) ? (
        <ChoirSingersBoxWrapper>
            <WrappedRow noGutters>
                {leafVoices.map((voice, idx) => {
                    const singers = groupedSingers[voice.id];
                    return (
                        <VoiceBox key={idx} title={t(`voice.name_${voice.abbr || "MS"}`)} singers={singers}/>
                    );
                })}
            </WrappedRow>
            {isManager ? (<EventFormSpacer />) : null}
            {isManager ? (
                <WrappedRow spacing={{mt: 2 }}>
                    <WrappedCol md={4}>
                        <SubmitButton to={['choir_add_members', {id: choir.id}]} data-t={'button.add_members'}>{t('choir.add_members')}</SubmitButton>
                    </WrappedCol>
                    <WrappedCol md={4}>
                        <SubmitButton to={edit_membership} data-t={'button.edit_my_membership'}>{t('choir.edit_my_membership')}</SubmitButton>
                    </WrappedCol>
                </WrappedRow>
            ) : null}
        </ChoirSingersBoxWrapper>
    ) : null;
};

export default function(props) {
    const t = useTrans();
    const {choir, isManager} = useContext(ChoirContext);
    return (
        <React.Fragment>
            <GreyHeader>
                <H5 spacing={{my: 1}}>{t('choir.members')}</H5>
                {isManager ? (
                    <Div spacing={{pt: 1, pr: 1}}>
                        <SmallUpper>{t('choir.add_members')}:</SmallUpper>
                        <ILink to={['choir_add_members', {id: choir.id}]}
                               title={t('choir.add_members')}
                               data-t={'link.add_members'}
                               spacing={{ml: 3}}
                               className={"text-white"}>
                            <Icon icon={'plus'} /><SrOnly>{t('event.grid_view')}</SrOnly>
                        </ILink>
                    </Div>
                ) : null}
            </GreyHeader>

            <ChoirSingersBox t={t} {...props}/>
        </React.Fragment>
    );
};