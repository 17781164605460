import resolver from '../../resolver'
import pathToRegexp from "path-to-regexp";
import i18n from "../../setup/i18n";

export class BaseUrl {
    constructor({path, name}) {
        this.path = path;
        this.name = name;
        this.keys = [];
        this.re = pathToRegexp(path, this.keys);
        this.build = pathToRegexp.compile(path);
    }

    is(url) {
        return this.re.test(url);
    }

    match(url) {
        const result = this.re.exec(url);
        if (result != null) {
            let params = {};
            this.keys.forEach((key, idx) => {
                if (key.name) {
                    params[key.name] = result[idx + 1];
                }
            });
            return params;
        }
        return null;
    }
}

export class Url extends BaseUrl {
    constructor({middlewares, component, props, ...params}) {
        super(params);
        this.middlewares = middlewares;
        this.component = component;
        this.props = props;
        resolver.register(`url:${this.name}`, this);
    }
}

const getMarketingSite = () => {
    const origin = window.location.origin;
    if (origin.indexOf('staging') !== -1 || origin.indexOf('d3bnfvb3vjdrq9.cloudfront.net') !== -1 || origin.indexOf("localhost") !== -1) {
        return 'http://2831296.hs-sites.com'
    }
    return 'https://www.choirmanager.com'
};

const markgingUrls = {
    en: {
        'contacts': '/en/contact/',
        'policy': '/en/terms/',
        'imprint': '/en/imprint/',
        'data_protection': '/en/privacy/',
        'home': '/',
    },
    de: {
        'contacts': '/de/kontakt/',
        'policy': '/de/agb/',
        'imprint': '/de/impressum/',
        'data_protection': '/de/datenschutz/',
        'home': '/',
    }
};

const LANG_URLS = {
    'lhref_help': (lng) => {
            if (lng==="en"){
                return `https://support.choirmanager.com/hc/en-us/requests/new`
            } else {
                return `https://support.choirmanager.com/hc/${lng}/requests/new`
            }
        }
};

const getMarketingURL = (mhref, language) => {
    if (!mhref || !language) return null;
    if (markgingUrls[language] && markgingUrls[language][mhref]) {
        return getMarketingSite() + markgingUrls[language][mhref];
    }
    return null;
};

export const reverse = (urlname, params) => {
    const lng = i18n.language;
    if (urlname.startsWith('mhref_')) {
        return getMarketingURL(urlname.replace(/^mhref_/, ''), lng);
    }
    if (LANG_URLS[urlname]) {
        return LANG_URLS[urlname](lng);
    }
    const urlconfig = resolver.resolve(`url:${urlname}`);
    return urlconfig.build({lng, ...params});
};

export const resolve = (urlString) => {
    const urls = resolver.resolve('urls');
    let params = null;
    const url = urls.find((urlObj) => {
        params = urlObj.match(urlString);
        return (params !== null);
    });
    params = url ? params : null;
    return {url, params};
};

