import {NoGuttersRow, StyledCollapse} from "./style";
import {EventFilterButton, H3} from "../../atoms/style";
import EventFilterForm from "./EventFilterForm";
import React from "react";
import {Col} from "reactstrap";
import moment from "moment";

const PureEventFilter = (props) => {
    const {t, isFiltered, filter, isOpen, onToggle} = props;
    const from_date = React.useMemo(() => moment(filter.from_date).format('Y-MM-DD'), [filter.from_date]);
    const to_date = React.useMemo(() => filter.to_date ? moment(filter.to_date).format('Y-MM-DD') : null, [filter.to_date]);
    return (
        <NoGuttersRow>

            <Col lg={9}>
                <H3>
                    {isFiltered ? (
                        to_date ? t('event.filter_status', {from_date, to_date}) : t('event.filter_status_from', {from_date})
                    ) : t('event.all_future_events')}
                </H3>
            </Col>
            <Col lg={3} style={{zIndex: 1}}>
                <EventFilterButton onClick={onToggle} isOpen={isOpen} arrowTitle={t("event.more")}>
                    {t('event.filter')}
                </EventFilterButton>
            </Col>

            <StyledCollapse isOpen={isOpen}>
                <EventFilterForm {...props}/>
            </StyledCollapse>

        </NoGuttersRow>
    );
};

export default PureEventFilter;