import moment from "moment-timezone";
import React, {useMemo} from "react";
import uuidv4 from "uuid/v4";
import {PopoverBody, UncontrolledPopover} from "reactstrap";


const DateTimePopover = ({timeString, timezone, children}) => {
    const start_moment = moment.tz(timeString, timezone);
    const userTimezone = moment.tz.guess();
    const popoverId = useMemo(() => {
        const result = `popover-${uuidv4()}`;
        // a hack to make id a change on different timeString and timezone
        return timeString || timezone ? result : result;
    }, [timeString, timezone]);
    children = children || start_moment.format("LLL")
    return (
        <>
            <span id={popoverId}>{children}</span>
            <UncontrolledPopover placement="bottom" target={popoverId} trigger={"hover"}>
                <PopoverBody>
                    <div>
                        <div><small>{`${timezone}: ${start_moment.format()}`}</small></div>
                        <div><small>{`${userTimezone}: ${start_moment.tz(userTimezone).format()}`}</small></div>
                        <div><small>{`UTC: ${start_moment.tz(userTimezone).utc().format()}`}</small></div>
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    )
};

export default DateTimePopover;