import React from "react";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {EventFormDottedSpacer, ReminderFormGroup, ReminderLegend} from "./style";
import {IconedLink} from "../../atoms/style";
import {useField} from "formik";
import InputErrorFeedback from "../../molecules/InputErrorFeedback";
import useTrans from "../../hooks/useTrans";

const ReminderDate = ({from_date, reminder_days}) => {
    const t = useTrans();

    const dateOfReminder = React.useMemo(() => {
        if (from_date && reminder_days) {
            return from_date.clone().subtract(reminder_days, 'days').format("dddd, LL")
        } else {
            return null;
        }
    }, [reminder_days, from_date]);

    return dateOfReminder ? (
        <Label>{t('event_form.on_date')} {dateOfReminder}</Label>
    ) : null;
}

const ReminderDaysInput = ({t, from_date, name, isCreated = false, disabled = false}) => {
    const [field, meta] = useField(name);
    const reminder_days = field.value;

    return (
        <ReminderFormGroup>
            <Col md={'auto'}>
                <Input {...field} type='text' size={'2'}  disabled={disabled}/>
            </Col>
            <Col md={'auto'}>
                <Label>{t('event_form.days_before_appointment')}</Label> <ReminderDate from_date={from_date} reminder_days={reminder_days} />
            </Col>
            <Col md={12}>
                <InputErrorFeedback error={meta.error}/>
            </Col>
        </ReminderFormGroup>
    );
};

const ReminderInput = ({t, from_date, index, prefix, setFieldValue, value, disabled = false, onDelete, form}) => {

    const isCreated = value ? !!value.id : false;
    return (
        <>
        <FormGroup tag="fieldset">
            <Row>
                <ReminderLegend>{index}. {t('event_form.reminder_legend')}: </ReminderLegend>
                <Col md={12}>
                    <ReminderDaysInput name={`${prefix}.reminder_days`}
                                       selectionName={`${prefix}._selection`}
                                       isCreated={isCreated}
                                       disabled={disabled}
                                       t={t}
                                       from_date={from_date}
                   />

                    {!disabled ? (<IconedLink className="float-right" color={'red'} icon={'minus-square'}  onClick={onDelete}>
                        {t('event_reminder.delete')}
                    </IconedLink>) : null}
                </Col>
            </Row>
        </FormGroup>
        <EventFormDottedSpacer/>
        </>
    );
};

export default ReminderInput;