import PageFeedback from "../../molecules/PageFeedback";
import React from 'react';
import {CenteringRow, LightColumn} from "./style";
import LoginFormContainer from "../../molecules/LoginFormContainer/LoginFormContainer";
import UnlockForm from "./UnlockForm";


const UnlockFrame = (props) => {
    return (
        <LoginFormContainer links={[
            {to: 'need_password_reset', key: 'login.forgot_password?'},
            {to: 'signup', key: 'login.signup'}
        ]}>
            <UnlockForm {...props}/>
        </LoginFormContainer>
    );
}

const LoginUnlockPage = () => {
    return (
        <PageFeedback>
            <CenteringRow>
                <LightColumn>
                    <UnlockFrame/>
                </LightColumn>
            </CenteringRow>
        </PageFeedback>
    );
};

export default LoginUnlockPage;