import ChoirFetcher from "../../fetchers/ChoirFetcher";
import isNotFound from "../../../utils/isNotFound";
import NotFoundPage from "../NotFoundPage";
import WithChoir from "../../molecules/WithChoir";
import useTrans from "../../hooks/useTrans";
import React, {useContext} from "react";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import ChoirContext from "../../contexts/ChoirContext";
import Markdown from "markdown-to-jsx";
import {SubmitButton, WhiteBox} from "../../atoms/style";
import {Col, Row} from "reactstrap";
import withAttrs from "../../utils/withAttrs";
import getApi from "../../../services/getApi";

export const IcalBoxWrapper = withAttrs(WhiteBox, {
    className: "container-var01-custom",
    spacing: {px: 3, pt: 3, pb: 0, mb: 5}
});

const ChoirCalPage = (props) => {
    const {t, onReset} = props;
    const {choir, isManager} = useContext(ChoirContext);
    const url = window.location.origin + `/api/v1/choir/${choir.id}/ical/?token=${choir.ical_token}`;

    return (
        <PageWithSidebar title={t('choir_ical.page_title')} sidebar={<SideBar />} breadcrumbs={[{title: choir.name, to: ['choir_detail', {id: choir.id}]}]} >
            <IcalBoxWrapper>
                <Row>
                    <Col>
                        <p>
                            <Markdown>{t('choir_ical.help_text_md')}</Markdown>
                        </p>
                        <p style={{wordBreak: 'break-all'}}>
                            {t('choir_ical.ical_url')}
                            <br/>
                            <a href={url}>{url}</a>
                        </p>
                        {isManager ? (
                            <p>
                                <Markdown>{t('choir_ical.reset_help_text_md')}</Markdown>
                                <SubmitButton onClick={onReset}>{t('choir_ical.reset_token')}</SubmitButton>
                            </p>
                        ) : null}
                    </Col>
                </Row>
            </IcalBoxWrapper>
        </PageWithSidebar>
    );
};

export default (props) => {
    const {match: {params}} = props;
    const choir = ChoirFetcher.read({choir_id: params.id});
    const invalidator = ChoirFetcher.invalidator({choir_id: params.id});
    if (isNotFound(choir)) {
        return <NotFoundPage/>;
    }

    const onReset = () => {
        getApi("choirs").resetChoirIcal(choir.id).then(() => {
            invalidator()
        });
    };
    return (
        <WithChoir choir={choir}>
            <ChoirCalPage {...props} t={useTrans()} onReset={onReset} />
        </WithChoir>
    ) ;
};