import React from "react";

const useCounter = () => {
    const [count, setState] = React.useState(0);
    const incCounter = React.useCallback(
        (delta) => setState(curCount => curCount + delta),
        [setState]
    );
    return [count, incCounter];
}

export default useCounter;