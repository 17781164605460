import ChoirContext from "../contexts/ChoirContext";
import React from "react";

const WithChoir = ({choir, children}) => {
    return choir ? (
        <ChoirContext.Provider value={{choir, voices: choir.voices, isSinger: !!choir.scp_id, isManager: !!choir.ccp_id, canEdit: !!choir.ccp_id}}>
            {children}
        </ChoirContext.Provider>
    ) : children;
};

export default WithChoir;