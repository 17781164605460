import useTrans from "../../hooks/useTrans";
import {Div, ILink} from "../../atoms/style";
import React from "react";

const LoginFormContainer = ({children, links}) => {
    links = links || [{
        to: 'login',
        key: 'link.back_to_login'
    }];
    const t = useTrans();
    return (
        <div data-t={"form-container"}>
            {children}

            {links.map(({to, key}, idx) => (
                <Div key={idx} className="text-center" spacing={{pt: 4}}>
                    <ILink data-t={key} to={to} title="">{t(key)}</ILink>
                </Div>
            ))}

        </div>
    );
};

export default LoginFormContainer;