import {BigIcon, ILink, WhiteBox} from "../atoms/style";

import React from "react";
import withAttrs from "../utils/withAttrs";

const StyledLink = withAttrs(ILink, {
    className: "d-flex no-textdecoration-custom"
});


const IconedLink = ({icon, title, ...props}) => {
    return (
        <StyledLink {...props} spacing={{mb: 2}}>
            <div><BigIcon icon={icon} spacing={{mr: 2}}/></div>
            <div>{title}</div>
        </StyledLink>
    );
};

const DownloadBox = ({links, spacing}) => (
    <WhiteBox spacing={{px: 3, pt: 3, pb: 2, mb: 5, ...spacing}}>
        {links.map((props, idx) => <IconedLink key={idx} {...props}/>)}
    </WhiteBox>
);

export default DownloadBox;