import React, { useContext, useReducer } from 'react';
import {
  A,
  AvatarImg,
  CenterFlex,
  Icon,
  ILink,
  SquareImageContainer,
  WhiteBox,
} from '../../atoms/style';

import withAttrs from '../../utils/withAttrs';
import { Table } from 'reactstrap';
import { orderBy as ordered } from 'lodash/collection';
import ColdEventOptOutBox from '../EventOptOutBox/ColdEventOptOutBox';
import EventAttendanceBox from '../EventAttendanceBox/EventAttendanceBox';
import { updateCommitment } from './util';
import { DECISION_CODES } from '../../../constants';
import EventContext from '../../contexts/EventContext';
import { getAvatarSrc } from '../../atoms/misc';

export const StyledTable = withAttrs(Table, {
  className: 'table-var02-custom',
  spacing: { w: 100, mb: 0 },
  size: 'sm',
});

const THead = withAttrs('thead', {
  className: 'bg-gray-custom small text-uppercase text-nowrap',
});

const TH = withAttrs('th', {
  scope: 'col',
  className: ({ w }) => (w ? `w-${w}-custom` : undefined),
  spacing: { py: 1 },
});

const TD = withAttrs('td', {});

const OptOutTitle = withAttrs('div', {
  className: 'h5 text-uppercase',
  spacing: { w: 100, pt: 2, pb: 1, d: 'block', md: { d: 'none' } },
});

const UserTitle = withAttrs('div', { className: 'align-self-center small' });

const UserLink = (user) => {
  const { first_name, last_name, id, choir_id } = user;
  return (
    <ILink to={['choir_member', { choir_id, id }]} title="" className="d-flex">
      <SquareImageContainer size={'sm'} spacing={{ mr: 2 }}>
        <AvatarImg src={getAvatarSrc(user)} alt="" />
      </SquareImageContainer>
      <UserTitle>
        {first_name} {last_name}
      </UserTitle>
    </ILink>
  );
};

const VoiceFormWrapper = withAttrs(CenterFlex, {
  className: 'bg-gray-custom m-height-custom',
  spacing: { py: 1, pl: 2 },
});

const HiddenTitle = withAttrs('div', {
  className: 'h5 text-uppercase',
  spacing: { pt: 1, md: { d: 'none' } },
});

const calcSortIcon = (sortKey, orderBy, orderDirection) =>
  sortKey && sortKey === orderBy
    ? orderDirection > 0
      ? 'sort-down'
      : 'sort-up'
    : 'sort';

const SortIcon = ({ sortKey, orderBy, orderDirection }) => (
  <Icon
    icon={calcSortIcon(sortKey, orderBy, orderDirection)}
    spacing={{ mt: 1 }}
  />
);

export const dopes = [
  StyledTable,
  OptOutTitle,
  UserTitle,
  TD,
  TH,
  THead,
  VoiceFormWrapper,
  HiddenTitle,
  SortIcon,
];
// END DOPES

const EventCommitment = ({ event_id, scp_id, ...props }) => {
  const onSubmit = updateCommitment(event_id, scp_id);
  return <ColdEventOptOutBox inline={true} onSubmit={onSubmit} {...props} />;
};

const EventAttendanceCommitment = ({ event_id, scp_id, ...props }) => {
  return (
    <EventAttendanceBox
      inline={true}
      event_id={event_id}
      scp_id={scp_id}
      {...props}
    />
  );
};

const SingerRow = ({
  voice,
  event_id,
  choir_id,
  scp_id,
  canChange,
  commitment,
  attendance,
  user,
  t,
}) => {
  const decision =
    commitment && commitment.decision != null
      ? commitment.decision
      : DECISION_CODES.later;
  const attendanceCommitment =
    attendance !== null
      ? attendance.decision !== 0 || attendance.decision !== -1
        ? attendance.decision
        : -1
      : -1;
  const { isManager } = useContext(EventContext);
  return voice ? (
    <tr>
      <TD spacing={{ pl: 0, pr: 2 }}>
        <UserLink {...user} choir_id={choir_id} />
      </TD>
      <TD spacing={{ pr: 2 }} className="align-middle text-nowrap small">
        {voice ? t(`voice.name_${voice.abbr || 'MS'}`) : null}
      </TD>
      <TD spacing={{ md: { pr: 3, pb: 0 }, pb: 2 }} className="align-middle">
        <EventCommitment
          t={t}
          readOnly={!canChange}
          event_id={event_id}
          scp_id={scp_id}
          decision={decision}
        />
      </TD>

      {isManager === true ? (
        <TD spacing={{ md: { p: 0, m: 0 } }} className="align-middle">
          <EventAttendanceCommitment
            t={t}
            readOnly={!canChange}
            event_id={event_id}
            scp_id={scp_id}
            decision={
              decision === 1
                ? decision
                : attendanceCommitment !== -1
                ? attendanceCommitment
                : decision
            }
          />
        </TD>
      ) : (
        ''
      )}
    </tr>
  ) : null;
};

const OrderByKey = ({
  toggleSortBy,
  sortKey,
  orderBy,
  orderDirection,
  children,
}) => (
  <A onClick={() => toggleSortBy && toggleSortBy(sortKey)}>
    <SortIcon
      sortKey={sortKey}
      orderBy={orderBy}
      orderDirection={orderDirection}
    />{' '}
    {children}
  </A>
);

const DummyAttendanceBoxList = ({ commitments, t, ...props }) => {
  const { event, isManager } = useContext(EventContext);
  return (
    <WhiteBox spacing={{ px: 3, pt: 3, pb: 4, mb: 4 }}>
      <StyledTable>
        <THead>
          <tr>
            <TH w={35} spacing={{ pl: 2 }}>
              <OrderByKey sortKey={'user.full_name'} {...props}>
                {t('singer.name')}
              </OrderByKey>
            </TH>
            <TH>
              <OrderByKey sortKey={'voice.id'} {...props}>
                {t('singer.group')}
              </OrderByKey>
            </TH>
            <TH style={{ width: '270px' }}>
              <OrderByKey sortKey={'commitment.decision'} {...props}>
                {t('optout.commitment')}
              </OrderByKey>
            </TH>
            {isManager === true ?
                            <TH w={30}>
                                {t('optout.did_come')}
                            </TH> : ''}
          </tr>
        </THead>
        <tbody>
          {commitments.map((commitment, idx) => (
            <SingerRow
              key={idx}
              choir_id={event.choir_id}
              canChange={
                isManager ||
                (event.scp_id && event.scp_id === commitment.scp_id)
              }
              t={t}
              {...commitment}
            />
          ))}
        </tbody>
      </StyledTable>
    </WhiteBox>
  );
};

const sortCommitments = (commitments, config) => {
  const { orderBy, orderDirection } = config;
  const fields = [
    orderBy,
    orderBy === 'voice.id' ? 'commitment.decision' : 'voice.id',
  ];
  return ordered(commitments, fields, [
    orderDirection < 0 ? 'desc' : 'asc',
    'asc',
  ]);
};

const mergeCommitments = (commitments, attendances) => {
  commitments.map(
    (item, idx) => (item['attendance'] = attendances[idx]['commitment'])
  );

  return commitments;
};

export default function ({ commitments, attendances, ...props }) {
  const [orderByConfig, dispatch] = useReducer(
    (state, key) => {
      if (state.orderBy === key) {
        return {
          orderBy: key,
          orderDirection: state.orderDirection * -1,
        };
      } else {
        return {
          orderBy: key,
          orderDirection: 1,
        };
      }
    },
    { orderBy: null, orderDirection: 1 }
  );
  const sortedCommitments = sortCommitments(commitments, orderByConfig);
  const sortedAttendances = sortCommitments(attendances, orderByConfig);
  const mergedCommitments = mergeCommitments(
    sortedCommitments,
    sortedAttendances
  );

  return (
    <DummyAttendanceBoxList
      {...props}
      {...orderByConfig}
      toggleSortBy={dispatch}
      commitments={mergedCommitments}
    />
  );
}
