import {Form, Formik, useFormikContext} from 'formik';
import {SubmitButton} from "../../atoms/style";
import React from "react";
import {Col, Row} from "reactstrap";
import {CheckBoxColumn} from "./style";
import BootstrapField from "../BootstrapField";
import {DECISION_CODES} from "../../../constants";
import FormikCheckboxMultiSelect from "./FormikCheckboxMultiSelect";
import {eventFilterSchema, eventFilterSerializer} from "../../validation/eventFilter";


const TheForm = ({t, choirs, eventOptions}) => {

    const {isSubmitting} = useFormikContext();
    return (
        <Form>

            <Row>
                <Col md={{size: 4, order: 1}}>
                    <h5>{t('event.choir')}</h5>
                </Col>
                <CheckBoxColumn md={{size: 4, order: 4}}>
                    <FormikCheckboxMultiSelect t={t} options={choirs && choirs.length > 0 ? choirs.map(choir => ({title: choir.name, value: choir.id})) : []} name={'choir'}/>

                </CheckBoxColumn>

                <Col md={{size: 4, order: 2}}>
                    <h5>{t('event.type')}</h5>
                </Col>
                <CheckBoxColumn md={{size: 4, order: 5}}>
                    <FormikCheckboxMultiSelect t={t} name={'event_type'} 
                        options={eventOptions}
                    />
                </CheckBoxColumn>

                <Col md={{size: 4, order: 3}}>
                    <h5>{t('event.my_commitment')}</h5>
                </Col>
                <CheckBoxColumn md={{size: 4, order: 6}} >
                    <FormikCheckboxMultiSelect t={t} name={'commitment_type'} options={[
                        {title: t('optout.yes'), value: DECISION_CODES.yes},
                        {title: t('optout.no'), value: DECISION_CODES.no},
                        {title: t('optout.maybe'), value: DECISION_CODES.maybe},
                        {title: t('optout.later'), value: DECISION_CODES.later},
                    ]}/>
                </CheckBoxColumn>
            </Row>

            <Row>
                <Col>
                    <h5>{t('event.period')}</h5>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <BootstrapField name={'from_date'} type={'date'} label={<strong>{t('event.from')}:</strong>}/>
                </Col>
                <Col md={4}>
                    <BootstrapField name={'to_date'} type={'date'} label={<strong>{t('event.to')}:</strong>}/>
                </Col>
            </Row>

            <SubmitButton disabled={isSubmitting}>{t('event.filter')}</SubmitButton>

        </Form>
    );
}
const EventFilterForm = ({t, choirs, onSubmit, filter}) => {
    let eventTypes = {}
    const fixedSortedEvents = ["Concert","Rehearsal","Other"]
    choirs.map(choir => {
        choir.event_types.map(data_eventtype => {
            if (fixedSortedEvents.indexOf(data_eventtype.slug) === -1) {
                eventTypes[data_eventtype.slug]="";
            }
            return false
        });
        return false
    });
    let eventtypeKeys = Object.keys(eventTypes);
    // sort and insert fixed event types:
    eventtypeKeys.sort((a, b) => {return t(`event_type.${a}`).localeCompare(t(`event_type.${b}`))});
    eventtypeKeys.unshift(fixedSortedEvents[1])
    eventtypeKeys.unshift(fixedSortedEvents[0])
    eventtypeKeys.push(fixedSortedEvents[2])

    let eventOptions = []
    eventtypeKeys.map(eventtype => {
        eventOptions.push(
            {
                title: t(`event_type.${eventtype}`),
                value: eventtype
            }
        );
        return false
    });

    const initialValues = React.useMemo(() => filter ? eventFilterSchema(eventtypeKeys).cast(filter) : null, [filter, eventtypeKeys]);
    const onFormSubmit = React.useCallback((values, {setSubmitting}) => {
        setSubmitting(true);
        onSubmit(eventFilterSerializer(eventtypeKeys).cast(values));
        setSubmitting(false);
    }, [onSubmit, eventtypeKeys])

    return (
        <Formik validationSchema={eventFilterSchema(eventtypeKeys)} initialValues={initialValues} onSubmit={onFormSubmit}>
            <TheForm t={t} choirs={choirs} eventOptions={eventOptions}/>
        </Formik>
    );
}

export default EventFilterForm;