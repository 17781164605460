import React, {createContext} from "react";
import ChoirListFetcher from "../components/fetchers/ChoirListFetcher";
import isApiError from "../utils/isApiError";

const UserChoirsContext = createContext([]);

export const withUserChoirs = (Component) => (props) => {
  const choirs = ChoirListFetcher.read();
  if (isApiError(choirs)) {
      return null;
  }
  return (
      <UserChoirsContext.Provider value={choirs || []}>
          <Component {...props}/>
      </UserChoirsContext.Provider>
  );
};

export default UserChoirsContext;