import {Button, Col, Collapse, FormGroup} from "reactstrap";
import withAttrs from "../../utils/withAttrs";
import React from 'react';
import classNames from 'classnames';
import {A, Arrow} from '../../atoms/style';

export const FormSubmitCol = withAttrs(Col, {
    md: 4,
    spacing: {mb: 4, md: {mb: 0}}
});

export const EventFormSpacer = withAttrs("div", {
    className: "spacer-custom-var01",
    spacing: {mb: 2}
});

export const EventFormDottedSpacer = withAttrs("div", {
    className: "spacer-custom-var03",
    style: {clear: 'both'},
    spacing: {my: 3}
});

export const StyledCollapse = withAttrs(Collapse, {
    spacing: {py: 2, mb: 2}
});

export const StyledFlexLink = withAttrs(A, {
    className: "align-items-center link-collapse-custom no-textdecoration-custom",
    spacing: {d: 'flex', py: 2},
});

export const EventFormGreyBox = withAttrs('div', {
    className: "small bg-gray-custom",
    spacing: {p: 3, mb: 3}
});

export const StyledCollapseLink = ({isOpen, className, children, ...props}) => (
    <StyledFlexLink className={classNames(className, {"collapsed": isOpen})} {...props}>
        <div className="mr-auto"><strong>{children}</strong></div>
        <Arrow isOpen={isOpen}/>
    </StyledFlexLink>
);

export const ReminderLegend = ({children}) => (
    <legend className="col-form-label col-md-12 mb-3"><strong>{children}</strong></legend>
);

export const ReminderFormGroup = withAttrs(FormGroup, {
    row: true,
    className: "align-items-center",
    spacing: {mb: 3}
});

export const GreyButton = withAttrs(Button, {
    className: "btn-gray-custom",
    spacing: {my: 2}
});