import {Form, Formik} from "formik";
import React from "react";
import {H2, SubmitButton} from "../../atoms/style";
import useTrans from "../../hooks/useTrans";
import {object, string} from "yup";
import Loading from "../../atoms/Loading";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {oktaAuth} from "../../../services/okta";
import goTo from "../../utils/goTo";
import ToastContext from "../../contexts/ToastContext";
import usePromptPromise from "../../hooks/usePromptPromise";
import {TextInputForm} from "../../molecules/TextInputForm/TextInputForm";

const unlockSchema = object({
    username: string().required('validation.this_field_is_required'),
    // factorType: string().required('validation.this_field_is_required').oneOf(["SMS", "EMAIL"]),
});


const UnlockForm = () => {
    const t = useTrans();
    const toaster = React.useContext(ToastContext);
    const promptPromise = usePromptPromise();

    const promptField = React.useCallback((msg) => {
        return promptPromise({
            component: TextInputForm,
            props: {msg}
        });
    }, [promptPromise]);

    const onSubmit = React.useCallback((values, {setSubmitting, setStatus}) => {
        setStatus(null);
        setSubmitting(true);

        oktaAuth.unlockAccount(values).then((transaction) => {
            return promptField("prompt.passcode").then(passCode => {
                return transaction.verify({
                    passCode
                });
            })
        }).then((transaction) => {
            if (transaction.status === 'SUCCESS') {
                console.log(transaction);
                toaster.onPush({msg: t('success.account_unlocked'), type: 'success'});
                goTo('login');
            } else {
                throw new Error('We cannot handle the ' + transaction.status + ' status');
            }
        })
            .catch((err) => {
                console.error(err);
            });
    }, [t, promptField, toaster]);

    const renderForm = ({isSubmitting, status}) => isSubmitting ? (
        <Loading/>
    ) : (
        <Form>
            <H2 className="text-center" spacing={{my: 2, mb: 4}}>{t('unlock_form.title')}</H2>
            <FormStatus/>
            <BootstrapField name={'username'}
                            label={<strong>{t('unlock_form.email')}</strong>}
                            required={true}/>
            <SubmitButton data-t={'button.login'} disabled={isSubmitting}>{t('unlock_form.unlock')}</SubmitButton>
        </Form>
    );

    return (
        <Formik validationSchema={unlockSchema}
                onSubmit={onSubmit}
                initialValues={{username: "", factorType: "SMS"}}>
            {renderForm}
        </Formik>
    );
};

export default UnlockForm;