import React, {Suspense} from "react";
import useTrans from "../../hooks/useTrans";
import ColdEventOptOutBox from "./ColdEventOptOutBox";
import OptOutDecisionFetcher from '../../fetchers/OptOutDecisionFetcher';
import * as PropTypes from "prop-types";
import Loading from "../../atoms/Loading";
import {updateCommitment} from "../AttendanceBox/util";
import {DECISION_CODES} from "../../../constants";

function LiveEventOptOutBox(props) {
    const {event_id, scp_id} = props;
    const decisionObj = OptOutDecisionFetcher.read({event_id, scp_id});
    const onSubmit = updateCommitment(event_id, scp_id);
    const decision = (decisionObj && decisionObj.commitment && decisionObj.commitment.decision != null) ? decisionObj.commitment.decision : DECISION_CODES.later;
    return (
        <ColdEventOptOutBox t={useTrans()} onSubmit={onSubmit} decision={decision} {...props}/>
    );
}
LiveEventOptOutBox.propTypes = {
    event_id: PropTypes.number.isRequired,
    scp_id: PropTypes.number.isRequired,
};

export default (props) => props.scp_id && props.event_id ? (
    <Suspense fallback={<Loading/>}>
        <LiveEventOptOutBox {...props}/>
    </Suspense>
) : null;