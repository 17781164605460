import React from "react";
import {CenterFlex, Icon, SrOnly} from "../../atoms/style";
import YesNoMaybe from "../YesNoMaybe";
import {COLOR_MAP, DECISION_MAP} from "./constants";
import {
    BlackOptOutTitle,
    ColoredDiv,
    DecisionIcon,
    OptOutDecisionWrapper,
    OptOutFormWrapper,
    OptOutTitle,
    StyledA,
    WhiteOptOutTitle
} from './style'
import * as PropTypes from "prop-types";
import useOnOff from "../../hooks/useOnOff";
import {DECISION_CODES} from "../../../constants";

const OptOutForm = (props) => (
    <OptOutFormWrapper>
        <WhiteOptOutTitle>{props.t('optout.accept?')}</WhiteOptOutTitle>
        <YesNoMaybe {...props} />
    </OptOutFormWrapper>
);


const InlineOptOutForm = (props) => {
    const {t, decision} = props;
    return (
        <React.Fragment>
            <OptOutTitle inline>{t('optout.accept?')}</OptOutTitle>
            <CenterFlex>
                <DecisionIcon decision={decision}/>
                <YesNoMaybe size={'sm'} {...props}/>
            </CenterFlex>
        </React.Fragment>
    );
};

const EditLink = ({t, inline, ...props}) => {
    return (
        <StyledA data-t={'event.edit_commitment'} inline={inline} {...props}>
            <Icon icon="pen-square"/>
            <SrOnly>{t('optout.change')}</SrOnly>
        </StyledA>
    );
};

const InlineOptOutDecision = ({decision, eventId, onEdit, readOnly, t}) => {
    const decisionKey = DECISION_MAP[decision];
    return (
        <React.Fragment>
            <OptOutTitle inline>{t('optout.accept?')}</OptOutTitle>
            <CenterFlex data-t={"optout.decision"}>
                <DecisionIcon decision={decision}/>
                <div className="small" data-t={decisionKey}><strong>{t(decisionKey)}</strong></div>
                {readOnly ? null : <EditLink t={t} onClick={onEdit} inline/>}
            </CenterFlex>
        </React.Fragment>
    );
};

const OptOutDecision = ({decision, eventId, onEdit, readOnly, t}) => {
    const color = COLOR_MAP[decision];
    const decisionKey = DECISION_MAP[decision];

    return (
        <OptOutDecisionWrapper data-t={"optout.decision"}>
            {readOnly ? null : <EditLink t={t} onClick={onEdit}/>}
            <BlackOptOutTitle>{t('optout.accept?')}</BlackOptOutTitle>
            <ColoredDiv color={color}>
                <Icon icon="user"/>
            </ColoredDiv>
            <div><strong>{t(decisionKey)}</strong></div>
        </OptOutDecisionWrapper>
    );
};

function ColdEventOptOutBox({inline, formComponent, displayComponent, onSubmit, ...props}) {
    const [isLoading, setLoading, setLoaded] = useOnOff(false);
    const wrappedOnSubmit = (...args) => {
        setLoading();
        onSubmit(...args).finally(()=> {
            setLoaded();
        });
    };

    const {decision, readOnly} = props;
    const FormComponent = formComponent || (inline === true ? InlineOptOutForm : OptOutForm);
    const DisplayComponent = displayComponent || (inline === true ? InlineOptOutDecision : OptOutDecision);
    if (!readOnly && (decision === DECISION_CODES.later)) {
        return <FormComponent onSubmit={wrappedOnSubmit} isLoading={isLoading} {...props}/>
    } else {
        return <DisplayComponent onEdit={() => wrappedOnSubmit(DECISION_CODES.later)} isLoading={isLoading} {...props}/>;
    }
}

ColdEventOptOutBox.propTypes = {
    decision: PropTypes.number,
    onEdit: PropTypes.func,
    onSubmit: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default ColdEventOptOutBox;