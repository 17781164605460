import React from "react";
import {Div, GreenButton, WhiteBox} from "../../atoms/style";
import EnrollFactor from "./EnrollFactor";
import VerifyFactor from "./VerifyFactor";
import useTrans from "../../hooks/useTrans";
import Markdown from "markdown-to-jsx";
import FactorContext from "./FactorContext";
import {NOT_SETUP} from "./constants";
import factorId from "./factorId";

const FactorDetail = () => {
    const t = useTrans();
    const {factorStatus, enrollFactor, factor} = React.useContext(FactorContext);
    const status = factorStatus?.status || NOT_SETUP;
    return (
        <WhiteBox spacing={{px: 3, pt: 3, pb: 4, mb: 4}}>
            <Div spacing={{pb: 3}}>
                <h5>{t(`factor.title.${factorId(factor)}`)} - {t(`factor.status.${status}`)}</h5>
                <Markdown>{t(`factor.description_md.${factor.factorType.replace(/:/g, "_")}_${factor.provider}`)}</Markdown>
            </Div>
            {factorStatus == null ? (
                <GreenButton onClick={enrollFactor}>{t('factor.enroll')}</GreenButton>
            ) : null}

            <EnrollFactor/>
            <VerifyFactor/>
        </WhiteBox>
    )
}

export default FactorDetail;