import styled from "styled-components";
import {Link, NavLink as RRNavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {minWidth} from "../utils/media";
import {Button, Col, NavItem, NavLink, Row} from "reactstrap";
import classNames from "classnames";
import withAttrs from "../utils/withAttrs";
import {DECISION_CODES} from "../../constants";
import withDI from "../utils/withDI";
import placeholderUserPath from "../../img/pic_placeholder_user.jpg"
import placeholderLogoPath from "../../img/pic_placeholder_logo.jpg";
import placeholderChoirPath from "../../img/pic_placeholder_choir2.jpg";
import useScrollDistance from "../hooks/useScrollDistance";
import {getAvatarSrc} from "./misc";
import useDimensions from "react-cool-dimensions";
import DownloadLink from "./DownloadLink";

export const H1 = withAttrs('h1', {});
export const H2 = withAttrs('h2', {});
export const H3 = withAttrs('h3', {});
export const H4 = withAttrs('h4', {});
export const H5 = withAttrs('h5', {});
export const Div = withAttrs('div', {});
export const A = withAttrs('a', {
    className: 'pointer-custom'
});
export const Span = withAttrs('span', {});
export const P = withAttrs('p', {});
export const SmallUpper = withAttrs('span', {
    className: "small text-uppercase"
});
export const SrOnly = withAttrs('span', {
    className: "sr-only"
});
export const WrappedRow = withAttrs(Row, {});
export const WrappedCol = withAttrs(Col, {});

function reverseIfNeeded(to, reverse){
    if (!reverse) {
        throw new Error(`reverse is not defined when reversing ${to}`);
    }
    if (Array.isArray(to)) {
        return reverse(to[0], to[1]);
    } else if (to.startsWith("/") || to.startsWith("http://") || to.startsWith("https://")) {
        return to;
    } else {
        return reverse(to);
    }
}

const PureILink = ({to, reverse, href, url, ...props}) => {
    to = to ? reverseIfNeeded(to, reverse) : to;
    if (to && to.startsWith("http")) {
        return <A href={to} {...props}/>;
    }
    if (to) {
        return <Link to={to} {...props}/>;
    }
    if (url) {
        return <DownloadLink url={url} {...props}/>;
    }
    return <A href={href} {...props}/>;
};

export const ILink = withDI(PureILink, ['reverse']);

const PureNavLink = ({to, href, reverse, ...props}) => {
    to = to ? reverseIfNeeded(to, reverse) : to;
    if (to && to.startsWith("http")) {
        return <NavLink href={to} {...props}/>
    }
    return to ? <RRNavLink to={to} {...props} /> : <NavLink href={href} {...props}/>;
};
export const INavLink = withDI(PureNavLink, ['reverse']);

export const IButton = ({to, ...props}) => {
    return (to ? <ILink to={to}><Button {...props}/></ILink> : <Button {...props}/>)
};


export const NavItemUpcase = (props) => <NavItem><NavLinkUpcase {...props} /></NavItem>;
export const NavLinkUpcase = withAttrs(INavLink, {
    className: "text-uppercase nav-link"
});

export const Icon = withAttrs(FontAwesomeIcon, {
    "aria-hidden": true,
    "icon": 'user'
});

export const BigIcon = withAttrs(Icon, {
    size: 'lg',
    className: 'fa-width-custom'
});

export const SideBarWrapper = ({children}) => (
    <Col sm={7} md={6} lg={3} className="col-9 mx-auto py-5 py-lg-0 sidebar-custom">
        <Row>
            {children}
        </Row>
    </Col>
);

export const ButtonMd = styled(IButton)`
padding: .95em .925em;
`;
export const ButtonXl = styled(IButton)`
padding: .95em .925em;
`;
export const ButtonSm = styled(IButton)`
padding: .2em .425em;
font-size: 0.785rem;
font-weight: 700;
`;

export const SubmitButton = withAttrs(ButtonMd, {
    type: "submit",
    "data-t": 'button.submit',
    className: "btn-green-custom",
    spacing: {w: 100, mt: 2}
});

export const RedSubmitButton = withAttrs(ButtonMd, {
    type: "submit",
    className: "btn-red-custom",
    spacing: {w: 100, mt: 2}
});

export const GreySubmitButton = withAttrs(ButtonMd, {
    type: "button",
    className: "btn-gray-custom",
    spacing: {w: 100, mt: 2}
});

export const LogoutLink = styled(ILink).attrs({
    className: 'btn text-uppercase font-weight-normal minified-transition-custom',
})`
    background-color: #f0582e;
    color: #fff !important;
    border-radius: 0;
    padding: 0.275em 0.65em;
    margin-left: .5rem;

    &:hover {
        color: #fff;
        transform: scale(1.0555);
    }

    ${minWidth.tablet`font-size: .75rem;`}
    ${minWidth.xs_desktop`font-size: .825rem;`}
`;

export const EventBoxFlex = ({leftComponent, rightComponent}) => (
    <div className="d-md-flex align-items-stretch">
        <div className="mr-auto mw-70-custom mt-1">
            {leftComponent}
        </div>

        <div className="mw-30-custom mt-1">
            {rightComponent}
        </div>
    </div>
);

export const GreenButton = withAttrs(Button, {
    className: props => classNames("btn-green-custom", props.size ? `btn-${props.size}-custom` : null),
});

export const GrayButton = withAttrs(Button, {
    className: props => classNames("btn-gray-custom", props.size ? `btn-${props.size}-custom` : null),
});

export const YellowButton = withAttrs(Button, {
    className: props => classNames("btn-yellow-custom", props.size ? `btn-${props.size}-custom` : null),
});

export const RedButton = withAttrs(Button, {
    className: props => classNames("btn-red-custom", props.size ? `btn-${props.size}-custom` : null),
});


export const PipeBox = ({name, value}) => (
    <div>{name} <span className="text-nowrap d-block d-md-inline mr-auto mr-sm-0"><span className="d-none d-md-inline">|</span> {value}</span></div>
);

export const BorderLight = styled.div.attrs({className: "border"})`
    border-color: #dfdfdf !important;
    border-radius: 0 !important;
`;

export const BorderDark = withAttrs('div', {
    className: "border border-dark-custom"
});

export const ImageContainer = styled(BorderLight)`
    overflow: hidden;
    position: relative;
    
    & img {
        position: absolute;
        left: -50%;
        right: -50%;
        top: -50%;
        bottom: -50%;
        height: 100%;
        width: auto;
        margin: auto;
    }
`;

const squareImageSizing = ({size}) => {
    if (size === 'sm') {
        return '3.625rem';
    } else if (size === 'lg') {
        return '12.75rem';
    } else {
        return '7rem';
    }
};

const rectangleImageSizing = ({size}) => {
    if (size === 'sm') {
        return '5.8rem';
    } else if (size === 'lg') {
        return '20.4rem';
    } else {
        return '11.2rem';
    }
};

export const SquareImageContainer = withAttrs(styled(ImageContainer)`
    width: ${squareImageSizing};
    height: ${squareImageSizing};
    min-width: ${squareImageSizing};
    min-height: ${squareImageSizing};
`, {});


export const RectangleImageContainer = withAttrs(styled(ImageContainer)`
    width: ${rectangleImageSizing};
    height: ${squareImageSizing};
    min-width: ${rectangleImageSizing};
    min-height: ${squareImageSizing};
`, {});

export const Arrow = ({isOpen = false, title = '', ...props}) => (
    <div>
        <GreenButton className="small" title={title} type={'button'} {...props}>
            <Icon icon={classNames({
                "angle-down": isOpen,
                "angle-right": !isOpen
            })} size="lg" aria-hidden="true" />
        </GreenButton>
    </div>
);

export const WhiteBox = withAttrs('div', {
    className: "bg-white border border-light-custom"
});

export const MessageBox = withAttrs(WhiteBox, {
    spacing: {p: 2},
    "data-t": "msg"
});

export const NoItemsFound = ({children}) => (
    <MessageBox data-t={"msg.no_items"}>
        <IconedNoteBox spacing={{mb: 0}}>{children}</IconedNoteBox>
    </MessageBox>
);

export const GreyBox = withAttrs('div', {
    className: "bg-darkgray-custom text-white"
});

export const Spacer = withAttrs('div', {
    className: "spacer-custom-var02 mt-5 mb-3"
});

export const IconedText = ({children, icon}) => (
    <div className="d-flex mb-2">
        <div className="text-blue-custom mb-0 mr-2 align-self-center">
            <BigIcon icon={icon}/>
        </div>
        <div className="small align-self-center">
            {children}
        </div>
    </div>
);

export const FlexLink = withAttrs(ILink, {
    className: "no-textdecoration-custom",
    spacing: {d: 'flex'}
});

export const IconedLink = ({children, color, icon, ...props}) => (
    <FlexLink spacing={{mb: 3}} {...props}>
        <div>
            <BigIcon icon={icon} color={color} spacing={{mr: 2}}/>
        </div>
        <div>{children}</div>
    </FlexLink>
);

export const SidebarBoxWrapper = withAttrs(WhiteBox, {
    className: "text-center",
    style: {maxWidth: 300, minWidth: 200},
    spacing: {p: 3}
});

const EventFilterLink = withAttrs(WhiteBox, {
    className: classNames(
        "link-container-custom",
        "justify-content-between",
        "align-items-center",
        "filter-collapse-custom",
        "no-textdecoration-custom",
    ),
    spacing: {pl: 3, pr: 2, py: 2, d: 'flex'}
});

const CollapsableEventFilterLink = ({collapsed, className, ...props}) => (
    <EventFilterLink className={`${className} ${collapsed ? "collapsed" : ''}`} {...props}/>
);

export const EventFilterButton = ({children, arrowTitle, isOpen, ...props}) => (
    <CollapsableEventFilterLink collapsed={!isOpen} {...props}>
        <div>{children}</div>
        <Arrow isOpen={isOpen} title={arrowTitle} />
    </CollapsableEventFilterLink>
);

export const ContainerLink = withAttrs(ILink, {
    className: "link-container-custom transition-custom",
    spacing: {pb: 2, d: 'block'}
});

export const StyledWidgetLink = withAttrs(ILink, {
    className: "text-left text-uppercase small no-textdecoration-custom",
    spacing: {mb: 2, d: 'flex'}
});

export const BreadcrumbLink = withAttrs(ILink, {className: "text-uppercase no-textdecoration-custom", spacing: {d: 'inline-block', pt: 3, md: {pt: 0}}});

export const PageEditButton = withAttrs(ILink, {
    className: "btn btn-xl-custom btn-green-custom",
    style: {color: 'white'},
    spacing: {mt: 0, w: '100'}
});

export const BigCreateButton = ({to, id, children, dataT, ...props}) => (
    <Row id={id} >
        <Col md={5}>
            <PageEditButton data-t={dataT ? dataT : "button.create"} spacing={{mb: 3}} to={to} {...props}>{children}</PageEditButton>
        </Col>
    </Row>
);

export const BigEditButton = withAttrs(BigCreateButton, {
    dataT: "button.edit"
});

export const Flex = withAttrs('div', {
    spacing: {d: 'flex'}
});

export const CenterFlex = withAttrs(Flex, {
    className: "align-items-center"
});

export const GreyFlex = withAttrs(Flex, {
    className: "justify-content-between bg-darkgray-custom text-white",
});

export const GreyHeader = withAttrs(GreyFlex, {
    spacing: {py: 2, px: 3}
});

export const VoiceMemberIcon = ({decision, className, ...props}) => (
    <Icon icon={'user'} className={classNames('pointer-custom', className, {
        "text-gray-custom": (decision === DECISION_CODES.later),
        "text-red-custom": decision === DECISION_CODES.no,
        "text-yellow-custom": decision === DECISION_CODES.maybe,
        "text-green-custom": decision === DECISION_CODES.yes
    })} {...props}/>
);

export const HeaderWrapper = ({children, minified}) => {
    const scrolled = useScrollDistance(50);
    const { ref, height } = useDimensions();
    return (
        <>
            <header className={classNames('fixed-top', {"minified-custom": scrolled})}>
                <div ref={ref} className="wrapper-custom">
                    {children}
                </div>
            </header>
            <div style={{width: '100%', height: Math.max(height, 145)}} />
        </>
    );
};

export const GreenBox = withAttrs('div', {
    className: "bg-green-custom-light",
    spacing: {my: 4, p: 3}
});

export const YellowBox = withAttrs('div', {
    className: "bg-yellow-custom-light",
    spacing: {mt: 4, px: 3, pt: 3, pb: 2}
});

const BorderBox = withAttrs(WhiteBox, {
    className: "container-var04-custom",
    spacing: {px: 3, pt: 3, pb: 3, mb: 4}
});

export const HeaderBox = ({header, children}) => (
    <>
        <GreyHeader>
            <H5 spacing={{my: 1}}>{header}</H5>
        </GreyHeader>
        <BorderBox style={{wordBreak: 'break-all'}}>
            {children}
        </BorderBox>
    </>
);

export const HelpText = withAttrs("span", {
    className: "form-text bg-gray-custom text-primary",
    spacing: {p: 3, mt: 2, mb: 3}
});

export const PlaceholderImg = ({src, defaultSrc, ...props}) => {
    const empty = !!!src;
    src = src || defaultSrc;
    return (
        <img alt={""} src={src} data-empty={empty} {...props} />
    );
};

export const AvatarImg = withAttrs(PlaceholderImg, {
    "data-t": "img.avatar",
    defaultSrc: placeholderUserPath
});

export const ChoirLogoImg = withAttrs(PlaceholderImg, {
    "data-t": "img.logo",
    defaultSrc: placeholderLogoPath
});

export const ChoirPictureImg = withAttrs(PlaceholderImg, {
    "data-t": "img.picture",
    defaultSrc: placeholderChoirPath
});

export const SmallPicture = ({src, imgTag}) => {
    const ImgTag = imgTag || AvatarImg;
    return (
        <SquareImageContainer spacing={{mr: 2, mb: 2}} size={'sm'}>
            <ImgTag src={src} alt="" width={'3.625rem'} height={'3.625rem'}/>
        </SquareImageContainer>
    );
};

export const SmallAvatar = ({src}) => (
    <SmallPicture src={getAvatarSrc(src)} imgTag={AvatarImg}/>
);

export const Avatar = ({src}) => {
    src = getAvatarSrc(src);
    return (
        <SquareImageContainer spacing={{mx: 'auto', mb: 3}}>
            <AvatarImg src={src} alt={'Avatar'}/>
        </SquareImageContainer>
    );
};

export const SmallLogo = ({src}) => (
    <SmallPicture src={src} imgTag={ChoirLogoImg}/>
);

export const SmallLandscapePicture = ({src}) => (
    <RectangleImageContainer spacing={{mr: 2, mb: 2}} size={'sm'}>
        <ChoirPictureImg src={src} />
    </RectangleImageContainer>
);

export const EventFormGreyBox = withAttrs('div', {
    className: "small bg-gray-custom",
    spacing: {p: 3, mb: 3}
});

export const EventFormSpacer = withAttrs("div", {
    className: "spacer-custom-var01",
});

export const EventFormDottedSpacer = withAttrs("div", {
    className: "spacer-custom-var03",
    style: {clear: 'both'},
    spacing: {my: 3}
});


export const FlexRow = ({children, justify = 'start'}) => (
    <div className={`d-flex justify-content-${justify}`}>
        {children}
    </div>
);


export const NoteBox = withAttrs(Div, {
    className: "bg-yellow-custom-light small",
    spacing: {p: 3, mb: 4}
});

export const IconedNoteBox = ({icon = 'bell', children, ...props}) => (
    <NoteBox {...props}>
        <BigIcon icon={icon} spacing={{mr: 2}}/>
        {children}
    </NoteBox>
);

export const CheckIcon = () => (
    <div className = "h1 text-center mb-3">
        <Icon icon="check-circle" className="text-green-custom"/>
    </div>
);

export const FailureIcon = () => (
    <div className = "h1 text-center mb-3">
        <Icon icon="exclamation-triangle" className="text-red-custom"/>
    </div>
);