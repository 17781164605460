import React, {Suspense} from 'react';
import useTrans from "../../hooks/useTrans";
import Markdown from "markdown-to-jsx";
import Loading from "../../atoms/Loading";
import isAppError from "../../../utils/isAppError";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import CcEmailConfirmationFetcher from "../../fetchers/CcEmailConfirmationFetcher";


const CcEmailConfirmation = ({t, token}) => {
    const confirmationResponse = CcEmailConfirmationFetcher.read({token});
    
    if (isAppError(confirmationResponse)) {
        return (
            <SimpleFeedback success={false} title={t('cc_email_change_confirmation.request_failure')}>
                <Markdown data-t={"failure-note"}>{t('cc_email_change_confirmation.confirmation_failure_description')}</Markdown>
            </SimpleFeedback>
        )
    }
    return (
        <SimpleFeedback  title={t('cc_email_change_confirmation.request_success')}>
            <Markdown data-t={"success-note"}>{t('cc_email_change_confirmation.cc_email_confirmation_success')}</Markdown>
        </SimpleFeedback>
    );
};


export default ({match: {params}}) => {
    const t = useTrans();
    return (
        <div data-t={"page.email_change_confirm"}>
        <Suspense fallback={<SimpleFeedback noIcon title={t('email_change_confirmation.confirm_form_title')}><Loading/></SimpleFeedback>}>
            <CcEmailConfirmation t={t} token={params.token}/>
        </Suspense>
        </div>
    );
}