import React, {useContext} from "react";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormDottedSpacer, IconedLink} from "../../atoms/style";
import {Field} from "formik";
import SingerSelect from "../../molecules/SingerSelect";
import ChoirContext from "../../contexts/ChoirContext";

const EmailDigestInput = ({t, index, name, onDelete}) => {
    const {choir} = useContext(ChoirContext);
    return (
        <Field name={name}>{() => {
            return (
                <div>
                    <BootstrapField name={`${name}.recipients`}
                                    label={t('choir_form.email_digest_recipients_label')}
                                    required={true}
                                    input={SingerSelect}
                                    voices={choir.voices}
                                    singers={choir.singers}
                                    managers={choir.managers}
                                    hideEmpty userSelect
                    />
                    <BootstrapField name={`${name}.interval`}
                                    label={t('choir_form.email_digest_interval_label')}
                                    help_text={t('choir_form.email_digest_interval_help_text')}
                                    required={true} type={'number'}/>

                    <BootstrapField name={`${name}.message`}
                                    label={t('choir_form.email_digest_message_label')}
                                    help_text={t('choir_form.email_digest_message_help_text')}
                                    type={'textarea'}
                                    rows={5}/>

                    <BootstrapField name={`${name}.signup_changes`}
                                    label={t('choir_form.email_digest_signup_changes_label')}
                                    help_text={t('choir_form.email_digest_signup_changes_help_text')}
                                    type={'checkbox'}/>

                    <IconedLink className={"float-right"} color={"red"} icon={'minus-square'} onClick={onDelete}>
                        {t('email_digest.delete')}
                    </IconedLink>
                    <EventFormDottedSpacer/>
                </div>
            );
        }}
        </Field>
    );
};

export default EmailDigestInput;