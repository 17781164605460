import React from "react";
import BootstrapField from "../BootstrapField";

const OpenQuestion = (props) => {
    const {idx, text} = props;
    const name = `open_question_answers.${idx}.answer`;
    return (
        <BootstrapField type={'text'} name={name} label={<strong>{text}</strong>}/>
    );
};

export default OpenQuestion;