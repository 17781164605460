import theI18n from 'i18next';
import Backend from 'i18next-locize-backend';
import locizeEditor from 'locize-editor';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import locizeLastUsed from "locize-lastused";

import moment from "moment";
import "moment/locale/de";
import "moment/locale/en-ca";

const PROJECT_ID = 'db9a48e2-37de-4305-8b9a-f43e91f6defb';
const API_KEY = 'e97e2dfc-95ff-42bd-90ff-ce4e1634cf92';

const locizeOptions = {
    // loadPath: '/locales/{{lng}}/{{ns}}.json',
    projectId: PROJECT_ID,
    apiKey: API_KEY,
    referenceLng: 'de',
    // private: true,
    allowedAddOrUpdateHosts: ['localhost', 'd3bnfvb3vjdrq9.cloudfront.net', 'app-staging.choirmanager.com', 'app.choirmanager.com']
};

const i18n = theI18n.createInstance();

i18n
    .use(Backend)
    .use(locizeEditor)
    .use(locizeLastUsed)
    .use(LanguageDetector)
    .use(initReactI18next)
    .on('languageChanged', function(lng) {
        console.info(`Language changed to ${lng}`);
        moment.locale(lng);
    })
    .init({
        locizeLastUsed: locizeOptions,
        backend: locizeOptions,
        editor: {
            defaultNS: "default",
            projectId: PROJECT_ID,
        },
        fallbackLng: 'de',
        language: 'de',
        whitelist: ['en', 'de', 'dev'],
        ns: ['default'],
        defaultNS: 'default',
        // keySeparator: true,

        debug: true,
        cache: false,
        saveMissing: true,
        saveMissingTo: 'all',

        interpolation: {
            escapeValue: false,
        },

        react: {
            wait: true
        }
    }, (error, t) => {
        if(error)
            console.error(error);
    });


export default i18n;