import React from "react";
import {Input} from "reactstrap";
import {connect} from "formik";
import TimeField from "./TimeField";

const TimeInputComponent = connect(({formik: {setFieldValue}, onChange, name, ...props}) => (
    <TimeField showSeconds={false} colon={':'} input={<Input name={name} />} {...props}
               onChange={(event, value) => {
                   setFieldValue(name, value);
               }}/>
));

const TimeInput = ({type, ...props}) => (
    <div className={'input-time-placeholder-custom'}>
        <TimeInputComponent type={'text'} className={"form-control"} {...props} />
    </div>
);

export default TimeInput;