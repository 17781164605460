import {Form, Formik} from "formik";
import React from "react";
import {object} from "yup";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {H2, SubmitButton} from "../../atoms/style";
import useTrans from "../../hooks/useTrans";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import {passwordSchema} from "../../validation/passwordSchema";

const passwordResetSchema = object({
    new_password: passwordSchema,
});

const onSubmit = ({t, uid, token, onSuccess}) => (values, {setSubmitting, setErrors, setStatus}) => {
    const api = getApi("user");
    const promise = api.resetPasswordConfirm({...values, uid, token});

    promise.then((choir)=>{
        setSubmitting(false);
        onSuccess();
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};

const TheForm = ({t, formProps: {isSubmitting, status}}) => (
    <Form>
        <H2 className="text-center" spacing={{my: 2, mb: 4}}>{t('password_reset.confirm_form_title')}</H2>
        <FormStatus status={status}/>

        <BootstrapField name={'new_password'}
                        type={'password'}
                        placeholder={t('password_reset.new_password')}
                        required={true}/>
        <SubmitButton disabled={isSubmitting}>{t('password_reset.change_password')}</SubmitButton>
    </Form>
);


export default (props) => {
    const t = useTrans();
    return (
        <Formik validationSchema={passwordResetSchema} initialValues={{}} onSubmit={onSubmit({t, ...props})}>
            {(formProps) => (
                <TheForm t={t} formProps={formProps}/>
            )}
        </Formik>
    );
};