import {A, BorderDark, Icon} from "../../atoms/style";
import withAttrs from "../../utils/withAttrs";
import {COLOR_MAP} from "./constants";

export const OptOutFormWrapper = withAttrs('div', {
    className: "box-cta-custom bg-darkgray-custom text-center position-relative",
    spacing: {p: 2, pb: 4, h: 100}
});

export const OptOutTitle = withAttrs('div', {
    className: "h5 text-uppercase",
    spacing: ({inline}) => inline ? {d: 'block', md: {d: 'none'}, w: 100, pt: 2, pb: 1} : {}
});

export const WhiteOptOutTitle = withAttrs(OptOutTitle, {
    className: "text-white",
    spacing: {mt: 2, mb: 3}
});

export const BlackOptOutTitle = withAttrs(OptOutTitle, {
    spacing: {mt: 2, pt: 1}
});

export const DecisionIcon = withAttrs(Icon, {
    icon: 'user',
    size: 'lg',
    className: ({decision}) => `text-${COLOR_MAP[decision] || 'gray'}-custom`,
    spacing: {mr: 3}
});

export const OptOutDecisionWrapper = withAttrs(BorderDark, {
    className: "position-relative text-center",
    spacing: {pb: 2, h: 100, clearfix: true}
});

export const DarkA = withAttrs(A, {
    className: "text-darkgray-custom h2",
});
export const StyledA = withAttrs(DarkA, ({inline}) => (inline ? {
    spacing: {d: 'block', my: 0, ml: 'auto'}
} : {
    className: "icon-edit-custom position-absolute",
    spacing: {px: 2}
}));

export const ColoredDiv = withAttrs('div', {
    className: ({color}) => `h2 text-${color}-custom`,
    spacing: {mb: 0}
});
