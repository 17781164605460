import React from "react";
import {Div, GreenButton, RedButton, YellowButton} from "../atoms/style";
import {DECISION_CODES} from "../../constants";
import withTrans from "../atoms/withTrans";


const YesNoMaybe = ({onSubmit, isLoading, t, size}) => (
    <Div className={'box-cta-custom w-100 text-right text-nowrap'}>
        <GreenButton size={size} data-t={'action.yes'} disabled={isLoading} onClick={()=>onSubmit && onSubmit(DECISION_CODES.yes)}>{t('optout.yes')}</GreenButton>
        <YellowButton size={size} data-t={'action.maybe'} disabled={isLoading} spacing={{ml: 1}} onClick={()=>onSubmit && onSubmit(DECISION_CODES.maybe)}>{t('optout.maybe')}</YellowButton>
        <RedButton size={size} data-t={'action.no'} disabled={isLoading} spacing={{ml: 1}} onClick={()=>onSubmit && onSubmit(DECISION_CODES.no)}>{t('optout.no')}</RedButton>
    </Div>
);

export default withTrans(YesNoMaybe);