import React from 'react';
import {LogoutLink} from "../atoms/style";
import useTrans from "../hooks/useTrans";

const LogoutButton = () => {
    const t = useTrans();
    return (
        <LogoutLink data-t={'button.logout'} to={'logout'}>{t("nav.logout")}</LogoutLink>
    );
};


export default LogoutButton;