import BirthdayBox from "../BirthdayBox";
import React, {useContext, useEffect, useState} from "react";
import {SidebarBoxWrapper} from "../../atoms/style";
import UserChoirsContext from "../../../state/UserChoirsContext";
import useTrans from "../../hooks/useTrans";
import getApi from "../../../services/getApi";


export const BirthDay = ({t, choirs}) => {
    let [birthdays, setBirthdays] = useState([]);

    useEffect(() => {
        let birthDays = [];
        choirs.map(async choir => {
            let id = choir.id
            await getApi("choir").getBirthday(id).then(res =>
                birthDays = birthDays.concat(res)
            )
            setBirthdays(birthDays)
        })
    }, [choirs]);

    return (
        <SidebarBoxWrapper data-t={'div.next_birthday'}>
            <BirthdayBox birthdays={birthdays}/>
        </SidebarBoxWrapper>
    );
};

export default (props) => {
    const choirs = useContext(UserChoirsContext);
    const t = useTrans();
    return <BirthDay choirs={choirs} t={t} {...props}/>;
};