import GlobalCache from "../atoms/GlobalCache";
import React from "react";

export default function useCacheUpdator(cacheId) {
    return React.useCallback((value = undefined) => {
        const cache = GlobalCache.singleton_value;
        if (cache) {
            if (value === undefined || value === null) {
                cache.remove(cacheId);
            } else {
                cache.set(cacheId, value);
            }
        }
    }, [cacheId])
}