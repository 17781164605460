import React, {useContext, useEffect} from 'react';
import EventFilter from '../../molecules/EventFilter/index';

import DownloadBox from "../../molecules/DownloadBox";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import EventBox from "../../molecules/EventBox/EventBox";
import SideBar from "../../molecules/SideBar";
import {EventGreyBox, Title} from "./style";
import EventListFetcher from '../../fetchers/EventListFetcher'
import WithEvent from "../../molecules/WithEvent";
import useEventFilter from "../../hooks/useEventFilter";
import EventFilterContext from "../../contexts/EventFilterContext";
import getApi from "../../../services/getApi";
import PaginationContext from "../../contexts/PaginationContext";
import WithPagination from "../../molecules/WithPagination";
import Pagination from "../../molecules/Pagination/Pagination";
import useTrans from "../../hooks/useTrans";
import {NoItemsFound} from "../../atoms/style";
import EventsBottomText from "../../molecules/EventsBottomText";
import useManagerStatus from "../../hooks/useManagerStatus";


const EventsComponent = ({onEventSelect}) => {
    const t = useTrans();
    const {filter, isFiltered} = useContext(EventFilterContext);
    const {pagination, updatePagination} = useContext(PaginationContext);
    const response = EventListFetcher.read({filter, page: pagination.cur_page});
    const isManager = useManagerStatus();
    useEffect(() => {
        updatePagination(response);
    }, [response, updatePagination]);

    const items = response.results;

    const downloadLink = React.useMemo(() => {
        const api = getApi("events");
        return [
            {
                title: isFiltered ? t('event.download_filtered_participation') : t('event.download_participation'),
                icon: 'file-excel',
                alt: "event_participation",
                url: api.getEventsExcelUrl(filter)
            }
        ]
    }, [t, isFiltered, filter])

    return items && items.length ? (
        <React.Fragment>
            {isManager ? <DownloadBox links={downloadLink}/> : null}

            <EventGreyBox>
                <Title>{t('event.next_event')}</Title>
            </EventGreyBox>

            {items.map((event, idx) =>
                <WithEvent key={idx} event={event}>
                    <EventBox isLink={true} onEventSelect={onEventSelect} />
                </WithEvent>
            )}

            <Pagination t={t}/>

            <EventsBottomText />
        </React.Fragment>
    ) : <NoItemsFound>{t("event.no_events_found")}</NoItemsFound>;
};

const EventListPage = (props) => {
    const t = useTrans();
    const eventFilter = useEventFilter();
    return (
        <PageWithSidebar title={t('events.list_title')} sidebar={<SideBar />} page={'event_list'}>
            <EventFilterContext.Provider value={eventFilter}>
                <EventFilter />
                <WithPagination>
                    <EventsComponent {...props}/>
                </WithPagination>
            </EventFilterContext.Provider>
        </PageWithSidebar>
    );
};
export default EventListPage;