import React, {Suspense} from 'react';
import useTrans from "../../hooks/useTrans";
import Markdown from "markdown-to-jsx";
import Loading from "../../atoms/Loading";
import isAppError from "../../../utils/isAppError";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import EmailChangeConfirmationFetcher from "../../fetchers/EmailChangeConfirmationFetcher";


const EmailChangeConfirmation = ({t, token}) => {
    const confirmationResponse = EmailChangeConfirmationFetcher.read({token});
    const links = [
        {to: 'change_email', key: 'link.change_email'},
        {to: 'profile', key: 'link.profile'}
    ]
    if (isAppError(confirmationResponse)) {
        return (
            <SimpleFeedback success={false} title={t('email_change_confirmation.request_failure')} links={links}>
                <Markdown data-t={"failure-note"}>{t('email_change_confirmation.email_change_confirmation_failure')}</Markdown>
            </SimpleFeedback>
        )
    }
    return (
        <SimpleFeedback  title={t('email_change_confirmation.request_success')} links={links}>
            <Markdown data-t={"success-note"}>{t('email_change_confirmation.email_change_confirmation_success')}</Markdown>
        </SimpleFeedback>
    );
};


export default ({match: {params}}) => {
    const t = useTrans();
    return (
        <div data-t={"page.email_change_confirm"}>
        <Suspense fallback={<SimpleFeedback noIcon title={t('email_change_confirmation.confirm_form_title')}><Loading/></SimpleFeedback>}>
            <EmailChangeConfirmation t={t} token={params.token}/>
        </Suspense>
        </div>
    );
}