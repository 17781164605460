import React from 'react';

import { NavbarToggler, NavItem } from 'reactstrap';
import MessageCounterBadge from '../Notifications/MessageCounterBadge';
import {
  CustomCollapse,
  CustomMetaNav,
  CustomNav,
  CustomNavbar,
  CustomNavLink,
  Logo,
  MdOnlyNavItem,
  NoMdNavItem,
  StyledContainer,
  StyledNavbarBrand,
  UsernameText,
  UserNavItem,
  CustomNavBlank,
} from './style';
import MessageCounterLink from '../Notifications/MessageCounterLink';
import LogoutButton from '../LogoutButton';
import { Icon, NavItemUpcase, NavLinkUpcase } from '../../atoms/style';
import LanguageSwitch from '../../atoms/LanguageSwitch';
import useToggle from '../../hooks/useToggle';
import useTrans from '../../hooks/useTrans';
import useManagerStatus from '../../hooks/useManagerStatus';
import useReverse from '../../hooks/useReverse';
import { changeLanguage } from '../../utils/language';
import useAuthUser from '../../hooks/useAuthUser';

export const PureMainNav = ({ t }) => {
  const managerStatus = useManagerStatus();
  return (
    <CustomNav>
      <NavItemUpcase to={'event_list'} data-t={'nav.events'} exact>
        {t('nav.events')}
      </NavItemUpcase>
      {managerStatus ? (
        <NavItemUpcase to={'event_create'} data-t={'nav.new_event'} exact>
          {t('nav.new_event')}
        </NavItemUpcase>
      ) : null}
      <NavItemUpcase to={'choir_list'} data-t={'nav.choirs'}>
        {t('nav.choirs')}
      </NavItemUpcase>
      <NavItemUpcase to={'profile'} data-t={'nav.my_profile'}>
        {t('nav.my_profile')}
      </NavItemUpcase>
      <NoMdNavItem>
        <MessageCounterLink />
      </NoMdNavItem>
      <NoMdNavItem>
        <LogoutButton />
      </NoMdNavItem>
    </CustomNav>
  );
};

export const UserLabel = ({ full_name }) => (
  <React.Fragment>
    <Icon icon="user" size="lg" spacing={{ mr: 3 }} />
    <UsernameText>{full_name}</UsernameText>
  </React.Fragment>
);

export const PureUserLink = ({ user }) => (
  <CustomNavLink to={'profile'}>
    <UserLabel full_name={user && user.full_name} />
  </CustomNavLink>
);

export const PureMetaNav = ({ t, i18n }) => {
  return (
    <CustomMetaNav>
      <LanguageSwitch lang={i18n.language} changeLanguage={changeLanguage} />
      <NavItem>
        <CustomNavBlank to={'lhref_help'}>{t('nav.help')}</CustomNavBlank>
      </NavItem>
      <NoMdNavItem>
        <NavLinkUpcase to={'mhref_data_protection'}>
          {t('nav.dataprotection')}
        </NavLinkUpcase>
      </NoMdNavItem>
      <NoMdNavItem>
        <NavLinkUpcase to={'mhref_imprint'}>{t('nav.imprint')}</NavLinkUpcase>
      </NoMdNavItem>
      <NoMdNavItem>
        <NavLinkUpcase to={'mhref_policy'}>{t('nav.policy')}</NavLinkUpcase>
      </NoMdNavItem>
      <MdOnlyNavItem>
        <MessageCounterLink />
      </MdOnlyNavItem>
      <UserNavItem>
        <UserLink />
      </UserNavItem>
      <MdOnlyNavItem aria-hidden="true">
        <LogoutButton />
      </MdOnlyNavItem>
    </CustomMetaNav>
  );
};

const MainNav = PureMainNav;
const UserLink = (props) => {
  const user = useAuthUser();
  return <PureUserLink user={user} />;
};
const MetaNav = PureMetaNav;

const NavBar = (props) => {
  const [isOpen, toggle] = useToggle();
  const { t, i18n } = useTrans(true);
  const reverse = useReverse();
  return (
    <CustomNavbar>
      <NavbarToggler onClick={toggle} />
      <StyledNavbarBrand href={reverse('event_list')} title={t('nav.home')}>
        <Logo alt={t('nav.logo_alt')} />
      </StyledNavbarBrand>
      <MessageCounterBadge />
      <CustomCollapse isOpen={isOpen}>
        <StyledContainer>
          <MainNav t={t} />
        </StyledContainer>
        <MetaNav {...props} t={t} i18n={i18n} />
      </CustomCollapse>
    </CustomNavbar>
  );
};

export default NavBar;
