import {HeaderBox} from "../../atoms/style";
import React from "react";
import OpenQuestionAnswers from "./OpenQuestionAnswers";
import isAppError from "../../../utils/isAppError";
import PollAllResponseFetcher from "../../fetchers/PollAllResponseFetcher";
import ComponentList from "../../atoms/ComponentList";
import MultipleChoiceAnswers from "./MultipleChoiceAnswers";
import MemberChoiceAnswers from "./MemberChoiceAnswers";
import EventCommitmentsFetcher from "../../fetchers/EventCommitmentsFetcher";

export default function ({t, event, voices}) {
    let {member_choices, open_questions, multiple_choices} = event;
    const answers = PollAllResponseFetcher.read({event_id: event.id});
    if (isAppError(answers)) {
        return null;
    }
    const eventCommitments = EventCommitmentsFetcher.read({event_id: event.id});
    if (isAppError(eventCommitments)) {
        return null;
    }
    const singers = eventCommitments.map(({user, voice, scp_id}) => ({
        user,
        voice,
        id: scp_id
    }));
    const {open_question_answers, multiple_choice_answers, member_choice_answers} = answers;
    return (
        <HeaderBox header={t('event.poll_result_title')}>
            <ComponentList component={OpenQuestionAnswers}
                           user_answers={open_question_answers}
                           items={open_questions}/>
            <ComponentList component={MultipleChoiceAnswers}
                           user_answers={multiple_choice_answers}
                           items={multiple_choices}/>
            <ComponentList component={MemberChoiceAnswers}
                           voices={voices}
                           singers={singers}
                           user_answers={member_choice_answers}
                           items={member_choices}/>
        </HeaderBox>
    );
}