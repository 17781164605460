import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import {Button} from "reactstrap";
import {storedLanguage} from "../utils/language";
import getMinUserInfo from "../../services/getMinUserInfo";

const boxStyle = {
    border: '3px dashed red',
    padding: 10,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { eventId: null, hasError: false, user: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        getMinUserInfo().then(user => this.setState(state => ({...state, user})));
        Sentry.withScope(scope => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    render() {
        if (this.state.hasError) {
            let conf = {
                eventId: this.state.eventId,
                lang: storedLanguage(),
            };
            if (this.state.user) {
                conf = {
                    ...conf,
                    user: {
                        email: this.state.user.email,
                        name: this.state.user.full_name
                    }
                }
            }
            return (
                <div style={boxStyle}>
                    <p>Something went wrong!</p>
                    <Button onClick={() => Sentry.showReportDialog(conf)}>Report feedback</Button>
                </div>
            );
        }

        //when there's not an error, render children untouched
        return this.props.children;
    }
}

export default ErrorBoundary;