import {FieldArray} from "formik";
import React from "react";

const FieldArrayWrapper = ({name, forComponent, ...props}) => {
    const Component = forComponent;
    return (
        <FieldArray name={name} render={arrayHelpers => <Component {...props} name={name} arrayHelpers={arrayHelpers}/>}/>
    );
};

export default FieldArrayWrapper;