import React from "react";
import {BreadcrumbLink, Icon} from "../atoms/style";

const BreadCrumbs = ({title, to}) => {
    return (
        <BreadcrumbLink to={to} data-t={'link.breadnav'}>
            <Icon icon={'angle-double-left'} spacing={{mr:2}}/> {title}
        </BreadcrumbLink>
    );
};

export default BreadCrumbs;