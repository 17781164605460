import {Form, Formik} from "formik";
import React, {useCallback, useContext, useEffect} from "react";
import {mixed, number, object, string} from "yup";
import {ACADEMIC_TITLES, GENDER_CHOICES, LANGUAGE_CHOICES} from "../../../constants";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, SubmitButton, WhiteBox} from "../../atoms/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import {Col, FormGroup, Label, Row} from "reactstrap";
import countries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import deCountries from "i18n-iso-countries/langs/de.json";
import moment from "moment-timezone";
import useAuthUser from "../../hooks/useAuthUser";
import useTrans from "../../hooks/useTrans";
import ImageInput from "../../molecules/ImageInput/ImageInput";
import ToastContext from "../../contexts/ToastContext";
import SelfUserFetcher from "../../fetchers/SelfUserFetcher";
import { castMomentToDateStr, castToMoment } from "../../validation/utils";

countries.registerLocale(enCountries);
countries.registerLocale(deCountries);

const timezoneNames = moment.tz.names();

const userSchema = object({
    id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
    first_name: string().required('validation.this_field_is_required'),
    last_name: string().required('validation.this_field_is_required'),
    email: string().required('validation.this_field_is_required'),
    birthday: mixed().transform(castToMoment).nullable(),
    gender: number().nullable().oneOf(Object.values(GENDER_CHOICES)),
    academic_title: number().nullable().oneOf(Object.values(ACADEMIC_TITLES)),
    language: string().nullable().oneOf(LANGUAGE_CHOICES),
    timezone: string().nullable().oneOf(timezoneNames),
    about: string().nullable(),
    avatar: string().nullable(),
    cc_email: string().nullable(),

    country: string().nullable().default("DE"),
    city: string().nullable(),
    street: string().nullable(),
    zipcode: string().nullable(),
});

const serializeUserSchema = userSchema.shape({
    birthday: mixed().nullable().transform(castMomentToDateStr),
});

const onSubmit = ({t, user, toaster}, onUpdate) => (values, {setSubmitting, setErrors, resetForm, setStatus}) => {
    const api = getApi("user");
    const invite_data = {
        "email": values.cc_email,
        "source": "profile edit"
    }
    const emailValidPromise = api.checkEmailValidity(values.cc_email, invite_data);
    emailValidPromise.then(value => {
        let checkInvalid = Object.values(value).filter((email) => {
            return email['result']['verdict'] === 'Invalid'
        })
        let invalidEmails = checkInvalid.map(item => {
            return item['result']['email']
        }).join(',')

        if (checkInvalid.length === 0){
            return api.updateUser(user.id, serializeUserSchema.cast(values));
        }else {
            console.log("Email invalid", value['result']);
            setSubmitting(false)
            setStatus({error: t('form.email_validation') + ': ' + invalidEmails});
            setErrors(value['result'])
        }
    }).then(value => {
        if (value === undefined){
            setSubmitting(false)
        }else {
            setSubmitting(false);
            resetForm({values: {}});
            resetForm({
                values: userSchema.cast(value)
            });
            toaster.onPush({msg: t('profile_form.updated_message'), type: 'success'});
            onUpdate(value);
        }
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};


const TheForm = ({user, onCancel, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {
    useEffect(() => {
        if (!values.country) {
            setFieldValue('country', "DE");
        }
    }, [values.country, setFieldValue]);

    useEffect(() => {
        if (!values.language) {
            setFieldValue('language', "de");
        }
    }, [values.language, setFieldValue]);

    useEffect(() => {
        if (!values.timezone) {
            setFieldValue('timezone', "Europe/Berlin");
        }
    }, [values.timezone, setFieldValue]);


    const {t, i18n} = useTrans(true);
    const countryPairs = Object.entries(countries.getNames(i18n.language));

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} />

                <FormGroup>
                    <Label><strong>Email:</strong></Label>
                    <div>
                        {user.email}
                    </div>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'gender'} label={<strong>{t('profile_form.gender')}</strong>} type={'select'}>
                        {Object.entries(GENDER_CHOICES).map(pair => <option key={pair[1]} value={pair[1]}>{t(`gender.${pair[0]}`)}</option>)}
                    </BootstrapField>
                    <BootstrapField md={6} fg={false} name={'academic_title'} label={<strong>{t('profile_form.academic_title')}</strong>} type={'select'}>
                        {Object.entries(ACADEMIC_TITLES).map(pair => <option key={pair[1]} value={pair[1]}>{t(`academic_title.${pair[0]}`)}</option>)}
                    </BootstrapField>
                </FormGroup>
                <FormGroup row>
                    <BootstrapField md={6} fg={false} required={true} name={'first_name'} label={<strong>{t('profile_form.first_name')}</strong>}/>
                    <BootstrapField md={6} fg={false} required={true} name={'last_name'} label={<strong>{t('profile_form.last_name')}</strong>}/>
                </FormGroup>
                <FormGroup row>
                    {/*<BootstrapField md={6} fg={false} required={true} name={'email'} label={<strong>{t('profile_form.email')}</strong>}/>*/}
                    <BootstrapField md={6} fg={false} type={'date'} name={'birthday'} label={<strong>{t('profile_form.birthday')}</strong>}/>
                </FormGroup>
                <FormGroup row>
                    <Col md={6}>
                        <ImageInput label={t('profile_form.avatar')} name={'avatar'} />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'street'} label={<strong>{t('profile_form.street')}</strong>}/>
                    <BootstrapField md={6} fg={false} name={'city'} label={<strong>{t('profile_form.city')}</strong>}/>
                </FormGroup>
                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'country'} label={<strong>{t('profile_form.country')}</strong>} type={'select'}>
                        {countryPairs.map(pair => <option key={pair[0]} value={pair[0]}>{pair[1]}</option>)}
                    </BootstrapField>
                    <BootstrapField md={6} fg={false} name={'zipcode'} label={<strong>{t('profile_form.zipcode')}</strong>}/>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'phone_mobile'} label={<strong>{t('profile_form.phone_mobile')}</strong>}/>
                    <BootstrapField md={6} fg={false} name={'phone_home'} label={<strong>{t('profile_form.phone_home')}</strong>}/>
                </FormGroup>
                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'phone_fax'} label={<strong>{t('profile_form.phone_fax')}</strong>}/>
                    <BootstrapField md={6} fg={false} required={false} type={'email'} name={'cc_email'} label={<strong>{t('profile_form.cc_email')}</strong>}/>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField md={6} fg={false} name={'language'} label={<strong>{t('profile_form.language')}</strong>} type={'select'}>
                        {LANGUAGE_CHOICES.map(lng => <option key={lng} value={lng}>{t(`language.${lng}`)}</option>)}
                    </BootstrapField>
                    <BootstrapField md={6} fg={false} name={'timezone'} label={<strong>{t('profile_form.timezone')}</strong>} type={'select'}>
                        {timezoneNames.map(tz => <option key={tz} value={tz}>{tz}</option>)}
                    </BootstrapField>
                </FormGroup>

                <BootstrapField name={'about'} label={<strong>{t('profile_form.about')}</strong>} type={'textarea'} rows={5}/>

                <EventFormSpacer />

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} disabled={isSubmitting}>{t('profile_form.save')}</SubmitButton>
                    </FormSubmitCol>
                    {onCancel ? (
                        <FormSubmitCol>
                            <GreySubmitButton type={'button'} onClick={(e) => {
                                e.preventDefault();
                                onCancel();
                                return false;
                            }}>{t('profile_form.cancel')}</GreySubmitButton>
                        </FormSubmitCol>
                    ) : null}
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const {user, onUpdate} = props;

    const currentUser = useAuthUser();
    const toaster = useContext(ToastContext);
    const onUpdateWrapper = useCallback((user) => {
        if (onUpdate) {
            onUpdate(user);
        }
        if (user.id === currentUser.id) {
            SelfUserFetcher.invalidate();
        }
    }, [onUpdate, currentUser]);

    return (
        <Formik validationSchema={userSchema} initialValues={userSchema.cast(user)} onSubmit={onSubmit({toaster, ...props}, onUpdateWrapper)}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
}