import getApi from "../../../services/getApi";
import EventCommitmentsFetcher from "../../fetchers/EventCommitmentsFetcher";
import OptOutDecisionFetcher from "../../fetchers/OptOutDecisionFetcher";
import goTo from "../../utils/goTo";
import isAppError from "../../../utils/isAppError";

const OPT_OUT_ERRORS = ['validation.optout_required', 'validation.optout_pending'];

const isOptOutError = (error) => isAppError(error) && (OPT_OUT_ERRORS.indexOf(error.body[0]) !== -1);

export const updateCommitment = (event_id, scp_id, callback) => (decision) => {
  const promise = getApi("decision").updateOptOutDecision(event_id, scp_id, decision).then((result) => {
      OptOutDecisionFetcher.update({event_id, scp_id}, result);
      return EventCommitmentsFetcher.preload({event_id});
  }).catch(error => {
      if (isOptOutError(error)) {
          goTo("event_optout", {event_id});
      }
      return null;
  });
  return callback ? promise.then(callback) : promise;
};
