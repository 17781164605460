import React from "react";
import {NavItem, NavLink} from "reactstrap";

const NavItemUpcase = ({isActive, className, children, ...props}) => (
    <NavItem><NavLink className={`text-uppercase ${isActive ? 'active' : ''} ${className}`} {...props}>{children}</NavLink></NavItem>
);
const LanguageSwitch = ({lang, changeLanguage}) => [
    <NavItemUpcase key={'de'} isActive={lang === 'de'} onClick={()=>changeLanguage('de')}>
        DE
    </NavItemUpcase>,
    <NavItemUpcase key={'en'} isActive={lang === 'en'} onClick={()=>changeLanguage('en')}>
        EN
    </NavItemUpcase>,
];

export default LanguageSwitch;