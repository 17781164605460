import React from "react";
import {A, HeaderWrapper} from "../atoms/style";
import logoPath from '../../img/choirmanager_logo.png';
import withAttrs from "../utils/withAttrs";
import {Container} from "reactstrap";
import useTrans from "../hooks/useTrans";
import PromptContainer from "./PromptComponent/PromptContainer";


const CenteredNav = withAttrs('div', {className: "bg-primary text-center"});
const StyledA = withAttrs(A, {className: "d-inline-block brand-center-custom"});
const StyledImg = withAttrs('img', {className: "minified-transition-custom"});

const LinkLogo = props => {
    const t = useTrans();
    return (
        <StyledA href="/" title={t('logo.goto_home')}>
            <StyledImg src={logoPath} alt="Choir Manager"/>
        </StyledA>
    );
};

const SimpleStyledWrapper = withAttrs('div', {className: "wrapper-custom pseudo-element"});
const StyledSection = withAttrs('section', {spacing: {md: {py: 5}, py: 3}});
const StyledContainer = withAttrs(Container, {spacing: {pt: 5, md: {pt: 0}, pb: 3}});

const PageFeedback = ({minified, children}) => (
    <React.Fragment>
        <HeaderWrapper>
            <CenteredNav>
                <LinkLogo/>
            </CenteredNav>
            <PromptContainer/>
        </HeaderWrapper>

        <SimpleStyledWrapper>
            <StyledSection>
                <StyledContainer>
                    {children}
                </StyledContainer>
            </StyledSection>

        </SimpleStyledWrapper>
    </React.Fragment>
);

export default PageFeedback;
