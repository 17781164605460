import {array, number, object, string} from "yup";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import React from "react";
import {Row} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import SingerSelect from "../../molecules/SingerSelect";
import AttachmentsField from "../EventEditPage/AttachmentsField";
import EventFormCollapse from "../EventEditPage/EventFormCollapse";
import LetterListFetcher from "../../fetchers/LetterListFetcher";
import validateExtension from "../../../utils/validateExtension";

const letterSchema = object({
    recipients: array().of(number()).min(1, 'validation.this_field_is_required').required('validation.this_field_is_required'),
    text: string().required('validation.this_field_is_required'),
    subject: string().required('validation.this_field_is_required'),
    attachments: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        name: string().required('validation.this_field_is_required').nullable(),
        file: string().nullable().when(['_file'], (file, schema) => {
            if (file && file.name) {
                return schema.test('invalid_file_extension', 'validation.invalid_file_extension', _ => validateExtension(file.name))
            } else {
                return schema;
            }
        }),
    })).compact(value => !value || (!!!value.name && !!!value.description && !!!value.file && !!!value._file))
});

const extractNewAttachments = (data) => ({
    newAttachments: (data && data.attachments ? data.attachments.filter(attachment => !attachment.id) : []),
    newData: {
        ...data,
        attachments: (data && data.attachments ? data.attachments.filter(attachment => !!attachment.id) : [])
    },
});

const onSubmit = ({t, choir, onSuccess}) => (values, {setSubmitting, setFieldError, setErrors, setStatus}) => {
    setSubmitting(true);
    const api = getApi("letter");
    const {newAttachments, newData} = extractNewAttachments(letterSchema.cast(values));
    const promise = api.createLetter(newData);

    promise.then((letter) => {
        if (newAttachments && newAttachments.length > 0) {
            return Promise.all(newAttachments.map(({_file, file, ...attachment}, idx) => {
                return api.createLetterAttachment(letter.id, attachment, _file).catch(error => {
                    setSubmitting(false);
                    if (isAppError(error)) {
                        setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
                        if (error.body) {
                            setFieldError(`attachments.${idx}`, error.body);
                        }
                    }
                    api.deleteLetter(letter.id).then(() => {
                        console.log(`Deleted letter ${letter.id} because of failed attachment upload`);
                    }).catch(() => {
                        console.error(`Failed to delete letter ${letter.id}`);
                    });
                    throw error;
                });
            })).then(() => letter);
        } else {
            return letter;
        }
    }).then(letter => api.sendLetter(letter.id, choir.id)).then((letter)=>{
        setSubmitting(false);
        onSuccess(letter);
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            if (!error.body.file) {
                setErrors(error.body)
            }
        }
    });
};

const TheForm = ({t, cancelUri, choir, withCommitments, voices, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} />
                <BootstrapField name={'recipients'}
                                required={true}
                                label={<strong>{t('letter_form.recipients_label')}</strong>}
                                voices={choir.voices}
                                singers={choir.singers}
                                managers={choir.managers}
                                hideEmpty userSelect withCommitments={withCommitments}
                                input={SingerSelect}
                />

                <BootstrapField name={'subject'}
                                required={true}
                                label={<strong>{t('letter_form.subject_label')}</strong>}
                                />

                <BootstrapField name={'text'}
                                required={true}
                                label={<strong>{t('letter_form.message_label')}</strong>}
                                type={'textarea'}
                                rows={5}/>

                <EventFormSpacer/>
                <EventFormCollapse title={t('letter_form.attachments')}>
                    <AttachmentsField t={t} value={values.attachments}
                                      setFieldValue={setFieldValue}
                                      attachmentType={'letter'}
                                      maxLimit={15}/>
                </EventFormCollapse>
                <EventFormSpacer/>

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} disabled={isSubmitting}>{t('letter_form.send_message')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton to={cancelUri}>{t('letter_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function({onSuccess, ...props}) {
    const initialValues = {
        subject: "",
        text: "",
        recipients: []
    };
    const t = useTrans();
    const invalidate = LetterListFetcher.invalidator({page : 1});
    const newProps = {
        ...props,
        onSuccess: (result) => {
            invalidate();
            onSuccess(result);
        }
    };
    return (
        <Formik validationSchema={letterSchema} initialValues={initialValues} onSubmit={onSubmit(newProps)}>
            {(formProps) => (
                <TheForm t={t} {...newProps} formProps={formProps} />
            )}
        </Formik>
    );
}