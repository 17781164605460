import {NoteBox, WhiteBox} from "./style";
import React from "react";
import useTrans from "../hooks/useTrans";

const PageNotAvailableNote = () => {
    const t = useTrans();
    return (
        <WhiteBox spacing={{px: 3, pt: 3, pb: 4, mb: 4}}>
            <NoteBox>
                {t("page_not_available")}
            </NoteBox>
        </WhiteBox>
    );
}

export default PageNotAvailableNote;