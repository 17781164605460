import resolver from "../resolver";

let APIS = {

};

const getApi = (name) => {
    if (!APIS[name]) {
        const ApiService = resolver.resolve('apiServiceClass');
        APIS[name] = new ApiService({callerId: name});
    }
    return APIS[name];
};

export default getApi;