import BootstrapField from "../BootstrapField";
import React from 'react';
import {getLeafVoices} from "../../../utils/choirUtils";

const VoiceSelect = ({name,  t, voices, ...props}) => {
    const leafVoices = getLeafVoices(voices);
    return (leafVoices && leafVoices.length > 0) ? (
        <BootstrapField type={'select'} name={name} {...props}>
            <option value={null}/>
            {leafVoices.map((voice, idx) => (
                <option key={idx} value={voice.id}>{t(`voice.name_${voice.abbr || "MS"}`)}</option>
            ))}
        </BootstrapField>
    ) : null;
};

export default VoiceSelect;