import classNames from "classnames";
import React, {useContext} from "react";
import PaginationContext from "../../contexts/PaginationContext";
import {Icon} from "../../atoms/style";

const PaginationPageNum = ({num, disabled, onClick}) => {
    const aClassName = classNames("page-link", {"text-primary": disabled,"no-textdecoration-custom" : disabled});
    return (
        <li className={classNames("page-item", {disabled: disabled})}>
            <button className={aClassName} onClick={(e) => {
                e.preventDefault();
                onClick(num);
                return false;
            }}>{num}</button>
        </li>
    );
};

const Pagination = ({t}) => {
    const {pagination: {cur_page, num_pages, }, onPageChange} = useContext(PaginationContext);
    if (!num_pages) {
        return null;
    }
    const isFirstPage = !cur_page || cur_page === 1;
    const isLastPage = cur_page === num_pages;
    const limit = 6;
    const offset = 1 + Math.max(0, (Math.floor((cur_page - 1) / limit))) * limit;

    return (
        <div className="d-flex justify-content-center justify-content-md-end">
            <nav aria-label={t('pagination.label')}>
                <ul className="pagination pagination-custom mb-0">
                    <li className={classNames("page-item", {disabled: isFirstPage})}>
                        <button className="page-link rounded-0"
                           aria-label={t('pagination.prev_label')}
                           onClick={() => onPageChange(Math.max(cur_page - 1, 1))}>
                            <Icon icon="angle-left" />
                            <span className="sr-only">{t('pagination.prev_label')}</span>
                        </button>
                    </li>
                    {Array.from(Array(Math.min(num_pages, limit)).keys()).map(num => (
                        <PaginationPageNum num={num + offset}
                                           key={num}
                                           disabled={cur_page === (num + offset)}
                                           onClick={onPageChange}/>
                    ))}
                    <li className={classNames("page-item", {disabled: isLastPage})}>
                        <button className="page-link rounded-0"
                           onClick={() => onPageChange(Math.min(cur_page + 1, num_pages))}
                           aria-label={t('pagination.next_label')}>
                            <Icon icon="angle-right" />
                            <span className="sr-only">{t('pagination.next_label')}</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};


export default Pagination;