import useChoir from "../hooks/useChoir";
import EventContext from "../contexts/EventContext";
import React from "react";

const WithEvent = ({event, children}) => {
    const choir = useChoir(event.choir_id);
    const voices = choir && choir.voices ? choir.voices : [];
    return (
        <EventContext.Provider value={{event, choir, voices, isSinger: !!event.scp_id, isManager: !!event.ccp_id, canEdit: !!event.ccp_id}}>
            {children}
        </EventContext.Provider>
    );
};

export default WithEvent;