import React, {useContext} from "react";
import PromptContext from "../../contexts/PromptContext";
import Prompt from "./Prompt";

export default function PromptContainer() {
    const [prompts, setPrompts] = useContext(PromptContext);
    const prompt = prompts.length > 0 ? prompts[0] : null
    const removePrompt = React.useCallback(() => {
        setPrompts(curPrompts => {
            return curPrompts.filter(curPrompt => curPrompt !== prompt)
        });
    }, [setPrompts, prompt]);
    if (prompt) {
        return <Prompt prompt={prompt} removePrompt={removePrompt}/>;
    } else {
        return null;
    }
}