import useTrans from "../../hooks/useTrans";
import ChoirFetcher from "../../fetchers/ChoirFetcher";
import isNotFound from "../../../utils/isNotFound";
import NotFoundPage from "../NotFoundPage";
import React from "react";
import WithChoir from "../../molecules/WithChoir";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import {GreyHeader, H5} from "../../atoms/style";
import ChoirEdit from "./ChoirEdit";
import goTo from "../../utils/goTo";
import ChoirListFetcher from "../../fetchers/ChoirListFetcher";

export default function ({isEdit = true, match: {params}}) {
    const t = useTrans();
    const choir = isEdit ? ChoirFetcher.read({choir_id: params.id}) : null;
    if (isEdit && isNotFound(choir)) {
        return <NotFoundPage/>;
    }
    const invalidate = ChoirListFetcher.invalidator();
    const postDelete = () => {
        invalidate();
        goTo('choir_list');
    };

    return (
        <WithChoir choir={choir}>
            <PageWithSidebar title={isEdit ? t('choir.edit_title') : t('choir.create_title')}
                             sidebar={<SideBar />}
                             page={'choir_edit'}>
                <GreyHeader>
                    <H5 spacing={{my: 1}}>{isEdit ? t('choir.edit_event') : t('choir.new_event')}</H5>
                </GreyHeader>
                <ChoirEdit t={t} choir={choir} isEdit={isEdit} postDelete={postDelete} />
            </PageWithSidebar>
        </WithChoir>
    )
}