import React, {useState} from "react";
import useCountdown from "../../hooks/useCountdown";
import useResendPasscode from "./useResendPasscode";
import useResetFactor from "./useResetFactor";
import FactorContext from "./FactorContext";
import {ACTIVE_FACTOR, NOT_SETUP} from "./constants";
import factorId from "./factorId";

const FactorContextProvider = ({factor, updateEnrolled, initialFactorStatus, children}) => {
    const [factorStatus, setFactorStatus] = useState(initialFactorStatus);
    const [timeLeft, startCountdown] = useCountdown(30);
    React.useEffect(() => {
        if (updateEnrolled && factor) {
            updateEnrolled(factorId(factor), factorStatus?.status !== ACTIVE_FACTOR)
        }
    }, [updateEnrolled, factor, factorStatus])
    const [resend, resendSubmitting] = useResendPasscode(factorStatus, startCountdown);
    const [resetFactor, resetSubmitting] = useResetFactor(factorStatus, setFactorStatus);
    const enrollFactor = React.useCallback(() => {
        setFactorStatus({
            status: NOT_SETUP
        })
    }, [setFactorStatus])

    const commonProps = {
        factorStatus,
        setFactorStatus,
        enrollFactor,
        factor,
        timeLeft,
        startCountdown,
        resend,
        resendSubmitting,
        resetFactor,
        resetSubmitting
    };
    return (
        <FactorContext.Provider value={commonProps}>
            {children}
        </FactorContext.Provider>
    )
};

export default FactorContextProvider