import React from "react";
import VoiceSelect from "../VoiceSelect";
import SingerSelect from "../SingerSelect";
import BootstrapField from "../BootstrapField";

const MemberChoice = (props) => {
    const {idx, choice, question, voices, singers} = props;
    const prefix = `member_choice_answers.${idx}`
    return (choice === "voice") ? (
        <VoiceSelect name={`${prefix}.voice`} voices={voices} label={<strong>{question}</strong>}/>
    ) : (
        <BootstrapField label={<strong>{question}</strong>}
                        input={SingerSelect}
                        name={`${prefix}.singers`}
                        voices={voices}
                        singers={singers} hideEmpty/>
    );
};

export default MemberChoice;