import React, {useContext} from "react";
import {ChoirBoxBody, ChoirHeadline, ChoirWrapper,} from "./style";
import useTrans from "../../hooks/useTrans";
import ChoirContext from "../../contexts/ChoirContext";
import countries from "../../../utils/countries"

const buildAddress = ({city, country}, lng) => {
    let result = [city, country ? countries.getName(country, lng) : null];

    return result.filter(part => !!part).join(", ");
};

const ChoirDetailBox = ({choir, t, isLink}) => {
    const {name, logo_thumb, logo, picture_thumb, picture, singers, managers} = choir;
    const {i18n} = useTrans(true);
    const location = buildAddress(choir, i18n.language);
    const linkTo = isLink ? ['choir_detail', {id: choir.id}] : undefined;
    return (
        <ChoirWrapper spacing={{mt: 2}}>

            <ChoirHeadline title={name} logo={logo_thumb ? logo_thumb : logo} to={linkTo}/>

            <ChoirBoxBody to={linkTo}
                          member_count={singers ? singers.length : 0}
                          location={location}
                          managers={managers}
                          picture={picture_thumb || picture}
                          t={t} />
        </ChoirWrapper>
    );
};

export default (props) => {
    const {choir} = useContext(ChoirContext);
    if (!choir) return null;
    return <ChoirDetailBox t={useTrans()} choir={choir} {...props}/>;
}