export const getChoirLogoSrc = ({logo, logo_thumb}) => logo_thumb ? logo_thumb : logo;

export const getAvatarSrc = (src) => {
    if (!src) {
        return null;
    }
    if (typeof src === "string") {
        return src;
    }
    return src.avatar_thumb || src.avatar;
};
