import {object, string} from "yup";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, HelpText, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {FormSubmitCol} from "../EventEditPage/style";
import React from "react";
import {Row} from "reactstrap";

const optoutSchema = object({
    reason: string().required('validation.this_field_is_required'),
});

const onSubmit = ({t, event, onSuccess}) => (values, {setSubmitting, setErrors, setStatus}) => {
    setSubmitting(true);
    const api = getApi("event_optout");
    const promise = api.createOptOutRequest(event.id, values);
    promise.then(() => {
        setSubmitting(false);
        onSuccess();
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};

const TheForm = ({t, onCancel, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} />

                <BootstrapField name={'reason'}
                                required={true}
                                label={<strong>{t('optout_form.reason')}</strong>}
                                type={'textarea'}
                                rows={5}
                                help_text={<HelpText>{t('optout_form.reason_help_text')}</HelpText>}/>

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} data-t={"optout_form.submit"} disabled={isSubmitting}>{t('optout_form.request_permission')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton data-t={"optout_form.cancel"} onClick={onCancel}>{t('optout_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const initialValues = {
        reason: "",
    };
    return (
        <Formik validationSchema={optoutSchema} initialValues={initialValues} onSubmit={onSubmit(props)}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
}