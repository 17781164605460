export const EMAIL_FACTOR = {factorType: "email", provider: "OKTA"};
export const SMS_FACTOR = {factorType: "sms", provider: "OKTA"};
export const GOOGLE_AUTHENTICATOR_FACTOR = {factorType: "token:software:totp", provider: "GOOGLE"}
export const UI_SUPPORTED_FACTORS = [
    EMAIL_FACTOR,
    SMS_FACTOR,
    GOOGLE_AUTHENTICATOR_FACTOR,
]

export const NOT_SETUP = "NOT_SETUP"
export const ACTIVE_FACTOR = "ACTIVE"