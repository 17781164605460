import React from "react";
import {Col, Row} from "reactstrap";
import Map from "./Map";

const LocationBox = ({t, location, show_map, lat, lng, show = true}) => show && location ? (
    <Row className="no-gutters pt-3">
        <Col lg={5} className="bg-gray-custom p-3 mb-1">
            <h5 className="text-uppercase">{t('event.location')}</h5>
            <p>
                {location}
            </p>
        </Col>

        {show_map && lat && lng ? (
            <Col lg={7} className="mb-1 px-lg-1">
                <Map point={[lat, lng]}/>
            </Col>
        ) : null}
    </Row>
) : null;

export default LocationBox;