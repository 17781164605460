import React, {useContext} from 'react';
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import useTrans from "../../hooks/useTrans";
import WithChoir from "../../molecules/WithChoir";
import ChoirDetailBox from "../../molecules/ChoirDetailBox/ChoirDetailBox";
import UserChoirsContext from "../../../state/UserChoirsContext";
import {BigCreateButton, NoItemsFound} from "../../atoms/style";


const ChoirsComponent = ({t, onEventSelect}) => {
    const items = useContext(UserChoirsContext);

    return items && items.length ? items.map((choir, idx) =>
        <WithChoir choir={choir}>
            <ChoirDetailBox key={idx} isLink={true} />
        </WithChoir>
    ) : <NoItemsFound>{t("choir.no_choirs_found")}</NoItemsFound>;
};

const ChoirListPage = (props) => {
    const t = useTrans();
    return (
        <PageWithSidebar title={t('choir.list_title')}
                         page={'choir_list'}
                         sidebar={<SideBar />}>
            <BigCreateButton to={'choir_create'}>{t('choir.create')}</BigCreateButton>
            <ChoirsComponent t={t} {...props}/>
        </PageWithSidebar>
    );
};
export default ChoirListPage;