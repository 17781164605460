import {oktaAuth, oktaConfig} from "../../../services/okta";
import i18n from "../../../setup/i18n";

const MFA_REQUIRED = "MFA_REQUIRED";
const MFA_CHALLENGE = "MFA_CHALLENGE"
const SUCCESS = "SUCCESS"
const PASSWORD_EXPIRED = "PASSWORD_EXPIRED"
const LOCKED_OUT = "LOCKED_OUT";

export const workflowBuilder = ({promptFactorSelection, promptPasscode, changePassword, toaster, t}) => {
    const handleMfaRequired = async (transaction) => {
        const factors = transaction?.factors;
        if (factors && factors.length === 0) {
            throw new Error("No factor options available");
        }
        let factor = transaction?.factors[0];
        if (transaction?.factors?.length > 1) {
            factor = await promptFactorSelection(transaction.factors);
        }
        return factor.verify();
    }

    const handleMfaChallenge = async (transaction) => {
        if (transaction.poll) {
            return transaction.poll({
                autoPush: true
            });
        } else {
            const values = await promptPasscode(transaction);
            const passCode = values.token;
            return transaction.verify({
                passCode,
                // autoPush: true
            });
        }
    }

    const handlePasswordExpired = async (transaction) => {
        const values = await changePassword(transaction);
        return transaction.changePassword(values);
    }

    const handleLockedOut = async (transaction) => {
        toaster.onPush({msg: t('account.locked_out'), type: 'error'});
        // goTo('login_unlock');
        return transaction.cancel();
    }

    const handleSuccess = async (transaction) => {
        const sessionToken = transaction?.sessionToken;
        await oktaAuth.signInWithRedirect({
            ...oktaConfig.extraParams,
            originalUri: `/${i18n.language}/`,
            sessionToken
        });
    }

    const handlers = {
        [MFA_REQUIRED]: handleMfaRequired,
        [MFA_CHALLENGE]: handleMfaChallenge,
        [SUCCESS]: handleSuccess,
        [PASSWORD_EXPIRED]: handlePasswordExpired,
        [LOCKED_OUT]: handleLockedOut,
    };

    const loginWorkflow = async (transaction) => {
        while (transaction?.status && transaction?.status !== SUCCESS) {
            console.log("TR", transaction);
            const handler = handlers[transaction.status];
            if (handler) {
                transaction = await handler(transaction);
                // isFinal = true;
            } else {
                console.log(`Unsupported transaction type: ${transaction?.status}`)
                if (transaction.skip) {
                    console.log(`Skipping ${transaction?.status}`);
                    transaction = await transaction.skip();
                } else {
                    transaction = await transaction.cancel();
                }
            }
        }
        if (transaction?.status === SUCCESS) {
            transaction = await handlers[transaction.status](transaction);
        }
        console.log("Last TR", transaction);
        return new Promise(() => {
        });
    }

    return loginWorkflow;
}
