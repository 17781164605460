import React from "react";
import useTrans from "../../hooks/useTrans"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


const BirthdayInstructionsModal = (props) => {
    const {
        modalVisible,
        closeModal
    } = props;
    const t = useTrans();

    return (
        <div>
            <Modal isOpen={modalVisible}>
                <ModalHeader>{t('birthday_calendar.instructions_title')}</ModalHeader>
                <ModalBody>
                    <p>{t('birthday_calendar.instructions_manager_message')}</p>
                    <br />
                    <p>{t('birthday_calendar.instructions_singer_message')}</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={closeModal}>{t('birthday_calendar.instruction_close')}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default BirthdayInstructionsModal;