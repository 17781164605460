import React from "react";

import {ColoredCell, ResponsiveDiv, StyledCell, StyledTable, ThCell, ThTitleCell} from "./style";
import {Flex} from "../../atoms/style";
import withTrans from "../../atoms/withTrans";

const EventBoxTableHeader = ({header, title}) => (
    <thead><tr>
        <ThTitleCell>{title}</ThTitleCell>
        {header.map((value, idx) => (
            <ThCell key={idx}>{value}</ThCell>
        ))}
    </tr></thead>
);

const FlexTableRowTitle = ({color, title, sum}) => (
    <StyledCell color={color}>
        <Flex>
            <ResponsiveDiv>{title}</ResponsiveDiv>
            <div>{sum}</div>
        </Flex>
    </StyledCell>
);

const EventBoxTableRow = ({title, header, color, data}) => {
    const sum = data.reduce((a, b) => a + b, 0);
    return (
        <tr>
            <FlexTableRowTitle color={color} title={title} sum={sum}/>
            {data.map((value, idx) => (
                <ColoredCell key={idx} color={color} data-label={header[idx]}>{value}</ColoredCell>
            ))}
        </tr>
    );
};


const EventBoxTable = ({header, data, t}) => header ? (
    <StyledTable>
        <EventBoxTableHeader header={header} title={t('event.total')}/>
        <tbody>
            <EventBoxTableRow color="green" header={header} title={t('event.accepted')} data={data.yes}/>
            <EventBoxTableRow color="yellow" header={header} title={t('event.undecided')} data={data.maybe}/>
            <EventBoxTableRow color="red" header={header} title={t('event.declined')} data={data.no}/>
        </tbody>
    </StyledTable>
) : null;

export default withTrans(EventBoxTable);