import FormAlert from "../FormAlert/FormAlert";
import React from "react";
import {connect} from "formik";
import {Alert} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import isAppError from "../../../utils/isAppError";
import isApiFailure from "../../../utils/isApiFailure";
import isOktaError from "../../../utils/isOktaError";

const FormSuccess = ({msg}) => (
    <Alert color={'success'} data-t={'form.success'}>
        {msg}
    </Alert>
);

const FormStatus = connect(({debug, error_title, formik: {submitCount, status, errors}}) => {
    const t = useTrans();

    if (error_title) {
        return (
            <FormAlert error_title={error_title} />
        );
    }

    if (!status) {
        if (submitCount === 0 || Object.keys(errors).length === 0) {
            return null;
        }
        return debug ? (
            <FormAlert error_title={t('form.some_fields_failed_validation')}>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
            </FormAlert>
        ) : (<FormAlert error_title={t('form.some_fields_failed_validation')}/>);
    }

    if (isOktaError(status?.error)) {
        const oktaErrorDetails = Array.isArray(status?.error?.body?.errorCauses) ? status?.error?.body?.errorCauses.map(cause => t(cause?.errorSummary.replace(/.*:\s*/g, ""))) : [];
        const oktaErrorMessage = oktaErrorDetails.length > 0 ? oktaErrorDetails.join(". ") : status?.error?.body?.errorSummary || t('form.some_fields_failed_validation');
        return <FormAlert error_title={oktaErrorMessage} />;
    }

    if (isAppError(status?.error)) {
        const error_message = status?.error?.body?.non_field_errors ? status.error.body.non_field_errors : (
            status?.error?.statusText ? status.error.statusText : t('form.some_fields_failed_validation')
        );
        return <FormAlert error_title={error_message}
                          errors={status.error.body} />;
    }

    if (isApiFailure(status.error)) {
        return <FormAlert error_title={t('form.api_error_msg')}/>;
    }

    if (status?.error instanceof Error) {
        return <FormAlert error_title={status?.error.message} errors={errors} />;
    }

    if (status?.error?.error_type === "InvalidJSON") {
        return <FormAlert error_title={t('validation.unexpected_response')} />;
    }

    if (status.error) {
        return <FormAlert error_title={status.error} errors={errors} />;
    }

    if (status.success) {
        return <FormSuccess msg={status.success} />;
    }

    return null;
});

export default FormStatus;