import useTrans from "../../hooks/useTrans";
import EventPollStatsBox from "./EventPollStatsBox";
import React, {Suspense, useContext} from "react";
import EventContext from "../../contexts/EventContext";
import hasItems from "../../utils/hasItems";
import Loading from "../../atoms/Loading";

export default function(props) {
    const {event, voices, isManager} = useContext(EventContext);
    const {member_choices, open_questions, multiple_choices} = event;
    const anyPoll = hasItems(member_choices, open_questions, multiple_choices);
    return isManager && anyPoll ? (
            <Suspense fallback={<Loading/>}>
            <EventPollStatsBox t={useTrans()} event={event} voices={voices} />
        </Suspense>
    ) : null;
}