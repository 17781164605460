import NotificationsContext from "../../contexts/NotificationsContext"
import React, {useEffect, useState} from "react";
import getApi from "../../../services/getApi";
import useAuth from "../../hooks/useAuth";


export default function ({children}) {
    const [state, setState] = useState({notifications: [], isLoading: false, isOpen: false});
    const notifications = state.notifications;
    const {isAuthenticated} = useAuth()
    useEffect(() => {
        if (isAuthenticated) {
            setState(curState => ({...curState, isLoading: true}));
            getApi("notification").getNotifications().then(response => {
                setState(curState => ({
                    ...curState,
                    isLoading: false,
                    notifications: response.results
                }));
            }).catch((err) => {
                console.log(err);
            }).finally(error => {
                setState(curState => ({...curState, isLoading: false}))
            });
        }
    }, [setState, isAuthenticated]);

    const open = () => {
        setState({
            ...state,
            isOpen: true
        });
    };

    const close = () => {
        setState({
            ...state,
            isOpen: false
        });
        if (notifications && notifications.length > 0) {
            const maxId = notifications.reduce((curMaxId, notification) => Math.max(curMaxId, notification.id), 0);
            return getApi("notification").clearNotifications(maxId).then(res => {
                setState({
                    ...state,
                    isOpen: false,
                    notifications: []
                });
            });
        }
    };

    return (
        <NotificationsContext.Provider value={{
            ...state,
            open,
            close
        }}>
            {children}
        </NotificationsContext.Provider>
    );
}