import React from "react";
import BootstrapField from "../../molecules/BootstrapField";
import GenericPollsField from "./GenericPollsField";

const MemberChoiceInput = ({t, index, prefix, disabled, setFieldValue, value}) => {
    return (
        <>
            <BootstrapField disabled={disabled} name={`${prefix}.question`} help_text={t('member_choice.question_help_text')}/>
            <BootstrapField disabled={disabled} name={`${prefix}.choice`} defaultValue={'multiple'} type={'select'} help_text={t('member_choice.select_help_text')}>
                <option value={'multiple'}>{t('member_choice.members')}</option>
                <option value={'voice'}>{t('member_choice.voice')}</option>
            </BootstrapField>
        </>
    );
};

export default (props) => (
    <GenericPollsField {...props} name={'member_choices'} inputComponent={MemberChoiceInput}/>
);