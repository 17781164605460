import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {array, boolean, number, object, string} from "yup";
import {CHOIR_SIZES, CHOIR_VOICINGS} from "../../../constants";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import isAppError from "../../../utils/isAppError";
import {
    GreySubmitButton,
    HelpText,
    RedSubmitButton,
    SmallLandscapePicture,
    SmallLogo,
    SubmitButton,
    WhiteBox,
    WrappedCol
} from "../../atoms/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import EventFormCollapse from "../EventEditPage/EventFormCollapse";
import AttachmentsField from "../EventEditPage/AttachmentsField";
import {Col, FormGroup, Row} from "reactstrap";
import ChoirFetcher from "../../fetchers/ChoirFetcher";
import ChoirListFetcher from "../../fetchers/ChoirListFetcher";
import EmailDigestsField from "./EmailDigestsField";
import countries from "../../../utils/countries"
import useTrans from "../../hooks/useTrans";
import ToastContext from "../../contexts/ToastContext";
import ImageInput from "../../molecules/ImageInput/ImageInput";
import validateExtension from "../../../utils/validateExtension";
import validateFileSize from "../../../utils/validateFileSize";
import moment from "moment-timezone";
import useAuthUser from "../../hooks/useAuthUser";
import UserFetcher from "../../fetchers/UserFetcher";

const timezoneNames = moment.tz.names();

const choirSchema = object({
    id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
    name: string().required('validation.this_field_is_required'),
    slug: string().required('validation.this_field_is_required')
        .matches(/^[a-z0-9]+(?:[-_]+[a-z0-9]+)*$/, "validation.must_be_valid_slug"),
    logo: string().nullable(),
    picture: string().nullable(),
    notes: string().nullable().ensure(),
    zipcode: string().nullable(),
    city: string().nullable(),
    voice_type: string().nullable().oneOf(CHOIR_VOICINGS),
    country: string().nullable().default("DE"),
    street: string().nullable(),
    timezone: string().nullable().oneOf(timezoneNames),
    size: string().nullable().oneOf(CHOIR_SIZES).default('s'),
    default_yes: boolean().nullable().default(false),
    public_profile: boolean().nullable().default(true),
    opt_out_events: boolean().nullable().default(false),
    singer_addresses_visible: boolean().nullable().default(true),
    singer_emails_visible: boolean().nullable().default(true),
    singer_numbers_visible: boolean().nullable().default(true),
    is_in_rotation: boolean().nullable().default(false),
    attachments: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        name: string().required('validation.this_field_is_required').nullable(),
        description: string().ensure(),
        file: string().nullable().when(['_file'], (file, schema) => {
            if (file && file.name) {
                return schema
                    .test('invalid_file_extension', 'validation.invalid_file_extension', _ => validateExtension(file.name))
                    .test('file_size_too_big', 'validation.file_size_too_big', _ => validateFileSize(file))
            } else {
                return schema;
            }
        }),
    })).compact(value => !value || (!!!value.name && !!!value.description && !!!value.file && !!!value._file))
});

const extractNewAttachments = (data) => ({
    newAttachments: (data && data.attachments ? data.attachments.filter(attachment => !attachment.id) : []),
    newData: {
        ...data,
        attachments: (data && data.attachments ? data.attachments.filter(attachment => !!attachment.id) : [])
    },
});

const onSubmit = ({t, toaster, isEdit, choir}) => (values, {setSubmitting, setErrors, setStatus}) => {
    const {_submitAndNew, ...data} = values;
    const api = getApi("choir");
    const {newAttachments, newData} = extractNewAttachments(choirSchema.cast(data));
    const promise = isEdit ? api.updateChoir(choir.id, newData) : api.createChoir(newData);

    promise.then((choir) => {
        toaster.onPush({msg: t('success.choir_saved'), type: 'success'});
        return choir;
    }).then((choir) => {
        if (newAttachments && newAttachments.length > 0) {
            toaster.onPush({msg: t('warning.uploading_attachments'), type: 'info'});
            return Promise.all(newAttachments.map(({_file, file, ...attachment}) => {
                return api.createChoirAttachment(choir.id, attachment, _file);
            })).then(() => {
                toaster.onPush({msg: t('success.attachments_uploaded'), type: 'success'});
                return api.getChoir({choir_id: choir.id})
            }).catch((error) => {
                toaster.onPush({msg: t('error.attachment_upload_failed'), type: 'error'});
                return choir;
            });
        } else {
            return choir;
        }
    }).then((choir)=>{
        ChoirFetcher.update({choir_id: choir.id}, choir);
        ChoirListFetcher.invalidate();
        setSubmitting(false);
        if (_submitAndNew) {
            goTo('choir_create');
        } else {
            goTo('choir_detail', {id: choir.id});
        }
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};

const TheForm = ({isEdit, choir, postDelete, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    const toaster = useContext(ToastContext);

    const onDelete = (e) => {
        e.preventDefault();
        const api = getApi("choir");
        if (choir && choir.id) {
            if (window.confirm(t('confirm.delete_choir?'))) {
                setStatus({error: null});
                api.deleteChoir(choir.id).then(() => {
                    postDelete();
                    toaster.onPush({msg: t('success.choir_deleted'), type: 'success'});
                }).catch(error => {
                    setStatus({error: t('error.failed_deleting_choir')});
                });
            }
        }
        return false;
    };

    const {t, i18n} = useTrans(true);
    const countryPairs = Object.entries(countries.getNames(i18n.language));

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} />

                <BootstrapField name={'name'} label={<strong>{t('choir_form.name')}</strong>} required={true}/>
                <BootstrapField name={'slug'}
                                label={<strong>{t('choir_form.slug')}</strong>}
                                required={true}
                                help_text={t('choir_form.slug_help_text')}/>

                <Row>
                    <WrappedCol lg={6} spacing={{mb: 3}}>
                        <ImageInput label={t('choir_form.logo')} name={'logo'}>
                            <SmallLogo src={values.logo}/>
                        </ImageInput>
                    </WrappedCol>
                    <Col lg={6}>
                        <ImageInput label={t('choir_form.picture')} name={'picture'}>
                            <SmallLandscapePicture src={values.picture}/>
                        </ImageInput>
                    </Col>
                </Row>

                {!isEdit ? (
                    <FormGroup row>
                        <BootstrapField md={6} name={'size'} label={<strong>{t('choir_form.size')}</strong>} type={'select'} required={true} fg={false}>
                            {CHOIR_SIZES.map(size => <option key={size} value={size}>{t(`choir.size_${size}`)}</option>)}
                        </BootstrapField>

                        <BootstrapField md={6} name={'voice_type'} label={<strong>{t('choir_form.voice_type')}</strong>} type={'select'} required={true} fg={false}>
                            {CHOIR_VOICINGS.map(voiceType => <option key={voiceType} value={voiceType}>{voiceType}</option>)}
                        </BootstrapField>
                    </FormGroup>
                ) : null}

                <BootstrapField name={'timezone'} label={<strong>{t('profile_form.timezone')}</strong>} type={'select'}>
                    {timezoneNames.map(tz => <option key={tz} value={tz}>{tz}</option>)}
                </BootstrapField>

                <BootstrapField name={'street'} label={<strong>{t('choir_form.street')}</strong>}/>

                <FormGroup row>

                    <BootstrapField md={4} name={'city'} label={<strong>{t('choir_form.city')}</strong>} fg={false}/>

                    <BootstrapField md={4} name={'country'} label={<strong>{t('choir_form.country')}</strong>} type={'select'} required={true}  fg={false}>
                        {countryPairs.map(pair => <option key={pair[0]} value={pair[0]}>{pair[1]}</option>)}
                    </BootstrapField>

                    <BootstrapField md={4} name={'zipcode'} label={<strong>{t('choir_form.zipcode')}</strong>}  fg={false}/>
                </FormGroup>

                <BootstrapField name={'notes'} label={<strong>{t('choir_form.notes')}</strong>} type={'textarea'} rows={5}/>

                <EventFormSpacer/>

                <EventFormCollapse title={t('choir_form.attachments')} data-t={'collapse.attachments'}>
                    <AttachmentsField t={t} value={values.attachments}
                                      setFieldValue={setFieldValue}
                                      attachmentType={'choir'}
                                      maxLimit={15}/>
                </EventFormCollapse>
                <EventFormSpacer />
                {isEdit ? (
                    <React.Fragment>
                        <EventFormCollapse title={t('choir_form.email_digests')} data-t={'collapse.email_digests'}>
                            <EmailDigestsField name={'email_digests'}
                                               t={t}
                                               maxLimit={10}/>
                        </EventFormCollapse>
                        <EventFormSpacer spacing={{mb: 1}}/>
                    </React.Fragment>
                ) : null}
                <EventFormCollapse title={t('choir_form.settings')}>
                    <BootstrapField type={'checkbox'}
                                    name={'opt_out_events'}
                                    label={<strong>{t('choir_form.opt_out_events_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.opt_out_events_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'singer_numbers_visible'}
                                    label={<strong>{t('choir_form.singer_numbers_visible_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.singer_numbers_visible_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'singer_emails_visible'}
                                    label={<strong>{t('choir_form.singer_emails_visible_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.singer_emails_visible_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'singer_addresses_visible'}
                                    label={<strong>{t('choir_form.singer_addresses_visible_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.singer_addresses_visible_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'event_notifications'}
                                    label={<strong>{t('choir_form.event_notifications_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.event_notifications_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'default_yes'}
                                    label={<strong>{t('choir_form.default_yes_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.default_yes_help')}</HelpText>}/>

                    <BootstrapField type={'checkbox'}
                                    name={'public_profile'}
                                    label={<strong>{t('choir_form.public_profile_label')}</strong>}
                                    help_text={<HelpText>{t('choir_form.public_profile_help')}</HelpText>}/>

                </EventFormCollapse>
                <EventFormSpacer />

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'}
                                      data-t={'button.submit'}
                                      disabled={isSubmitting}>{t('choir_form.save')}</SubmitButton>
                    </FormSubmitCol>
                        {isEdit ? (
                            <FormSubmitCol>
                                <RedSubmitButton onClick={onDelete} disabled={isSubmitting} data-t={'button.delete_choir'}>
                                    {t('choir_form.delete_choir')}
                                </RedSubmitButton>
                            </FormSubmitCol>
                        ) :  null}
                    <FormSubmitCol>
                        <GreySubmitButton to={choir ? ['choir_detail', {id: choir.id}] : 'choir_list'}
                                          data-t={'button.cancel'}>
                            {t('choir_form.cancel')}
                        </GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const {isEdit, choir} = props;
    const user = useAuthUser();
    const userProfile = UserFetcher.read({user_id: user.id});
    const initialValues = isEdit ? choir : {
        name: "",
        slug: "",
        voice_type: CHOIR_VOICINGS[0],
        size: CHOIR_SIZES[0],
        timezone: userProfile.timezone ? userProfile.timezone : "Europe/Berlin",
        country: userProfile.country ? userProfile.country : 'DE',
        event_notifications: true,
        public_profile: true,
        singer_numbers_visible: true,
        singer_emails_visible: true,
        singer_addresses_visible: true
    };
    const toaster = useContext(ToastContext);
    return (
        <Formik validationSchema={choirSchema} initialValues={initialValues} onSubmit={onSubmit({toaster, ...props})}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
}