import withAttrs from "../../utils/withAttrs";
import {ILink, WhiteBox} from "../../atoms/style";

export const EventNotesTitle = withAttrs('h4', {
    className: "h4 text-uppercase mt-4 pt-2",
    spacing: {mt: 4, pt: 2}
});

export const StyledH3 = withAttrs('h3', {
    className: "d-inline-block w-85-custom mt-1 pb-1"
});

export const FromHourToHourWrapper = withAttrs('span', {
   className: "text-nowrap",
   spacing: {mr: 'auto', sm: {mr: 0}, d: 'block', lg: {d: 'inline'}}
});

export const VertLine = withAttrs('span', {
    className: "font-weight-normal",
    spacing: {d: 'none', lg: {d: 'inline'}},
    children: " | "
});

export const EventNotesWrapper = withAttrs('div', {
    className: "small"
});

export const StyledTable = withAttrs('table', {
    className: "table table-sm table-var01-custom text-center",
    spacing: {w: 100, mb: 0}
});

export const ResponsiveDiv = withAttrs('div', {
    className: "responsive-custom",
    spacing: {mr: 'auto'}
});


export const ColoredCell = withAttrs('td', {
    className: props => `bg-${props.color}-custom-light`
});

export const StyledCell = withAttrs('td', {
    className: props => `bg-${props.color}-custom text-white font-weight-bold`
});

export const ThCell = withAttrs('th', {
    scope: "col",
    className: "font-weight-normal bg-gray-custom"
});

export const ThTitleCell = withAttrs('th', {
    scope: "col",
    className: "w-custom-33 text-left font-weight-normal bg-gray-custom"
});


export const EventBoxWrapper = withAttrs(WhiteBox, {
    className: "container-var01-custom",
    spacing: {px: 3, pt: 3, pb: 4, mb: 5}
});

export const StyledLink = withAttrs(ILink, {
    className: "clearfix link-container-custom transition-custom",
    spacing: {d: 'block'}
});

export const AnimatedRowWrapper = withAttrs('div', {
    className: "float-right animation-custom transition-custom"
});

export const EventInfoWrapper = withAttrs('div', {
    className: "bg-gray-custom",
    spacing: {p: 3, mt: 1, clearfix: true}
});