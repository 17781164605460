import React from "react";
import EventListPage from "../components/pages/EventListPage/EventListPage";
import resolver from "../resolver";
import {resolve, reverse, Url} from "../components/utils/urlresolve";
import LoginPage from "../components/pages/LoginPage/LoginPage";
import loginRequired from "../components/utils/loginRequired";
import LogoutPage from "../components/pages/LogoutPage/LogoutPage";
import NotFoundPage from '../components/pages/NotFoundPage';
import {withUserChoirs} from "../state/UserChoirsContext";
import SignupPage from "../components/pages/SignupPage/SignupPage";
import PasswordResetPage from "../components/pages/PasswordResetPage/PasswordResetPage";
import PasswordResetConfirmPage from "../components/pages/PasswordResetConfirmPage/PasswordResetConfirmPage";
import ActivateUserPage from "../components/pages/ActivateUserPage/ActivateUserPage";
import InvitationSignupPage from "../components/pages/InvitationSignupPage/InvitationSignupPage";

import MemberPage from "../components/pages/MemberPage/MemberPage";
// const MemberPage = React.lazy(() => import("../components/pages/MemberPage/MemberPage"));
import LetterListPage from "../components/pages/LetterListPage/LetterListPage";
// const LetterListPage = React.lazy(() => import("../components/pages/LetterListPage/LetterListPage"));
import LetterSendForChoirPage from "../components/pages/LetterSendPage/LetterSendForChoirPage";
// const LetterSendForChoirPage = React.lazy(() => import("../components/pages/LetterSendPage/LetterSendForChoirPage"));
import LetterSendForEventPage from "../components/pages/LetterSendPage/LetterSendForEventPage";
// const LetterSendForEventPage = React.lazy(() => import("../components/pages/LetterSendPage/LetterSendForEventPage"));
import OptOutRequestListPage from "../components/pages/OptOutRequestListPage/OptOutRequestListPage";
// const OptOutRequestListPage = React.lazy(() => import("../components/pages/OptOutRequestListPage/OptOutRequestListPage"));
import EventOptOutPage from "../components/pages/EventOptOutPage/EventOptOutPage";
// const EventOptOutPage = React.lazy(() => import("../components/pages/EventOptOutPage/EventOptOutPage"));
import ChoirDetailPage from "../components/pages/ChoirDetailPage/ChoirDetailPage";
// const ChoirDetailPage = React.lazy(() => import("../components/pages/ChoirDetailPage/ChoirDetailPage"));
import ChoirListPage from "../components/pages/ChoirListPage/ChoirListPage";
// const ChoirListPage = React.lazy(() => import("../components/pages/ChoirListPage/ChoirListPage"));
import ChoirEditPage from "../components/pages/ChoirEditPage/ChoirEditPage";
// const ChoirEditPage = React.lazy(() => import("../components/pages/ChoirEditPage/ChoirEditPage"));
import AddMembersPage from "../components/pages/AddMembersPage/AddMembersPage";
// const AddMembersPage = React.lazy(() => import("../components/pages/AddMembersPage/AddMembersPage"));
import ProfileEditPage from "../components/pages/ProfileEditPage/ProfileEditPage";
// const ProfileEditPage = React.lazy(() => import("../components/pages/ProfileEditPage/ProfileEditPage"));
import MyProfileEditPage from "../components/pages/ProfileEditPage/MyProfileEditPage";
// const MyProfileEditPage = React.lazy(() => import("../components/pages/ProfileEditPage/MyProfileEditPage"));
import ChangePasswordPage from "../components/pages/ChangePasswordPage/ChangePasswordPage";
// const ChangePasswordPage = React.lazy(() => import("../components/pages/ChangePasswordPage/ChangePasswordPage"));
import ChoirCalPage from "../components/pages/ChoirCalPage/ChoirCalPage";
// const ChoirCalPage = React.lazy(() => import("../components/pages/ChoirCalPage/ChoirCalPage"));
import OptOutRequestPage from "../components/pages/OptOutRequestListPage/OptOutRequestPage";
// const OptOutRequestPage = React.lazy(() => import("../components/pages/OptOutRequestListPage/OptOutRequestPage"));
import EventDetailPage from "../components/pages/EventDetailPage/EventDetailPage";
// const EventDetailPage = React.lazy(() => import("../components/pages/EventDetailPage/EventDetailPage"));
import EventEditPage from "../components/pages/EventEditPage";
import ActivationResendPage from "../components/pages/ActivationResendPage/ActivationResendPage";
import ChangeUsernamePage from "../components/pages/ChangeUsernamePage/ChangeUsernamePage";
import MultifactorPage from "../components/pages/MultifactorPage/MultifactorPage";
import EmailChangeConfirmPage from "../components/pages/EmailChangeConfirmPage/EmailChangeConfirmPage";
import CcEmailConfirmPage from "../components/pages/CcEmailConfirmPage/CcEmailConfirmPage";
import LoginUnlockPage from "../components/pages/LoginUnlockPage/LoginUnlockPage";
// const EventEditPage = React.lazy(() => import("../components/pages/EventEditPage"));

const withLCN = (Component) => loginRequired(withUserChoirs(Component));

const EventCreatePage = (props) => (
    <EventEditPage {...props} isEdit={false}/>
);

const ChoirCreatePage = (props) => (
    <ChoirEditPage {...props} isEdit={false}/>
);

const urls = [
    new Url({
        name: 'event_list',
        path: '/:lng/',
        component: withLCN(EventListPage),
    }),
    new Url({
        name: 'event_detail',
        path: '/:lng/events/:id/detail/',
        component: withLCN(EventDetailPage),
    }),
    new Url({
        name: 'event_optout',
        path: '/:lng/events/:event_id/optout/',
        component: withLCN(EventOptOutPage),
    }),
    new Url({
        name: 'event_edit',
        path: '/:lng/events/:id/edit/',
        component: withLCN(EventEditPage),
    }),
    new Url({
        name: 'event_send_letter',
        path: '/:lng/events/:id/email/',
        component: withLCN(LetterSendForEventPage),
    }),
    new Url({
        name: 'event_create',
        path: '/:lng/events/create',
        component: withLCN(EventCreatePage),
    }),
    new Url({
        name: 'choir_list',
        path: '/:lng/choirs/',
        component: withLCN(ChoirListPage),
    }),
    new Url({
        name: 'choir_detail',
        path: '/:lng/choirs/:id/detail/',
        component: withLCN(ChoirDetailPage),
    }),
    new Url({
        name: 'choir_add_members',
        path: '/:lng/choirs/:id/add-members/',
        component: withLCN(AddMembersPage),
    }),
    new Url({
        name: 'choir_member',
        path: '/:lng/choirs/:choir_id/member/:id/',
        component: withLCN(MemberPage),
    }),
    new Url({
        name: 'edit_choir_membership',
        path: '/:lng/choirs/:choir_id/member/:id/:action/',
        component: withLCN(MemberPage),
    }),
    new Url({
        name: 'choir_edit',
        path: '/:lng/choirs/:id/edit/',
        component: withLCN(ChoirEditPage),
    }),
    new Url({
        name: 'choir_create',
        path: '/:lng/choirs/create/',
        component: withLCN(ChoirCreatePage),
    }),
    new Url({
        name: 'profile',
        path: '/:lng/profile/',
        component: withLCN(MyProfileEditPage),
    }),
    new Url({
        name: 'optout_requests',
        path: '/:lng/requests/',
        component: withLCN(OptOutRequestListPage),
    }),
    new Url({
        name: 'optout_request',
        path: '/:lng/optout/:token/',
        component: OptOutRequestPage,
    }),
    new Url({
        name: 'profile_edit',
        path: '/:lng/profile/:id/edit/',
        component: withLCN(ProfileEditPage),
    }),
    new Url({
        name: 'change_password',
        path: '/:lng/account/password/',
        component: withLCN(ChangePasswordPage),
    }),
    new Url({
        name: 'change_email',
        path: '/:lng/account/email/',
        component: withLCN(ChangeUsernamePage),
    }),
    new Url({
        name: 'factors',
        path: '/:lng/account/factors/',
        component: withLCN(MultifactorPage),
    }),
    new Url({
        name: 'letters',
        path: '/:lng/letters/',
        component: withLCN(LetterListPage),
    }),
    new Url({
        name: 'choir_download_vcf',
        path: '/:lng/choir/:id/download/vcf',
        component: NotFoundPage,
    }),
    new Url({
        name: 'choir_download_pdf',
        path: '/:lng/choir/:id/download/pdf',
        component: NotFoundPage,
    }),
    new Url({
        name: 'choir_download_ical',
        path: '/:lng/choir/:id/download/ical',
        component: withLCN(ChoirCalPage),
    }),
    new Url({
        name: 'choir_send_letter',
        path: '/:lng/choir/:id/letter/',
        component: withLCN(LetterSendForChoirPage),
    }),
    new Url({
        name: 'signup',
        path: '/:lng/signup/',
        component: SignupPage,
    }),
    new Url({
        name: 'invitation_signup',
        path: '/:lng/invitation/:token/signup/',
        component: InvitationSignupPage,
    }),
    new Url({
        name: 'login',
        path: '/:lng/login/',
        component: LoginPage,
    }),
    new Url({
        name: 'login_unlock',
        path: '/:lng/login/unlock/',
        component: LoginUnlockPage,
    }),
    new Url({
        name: 'need_password_reset',
        path: '/:lng/password/reset/',
        component: PasswordResetPage,
    }),
    new Url({
        name: 'resend_activation',
        path: '/:lng/activate/resend/',
        component: ActivationResendPage,
    }),
    new Url({
        name: 'need_password_reset_confirm',
        path: '/:lng/password/reset/confirm/:uid/:token/',
        component: PasswordResetConfirmPage,
    }),
    new Url({
        name: 'activate_user',
        path: '/:lng/activate/:uid/:token/',
        component: ActivateUserPage,
    }),
    new Url({
        name: 'email_change_confirm',
        path: '/:lng/emailchange/confirm/:token/',
        component: EmailChangeConfirmPage,
    }),
    new Url({
        name: 'cc_email_change_confirm',
        path: '/:lng/ccemailset/confirm/:token/',
        component: CcEmailConfirmPage,
    }),
    new Url({
        name: 'logout',
        path: '/:lng/logout/',
        component: LogoutPage,
    })
];

resolver.register('urls', urls);

resolver.register('reverse', reverse);
resolver.register('resolve', resolve);
export default urls;