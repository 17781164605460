import resolver from "../../resolver";

const LANGUAGE_KEY = 'preferred_language';
const SUPPORTED_LANGUAGES = ['en', 'de'];

export const rememberLanguage = (lng) => {
    localStorage.setItem(LANGUAGE_KEY, lng);
};

export const rememberLanguageIfNone = (lng) => {
    if (storedLanguage()) {
        return;
    }
    rememberLanguage(lng);
};

export const storedLanguage = (defaultLanguage) => {
    const lng = localStorage.getItem(LANGUAGE_KEY);
    if (SUPPORTED_LANGUAGES.indexOf(lng) !== -1) {
        return lng;
    }
    return defaultLanguage;
};

export const changeLanguage = (lng) => {
    if (storedLanguage() === lng) {
        return;
    }
    const resolve = resolver.resolve('resolve');
    const reverse = resolver.resolve('reverse');
    const {url, params} = resolve(window.location.pathname);
    rememberLanguage(lng);
    window.location = reverse(url.name, {...params, lng});
};