import {
  BigEditButton,
  FlexRow,
  GrayButton,
  GreenButton,
  IconedLink,
  WhiteBox,
} from '../../atoms/style';
import React from 'react';
import { FormGroup, ListGroup, ListGroupItem } from 'reactstrap';
import StatusListFetcher from '../../fetchers/StatusListFetcher';
import isAppError from '../../../utils/isAppError';
import useTrans from '../../hooks/useTrans';
import { STATUS_NAMES, STATUS_VALUES } from '../../../constants';
import useToggle from '../../hooks/useToggle';
import BootstrapField from '../../molecules/BootstrapField';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { mixed, number, object } from 'yup';
import { castMomentToDateStr, castToMoment } from '../../validation/utils';
import getApi from '../../../services/getApi';
import isApiFailure from '../../../utils/isApiFailure';
import { EventFormGreyBox } from '../EventEditPage/style';

function getStatusSchema(statuses) {
  const statusSchema = object({
    date: mixed()
      .transform(castToMoment)
      .test('not_in_past', 'validation.date_cannot_be_in_past', (value) => {
        return moment().startOf('day').isBefore(value);
      })
      .test('unique', 'validation.date_must_be_unique', (value) => {
        const same_dates = statuses.filter((obj) =>
          obj ? moment(obj.date).isSame(value, 'date') : false
        );
        return same_dates.length === 0;
      }),
    status: number()
      .oneOf(STATUS_VALUES)
      .default(STATUS_VALUES[0])
      .required('validation.this_field_is_required')
      .when('date', (date, schema) => {
        const statusesBefore = statuses.filter((obj) =>
          obj ? moment(obj.date).isBefore(date) : false
        );
        const lastStatus =
          statusesBefore.length > 0
            ? statusesBefore[statusesBefore.length - 1]
            : null;
        if (lastStatus) {
          return schema.test(
            'test-status',
            'validation.status_is_same',
            (value) => parseInt(value) !== parseInt(lastStatus.status)
          );
        } else {
          return schema;
        }
      }),
  });
  return statusSchema;
}

const onSubmit =
  (args, onSuccess, serializeStatusSchema) =>
  (values, { setSubmitting, setErrors, setStatus }) => {
    const { _submitAndNew, ...data } = values;
    const api = getApi('status');

    const promise = api.createStatus(args, serializeStatusSchema.cast(data));
    promise
      .then((status) => {
        onSuccess();
        setSubmitting(false);
      })
      .catch((error) => {
        console.error('FAILED', error);
        setSubmitting(false);
        if (isAppError(error)) {
          setStatus({ error: error });
          setErrors(error.body);
        }
      });
  };

const AddStatusForm = ({ t, onSuccess, onCancel, statuses, args }) => {
  const initialValues = {
    date: moment(),
    status: 0,
  };

  const statusSchema = getStatusSchema(statuses);

  const serializeStatusSchema = statusSchema.shape({
    date: mixed().transform(castMomentToDateStr),
  });
  return (
    <Formik
      validationSchema={statusSchema}
      initialValues={initialValues}
      onSubmit={onSubmit(args, onSuccess, serializeStatusSchema)}
    >
      {(formProps) => (
        <Form className={'mt-3'}>
          <FormGroup row>
            <BootstrapField
              type={'date'}
              name={'date'}
              md={4}
              label={t('member.from_date_label')}
            />
            <BootstrapField
              type={'select'}
              name={'status'}
              md={8}
              label={t('member.status_label')}
            >
              {STATUS_VALUES.map((status, idx) => (
                <option value={status}>
                  {t(`member.status_${STATUS_NAMES[status]}`)}
                </option>
              ))}
            </BootstrapField>
          </FormGroup>
          <FormGroup>
            <GreenButton>{t('member.add_status')}</GreenButton>
            <GrayButton spacing={{ ml: 2 }} onClick={onCancel}>
              {t('member_status_form.done')}
            </GrayButton>
          </FormGroup>
        </Form>
      )}
    </Formik>
  );
};

export default function ({ args }) {
  const statuses = StatusListFetcher.read(args);
  const invalidate = StatusListFetcher.invalidator(args);
  const t = useTrans();

  const [isEdit, onToggle] = useToggle(false);

  const onSuccess = () => {
    invalidate();
  };

  const onStatusDelete = (id) => {
    getApi('status').deleteStatus(args, id).then(onSuccess);
  };

  if (isApiFailure(statuses)) {
    return null;
  }

  if (isAppError(statuses)) {
    return null;
  }

  return (
    <div>
      <BigEditButton onClick={onToggle} data-t={'choir_member.edit_status'}>
        {t('choir_member.edit_status')}
      </BigEditButton>

      <WhiteBox spacing={{ p: 3 }}>
        {isEdit ? (
          <EventFormGreyBox>
            {t('choir_member.edit_status_note')}
          </EventFormGreyBox>
        ) : null}
        <div>
          <h2>{t('choir_member.statuses_title')}</h2>
        </div>
        <ListGroup>
          {statuses.map((status, idx) => (
            <ListGroupItem key={idx}>
              <FlexRow justify={'between'}>
                <div>
                  {status.date} -{' '}
                  {t(`member.status_${STATUS_NAMES[status.status]}`)}
                </div>
                {isEdit && idx !== 0 ? (
                  <div>
                    <IconedLink
                      className="float-right"
                      color={'red'}
                      icon={'minus-square'}
                      onClick={() => onStatusDelete(status.id)}
                    >
                      {t('choir_member.status_delete')}
                    </IconedLink>
                  </div>
                ) : null}
              </FlexRow>
            </ListGroupItem>
          ))}
        </ListGroup>

        {isEdit ? (
          <AddStatusForm
            t={t}
            args={args}
            statuses={statuses}
            onCancel={onToggle}
            onSuccess={onSuccess}
          />
        ) : null}
      </WhiteBox>
    </div>
  );
}
