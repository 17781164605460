import {FlexLink, UserTitle} from "./AttendanceBox/style";
import React from "react";
import {SmallAvatar} from "../atoms/style";
import useTrans from "../hooks/useTrans";

export default function ({src, name, inactive, title, to}) {
    const t = useTrans();
    return (
        <FlexLink to={to} style={inactive ? {color: 'grey'} : null} title={inactive ? t('user.inactive') : t('user.active')}>
            <SmallAvatar src={src} />
            <UserTitle name={name} title={title} />
        </FlexLink>
    );
}