import {Div, RedButton, WrappedCol, WrappedRow} from "../../atoms/style";
import React from "react";
import useTrans from "../../hooks/useTrans";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import DangerBox from "../DangerBox/DangerBox";
import ChoirFetcher from "../../fetchers/ChoirFetcher";


export default function ({choir_id, user_id}) {
    const t = useTrans();
    const onRemove = () => {
        if (window.confirm(t('choir.remove_member_prompt?'))) {
            getApi("choir_detail").removeMember(choir_id, user_id).then(() => {
                ChoirFetcher.invalidate({choir_id});
                goTo('choir_detail', {id: choir_id});
            })
        }
    };
    return (
        <DangerBox icon={'sign-out-alt'} title={t('choir.remove_member')}>
            <Div spacing={{p: 3, mb: 3}} className="small bg-red-custom-light">
                {t('choir.remove_member_warning')}
            </Div>
            <WrappedRow>
                <WrappedCol md={4} spacing={{md: {mb: 0}, mb: 4}}>
                    <RedButton onClick={onRemove} size={'md'} spacing={{w: 100}} title="">{t('choir.action_remove_member')}</RedButton>
                </WrappedCol>
            </WrappedRow>
        </DangerBox>
    );
}