import React from 'react';
import {Flex} from "../../atoms/style";
import YesNoMaybe from "../YesNoMaybe";
import {ButtonsWrapper, VoiceMemberDecisionWrapper} from "./style";
import UserWithAvatar from "../UserWithAvatar";


const VoiceMemberDecisionForm = ({user, choir_id, onSubmit, canChange, voice}) => {
    const {first_name, last_name, id} = user || {};
    const {title} = voice|| {};
    return (
        <VoiceMemberDecisionWrapper style={{maxWidth: 300}}>
            <UserWithAvatar to={['choir_member', {choir_id, id}]} src={user} name={`${first_name} ${last_name}`} title={title}/>
            {canChange ? <Flex className="align-items-center" spacing={{mt: 0}}>
                <ButtonsWrapper>
                    <YesNoMaybe onSubmit={onSubmit}  className="btn-sm-custom"/>
                </ButtonsWrapper>
            </Flex> : null}
        </VoiceMemberDecisionWrapper>
    );
};

export default VoiceMemberDecisionForm;