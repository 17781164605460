import {useCallback, useState} from "react";
import getApi from "../../../services/getApi";
import {NOT_SETUP} from "./constants";

const useResetFactor = (factorStatus, setFactorStatus) => {
    const [resetSubmitting, setSubmitting] = useState(false);
    const resetFactor = useCallback(() => {
        if (factorStatus) {
            if (factorStatus?.status === NOT_SETUP) {
                setFactorStatus(null);
            } else {
                const api = getApi("factor");
                setSubmitting(true);
                api.resetFactor(factorStatus?.id).then(() => {
                    setFactorStatus(null);
                }).finally(() => {
                    setSubmitting(false);
                });
            }
        }
    }, [setSubmitting, setFactorStatus, factorStatus]);
    return [resetFactor, resetSubmitting];
}

export default useResetFactor;