import useTrans from "../../hooks/useTrans";
import React from "react";
import {Div, SmallAvatar} from "../../atoms/style";
import {Input, Label} from "reactstrap";
import BootstrapField from "../BootstrapField";
import {connect, useField} from "formik";
import InputErrorFeedback from "../InputErrorFeedback";

const PureImageInput = ({label, name, children, formik: {values, setFieldValue}}) => {
    const [, meta] = useField(name);
    const isDelete = values[`_delete_${name}`];
    const value = values[name];
    const t = useTrans();
    return (
        <React.Fragment>
            {children ? children : <SmallAvatar src={values}/>}
            <Label><strong>{label}</strong></Label>
            <Input type={'file'} name={name} disabled={isDelete} onChange={(e) => {
                const theFile = e.currentTarget.files[0];
                setTimeout(() => setFieldValue(`_${name}`, theFile), 1);
                return false;
            }}/>
            <InputErrorFeedback error={meta.error} />
            {value ? (
                <Div spacing={{mt: 2}}>
                    <BootstrapField name={`_delete_${name}`} type={'checkbox'} data-t={`input.delete_${name}`} label={t(`delete_file.${name}`)}/>
                </Div>
            ) : null}
        </React.Fragment>
    );
};

export default connect(PureImageInput);

