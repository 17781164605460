import React from "react";
import {StyledCollapse, StyledCollapseLink} from "./style";
import useToggle from "../../hooks/useToggle";


const EventFormCollapse = ({title, children, defaultIsOpen = false, ...props}) => {
    const [isOpen, toggle] = useToggle(defaultIsOpen);
    return (
        <div>
            <StyledCollapseLink onClick={toggle} isOpen={isOpen} {...props}>{title}</StyledCollapseLink>
            <StyledCollapse isOpen={isOpen}>
                {children}
            </StyledCollapse>
        </div>
    );
};

export default EventFormCollapse;