import React from "react";
import {GreyHeader, H5, IconedNoteBox, MessageBox} from "../../atoms/style";
import EventForm from "./EventForm";
import Markdown from "markdown-to-jsx";

const EventEdit = (props) => {
    const {t, choirs, isEdit} = props;

    if (choirs.length === 0) {
        return (
            <MessageBox>
                <IconedNoteBox spacing={{mb: 0}}>
                    <Markdown>{t("event.not_manager_in_any_choir")}</Markdown>
                </IconedNoteBox>
            </MessageBox>
        );
    }
    return (
        <React.Fragment>
            <GreyHeader>
                <H5 spacing={{my: 1}}>{isEdit ? t('event.edit_event') : t('event.new_event')}</H5>
            </GreyHeader>
            <EventForm {...props}/>
        </React.Fragment>
    );
};

export default EventEdit;