import withAttrs from '../../utils/withAttrs';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
} from 'reactstrap';
import logoImagePath from '../../../img/choirmanager_logo.png';
import { NavLinkUpcase } from '../../atoms/style';

export const UsernameText = withAttrs('span', {
  className: 'd-inline-block text-truncate',
});

export const StyledNavbarBrand = withAttrs(NavbarBrand, {
  className: 'navbar-brand-custom minified-transition-custom',
});

export const StyledContainer = withAttrs(Container, {
  className: 'container-mobile-w100-custom',
  style: { justifyContent: 'inherit' },
  spacing: { px: 0, mx: 0, lg: { mx: 'auto' }, md: { px: 2 } },
});

export const CustomNav = withAttrs(Nav, {
  className: 'main-nav-custom justify-content-end minified-transition-custom',
  navbar: true,
  spacing: { lg: { mr: 2 } },
});

export const NoMdNavItem = withAttrs(NavItem, {
  spacing: { md: { d: 'none' } },
});

export const CustomCollapse = withAttrs(Collapse, {
  className: 'justify-content-end',
  navbar: true,
});

export const CustomNavbar = withAttrs(Navbar, {
  expand: 'md',
  color: 'primary',
  dark: true,
});

export const CustomMetaNav = withAttrs(Nav, {
  className: 'meta-nav-custom minified-transition-custom',
  navbar: true,
});

export const Logo = withAttrs('img', {
  src: logoImagePath,
});

export const CustomNavLink = withAttrs(NavLinkUpcase, {
  className: 'nav-link-custom',
});

export const CustomNavBlank = withAttrs(NavLinkUpcase, {
  className: 'nav-link-custom',
  target: '_blank',
});

export const MdOnlyNavItem = withAttrs(NavItem, {
  spacing: { md: { d: 'inline-block' }, d: 'none' },
});

export const UserNavItem = withAttrs(MdOnlyNavItem, {
  className: 'user-custom',
});
