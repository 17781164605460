import React, {useContext} from "react";
import {Div, HeaderBox, SubmitButton} from "../../atoms/style";
import {Form, Formik} from "formik";
import PollResponseFetcher from "../../fetchers/PollResponseFetcher";
import isAppError from "../../../utils/isAppError";
import getApi from "../../../services/getApi";
import OpenQuestion from "./OpenQuestion";
import MultipleChoice from "./MultipleChoice";
import MemberChoice from "./MemberChoice";
import withAttrs from "../../utils/withAttrs";
import EventCommitmentsFetcher from "../../fetchers/EventCommitmentsFetcher";
import FormStatus from "../FormStatus/FormStatus";
import hasItems from "../../utils/hasItems";
import ComponentList from "../../atoms/ComponentList";
import PollAllResponseFetcher from "../../fetchers/PollAllResponseFetcher";
import ToastContext from "../../contexts/ToastContext";

function filterOut(list) {
    return list.filter(item => item.answer || item.singers || item.options || item.voice);
}

function formatValues({open_question_answers, multiple_choice_answers, member_choice_answers}) {
    return {
        open_question_answers: filterOut(open_question_answers),
        multiple_choice_answers: filterOut(multiple_choice_answers),
        member_choice_answers: filterOut(member_choice_answers)
    };
}


const onSubmit = (event, t, toaster, updater) => (values, {setValues, setSubmitting, setErrors, setStatus}) => {
    const api = getApi("event_detail");
    setSubmitting(true);
    api.updatePollResponses(event.id, formatValues(values)).then(result=> {
        updater(result);
        // PollAllResponseFetcher.invalidate({event_id: event.id});
        // PollResponseFetcher.invalidate({event_id: event.id});
        setValues(mergeResponses(event, result));
        setSubmitting(false);
        toaster.onPush({msg: t('poll_form.answers_saved'), type: 'success'});
        // setStatus({success: t('poll_form.answers_saved')});
    }).catch(error => {
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: t('poll_form.failed_saving_answers')})
        }
    })
};

const _mergeRespones = (questions, answers) => {
    if (!questions) return [];
    return questions.map(question => {
      const answer = answers && answers.length > 0 ? answers.find(answer => answer.poll === question.id) : null;
      return answer ? answer : {
          poll: question.id,
      };
    });
};

const mergeResponses = ({open_questions, multiple_choices, member_choices},
                        {multiple_choice_answers, open_question_answers, member_choice_answers}) => ({
    multiple_choice_answers: _mergeRespones(multiple_choices, multiple_choice_answers),
    open_question_answers: _mergeRespones(open_questions, open_question_answers),
    member_choice_answers: _mergeRespones(member_choices, member_choice_answers),
});


const PollFormSpacer = withAttrs(Div, {
    className: "spacer-custom-var01",
    spacing: {my: 4}
});

const PollsForm = ({event, polls, voices, t}) => {
    const {member_choices, open_questions, multiple_choices} = event;
    const responses = PollResponseFetcher.read({event_id: event.id});
    const eventCommitments = EventCommitmentsFetcher.read({event_id: event.id});
    const responseUpdater = PollResponseFetcher.updator({event_id: event.id});
    const invalidator = PollAllResponseFetcher.invalidator({event_id: event.id});
    const toaster = useContext(ToastContext);
    if (isAppError(responses) || isAppError(eventCommitments)) {
        return null;
    }
    const singers = eventCommitments.map(({user, voice, scp_id}) => ({
        user,
        voice,
        id: scp_id
    }));
    const updater = (result) => {
        responseUpdater(result);
        invalidator();
    };
    return (
        <Formik validateOnBlur={false} isInitialValid={true}
                initialValues={mergeResponses(event, responses)}
                onSubmit={onSubmit(event, t, toaster, updater)}>
            {({isSubmitting, status}) => (
                <Form>
                    <ComponentList items={open_questions} component={OpenQuestion}/>
                    <ComponentList items={multiple_choices} component={MultipleChoice}/>
                    <ComponentList items={member_choices} voices={voices} singers={singers} component={MemberChoice}/>
                    <PollFormSpacer />
                    <FormStatus/>
                    <SubmitButton type={'submit'} disabled={isSubmitting}>{t('poll_response.save')}</SubmitButton>
                </Form>
            )}
        </Formik>
    );
};

const EventPollBox = (props) => {
    const {t, event} = props;
    if (!event) return null;

    const {open_questions, multiple_choices, member_choices} = event;

    const hasPolls = hasItems(open_questions, multiple_choices, member_choices);
    return hasPolls ? (
        <HeaderBox header={t('event.poll_title')}>
            <PollsForm {...props}/>
        </HeaderBox>
    ) : null;
};

export default EventPollBox;