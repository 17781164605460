import useTrans from "../../hooks/useTrans";
import React from "react";
import {Form, Formik, useFormikContext} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import getApi from "../../../services/getApi";
import {object, string} from "yup";
import {passwordSchema} from "../../validation/passwordSchema";
import {GreenButton} from "../../atoms/style";
import FactorContext from "./FactorContext";
import useAuthUser from "../../hooks/useAuthUser";
import {emailSchema} from "../../validation/emailSchema";
import {ModalBody, ModalFooter} from "reactstrap";
import {NOT_SETUP} from "./constants";
import ResetFactor from "./ResetFactor";

const onSubmit = ({t, startCountdown, factor, setFactorStatus}) => (values, {setStatus, setSubmitting}) => {
    setSubmitting(true);
    const api = getApi("factor");
    api.factorEnroll(values.password, values.profile, factor?.factorType, factor?.provider).then(result => {
        if (result?.status) {
            startCountdown();
            setFactorStatus(result);
        }
    }).catch(error => {
        setStatus({error});
    }).finally(() => {
        setSubmitting(false);
    });
}

const phoneRegex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

const profileFieldName = (factor) => {
    if (factor?.factorType === "sms") {
        return [
            "profile.phoneNumber",
            object({
                phoneNumber: string()
                    .required('validation.this_field_is_required')
                    .matches(phoneRegex, "validation.invalid_phone_number")
            })
        ];
    } else if (factor?.factorType === "email") {
        return [
            'profile.email',
            object({
                email: emailSchema
            })
        ];
    } else {
        return [null, object().nullable()];
    }
}

const TheForm = ({fieldName, factorStatus}) => {
    const t = useTrans()
    const {isSubmitting, submitForm, status, errors} = useFormikContext();
    const isPending = factorStatus && factorStatus?.status !== NOT_SETUP;
    return (
        <Form>
            <ModalBody>
                <FormStatus status={status}/>

                {fieldName ? (
                    <BootstrapField disabled={isSubmitting || isPending || fieldName === "profile.email"}
                                    name={fieldName}
                                    label={<strong>{t(`factor.${fieldName}`)}</strong>} required={true}/>
                ) : null}
                <BootstrapField disabled={isSubmitting || isPending} name={'password'} type={'password'}
                                label={<strong>{t('factor.password')}</strong>} required={true}/>
            </ModalBody>
            <ModalFooter>
                <GreenButton data-t={'action.enroll'} disabled={isSubmitting || errors.profile || isPending}
                             onClick={submitForm}>{t('factor.enroll')}</GreenButton>
                <ResetFactor cancel/>
            </ModalFooter>
        </Form>
    )
};


const validationSchema = (profileSchema) => {
    return object({
        password: passwordSchema,
        profile: profileSchema
    });
}

const FactorProfileForm = () => {
    const t = useTrans();
    const user = useAuthUser();
    const factorContext = React.useContext(FactorContext)
    const [fieldName, profileSchema] = profileFieldName(factorContext.factor);
    const profile = fieldName === "profile.email" ? {email: user.email} : {};

    return (
        <div>
            <Formik
                initialValues={{profile}}
                validationSchema={validationSchema(profileSchema)}
                onSubmit={onSubmit({t, ...factorContext})}
            >
                <TheForm t={t} fieldName={fieldName} {...factorContext}/>
            </Formik>
        </div>
    );
}


const EnrollFactor = () => {
    const factorContext = React.useContext(FactorContext);
    if (factorContext?.factorStatus?.status === "ACTIVE") {
        return null;
    }

    return factorContext.factorStatus ? (
        <FactorProfileForm/>
    ) : null;
}
export default EnrollFactor;