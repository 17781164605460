import {abortForCaller, apiCall} from "../utils/abortableFetch";
import i18n from '../setup/i18n';
import * as queryString from "querystring";
import moment from "moment";
import {oktaAuth, OKTA_DOMAIN} from "./okta";
import {beAuthService} from "./beAuth"
import {clearSession} from "./authn";

const popFiles = (data, fields) => {
    const files = fields.map(field => {
        if (data[`_delete_${field}`]) {
            data[field] = null;
            return [field, undefined];
        } else {
            delete data[field];
            return [field, data["_" + field]]
        }
    }).filter(pair => !!pair[1]);
    if (files.length === 0) {
        return null;
    }
    const formData = new FormData();
    files.forEach(pair => {
        const [field, file] = pair;
        formData.append(field, file, file.name);
    });
    return formData;
};


export default class ChoirService {

    constructor(props) {
        this.callerId = props.callerId;
    }

    getEvents = (filter) => {

        let url = "/api/v1/event/";
        if (filter) {
            url = `${url}?${queryString.stringify({from_date: moment().format("YYYY-MM-DD"), ...filter})}`;
        }
        return this.request(url, {params: filter});
    };

    getLetters = ({page = 1}) => {
        return this.request(`/api/v1/letter/?page=${page}`);
    };

    getEventsExcelUrl = (filter) => {
        filter = {
            from_date: moment().format("YYYY-MM-DD"),
            language: i18n.language,
            ...filter
        };
        const url = new URL(window.location.origin + "/api/v1/event/participation_excel/");
        url.search = queryString.stringify(filter);
        return url.toString()
    };

    getEventDetailExcelUrl = (event_id) => `/api/v1/event/${event_id}/excel/?language=${i18n.language}`;

    createEvent = (data) => {
        return this.request("/api/v1/event/", {method: "POST", data});
    };

    inviteMembers = (choir_id, data) => {
        return this.request(`/api/v1/choir/${choir_id}/member/invite/`, {method: "POST", data});
    };

    getMember = (choir_id, user_id) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/`);
    };

    getBirthday = (choir_id) => {
        return this.request(`/api/v1/choir/${choir_id}/birthday/`);
    };

    removeMember = (choir_id, user_id) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/`, {method: "DELETE"});
    };

    getMemberVCFUrl = (choir_id, user_id) => `/api/v1/choir/${choir_id}/member/${user_id}/vcf/?language=${i18n.language}`;

    updateMembershipProfile = (choir_id, user_id, data) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/membership/`, {method: "PATCH", data});
    };

    getStatuses = ({choir_id, user_id}) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/status/`);
    };

    createStatus = ({choir_id, user_id}, data) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/status/`, {method: "POST", data});
    };

    deleteStatus = ({choir_id, user_id}, id) => {
        return this.request(`/api/v1/choir/${choir_id}/member/${user_id}/status/${id}/`, {method: "DELETE"});
    };

    createEventAttachment = (event_id, data, file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('event', event_id);
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                formData.append(property, data[property]);
            }
        }
        return this.request(`/api/v1/event/${event_id}/attachment/`, {
            method: "POST",
            body: formData,
            headers: {'Content-Type': undefined}
        });
    };

    createChoirAttachment = (choir_id, data, file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('choir', choir_id);
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                formData.append(property, data[property]);
            }
        }
        return this.request(`/api/v1/choir/${choir_id}/attachment/`, {
            method: "POST",
            body: formData,
            headers: {'Content-Type': undefined}
        });
    };


    createLetter = (data) => {
        return this.request("/api/v1/letter/", {method: "POST", data});
    };

    deleteLetter = (pk) => {
        return this.request(`/api/v1/letter/${pk}/`, {method: "DELETE"});
    };


    createLetterAttachment = (letter_id, data, file) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        for (var property in data) {
            if (data.hasOwnProperty(property)) {
                formData.append(property, data[property]);
            }
        }
        return this.request(`/api/v1/letter/${letter_id}/attachment/`, {
            method: "POST",
            body: formData,
            headers: {'Content-Type': undefined}
        });
    };

    sendLetter = (letter_id, choir_id) => {
        return this.request(`/api/v1/letter/${letter_id}/send/?choir_id=${choir_id}`, {method: "PATCH"});
    };

    getOptOutDecision = (event_id, scp_id) => {
        return this.request(`/api/v1/event/${event_id}/commitment/${scp_id}/`);
    };

    getAttendanceDecision = (event_id, scp_id) => {
        return this.request(`/api/v1/event/${event_id}/attendance/${scp_id}/`);
    };

    getEventOptOutRequests = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/optout/`);
    };

    getManagerOptOutRequests = ({page = 1}) => {
        return this.request(`/api/v1/optout/?page=${page}`);
    };

    getOptOutRequest = (token) => {
        return this.request(`/api/v1/optout/${token}/`);
    };

    acceptOptOutRequest = (id, manager_comment) => {
        return this.request(`/api/v1/optout/${id}/accept/`, {
            method: "POST",
            data: {
                manager_comment
            }
        });
    };

    rejectOptOutRequest = (id, manager_comment) => {
        return this.request(`/api/v1/optout/${id}/reject/`, {
            method: "POST",
            data: {
                manager_comment
            }
        });
    };

    createOptOutRequest = (event_id, data) => {
        return this.request(`/api/v1/event/${event_id}/optout/`, {
            method: 'POST',
            data: {
                ...data,
                event_id: event_id
            },
        });
    };

    checkEmailValidity = (email, data) => {
        return this.request(`/api/v1/emailcheck/`, {
            method: "POST",
            data: data
        });
    };

    getOptOutDecisions = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/commitment/`);
    };

    getAttendanceDecisions = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/attendance/`);
    };

    getPollAllResponses = (event_id) => {
        return this.request(`/api/v1/event/poll_all_responses/${event_id}/`);
    };

    getPollResponses = (event_id) => {
        return this.request(`/api/v1/event/poll_response/${event_id}/`);
    };

    updatePollResponses = (event_id, data) => {
        return this.request(`/api/v1/event/poll_response/${event_id}/`, {
            method: 'PATCH',
            data,
        });
    };

    updateOptOutDecision = (event_id, scp_id, decision) => {
        return this.request(`/api/v1/event/${event_id}/commitment/${scp_id}/`, {
            method: "PATCH", data: {
                commitment: {decision}
            }
        });
    };

    updateAttendanceDecision = (event_id, scp_id, decision) => {
        console.log("item attendance", event_id, scp_id, decision)
        return this.request(`/api/v1/event/${event_id}/attendance/${scp_id}/`, {
            method: "PATCH", data: {
                commitment: {decision}
            }
        });
    };

    updateEvent = (event_id, data) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=only`, {method: "PATCH", data});
    };

    updateEventThisAndNext = (event_id, data) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=this_and_next`, {method: "PATCH", data});
    };

    updateEventAllRecurring = (event_id, data) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=all`, {method: "PATCH", data});
    };

    updateChoir = (choir_id, data) => {
        const formData = popFiles(data, ["logo", "picture"]);
        const url = `/api/v1/choir/${choir_id}/`;
        return this.request(url, {method: "PATCH", data}).then(choir => {
            if (formData) {
                return this.request(url, {
                    method: "PATCH",
                    body: formData,
                    headers: {'Content-Type': undefined}
                });
            } else {
                return choir;
            }
        });
    };

    createChoir = (data) => {
        const formData = popFiles(data, ["logo", "picture"]);
        const url = "/api/v1/choir/";
        return this.request(url, {method: "POST", data}).then(choir => {
            if (formData) {
                return this.request(`/api/v1/choir/${choir.id}/`, {
                    method: "PATCH",
                    body: formData,
                    headers: {'Content-Type': undefined}
                });
            } else {
                return choir;
            }
        });
    };

    deleteChoir = (choir_id) => {
        return this.request(`/api/v1/choir/${choir_id}/`, {method: "DELETE"});
    };

    deleteEvent = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=only`, {method: "DELETE"});
    };

    deleteEventThisAndNext = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=this_and_next`, {method: "DELETE"});
    };

    deleteEventAllRecurring = (event_id) => {
        return this.request(`/api/v1/event/${event_id}/?action_type=all`, {method: "DELETE"});
    };

    getEvent = ({event_id}) => {
        return this.request(`/api/v1/event/${event_id}/`);
    };

    getChoir = ({choir_id}) => {
        return this.request(`/api/v1/choir/${choir_id}/`);
    };

    getChoirVCFUrl = (choir_id) => `/api/v1/choir/${choir_id}/vcf/?language=${i18n.language}`;

    getChoirPDFUrl = (choir_id) => `/api/v1/choir/${choir_id}/pdf/?language=${i18n.language}`;

    resetChoirIcal = (choir_id) => {
        return this.request(`/api/v1/choir/${choir_id}/ical_reset/`, {method: "POST"});
    };

    leaveChoir = ({choir_id}) => {
        return this.request(`/api/v1/choir/${choir_id}/leave/`, {method: "POST"});
    };

    getNotifications = (props) => {
        return this.request("/api/v1/notification/", props);
    };

    clearNotifications = (maxId) => {
        return this.request(`/api/v1/notification/${maxId}/clear/`, {method: 'DELETE'});
    };

    getUser = (user_id) => {
        return this.request(`/api/v1/user/${user_id}/`);
    };

    getSelfUser = () => {
        return this.request(`/api/v1/auth/me/`);
    };

    getEmailChangeRequest = (userId) => {
        return this.request(`/api/v1/emailchange/${userId}/`);
    };

    confirmCcEmailRequest = (token) => {
        return this.request(`/api/v1/ccemailset/confirm/`, {
            method: "POST",
            auth: false,
            data: {token}
        });
    };

    notifyFailedLogin = (user_id) => {
        return this.request(`/api/v1/ssoreject/`, {
            method: "POST",
            auth: false,
            data: {user_id}
        });
    };

    confirmEmailChangeRequest = (token) => {
        return this.request(`/api/v1/emailchange/confirm/`, {
            method: "POST",
            auth: false,
            data: {token}
        });
    };

    deleteEmailChangeRequest = (userId) => {
        return this.request(`/api/v1/emailchange/${userId}/`, {method: "DELETE"});
    };

    createEmailChangeRequest = (data) => {
        return this.request(`/api/v1/emailchange/`, {
            method: "POST",
            data
        });
    };

    updateUser = (user_id, data) => {
        delete data.avatar;
        if (data._avatar && !data._delete_avatar) {
            const formData = new FormData();
            formData.append('avatar', data._avatar, data._avatar.name);
            for (var property in data) {
                if (property !== "_avatar" && data.hasOwnProperty(property) && data[property]) {
                    formData.append(property, data[property]);
                }
            }
            return this.request(`/api/v1/user/${user_id}/`, {
                method: 'PATCH',
                body: formData,
                headers: {'Content-Type': undefined}
            });
        } else {
            if (data._delete_avatar) {
                data = {
                    ...data,
                    avatar: null
                };
            }
            return this.request(`/api/v1/user/${user_id}/`, {method: 'PATCH', data});
        }
    };

    deleteUser = async (user_id) => {
        const auth = await this.getAuthHeader();
        await clearSession();
        return this.request(`/api/v1/user/${user_id}/`, {method: 'DELETE', auth});
    };

    oktaProxyRequest = async (url, opts = {}) => {
        const oktaAccessToken = await oktaAuth.getAccessToken();
        const auth = `Bearer ${oktaAccessToken}`;
        return this.request(url, {
            auth,
            ...opts,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                ...opts.headers
            }
        })
    }

    oktaRequest = async (url, opts = {}) => {
        const oktaAccessToken = await oktaAuth.getAccessToken();
        const auth = `SSWS ${oktaAccessToken}`;
        return this.request(`https://${OKTA_DOMAIN}${url}`, {
            auth,
            ...opts,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                ...opts.headers
            }
        })
    }

    getUserFactors = () => {
        return this.oktaProxyRequest("/api/v1/proxyokta/api/v1/users/{userId}/factors")
    }

    challengeFactor = (factorId) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors/${factorId}/verify`, {
            method: "POST"
        })
    }

    resetFactor = (factorId) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors/${factorId}`, {
            method: "DELETE"
        })
    }

    factorChallengeVerify = (factorId, passCode) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors/${factorId}/verify`, {
            method: "POST",
            data: {
                passCode
            }
        })
    }

    factorEnroll = (password, profile, factorType = 'email', provider = 'OKTA') => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors`, {
            method: "POST",
            headers: {
                'X-User-Pwd': password
            },
            data: {
                factorType,
                provider,
                profile
            }
        })
    }

    factorActivate = (factorId, passCode) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors/${factorId}/lifecycle/activate`, {
            method: "POST",
            data: {
                passCode
            }
        })
    }

    factorResend = (factorId) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/factors/${factorId}/resend`, {
            method: "POST"
        })
    }

    getUserFactorsCatalog = () => {
        return this.oktaProxyRequest("/api/v1/proxyokta/api/v1/users/{userId}/factors/catalog")
    }

    updateOktaUser = async (data) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}`, {
            method: "POST",
            data
        })
    }

    getOktaUser = () => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}`);
    }

    getOktaUserGroups = () => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/users/{userId}/groups`);
    }

    addUserToGroup = (groupId, add = true) => {
        return this.oktaProxyRequest(`/api/v1/proxyokta/api/v1/groups/${groupId}/users/{userId}`, {
            method: add ? 'PUT': 'DELETE'
        });
    }

    changePassword = (user_id, data) => {
        return this.request(`/api/v1/auth/password/`, {method: 'POST', data});
    };

    changeUsername = (data) => {
        return this.request(`/api/v1/user/change_username/`, {method: 'POST', data});
    };

    resetPassword = (data) => {
        return this.request('/api/v1/auth/password/reset/', {method: 'POST', data});
    };

    resendActivation = (data) => {
        return this.request('/api/v1/auth/users/resend_activation/', {method: 'POST', data});
    };

    resetPasswordConfirm = (data) => {
        return this.request('/api/v1/auth/password/reset/confirm/', {method: 'POST', data});
    };

    activateUser = (data) => {
        return this.request('/api/v1/auth/users/activate/', {method: 'POST', data});
    };

    getSingerInvitation = (token) => {
        return this.request(`/api/v1/invitation/${token}/`);
    };


    signupInvitedUser = (token, data) => {
        return this.request(`/api/v1/invitation/${token}/signup/`, {
            method: 'POST', data: {
                ...data,
                email: data.username
            }
        });
    };

    signupUser = (data) => {
        return this.request('/api/v1/auth/signup/', {
            method: 'POST', data: {
                ...data,
                email: data.username
            }
        });
    };

    getChoirs = (props) => {
        return this.request("/api/v1/choir/", props);
    };

    abort = () => {
        abortForCaller(this.callerId);
    };

    getLanguage = () => {
        return i18n.language
    };

    getAuthHeader = async () => {
        const jwtToken = await beAuthService.getAccessToken();
        if (jwtToken) {
            return 'JWT ' + jwtToken
        } else {
            const oktaAccessToken = await oktaAuth.getAccessToken();
            if (oktaAccessToken) {
                return 'Bearer ' + oktaAccessToken
            }
        }
    };

    buildParams = async (params) => {
        const headers = {
            'Accept-Language': this.getLanguage(),
            ...(params ? params.headers : null)
        };
        params = {auth: true, method: "GET", ...params};
        if (params.auth) {
            if (params.auth !== true) {
                headers['Authorization'] = params.auth;
            } else {
                headers['Authorization'] = await this.getAuthHeader();
            }
        }
        params.headers = headers;
        return params;
    };

    request(url, params) {
        return this.buildParams(params).then(params => apiCall(url, params, this.callerId));
    }

}