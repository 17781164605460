import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {GreyButton} from "../../pages/EventEditPage/style";
import useTrans from "../../hooks/useTrans";

const PromptButton = ({removePrompt, onClick, text, ...props}) => {
    const onClickAndRemovePrompt = React.useCallback(() => {
        if (onClick) {
            onClick();
        }
        removePrompt();
    }, [onClick, removePrompt])
    return (
        <GreyButton {...props} onClick={onClickAndRemovePrompt}>{text}</GreyButton>
    )
}

export default function Prompt({prompt, removePrompt}) {
    const t = useTrans();
    const onClose = React.useCallback(() => {
        removePrompt();
        if (prompt?.onClose) {
            prompt.onClose();
        }
        if (prompt?.reject) {
            prompt.reject()
        }
    }, [removePrompt, prompt]);
    const onResolve = React.useCallback((value) => {
        if (prompt?.resolve) {
            prompt.resolve(value)
        }
        removePrompt();
    }, [removePrompt, prompt]);
    const cancelButton = (
        <GreyButton onClick={onClose} data-t={"button.close"} data-dismiss="modal">{t('action.close')}</GreyButton>
    );
    const PromptComponent = prompt.component ? prompt.component : null;
    const footer = (
        <ModalFooter>
            {prompt.actions ? prompt.actions.map((props, idx) => (
                <PromptButton key={idx} {...props} removePrompt={removePrompt} />
            )) : null}
            {cancelButton}
        </ModalFooter>
    );
    return (
        <Modal isOpen={true} centered={true} className={'modal-sm'} size={'sm'} toggle={onClose} data-t={"modal.prompt"}>
            {prompt.title ? (
                <ModalHeader toggle={onClose}>
                    {prompt.title}
                </ModalHeader>
            ) : null}
            {prompt.body ? (
                <ModalBody>
                    {prompt.body}
                </ModalBody>
            ) : null}
            {PromptComponent ? <PromptComponent close={onClose} resolve={onResolve} cancelButton={cancelButton} {...prompt.props} /> : footer}
        </Modal>
    )
}
