import useTrans from "../../hooks/useTrans";
import NotFoundPage from "../NotFoundPage";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import React from "react";
import useChoir from "../../hooks/useChoir";
import SendLetter from "./SendLetter";
import goTo from "../../utils/goTo";
import EventFetcher from "../../fetchers/EventFetcher";
import isNotFound from "../../../utils/isNotFound";
import EventCommitmentsFetcher from "../../fetchers/EventCommitmentsFetcher";
import isAppError from "../../../utils/isAppError";
import {DECISION_NAMES, DECISION_TYPES} from "../../../constants";

const userSelectionForCommitment = (eventCommitments, decision_code) => eventCommitments.filter(
    eventCommitment => eventCommitment && eventCommitment.commitment && eventCommitment.commitment.decision === decision_code
).map(commitment => commitment.user.id);

const userSelectionByCommitment = (eventCommitments) => DECISION_TYPES.map(decision_code => [
    DECISION_NAMES[decision_code],
    userSelectionForCommitment(eventCommitments, decision_code)
]);

export default (props) => {
    const {match: {params}} = props;
    const event = EventFetcher.read({event_id: params.id});
    const choir = useChoir(event.choir_id);

    const t = useTrans();

    if (isNotFound(event)) {
        return <NotFoundPage/>;
    }

    const eventCommitments = EventCommitmentsFetcher.read({event_id: event.id});
    let withCommitments = null;
    if (!isAppError(eventCommitments)) {
        withCommitments = userSelectionByCommitment(eventCommitments);
    }

    const uri = ['event_detail', {id: event.id}];
    return (
        <PageWithSidebar title={t('letters.send_letter_for_event_page_title')}
                         sidebar={<SideBar />}
                         breadcrumbs={[{title: event.title, to: ['event_detail', {id: event.id}]}]} >
            <SendLetter t={t} choir={choir} withCommitments={withCommitments} cancelUri={uri} onSuccess={() => {
                goTo('event_detail', {id: event.id})
            }}/>
        </PageWithSidebar>
    ) ;
};