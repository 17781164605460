import PageFeedback from "../../molecules/PageFeedback";
import React from 'react';
import useTrans from "../../hooks/useTrans";
import {CenteringRow, LightColumn} from "../LoginPage/style";
import PasswordResetForm from "./PasswordResetForm";
import Markdown from "markdown-to-jsx";
import useToggle from "../../hooks/useToggle";
import LoginFormContainer from "../../molecules/LoginFormContainer/LoginFormContainer";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";


export default () => {
    const t = useTrans();
    const [isRequested, toggleRequested] = useToggle();
    if (isRequested) {
        return (
            <SimpleFeedback title={t('password_reset.request_success')}>
                <Markdown data-t={"success-note"}>{t('password_reset.reset_request_success')}</Markdown>
            </SimpleFeedback>
        );
    }
    return (
        <PageFeedback>
            <CenteringRow>
                <LightColumn>
                    <LoginFormContainer>
                        <PasswordResetForm  onSuccess={toggleRequested}/>
                    </LoginFormContainer>
                </LightColumn>
            </CenteringRow>
        </PageFeedback>
    );
}