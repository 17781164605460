import React, {useContext} from "react";
import useTrans from "../../hooks/useTrans";
import {Badge, StyledNavLinkUpcase, Text} from "./style";
import NotificationsContext from "../../contexts/NotificationsContext";

const MessageCounterLink = ({t, count, onOpen}) => (
    <StyledNavLinkUpcase onClick={onOpen}>
        <Badge data-t={'badge.notifications'} num={count}/>
        <Text>{t('nav.notifications')}</Text>
    </StyledNavLinkUpcase>
);

export default (props) => {
    const {notifications, open} = useContext(NotificationsContext);
    return notifications ? (
        <MessageCounterLink {...props} t={useTrans()} count={notifications ? notifications.length : 0} onOpen={open}/>
    ) : null;
};