import useTrans from "../../hooks/useTrans";
import NotFoundPage from "../NotFoundPage";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import React from "react";
import useChoir from "../../hooks/useChoir";
import SendLetter from "./SendLetter";
import goTo from "../../utils/goTo";

export default (props) => {
    const {match: {params}} = props;
    const choir = useChoir(params.id);

    const t = useTrans();

    if (!choir) {
        return <NotFoundPage/>;
    }
    const uri = ['choir_detail', {id: choir.id}];
    return (
        <PageWithSidebar title={t('letters.send_letter_for_choir_page_title')}
                         sidebar={<SideBar />}
                         breadcrumbs={[{title: choir.name, to: ['choir_detail', {id: choir.id}]}]} >
            <SendLetter data-t={'form.send_letter_to_choir'} t={t} choir={choir} cancelUri={uri} onSuccess={() => {
                goTo('letters')
            }}/>
        </PageWithSidebar>
    ) ;
};