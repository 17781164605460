import {useOktaAuth} from "@okta/okta-react";
import useBeAuth from "./useBeAuth";
import React from "react";

export default function useAuth() {
    const {authState, oktaAuth} = useOktaAuth();
    const {beAuthState} = useBeAuth();
    let auth = {
        isAuthenticated: authState.isAuthenticated,
        isPending: authState.isPending,
        token: authState.accessToken,
    }
    if (beAuthState && beAuthState.isAuthenticated) {
        if (authState.isAuthenticated) {
            oktaAuth.signOut();
        }
        auth = {
            isAuthenticated: beAuthState.isAuthenticated,
            isPending: beAuthState.isPending,
            token: beAuthState.accessToken
        };
    }

    return React.useMemo(() => ({
        isAuthenticated: auth.isAuthenticated,
        isPending: auth.isPending,
        token: auth.token
    }), [auth.isAuthenticated, auth.isPending, auth.token])
}