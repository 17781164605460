import React, {useContext} from 'react';
import {BigEditButton} from "../../atoms/style";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import useTrans from "../../hooks/useTrans";
import NotFoundPage from "../NotFoundPage";
import isNotFound from "../../../utils/isNotFound";
import WithChoir from "../../molecules/WithChoir";
import ChoirContext from "../../contexts/ChoirContext";
import ChoirDetailBox from "../../molecules/ChoirDetailBox/ChoirDetailBox";
import ChoirFetcher from "../../fetchers/ChoirFetcher";
import ChoirSingersBox from "../../molecules/ChoirSingersBox/ChoirSingersBox";
import LeaveChoirBox from "../../molecules/LeaveChoirBox/LeaveChoirBox";
import EventAttachmentBox from "../../molecules/EventAttachmentBox";
import getApi from "../../../services/getApi";
import DownloadBox from "../../molecules/DownloadBox";
import useAuthUser from "../../hooks/useAuthUser";

const ChoirDetail = (props) => {
    const {t} = props;
    const {choir, canEdit} = useContext(ChoirContext);
    const api = getApi("choir");
    const user = useAuthUser();

    return (
        <React.Fragment>
            {canEdit ? (
                <BigEditButton to={['choir_edit', {id: choir.id}]}>{t('choir.edit')}</BigEditButton>
            ) : null}

            <DownloadBox spacing={{mb: 4}} links={[
                {to: ['choir_member', {choir_id: choir.id, id: user.id}], title: t('choir.membership_profile')},
                {url: api.getChoirVCFUrl(choir.id), icon: "download", title: t('choir.address_vcf')},
                {url: api.getChoirPDFUrl(choir.id), icon: "download", title: t('choir.address_pdf')},
                {to: ['choir_download_ical', {id: choir.id}], icon: "calendar-alt", title: t('choir.subscribe_to_events')},
                {to: ['choir_send_letter', {id: choir.id}], icon: "envelope", title: t('choir.send_letter')},
            ]}/>

            <ChoirDetailBox/>

            <EventAttachmentBox attachments={choir.attachments}/>
            {/*<ChoirAttachmentBox attachments={choir.attachments}/>*/}

            <ChoirSingersBox />

            <LeaveChoirBox />

        </React.Fragment>
    );
};


const ChoirDetailPage = (props) => {
    const {t} = props;
    return (
        <PageWithSidebar title={t('choir.detail_title')}
                         sidebar={<SideBar />}
                         breadcrumbs={[{title: t('nav.choirs'), to: 'choir_list'}]}
                         page={'choir_detail'} >
            <ChoirDetail {...props}/>
        </PageWithSidebar>
    );
};

export default (props) => {
    const {match: {params}} = props;
    // const choir = useChoir(parseInt(params.id));
    const choir = ChoirFetcher.read({choir_id: params.id});
    if (isNotFound(choir)) {
        return <NotFoundPage/>;
    }
    return (
        <WithChoir choir={choir}>
            <ChoirDetailPage {...props} t={useTrans()} />
        </WithChoir>
    ) ;
};