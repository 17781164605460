import moment from "moment-timezone";
import React from "react";
import {FromHourToHourWrapper, StyledH3, VertLine} from "./style";
import useTrans from "../../hooks/useTrans";
import DateTimePopover from "../DateTimePopover/DateTimePopover";


export const date_range = (t, from_date, to_date) => {
    const from_str = moment(from_date).format(t("event.fmt_title_from", "LLL"));
    const to_str = moment(to_date || from_date).format(t('event.fmt_title_to', "LLL"));
    if (from_str === to_str) {
        return from_str;
    }
    return `${from_str} - ${to_str}`;
};

const remove_00 = (time) => time ? time.replace(/:00$/, '') : time;

export const time_range = (t, from_hour, to_hour) => {
    if (!from_hour) {
        return null;
    }
    from_hour = remove_00(from_hour);
    to_hour = remove_00(to_hour);
    if (from_hour === to_hour) {
        return from_hour;
    }
    const uhr = t('uhr') === "EMPTY" ? '' : ' ' + t('uhr');
    const to_hour_str = to_hour ? ` - ${to_hour}${uhr}` : '';
    return `${from_hour}${to_hour_str}`;
};

const FromHourToHour = ({from_hour = null, to_hour, t}) => {
    const timeRange = React.useMemo(() => time_range(t, from_hour, to_hour), [t, from_hour, to_hour]);
    return from_hour ? (
        <FromHourToHourWrapper cy-from-hour={from_hour} cy-to-hour={to_hour}>
            <VertLine />
            {timeRange}
        </FromHourToHourWrapper>
    ) : null;
};

const EventHeadline = (props) => {
    const t =  useTrans();
    const {from_date, to_date} = props;
    const dateRange = React.useMemo(() => date_range(t, from_date, to_date), [t, from_date, to_date]);
    return (
        <StyledH3>
            <DateTimePopover timeString={props.start_time} timezone={props.timezone}>
                {dateRange}
                <FromHourToHour {...props}/>
            </DateTimePopover>
        </StyledH3>
    );
};

export default EventHeadline;