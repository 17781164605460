import React from 'react';
import {
  ContactsCol,
  Copyright,
  CustomRow,
  ExternalWhiteLink,
  FooterMenuWrapper,
  FooterWrapper,
  IconedCol,
  IconedLinksWrapper,
  NavCol,
  TelLink,
  WhiteLink,
  WhiteLinkBlank,
} from './style';
import { Icon, SrOnly } from '../../atoms/style';
import { Col, Container, Row } from 'reactstrap';
import withTrans from '../../atoms/withTrans';

const PureFooterContacts = ({ t }) => (
  <ContactsCol>
    <p>
      {t('data.company_title')}
      <br />
      {t('data.company_address')}
    </p>
    <p>
      {t('label.email')}:{' '}
      <WhiteLink
        href={`mailto:${t('data.contact_email')}`}
        title={t('action.write_an_email')}
      >
        {t('data.contact_email')}
      </WhiteLink>
      <br />
      {t('label.phone')}:{' '}
      <TelLink href={`tel:${t('data.contact_phone')}`} title="">
        {t('data.contact_phone')}
      </TelLink>
      <br />
      {t('label.fax')}: {t('data.contact_fax')}
    </p>
  </ContactsCol>
);

const FooterContacts = withTrans(PureFooterContacts);

const PureFooterMenu = ({ links, t, i18n }) => (
  <FooterMenuWrapper>
    {links.map(({ title, ...props }, idx) => {
      return (
        <React.Fragment key={idx}>
          <WhiteLink {...props}>{t(title)}</WhiteLink>
          <br />
        </React.Fragment>
      );
    })}
  </FooterMenuWrapper>
);

const FooterMenu = withTrans(PureFooterMenu);

const PureFooterMenuBlank = ({ links, t, i18n }) => (
  <FooterMenuWrapper>
    {links.map(({ title, ...props }, idx) => {
      return (
        <React.Fragment key={idx}>
          <WhiteLinkBlank {...props}>{t(title)}</WhiteLinkBlank>
          <br />
        </React.Fragment>
      );
    })}
  </FooterMenuWrapper>
);

const FooterMenuBlank = withTrans(PureFooterMenuBlank);

const _links1 = [
  { title: 'nav.home', to: 'mhref_home' },
  { title: 'nav.contacts', to: 'mhref_contacts' },
  { title: 'nav.policy', to: 'mhref_policy' },
  { title: 'nav.dataprotection', to: 'mhref_data_protection' },
  { title: 'nav.imprint', to: 'mhref_imprint' },
];
const _links2 = [
  { title: 'nav.events', to: 'event_list' },
  { title: 'nav.choirs', to: 'choir_list' },
  { title: 'nav.profile', to: 'profile' },
  { title: 'nav.help', to: 'lhref_help' },
  { title: 'nav.logout', to: 'logout' },
];

const ExteralIconedLink = ({ href, icon, title }) => (
  <ExternalWhiteLink href={href}>
    <Icon icon={icon} />
    <SrOnly>{title}</SrOnly>
  </ExternalWhiteLink>
);
ExteralIconedLink.defaultProps = {
  href: 'https://www.facebook.com/ChoirManager',
  icon: ['fab', 'facebook-f'],
  title: 'Facebook',
};

const FacebookLink = () => (
  <ExteralIconedLink
    href={'https://www.facebook.com/ChoirManager'}
    icon={['fab', 'facebook-f']}
    title={'Facebook'}
  />
);

const TwitterLink = () => (
  <ExteralIconedLink
    href={'https://twitter.com/choirmanager'}
    icon={['fab', 'twitter']}
    title={'Twitter'}
  />
);

const Footer = ({ links1, links2 }) => {
  return (
    <FooterWrapper>
      <Container>
        <CustomRow>
          <FooterContacts />
          <NavCol>
            <FooterMenuBlank links={links1} />
          </NavCol>
          <NavCol>
            <FooterMenu links={links2} />
          </NavCol>
        </CustomRow>
        <Row>
          <IconedCol md={10}>
            <IconedLinksWrapper>
              <FacebookLink />
              <TwitterLink />
            </IconedLinksWrapper>
          </IconedCol>
          <Col md={2}>
            <Copyright />
          </Col>
        </Row>
      </Container>
    </FooterWrapper>
  );
};
Footer.defaultProps = {
  links1: _links1,
  links2: _links2,
};

export default Footer;
