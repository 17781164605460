import {DECISION_CODES} from "../../../constants";

export const COLOR_MAP = {
    [DECISION_CODES.no]: 'red',
    [DECISION_CODES.yes]: 'green',
    [DECISION_CODES.maybe]: 'yellow',
};
export const DECISION_MAP = {
    [DECISION_CODES.no]: 'optout.no',
    [DECISION_CODES.yes]: 'optout.yes',
    [DECISION_CODES.maybe]: 'optout.maybe',
    [DECISION_CODES.later]: 'optout.later',
};