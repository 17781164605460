import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {object, ref, string} from "yup";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, SubmitButton, WhiteBox} from "../../atoms/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import {Row} from "reactstrap";
import ToastContext from "../../contexts/ToastContext";
import {passwordSchema} from "../../validation/passwordSchema";

const passwordChangeSchema = object({
    current_password: string().required('validation.this_field_is_required'),
    new_password: passwordSchema,
    re_new_password: string().required('validation.this_field_is_required')
        .oneOf([ref('new_password'), null], 'validation.passwords_should_match'),
});

const onSubmit = ({t, toaster, user_id}) => (values, {setSubmitting, setErrors, setStatus}) => {
    const api = getApi("user");
    const promise = api.changePassword(user_id, values);

    promise.then((choir)=>{
        setSubmitting(false);
        toaster.onPush({msg: t('success.password_changed'), type: 'success'});
        goTo('profile');
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};


const TheForm = ({t, formProps: {isSubmitting, setStatus, touched, errors, status}}) => {

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status}/>

                <BootstrapField type={'password'} name={'current_password'} label={<strong>{t('change_password_form.current_password')}</strong>} required={true}/>
                <BootstrapField type={'password'} name={'new_password'} label={<strong>{t('change_password_form.new_password')}</strong>} required={true}/>
                <BootstrapField type={'password'} name={'re_new_password'} label={<strong>{t('change_password_form.re_new_password')}</strong>} required={true}/>

                <EventFormSpacer />

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} disabled={isSubmitting}>{t('change_password_form.save')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton to={'profile'}>{t('change_password_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const initialValues = {
        current_password: "",
        new_password: "",
        re_new_password: "",
    };
    const toaster = useContext(ToastContext);
    return (
        <Formik validationSchema={passwordChangeSchema} initialValues={initialValues} onSubmit={onSubmit({toaster, ...props})}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
}