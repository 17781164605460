import useTrans from "../../hooks/useTrans";
import React from "react";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import {GreyHeader, H5} from "../../atoms/style";
import useAuthUser from "../../hooks/useAuthUser";
import ChangePasswordForm from "./ChangePasswordForm";

export default function ({match: {params}}) {
    const t = useTrans();
    const user = useAuthUser();

    return (
        <PageWithSidebar title={t('account.change_password')} sidebar={<SideBar />}>
            <GreyHeader>
                <H5 spacing={{my: 1}}>{t('account.change_password')}</H5>
            </GreyHeader>
            <ChangePasswordForm t={t} user_id={user.id} />


        </PageWithSidebar>
    )
}