import React from "react";
import useTrans from "../../hooks/useTrans";
import {Card, CardBody, CardTitle, ModalBody} from "reactstrap";
import {GreenButton} from "../../atoms/style";

const FactorButton = ({factor, onSelect}) => {
    const t = useTrans();
    const onClick = React.useCallback(() => {
        onSelect(factor)
    }, [factor, onSelect]);
    return (
        <Card>
            <CardBody>
                <CardTitle>{t(`login_factor.title.${factor.factorType.replace(/:/g, "_")}_${factor.provider}`)}</CardTitle>
                <GreenButton data-t={"login_factor.select_action"} onClick={onClick}>{t(`login_factor.select_action`)}</GreenButton>
            </CardBody>
        </Card>
    )
}

export const FactorSelectionInput = ({resolve, factors}) => {
    return (
        <ModalBody>
            {factors.map(factor => (
                <FactorButton factor={factor} onSelect={resolve}/>
            ))}
        </ModalBody>
    );
}