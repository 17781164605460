import usePrompt from "./usePrompt";
import React from "react";

export default function usePromptPromise() {
    const promptModal = usePrompt();
    return React.useCallback((promptConfig) => {
        return new Promise((resolve, reject) => {
            promptModal({
                ...promptConfig,
                resolve,
                reject
            });
        });
    }, [promptModal]);

}