import PageFeedback from "../../molecules/PageFeedback";
import React from "react";
import {GreenBox, GreyHeader, GreySubmitButton, H4, HelpText, NoteBox, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import useTrans from "../../hooks/useTrans";
import {Col, FormGroup, Row} from "reactstrap";
import Markdown from "markdown-to-jsx";
import BootstrapField from "../../molecules/BootstrapField";
import {boolean, object, ref, string} from "yup";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import useToggle from "../../hooks/useToggle";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import isNotFound from "../../../utils/isNotFound";
import InvitationFetcher from "../../fetchers/InvitationFetcher";
import isApiError from "../../../utils/isApiError";

const signupSchema = object({
    first_name: string().required('validation.this_field_is_required'),
    last_name: string().required('validation.this_field_is_required'),

    password: string().required('validation.this_field_is_required'),
    re_password: string().required('validation.this_field_is_required')
        .oneOf([ref('password'), null], 'validation.passwords_should_match'),
    terms_agree: boolean().required('validation.this_field_is_required')
        .oneOf([true], 'validation.terms_must_be_accepted'),
});

const onSubmit = ({t, token, onSuccess}) => (values, {setSubmitting, setErrors, setStatus}) => {
    const api = getApi("user");
    const promise = api.signupInvitedUser(token, values);

    promise.then((choir)=>{
        setSubmitting(false);
        onSuccess();
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error});
            setErrors(error.body)
        }
    });
};

const TheForm = ({t, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    return (
        <Form data-t={'form'}>

            <GreyHeader spacing={{py: 1}}><H4 spacing={{my: 2}}>{t('signup_form.header')}</H4></GreyHeader>

            <WhiteBox spacing={{px: 3, pt: 3, pb: 4}}>

                <FormStatus status={status}/>

                <FormGroup row>
                    <BootstrapField col={6} required={true} name={'first_name'} label={<strong>{t('profile_form.first_name')}</strong>}/>
                    <BootstrapField col={6} required={true} name={'last_name'} label={<strong>{t('profile_form.last_name')}</strong>}/>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField col={6} required={true} name={'password'}
                                    label={<strong>{t('profile_form.password')}</strong>}
                                    type={'password'}/>
                    <BootstrapField col={6} required={true} name={'re_password'}
                                    label={<strong>{t('profile_form.re_password')}</strong>}
                                    type={'password'}/>
                    <Col>
                        <HelpText>{t('choir_form.password_help_text')}</HelpText>
                    </Col>
                </FormGroup>

                <GreenBox>
                    <BootstrapField type={'checkbox'} name={'terms_agree'} label={<Markdown>{t('signup_form.terms_agree_md?')}</Markdown>}/>
                </GreenBox>

                <NoteBox>
                    <Markdown>{t("signup_form.note_md")}</Markdown>
                </NoteBox>

                <EventFormSpacer/>

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton disabled={isSubmitting}>{t('signup_form.register')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton data-t={'signup_form.cancel'} to={"/"}>{t('signup_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>
            </WhiteBox>

        </Form>
    );
};

const SignupForm = (props) => {
    return (
        <Formik validationSchema={signupSchema} initialValues={{}} onSubmit={onSubmit(props)}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
};

export default function ({match}) {
    const {params: {token}} = match;
    const invitation = InvitationFetcher.read(token);
    const [isRequested, toggleRequested] = useToggle();
    const t = useTrans();

    if (isNotFound(invitation) || isApiError(invitation)) {
        return (
            <SimpleFeedback title={t('invitation.invalid_or_expired')} success={false}>
                <Markdown data-t={"failure-note"}>{t('invitation.invalid_or_expired_md')}</Markdown>
            </SimpleFeedback>
        );
    }

    if (isRequested){
        return (
            <SimpleFeedback title={t('signup.signup_success')}>
                <Markdown data-t={"success-note"}>{t('signup.signup_success_note')}</Markdown>
            </SimpleFeedback>
        );
    }

    if (invitation && invitation.registration_required) {
        return (
            <PageFeedback>
                <Row className="justify-content-md-center">

                    <Col lg={9}>
                        <SignupForm t={t} token={token} onSuccess={toggleRequested} />
                    </Col>

                </Row>
            </PageFeedback>
        );
    } else {
        return (
            <SimpleFeedback title={t('invitation.invitation_success')}>
                <Markdown data-t={"success-note"}>{t('invitation.invitation_success_note')}</Markdown>
            </SimpleFeedback>
        );
    }

}