import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import history from './setup/history'
import './setup/setup';
import App from './App';

import {Router} from "react-router-dom";
import GlobalCache from './components/atoms/GlobalCache';
import i18n from "./setup/i18n";
import PageLoading from "./components/atoms/PageLoading";
import * as Sentry from '@sentry/browser';
import {storedLanguage} from "./components/utils/language";
import useHjkHook from "./components/hooks/useHjkHook";
import {detect} from "detect-browser";
import {Security} from "@okta/okta-react";
import {oktaAuth, onAuthRequired, restoreOriginalUri} from "./services/okta";
import {BeAuthProvider} from "./components/molecules/BeAuthProvider";

const browser = detect();
const isUnsupported = browser.name === "ie";

promiseFinally.shim();

if (process.env.NODE_ENV === 'production') {
    if (window.location.hostname === 'app.choirmanager.com') {
        Sentry.init({dsn: "https://7556ab095c6e46d69f635542a2c8bc72@sentry.io/1512640"});
    }

    if (window.location.hostname === 'app-staging.choirmanager.com') {
        Sentry.init({dsn: "https://54a3ada3ab4642bd80857af7e23885fa@sentry.io/1512643"});
    }
}

const handleLanguage = (location) => {
    if (location.pathname.startsWith("/en")) {
        if (i18n.language !== 'en') {
            i18n.changeLanguage("en");
        }
    } else if (location.pathname.startsWith("/de")) {
        if (i18n.language !== 'de') {
            i18n.changeLanguage("de");
        }
    } else if (location.pathname.startsWith("/implicit")) {
        // do nothing
    } else {
        history.push({
            ...location,
            pathname: `/${storedLanguage(i18n.language ? i18n.language : 'de')}${location.pathname}`,
        });
    }
};

const onUrl = (location) => {
    handleLanguage(location);
};

history.listen((location, action) => {
    window.scrollTo(0, 0);
    onUrl(location);
    console.log(action, location.pathname, location.state);
});

const HijackWrapper = (props) => {
    useHjkHook();
    return props.children;
};

const UnsupportedBrowser = (props) => {
    if (isUnsupported) {
        return (
            <div style={{padding: 10, textAlign: 'center'}}>
                <p>
                    Your browser is not supported. Please <a href={"https://browser-update.org/update.html"} rel="noopener noreferrer" target="_blank">update your browser</a>
                </p>
                <p>
                    Ihr Browser wird nicht unterstützt. Bitte <a href={"https://browser-update.org/update.html"} rel="noopener noreferrer" target="_blank">updaten Sie ihren Browser</a>
                </p>
            </div>
        )
    } else {
        return props.children;
    }
};


onUrl(window.location);

ReactDOM.render(
    <React.StrictMode>
        <UnsupportedBrowser>
            <GlobalCache.Render>
                <Suspense fallback={<PageLoading/>}>
                    <BeAuthProvider>
                        <Security oktaAuth={oktaAuth}
                                  onAuthRequired={onAuthRequired}
                                  restoreOriginalUri={restoreOriginalUri}>
                            <HijackWrapper>
                                <Router history={history}>
                                    <App />
                                </Router>
                            </HijackWrapper>
                        </Security>
                    </BeAuthProvider>
                </Suspense>
            </GlobalCache.Render>
        </UnsupportedBrowser>
    </React.StrictMode>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
