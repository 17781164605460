import withAttrs from '../../utils/withAttrs';
import { Col, Row } from 'reactstrap';
import { ILink } from '../../atoms/style';

export const FooterMenuWrapper = withAttrs('nav', {
  className: 'text-uppercase line-height-custom',
});

export const WhiteLink = withAttrs(ILink, {
  className: 'text-white',
});

export const WhiteLinkBlank = withAttrs(ILink, {
  className: 'text-white',
  target: '_blank',
});

export const TelLink = withAttrs(WhiteLink, {
  className: 'tel-custom',
});

export const ExternalWhiteLink = withAttrs('a', {
  className: 'text-white',
  target: '_blank',
  spacing: { mr: 4 },
});

export const Copyright = withAttrs('p', {
  className: 'copyright-custom small',
  children: '© 2019 G&L',
});

export const IconedLinksWrapper = withAttrs('nav', {
  className: 'h4 pl-1',
  spacing: { pl: 1 },
});

export const IconedCol = withAttrs(Col, {
  className: 'col-fa-custom',
});

export const CustomRow = withAttrs(Row, {
  className: 'row-custom',
  spacing: { py: 2 },
});

export const ContactsCol = withAttrs(Col, {
  sm: 6,
  className: 'col-address-custom',
  spacing: { pt: 1 },
});

export const NavCol = withAttrs(Col, {
  className: 'col-nav-custom',
  sm: 3,
});

export const FooterWrapper = withAttrs('footer', {
  className: 'footer bg-primary text-white',
  spacing: { pt: 4 },
});
