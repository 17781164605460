import React, { useState } from 'react';
import { Div } from '../atoms/style';
import { DECISION_CODES } from '../../constants';
import withTrans from '../atoms/withTrans';
import useTrans from "../hooks/useTrans"

const YesNo = ({ decision, onSubmit }) => {
  const [state, setState] = useState(decision);
  const t = useTrans();
  return (
    <form className={'attendance-checkboxes'}>
      <Div>
        <label htmlFor="yes" className="option-yes">
          <input
            type="radio"
            data-t="input.yes"
            name="attendance"
            value="yes"
            checked={state === 2}
            onClick={() => {
              if (onSubmit) {
                setState(2);
                onSubmit(DECISION_CODES.yes);
              }
            }}
          />
          <span>{t('attendance.input.yes')}</span>
        </label>

        <label htmlFor="no" className="option-no">
          <input
            type="radio"
            data-t="input.no"
            name="attendance"
            value="no"
            checked={state === 1}
            onClick={() => {
              if (onSubmit) {
                setState(1);
                onSubmit(DECISION_CODES.no);
              }
            }}
          />
          <span>{t('attendance.input.no')}</span>
        </label>
      </Div>
    </form>
  );
};

export default withTrans(YesNo);
