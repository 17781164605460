import withAttrs from "../../utils/withAttrs";
import React from "react";
import {Div} from "../../atoms/style";


export const PollTitleWrapper = withAttrs(Div, {
    className: "bg-gray-custom",
    spacing: {px: 3, py: 2, mb: 2}
});

export const PollTitle = (props) => (
    <PollTitleWrapper>
        <strong>{props.children}</strong>
    </PollTitleWrapper>
);