import React from "react";
import UserBox from "./UserBox/index";
import UserChoirList from "./UserChoirList/UserChoirList";
import BirthDay from "./BirthDay/BirthDay";
import {Col} from "reactstrap";

const SideBar = () => (
    <React.Fragment>
        <Col className="small">
            <UserBox />
        </Col>

        <Col className="mt-4 small">
            <UserChoirList />
        </Col>

        <Col className="mt-4 small">
            <BirthDay />
        </Col>
    </React.Fragment>
);

export default SideBar;