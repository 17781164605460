import {Field, FieldArray} from "formik";
import {EventFormGreyBox, IconedLink} from "../../atoms/style";
import React from "react";
import EmailDigestInput from "./EmailDigestInput";

const EmailDigestsField = ({name, maxLimit = 4, t}) => {
    return (
        <Field name={name}>{({field, form, otherProps}) => (
                <FieldArray name={name} render={arrayHelpers => {
                    const value = field.value;
                    return (
                        <div>
                            <EventFormGreyBox>
                                {t(`choir_form.email_digests_note`)}
                            </EventFormGreyBox>
                            {value && value.length > 0 ? value.map((email_digest, index) => (
                                <EmailDigestInput
                                    index={index + 1}
                                    name={`${name}.${index}`}
                                    key={`${name}.${email_digest.id || email_digest._id}`}
                                    t={t}
                                    onDelete={() => {
                                        if (window.confirm(t('confirm.delete_email_digest?'))) {
                                            arrayHelpers.remove(index)
                                        }
                                    }}/>
                            )) : null}
                            <EventFormGreyBox>
                                {t(`choir_form.email_digests_limit_note`)}
                            </EventFormGreyBox>
                            {!value || value.length < maxLimit ? (
                                <IconedLink icon={'plus-square'}
                                            onClick={() => arrayHelpers.push({_id: Math.round(Math.random() * 10000)})}
                                            data-t={'button.add_email_digest'}>
                                    {t(`choir_form.add_email_digest`)}
                                </IconedLink>
                            ) : null}
                        </div>
                    );
                }}/>
            )}
        </Field>
    );
};

export default EmailDigestsField;