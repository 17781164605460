import {mixed, number, object, string} from "yup";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, HelpText, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {FormSubmitCol} from "../EventEditPage/style";
import React, {useContext, useEffect} from "react";
import {Row} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import {MEMBERSHIP_CODES} from "../../../constants";
import VoiceSelect from "../../molecules/VoiceSelect/VoiceSelect";
import ToastContext from "../../contexts/ToastContext";

const inviteSchema = object({
    emails: string().required('validation.this_field_is_required').matches(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?\n?)+$/, {
        message: 'validation.should_be_comma_separated_emails',
        excludeEmptyString: true
    }),
    invitation_type: number().required('validation.this_field_is_required'),
    voice: mixed().when('invitation_type', (invitation_type, schema) => {
        if (invitation_type === MEMBERSHIP_CODES.singer || invitation_type === MEMBERSHIP_CODES.singer_and_manager) {
            return schema.required('validation.this_field_is_required');
        } else {
            return schema.nullable();
        }
    }),
});

const serializeInviteSchema = inviteSchema.shape({
    emails: mixed().transform((value) => value ? value.replace(/[\s,]+/g, ",").split(",") : []).required('validation.this_field_is_required'),
});

const onSubmit = ({t, isEdit, toaster, choir}) => (values, {setSubmitting, setErrors, setStatus}) => {
    setSubmitting(true);
    let api = getApi("invite");
    const email = values['emails']
    const invite_data = {
        "email": email,
        "source": "invite"
    }
    const emailValidPromise = api.checkEmailValidity(email, invite_data);

    emailValidPromise.then(value => {
        let checkInvalid = Object.values(value).filter((email) => {
            return email['result']['verdict'] === 'Invalid'
        })
        let invalidEmails = checkInvalid.map(item => {
            return item['result']['email']
        }).join(',')

        if (checkInvalid.length === 0){
            console.log("Email valid", value);
            return api.inviteMembers(choir.id, serializeInviteSchema.cast(values));
        }else {
            console.log("Email invalid", checkInvalid);
            setSubmitting(false)
            setStatus({error: t('form.email_validation') + ': ' + invalidEmails});
            setErrors(value['result'])
        }
    }).then(value => {
        console.log("second promise result", value)
        if (value === undefined){
            setSubmitting(false)
        }else {
            toaster.onPush({msg: t('success.members_invited'), type: 'success'});
            goTo('choir_detail', {id: choir.id});
        }
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};

const TheForm = ({t, choir, voices, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    const managerOnly = parseInt(values.invitation_type) === MEMBERSHIP_CODES.manager;

    useEffect(() => {
        if (managerOnly) {
            setFieldValue('voice', null);
        }
    }, [managerOnly, setFieldValue]);

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} />

                <BootstrapField name={'emails'}
                                label={<strong>{t('invite_form.emails')}</strong>}
                                type={'textarea'}
                                rows={5}
                                help_text={<HelpText>{t('invite_form.emails_help_text')}</HelpText>}/>

                <BootstrapField name={'invitation_type'} label={<strong>{t('invite_form.membership_type')}</strong>} type={'select'}>
                    {Object.entries(MEMBERSHIP_CODES).map((entry) => {
                        const [membership_type, code] = entry;
                        return (
                            <option value={code}>{t(`membership_type.${membership_type}`)}</option>
                        );
                    })}
                </BootstrapField>

                <VoiceSelect name={'voice'} t={t} voices={voices} disabled={managerOnly} label={<strong>{t('invite_form.voice')}</strong>}/>

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} disabled={isSubmitting}>{t('choir.invite_members')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton to={choir ? ['choir_detail', {id: choir.id}] : 'choir_list'} disabled={isSubmitting}>{t('invite_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const initialValues = {
        emails: "",
        invitation_type: MEMBERSHIP_CODES.singer,
        voice: null,
    };
    const t = useTrans();
    const toaster = useContext(ToastContext);
    return (
        <Formik validationSchema={inviteSchema} initialValues={initialValues} onSubmit={onSubmit({toaster, ...props})}>
            {(formProps) => (
                <TheForm t={t} {...props} formProps={formProps} />
            )}
        </Formik>
    );
}