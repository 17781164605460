import React from "react";
import {Form, Formik, useFormikContext} from "formik";
import FormStatus from "../FormStatus/FormStatus";
import BootstrapField from "../BootstrapField";
import {ModalBody, ModalFooter} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import {GreenButton} from "../../atoms/style";

const TheForm = ({msg}) => {
    const t = useTrans();
    const {status, isSubmitting, errors, submitForm} = useFormikContext();
    return (
        <Form>
            <ModalBody>
                <FormStatus status={status}/>
                <BootstrapField disabled={isSubmitting} name={'value'} label={<strong>{t(msg)}</strong>}
                                required={true}/>
            </ModalBody>
            <ModalFooter>
                <GreenButton
                    data-t={'action.submit'}
                    disabled={isSubmitting || errors.value}
                    onClick={submitForm}>{t('action.submit')}</GreenButton>
            </ModalFooter>
        </Form>
    );
}
export const TextInputForm = ({resolve, msg}) => {

    const onFormSubmit = React.useCallback((values, {setSubmitting}) => {
        setSubmitting(true);
        resolve(values.value);
    }, [resolve]);
    return (
        <Formik initialValues={{value: ''}} onSubmit={onFormSubmit}>
            <TheForm msg={msg}/>
        </Formik>
    )
}