import {PollTitle} from "./PollTitle";
import React from "react";
import AnswerTable from "./AnswerTable";
import {Progress} from "reactstrap";
import NoAnswersYet from "./NoAnswersYet";

const ProgressBar = ({total, count}) => count > 0 ? (
    <Progress color={'purple-custom'} value={count} max={total}>{count}</Progress>
) : (
    <Progress color={'purple-custom'} value={count} max={total}/>
);

const ProgressRow = ({title, total, count}) => (
    <tr>
        <td className="w-40-custom">{title}:</td>
        <td className="w-60-custom"><ProgressBar total={total} count={count}/></td>
    </tr>
);


const buildHistogram = (user_answers, options) => {
    const mapping = {};
    const total = user_answers.length;
    const answers_counted = options.map(({id, text}, idx) => {
        mapping[id] = idx;
        return {id, count: 0, total, title: text};
    });
    user_answers.forEach(({options}) => {
        options.forEach(optionId => {
            const idx = mapping[optionId];
            answers_counted[idx].count += 1;
        })
    });
    return answers_counted;
};

export default function ({text, id, question, options, user_answers}) {
    const answers = user_answers ? user_answers.filter(answer => answer.poll === id) : [];
    const answers_counted = buildHistogram(answers, options);
    return (
        <>
            <PollTitle>{text || question}</PollTitle>
            <AnswerTable answers={answers_counted} component={ProgressRow}
                         empty={<NoAnswersYet/>}/>
        </>
    );
}