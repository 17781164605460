import {useEffect, useState} from "react";
import {GOOGLE_API_KEY} from "../../constants";
import useTrans from "./useTrans";

export default function useMaps() {
    const [scriptLoaded, setLoaded] = useState(false);
    const { i18n } = useTrans(true);
    useEffect(() => {
        let node = document.createElement("script");
        document.body.appendChild(node);
        node.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&language=${i18n.language}`;
        node.onload = () => {
            setLoaded(true);
        };
        return () => {
            document.body.removeChild(node);
        };
    }, [setLoaded, i18n]);
    return scriptLoaded;
}