import ReactDatePicker from "react-datepicker";
import React from "react";
import useTrans from "../hooks/useTrans";
import {Input} from "reactstrap";

const DateInput = React.forwardRef((props, ref) => (
    <div className={'input-date-placeholder-custom'}>
        <Input ref={ref} className={"form-control form-control-date"} {...props} type={'text'} />
    </div>
));

const DatePicker = (props) => {
    const { i18n } = useTrans(true);
    const language = window.location.href.split('/')[3];
    return (
        <div>
            <ReactDatePicker customInput={<DateInput {...props}/>} fixedHeight locale={i18n.language} placeholderText={language === 'de' ? 'TT.MM.JJJJ' : 'MM/DD/YYYY'} {...props}/>
        </div>
    );
};

export default DatePicker;