import useTrans from "../../hooks/useTrans";
import React from "react";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import ChangeUsernameForm from "./ChangeUsernameForm";
import useIsOktaAuthenticated from "../../hooks/useIsOktaAuthenticated";
import PageNotAvailableNote from "../../atoms/PageNotAvailableNote";

export default function ({match: {params}}) {
    const t = useTrans();
    const isOktaAuth = useIsOktaAuthenticated();
    return (
        <PageWithSidebar title={t('account.change_email')} sidebar={<SideBar/>}>
            {isOktaAuth ? (
                <ChangeUsernameForm t={t}/>
            ) : (
                <PageNotAvailableNote/>
            )}
        </PageWithSidebar>
    )
}