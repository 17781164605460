import React from "react";
import {A, Div, GreyHeader, H5, Icon, SmallUpper, SrOnly} from "../../atoms/style";

import classNames from 'classnames';
import withTrans from "../../atoms/withTrans";

const selectClasses = (selected, value) => classNames({
    "text-gray-custom": selected === value,
    "text-white": selected !== value,
});

const AttendanceBoxHeader = ({children, selected, selectGrid, selectList, t}) => {
    return (
        <GreyHeader>
            <H5 spacing={{my: 1}}>{children}</H5>

            <Div spacing={{pt: 1, pr: 1}}>
                <SmallUpper>{t('event.view_type')}:</SmallUpper>
                <A onClick={selectGrid} title={t('event.grid_view')} data-t={'event.attendance.grid'} className={selectClasses(selected, "grid")} spacing={{ml: 3}}>
                    <Icon icon={'th'} /><SrOnly>{t('event.grid_view')}</SrOnly>
                </A>
                <A onClick={selectList} title={t('event.list_view')}  data-t={'event.attendance.list'} className={selectClasses(selected, "list")} spacing={{ml: 3}}>
                    <Icon icon={'list-ul'}/><SrOnly>{t('event.list_view')}</SrOnly>
                </A>
            </Div>
        </GreyHeader>
    );
};

export default withTrans(AttendanceBoxHeader);