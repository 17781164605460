import React from "react";
import resolver from '../../resolver';
import ErrorBoundary from "../atoms/ErrorBoundary";
import withAttrs from "./withAttrs";

const defaults = ['resolve', 'reverse', ['t', (x)=>(x)]];

function populateDeps(result, avail, val) {
    if (typeof val === "string" && !avail[val]) {
        result.push([val, resolver.resolve(val)]);
        avail[val] = true;
    }
    if (Array.isArray(val)) {
        const [key, value] = val;
        if (typeof key === "string") {
            result.push([key, value]);
        }
    }
}

function buildDependencies(allDependencies, props) {
    let result = [];
    let avail = {};
    for (let i = allDependencies.length - 1; i >= 0; i--) {
        const deps = allDependencies[i];
        populateDeps(result, avail, deps);
    }
    if (result.length === 0 && defaults) {
        for (let j = defaults.length - 1; j >= 0; j--) {
            populateDeps(result, avail, defaults[j]);
        }
    }
    return result;
}

export function dependenciesToProps(props, allDependencies) {
    let newProps = {...props};
    const finalDependecies = buildDependencies(allDependencies, props);
    for (let i = 0; i < finalDependecies.length; i++) {
        const [key, dependency] = finalDependecies[i];
        newProps[key] = props[key] ? props[key] : dependency;
    }
    return newProps;

}


const withDI = (Component, dependencies, attrs) => {
    const CompWithAttrs = withAttrs(Component, attrs);
    const RootComponent = CompWithAttrs.rootDIComponent || CompWithAttrs;
    const inheritedDeps = Array.isArray(CompWithAttrs.dependencies) ? CompWithAttrs.dependencies : [];
    const allDependencies = dependencies ? [...inheritedDeps, ...dependencies] : inheritedDeps;
    const compWithDI = ({...props}) => {
        return <ErrorBoundary><RootComponent {...dependenciesToProps(props, allDependencies)}/></ErrorBoundary>;
    };
    compWithDI.displayName = `withDI(${RootComponent.displayName || RootComponent.name || RootComponent})`;
    compWithDI.rootDIComponent = RootComponent;
    compWithDI.dependencies = allDependencies;
    return compWithDI;
};

export default withDI;