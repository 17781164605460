import {PollTitle} from "./PollTitle";
import React from "react";
import AnswerTable from "./AnswerTable";
import NoAnswersYet from "./NoAnswersYet";

const VoiceValue = ({voices, value}) => {
    const voice = voices.find(voice => voice.id === value);
    if (!voice) {
        return null;
    }
    return (
        <span>{voice.name}</span>
    );
};

const SingerList = ({event_singers, values}) => {
    if (!(values && values.length > 0)) {
        return null;
    }

    const singers = values.map(
        singer_id => event_singers.find(singer => singer.id === singer_id)
    ).filter(singer => singer !== null);

    if (!(singers && singers.length > 0)) {
        return null;
    }

    return (
        <ul>
            {singers.map(({user}) => (<li>{user.full_name}</li>))}
        </ul>
    );
};

const MemberChoiceAnswerRow = ({user, singers, event_singers, voices, voice}) => {
    const isSinger = (singers && singers.length > 0);
    const nonEmptyAnswer = isSinger || (voice !== null);
    return nonEmptyAnswer ? (
        <tr>
            <td className="w-40-custom">{user.full_name}:</td>
            <td className="w-60-custom">{isSinger ? <SingerList event_singers={event_singers} values={singers}/> : <VoiceValue voices={voices} value={voice}/>}</td>
        </tr>
    ) : null;
};

export default function ({text, id, question, singers, voices, user_answers}) {
    return (
        <>
            <PollTitle>{text || question}</PollTitle>
            <AnswerTable answers={user_answers.filter(answer => answer.poll === id)}
                         event_singers={singers}
                         voices={voices}
                         component={MemberChoiceAnswerRow}
                         empty={<NoAnswersYet/>}/>
        </>
    );
}