import GlobalCache from "../atoms/GlobalCache";
import useCache from "../hooks/useCache";
import useCacheUpdator from "../hooks/useCacheUpdator";

const isPromise = (subject) => (subject && (typeof subject.then === 'function'));

const getCache = () => {
    const value =  GlobalCache.singleton_value;
    return value ? value : null
};

const loadValue = (cache, cache_id, callback, args) => {
    return callback(args).then(value => {
        cache.set(cache_id, value);
        return value;
    }).catch(error => {
        cache.set(cache_id, error);
    });
};

const readCallback = (callback, opts) => (args) => {
    const cache_id = opts.cacheId(args);
    if (!cache_id) {
        throw new Error("cache id is not valid");
    }
    const cache = useCache();
    let value = cache.get(cache_id);
    if (value === undefined) {
        value = loadValue(cache, cache_id, callback, args);
        cache.set(cache_id, value);
    }
    if (isPromise(value)) {
        throw value;
    }
    return value;
};


const preloadCallback = (callback, opts) => (args) => {
    const cache_id = opts.cacheId(args);
    if (!cache_id) {
        throw new Error("cache id is not valid");
    }
    const cache = getCache();
    return loadValue(cache, cache_id, callback, args);
};

export default function dummyFetcher(opts) {
    return {
        read: opts.read ? readCallback(opts.read, opts) : null,
        preload: opts.read ? preloadCallback(opts.read, opts) : null,
        cacheId: opts.cacheId,
        invalidate: (...args) => {
            const cache = getCache();
            cache.remove(opts.cacheId(...args));
        },
        invalidateAll: () => {
            const cache = getCache();
            //TODO: should be clearing cache only for this resource
            cache.clear();
        },
        update: (args, value) => {
            const cache = getCache();
            cache.set(opts.cacheId(args), value);
        },
        invalidator: (...args) => {
// eslint-disable-next-line react-hooks/rules-of-hooks
            return useCacheUpdator(opts.cacheId(...args))
        },
        updator: (...args) => {
// eslint-disable-next-line react-hooks/rules-of-hooks
            return useCacheUpdator(opts.cacheId(...args))
        },
    };
}