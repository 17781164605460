import PageFeedback from "../../molecules/PageFeedback";
import LoginForm from "../../molecules/LoginForm";
import React from 'react';
import {CenteringRow, LightColumn} from "./style";
import LoginFormContainer from "../../molecules/LoginFormContainer/LoginFormContainer";
import useAuth from "../../hooks/useAuth";
import goTo from "../../utils/goTo";
import {workflowBuilder} from "./loginWorkflow";
import usePromptPromise from "../../hooks/usePromptPromise";
import useTrans from "../../hooks/useTrans";
import {ChangePassword, isTotp, PasscodeInput, ResendButton} from "../MultifactorPage/VerifyFactor";
import {FactorSelectionInput} from "../MultifactorPage/FactorSelection";
import ToastContext from "../../contexts/ToastContext";
import i18n from "../../../setup/i18n";


const LoginFrame = (props) => {
    return (
        <LoginFormContainer links={[
            {to: 'need_password_reset', key: 'login.forgot_password?'},
            {to: i18n.language === 'de' ? "https://support.choirmanager.com/hc/de/requests/new" : "https://support.choirmanager.com/hc/en-us/requests/new", key: 'login.help_link'},
            {to: 'signup', key: 'login.signup'}
        ]}>
            <LoginForm {...props}/>
        </LoginFormContainer>
    );
}

const LoginWorkflow = () => {
    const promptPromise = usePromptPromise();
    const t = useTrans();
    const toaster = React.useContext(ToastContext);
    const promptPasscode = React.useCallback((transaction) => {
        return promptPromise({
            component: PasscodeInput,
            props: {
                factor: transaction.factor,
                extraActions: isTotp(transaction.factor) ? [] : [
                    <ResendButton resend={() => transaction.resend('sms')}/>
                ]
            }
        });
    }, [promptPromise]);
    const changePassword = React.useCallback((transaction) => {
        return promptPromise({
            component: ChangePassword,
            props: {
            }
        });
    }, [promptPromise]);
    const promptFactorSelection = React.useCallback((factors) => {
        return promptPromise({
            title: t("factor.prompt_factor_option"),
            component: FactorSelectionInput,
            props: {
                factors
            }
        });
    }, [t, promptPromise]);
    const loginHandler = React.useMemo(() => {
        return workflowBuilder({
            promptFactorSelection,
            promptPasscode,
            changePassword,
            toaster, t
        });
    }, [
        promptPasscode,
        promptFactorSelection,
        changePassword,
        toaster,
        t
    ]);
    return (
        <LightColumn>
            <LoginFrame loginHandler={loginHandler}/>
        </LightColumn>
    );
};

const LoginPage = () => {
    const {isAuthenticated} = useAuth();

    React.useEffect(() => {
        if (isAuthenticated) {
            goTo("event_list");
        }
    }, [isAuthenticated]);

    return isAuthenticated ? null : (
        <PageFeedback>
            <CenteringRow>
                <LoginWorkflow/>
            </CenteringRow>
        </PageFeedback>
    );
};

export default LoginPage;