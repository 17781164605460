import {library} from '@fortawesome/fontawesome-svg-core'
import {faFacebookF, faTwitter} from '@fortawesome/free-brands-svg-icons'
import {
    faAngleDoubleLeft,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faBell,
    faCalendar,
    faCalendarAlt,
    faCheckCircle,
    faClock,
    faDownload,
    faEnvelope,
    faExclamationTriangle,
    faFileDownload,
    faFileExcel,
    faInfoCircle,
    faKey,
    faListUl,
    faMapMarkerAlt,
    faMinusSquare,
    faPaperclip,
    faPencilAlt,
    faPenSquare,
    faPlus,
    faPlusSquare,
    faQuestionCircle,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faTh,
    faTrash,
    faUser,
    faUserShield,
    faAt
} from '@fortawesome/free-solid-svg-icons'

library.add(faTwitter, faFacebookF, faUser, faEnvelope, faQuestionCircle, faDownload, faCalendarAlt, faAngleRight,
    faAngleDown, faFileExcel, faExclamationTriangle, faInfoCircle, faPenSquare, faAngleDoubleLeft, faTh, faListUl,
    faSortUp, faSortDown, faSort, faPlusSquare, faMapMarkerAlt, faMinusSquare, faFileDownload, faCalendar, faClock,
    faSignOutAlt, faPlus, faKey, faTrash, faPencilAlt, faAngleLeft, faPaperclip, faCheckCircle, faBell, faUserShield,
    faAt
);
