import React, {useCallback, useEffect, useState} from "react";
import useToggle from "../../hooks/useToggle";
import {GreyButton} from "./style";
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {Input, ListGroup, ListGroupItem} from "reactstrap";
import Map from "../../molecules/Map";
import useTrans from "../../hooks/useTrans";
import useMaps from "../../hooks/useMaps";

const AutocompleteInput = ({isInvalid, field, ...props}) => (
    <Input type={'text'} invalid={isInvalid} {...field} {...props} />
);
const rederFunc = ({ loading, getInputProps, getSuggestionItemProps, suggestions, ...props }) => (
    <>
        <AutocompleteInput {...props} {...getInputProps()}/>
        <ListGroup flush>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => (
                <ListGroupItem action active={suggestion.active} tag={"button"} {...getSuggestionItemProps(suggestion)}>
                    <span>{suggestion.description}</span>
                </ListGroupItem>
            ))}
        </ListGroup>
    </>
);

// function defer() {
//     let deferObj = {};
//     deferObj.promise = new Promise((resolve, reject) => {
//         deferObj.resolve = resolve;
//         deferObj.reject = reject;
//     });
//     deferObj.isCompleted = false;
//     deferObj.isResolved = false;
//     deferObj.isRejected = false;
//     deferObj.resolve = (...args) => {
//         deferObj.resolve.apply(null, args);
//         return {
//             ...deferObj,
//             isCompleted: true,
//             isResolved: true
//         };
//     };
//     deferObj.reject = (...args) => {
//         deferObj.reject.apply(null, args);
//         return {
//             ...deferObj,
//             isCompleted: true,
//             isRejected: true
//         };
//     };
//     return deferObj;
// }
//
// function useDefer() {
//     const [waiting, setDeferred] = useState(defer());
//     return [waiting, (...args) => setDeferred(waiting.resolve(...args)), (...args) => setDeferred(waiting.reject(...args))]
// }
//
// function useMaps() {
//     const [waiting, resolve, _] = useDefer();
//     useEffect(() => {
//         let node = document.createElement("script");
//         document.body.appendChild(node);
//         node.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
//         node.onload = () => {
//             resolve();
//         };
//         return () => {
//             document.body.removeChild(node);
//         };
//     }, []);
//     if (!waiting.isCompleted) {
//         throw waiting.promise;
//     }
//     return waiting.isResolved;
// }

function useLatLng(address, callback) {
    const [point, setPoint] = useState(null);
    useEffect(() => {
        let mounted = true;
        setPoint(null);
        if (address) {
            geocodeByAddress(address).then(results => getLatLng(results[0])).then(latLng => {
                if (mounted) {
                    setPoint(latLng);
                    callback(latLng);
                }
            }).catch(error => console.error('Error getting lat lng', error));
        }
        return () => {mounted = false;}
    }, [address, setPoint, callback]);
    return point;
}

const CustomPlacesAutocomplete = ({latField = 'lat', lngField = 'lng', field, form: {setFieldValue}, ...props}) => {
    const {value, name} = field;
    const setPoint = useCallback((point) => {
        setFieldValue(latField, point.lat);
        setFieldValue(lngField, point.lng);
    }, [latField, lngField, setFieldValue]);
    useLatLng(value, setPoint);
    return (
        <PlacesAutocomplete value={value ? value : ''} onChange={(value) => setFieldValue(name, value)} {...props}>
            {rederFunc}
        </PlacesAutocomplete>
    );
};

export const LocationAutocomplete = (props) => {
    const {field, latField, lngField, ...otherProps} = props;
    const scriptLoaded = useMaps();
    if (scriptLoaded) {
        return (
            <CustomPlacesAutocomplete {...props}/>
        );
    } else {
        return (
          <AutocompleteInput {...field} {...otherProps}/>
        );
    }
};

const CollapseMap = ({value, defaultValue, onChange}) => {
    const [isOpen, toggle] = useToggle(defaultValue || false, onChange);
    const t = useTrans();
    return (
        <>
            <GreyButton spacing={{mt: 2, my: 0}} onClick={toggle}>{!isOpen ? t('action.show_map') : t('action.hide_map')}</GreyButton>
            {isOpen ? (
                <Map point={value} />
            ) : null}
        </>
    );
};

export default CollapseMap;