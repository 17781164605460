const valid_extensions = [
    'xlsx',
    'docx',
    'mscz',
    'bpmn',
    'doc',
    'mp3',
    'pdf',
    'txt',
    'm4a',
    'xls',
    'png',
    'odt',
    'zip',
    'rar',
    '7z',
    'ogg',
    'avi',
    'mov',
    '3gp',
    'mkv',
    'mpeg',
    'mpg',
    'webm',
    'flv',
    'wmv',
    'mp4',
    'mp3',
    'wav',
    'wma',
    'm4a',
    'au',
    'aiff',
    'aac',
    'jpeg',
    'jpg',
    'png',
    'bmp',
    'gif'
];
const validateExtension = (filename) => {
    if (!filename) {
        return false;
    }
    const parts = filename.split(".");
    const extension = parts[parts.length - 1];
    return valid_extensions.indexOf(extension.toLowerCase()) !== -1;
}

export default validateExtension;