import {Spinner} from "reactstrap";
import React from "react";

const Loading = () => {
    return (
        <div className={"d-flex flex-row justify-content-center"}>
            <Spinner/>
        </div>
    );
};

export default Loading;