import withAttrs from "../../utils/withAttrs";
import {Div, WhiteBox} from "../../atoms/style";
import React from "react";

export const ChoirSingersBoxWrapper = withAttrs(WhiteBox, {
    className: "container-var04-custom",
    spacing: {px: 3, pt: 3, pb: 3, mb: 4}
});


export const VoiceTitleWrapper = withAttrs(Div, {
    className: "bg-gray-custom text-uppercase text-center small",
    spacing: {py: 1, mb: 3}
});

export const VoiceTitle = ({children}) => (
    <VoiceTitleWrapper><strong>{children}</strong></VoiceTitleWrapper>
);