import {Div, EventBoxFlex, H5, Icon, PipeBox} from "../../atoms/style";
import React, {useContext} from "react";
import EventNotes from "./EventNotes";
import EventHeadline from "./EventHeadline";
import EventOptOutBox from "../EventOptOutBox/index";
import EventBoxTable from "./EventBoxTable";
import LocationBox from "../LocationBox";
import {AnimatedRowWrapper, EventBoxWrapper, EventInfoWrapper, StyledLink} from "./style";
import useTrans from "../../hooks/useTrans";
import {UncontrolledTooltip} from "reactstrap";
import EventContext from "../../contexts/EventContext";

const EventIcons = ({t, event_id, pollsIcon, downloadIcon}) => {
    const pollIconId = `poll_icon_${event_id}`;
    const downloadIconId = `download_icon_${event_id}`;
    return (
        <>
            {downloadIcon ? (
                <div className="float-right text-blue-custom tooltip-custom">
                    <Icon icon={'download'} size={'2x'} id={downloadIconId} spacing={{ml: 2}}/>
                    <UncontrolledTooltip placement={'left'} target={downloadIconId}>{t('event.download_icon_tooltip')}</UncontrolledTooltip>
                </div>
            ) : null}
            {pollsIcon ? (
                <Div className="float-right text-blue-custom tooltip-custom" >
                    <Icon icon={'exclamation-triangle'} size={'2x'} id={pollIconId}/>
                    <UncontrolledTooltip placement={'left'} target={pollIconId}>{t('event.poll_icon_tooltip')}</UncontrolledTooltip>
                </Div>
            ) : null}
        </>
    );
};

const EventInfo = ({title, choir_name, id, rehearsal_time, pollsIcon, downloadIcon, event_type, t}) => {

    const rehearsal = React.useMemo(() => rehearsal_time ? t('event.rehearsal', {rehearsal_time: rehearsal_time.replace(/:00$/, '')}) : null, [t, rehearsal_time]);
    return (
        <EventInfoWrapper data-t={title}>
            <EventIcons t={t} event_id={id} pollsIcon={pollsIcon} downloadIcon={downloadIcon}/>
            <H5>{choir_name}</H5>
            {rehearsal}
            <PipeBox name={title} value={t(`event_type.${event_type}`)}/>
        </EventInfoWrapper>
    );
}

const AnimatedArrow = () => (
    <AnimatedRowWrapper>
        <span>&middot;</span><span>&middot;</span>
        <Icon icon="angle-right"/>
    </AnimatedRowWrapper>
);

const PureEventTitle = (props) => {
    const {id, isLink, t} = props;
    return isLink ? (
        <StyledLink data-t={'event.link'} to={id ? ['event_detail', {id}] : null} title={t('event.go_to_details')}>
            <EventHeadline {...props}/>
            <AnimatedArrow/>
            <EventInfo {...props}/>
        </StyledLink>
    ) : <React.Fragment><EventHeadline {...props}/><EventInfo {...props}/></React.Fragment>;
};

const EventTitle = PureEventTitle;

const EventBox = (props) => {
    const {id, notes, participation, location, hasPolls, lat, lng, show_map, hasAttachments, showLocation, scp_id} = props;
    const t = props.t;
    return (
        <EventBoxWrapper>
            <EventTitle {...props} pollsIcon={hasPolls} downloadIcon={hasAttachments}/>

            <EventBoxFlex leftComponent={<EventBoxTable {...participation}/>} rightComponent={scp_id ? <EventOptOutBox scp_id={scp_id} event_id={id}/> : null}/>

            <EventNotes notes={notes}/>

            <LocationBox t={t} show={showLocation} location={location} show_map={show_map} lat={lat} lng={lng}/>

        </EventBoxWrapper>
    );
};

export default (props) => {
    const {event} = useContext(EventContext);
    if (!event) return null;
    let hasPolls = (event.multiple_choices && event.multiple_choices.length > 0);
    hasPolls = hasPolls || (event.open_questions && event.open_questions.length > 0);
    hasPolls = hasPolls || (event.member_choices && event.member_choices.length > 0);
    const hasAttachments = event.attachments && event.attachments.length > 0;
    return <EventBox t={useTrans()} hasPolls={hasPolls} hasAttachments={hasAttachments} {...event} {...props}/>;
}