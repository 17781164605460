import React from "react";
import {HeaderBox, IconedText} from "../../atoms/style";
import {dirname} from "../../utils/tiny";
import NullableLink from "../NullableLink";
import AttachmentVirusStatus from "../AttachmentVirusStatus";

const EventAttachmentBox = (props) => {
    const {t, attachments} = props;
    return attachments && attachments.length > 0 ? (
        <HeaderBox header={t('event.attachments')}>
                {attachments.map((attachment, idx) => (
                    <IconedText key={idx} icon={'paperclip'}>
                        <NullableLink href={!attachment.virus_scan_status || attachment.virus_scan_status === "clean" ? attachment.file : null}>{attachment.name || dirname(attachment.file)}</NullableLink><br/>
                        <small>{attachment.description}</small><br/>
                        <AttachmentVirusStatus attachment={attachment} />
                    </IconedText>
                ))}
        </HeaderBox>
    ) : null;
};

export default EventAttachmentBox;