import {PollTitle} from "./PollTitle";
import React from "react";
import AnswerTable from "./AnswerTable";
import NoAnswersYet from "./NoAnswersYet";


export default function ({text, id, question, user_answers}) {
    return (
        <>
            <PollTitle>{text || question}</PollTitle>
            <AnswerTable answers={user_answers.filter(answer => answer.poll === id)}
                         empty={<NoAnswersYet/>}/>
        </>
    );
}