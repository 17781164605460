import React, {Suspense, useContext} from "react";
import AttendanceBoxHeader from "./AttendanceBoxHeader";
import AttendanceBoxGrid from "./AttendanceBoxGrid";
import AttendanceBoxList from "./AttendanceBoxList";
import useTrans from "../../hooks/useTrans";
import EventCommitmentsFetcher from "../../fetchers/EventCommitmentsFetcher";
import useSwitch from "../../hooks/useSwitch";
import Loading from "../../atoms/Loading";
import EventContext from "../../contexts/EventContext";
import isApiError from "../../../utils/isApiError";
import EventAttendanceFetcher from "../../fetchers/EventAttendanceFetcher";

const AttendanceBox = (props) => {
    const {t} = props;
    const [selected, selectGrid, selectList] = useSwitch(['grid', 'list'], 'grid');
    return (
        <React.Fragment>
            <AttendanceBoxHeader selected={selected} selectGrid={selectGrid} selectList={selectList}>
                {t('event.attendance')}
            </AttendanceBoxHeader>
            {selected === "grid" ? (
                <AttendanceBoxGrid {...props}/>
            ) : (
                <AttendanceBoxList {...props}/>
            )}
        </React.Fragment>
    );
};

const WiredAttendanceBox = (props) => {
    const {event, voices} = useContext(EventContext);
    const commitments = EventCommitmentsFetcher.read({event_id: event.id});
    const attendances = EventAttendanceFetcher.read({event_id: event.id});
    const t = useTrans();
    if (isApiError(commitments)) {
        return null;
    }
    if (isApiError(attendances)) {
        return null;
    }
    return commitments && voices ? (
        <AttendanceBox {...props} t={t} voices={voices} commitments={commitments} attendances={attendances}/>
    ) : null;
};

export default (props) => (
    <Suspense fallback={<Loading/>}>
        <WiredAttendanceBox {...props}/>
    </Suspense>
)