import React, {Suspense} from 'react';
import useTrans from "../../hooks/useTrans";
import Markdown from "markdown-to-jsx";
import Loading from "../../atoms/Loading";
import ActivationFetcher from "../../fetchers/ActivationFetcher";
import isAppError from "../../../utils/isAppError";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";


const UserActivation = ({t, uid, token}) => {
    const activation = ActivationFetcher.read({uid, token});
    const links = [
        {to: 'resend_activation', key: 'link.resend_activation'},
        {to: 'login', key: 'link.back_to_login'}
    ]
    if (isAppError(activation)) {
        return (
            <SimpleFeedback success={false} title={t('activate_user.request_failure')} links={links}>
                <Markdown data-t={"failure-note"}>{t('activate_user.activate_user_failure')}</Markdown>
            </SimpleFeedback>
        )
    }
    return (
        <SimpleFeedback  title={t('activate_user.request_success')}>
            <Markdown data-t={"success-note"}>{t('activate_user.activate_user_seccuss')}</Markdown>
        </SimpleFeedback>
    );
};


export default ({match: {params}}) => {
    const t = useTrans();
    return (
        <div data-t={"page.activate_user"}>
        <Suspense fallback={<SimpleFeedback noIcon title={t('activate_user.confirm_form_title')}><Loading/></SimpleFeedback>}>
            <UserActivation t={t} uid={params.uid} token={params.token}/>
        </Suspense>
        </div>
    );
}