import React, { useState, useEffect } from "react";
import {
    BirthdayBoxWrapper,
    BirthdayTextWrapper
} from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import useTrans from "../../hooks/useTrans"
import BirthdayInstructionsModal from "./birthdayInstructionsModal";

import Calendar from 'react-calendar';
import '../../../css/Calendar.css';

const monthMapping = {
    '01': 'JANUARY',
    '02': 'FEBRUARY',
    '03': 'MARCH',
    '04': 'APRIL',
    '05': 'MAY',
    '06': 'JUNE',
    '07': 'JULY',
    '08': 'AUGUST',
    '09': 'SEPTEMBER',
    '10': 'OCTOBER',
    '11': 'NOVEMBER',
    '12': 'DECEMBER'
}


export const BirthdayBox = props => {
    const [today, setToday] = useState(new Date());
    const {birthdays} = props;
    const t = useTrans();
    const [currentMonth, setCurrentMonth] = useState();
    const [birthDates, setBirthDates] = useState();
    const [birthDayHeader, setBirthDayHeader] = useState();
    const [checkedMonth, setCheckedMonth] = useState();
    const [checkedDate, setCheckedDate] = useState();
    const [checkedName, setCheckedName] = useState();
    const [dateInfoObj, setDateInfoObj] = useState();
    const [isTodayBirthday, setIsTodayBirthday] = useState();
    const [nextBirthdayDate, setNextBirthdayDate] = useState();
    const [modalVisible, setModalVisible] = useState(false);

    let curMonth = (today && today.getMonth() + 1).toString().length === 1 ?
        '0'+(today.getMonth() + 1).toString(): today.getMonth() + 1
    let curDate = (today && today.getDate()).toString().length === 1 ?
        '0'+(today.getDate()).toString(): today.getDate()
    const todayDateString = today.getFullYear() + '-' + curMonth + '-' + curDate

    useEffect(() => {
        // WARNING: ignore the React warning of missing dependencies on useEffect.
        // Fixing it will break the component.
        let [userIds, onlyBirthdays] = [[], []];
        let [bdTodayMonth, bdTodayDate, checkBirthday, dateNameObj, earliestDate, strDate] =
            ['', '', false, {}, [], ''];
        setCurrentMonth(curMonth)

        birthdays.forEach(item => {
            if (!userIds.includes(item.id)) {
                if (item.membership && item.membership.birthdate_visible_to_singers === true && item.birthday !== null){
                    userIds.push(item.id)
                    onlyBirthdays.push(item)
                }
            }
        })

        // for simplicity, replace all years to the current year
        onlyBirthdays.forEach(item => {
            let dateSplit = item.birthday.split('-')
            dateSplit[0] = today.getFullYear()
            dateSplit = dateSplit.join('-')

            item['birthday'] = dateSplit
        })

        onlyBirthdays.forEach(birthdayItem => {
            let strDateItem = birthdayItem.birthday.split('-')
            if (parseInt(strDateItem[0]) !== today.getFullYear()){
                strDateItem[0] = today.getFullYear()
                strDate = strDateItem.join('-');
            }
            else{
                strDate = birthdayItem.birthday;
            }

            let bd = new Date(strDate);
            let itemResult = reArrangeDate({'date': bd})
            let todayResult = reArrangeDate({'date': today})
            let name = birthdayItem.first_name + ' ' + birthdayItem.last_name
            let nextYearDateString = (itemResult['year'] + 1) + '-' +  itemResult['month'] + '-' + itemResult['day']

            if (checkBirthday === false){
                checkBirthday = birthdayItem.birthday === todayDateString;
            }

            // set the dates from the birthday list
            if (bd > today) {
                earliestDate.push(bd)
            }else if (itemResult['month'] === todayResult['month'] &&
                itemResult['date'] === todayResult['date']){
                bdTodayMonth = todayResult['monthName']
                bdTodayDate = todayResult['day']
            }

            // set the birthdates with date from next year
            if (new Date(nextYearDateString) > today){
                earliestDate.push(new Date(nextYearDateString))
            }

            if (dateNameObj[strDate]) {
                dateNameObj[strDate].push(name)
                dateNameObj[nextYearDateString].push(name)
            }
            else {
                dateNameObj[strDate] = [name];
                dateNameObj[nextYearDateString] = [name];
            }
            // dateNameObj = {}
        })

        earliestDate = earliestDate.filter(item => new Date(item) > today);
        earliestDate.sort(function(a,b){return a.getTime() - b.getTime()})

        // extract only the first nearby item representing the next birthdate, and
        // create a date string.
        let earliestDateResult = reArrangeDate({'date': new Date(earliestDate[0])});
        earliestDate = [];
        if (earliestDateResult) {
            earliestDate.push(
                earliestDateResult['year'] +
                '-' + earliestDateResult['month'] +
                '-' + earliestDateResult['day']
            )
        }

        setBirthDates(Object.keys(dateNameObj))
        setDateInfoObj(dateNameObj)
        setIsTodayBirthday(checkBirthday)
        setNextBirthdayDate(earliestDate[0])
        setBirthDayHeader(checkBirthday ? t('birthday_calendar.today') :
            earliestDate.length !== 0 ? t('birthday_calendar.next_birthday') : '')
        setCheckedMonth(checkBirthday ? bdTodayMonth: earliestDateResult['monthName'])
        setCheckedDate(checkBirthday ? bdTodayDate: earliestDateResult['day'])
        setCheckedName(checkBirthday ? dateNameObj[todayDateString]: dateNameObj[earliestDate[0]])
    }, [birthdays]);

    const adjustMonth = (date) => {
        return (date.getMonth() + 1).toString().length === 1 ? '0'+(date.getMonth() + 1)
            .toString(): date.getMonth() + 1
    }

    const adjustDate = (date) => {
        return (date.getDate().toString().length === 1 ? '0'+(date.getDate())
        .toString(): date.getDate())
    }

    const reArrangeDate = (date) => {
        let year = date['date'].getFullYear()
        let month = adjustMonth(date['date'])
        let monthName = t(`birthday_calendar.month_${monthMapping[month]}`)
        let day = adjustDate(date['date'])
        let currentDateString = date['date'].getFullYear() + '-' +
            month + '-' + day

        return {
            'date': currentDateString,
            'year': year,
            'month': month,
            'day': day,
            'monthName': monthName
        }
    }

    const onChange = (value) => {
        let valueResult = reArrangeDate({'date': value})
        let bdMonth = valueResult['monthName']
        let clickedName = dateInfoObj[valueResult['date']]
        let checkBirthday = valueResult['date'] === todayDateString &&
            birthDates.includes(todayDateString);

        setToday(new Date())
        setBirthDayHeader(
            nextBirthdayDate === valueResult['date'] ? t('birthday_calendar.next_birthday') :
                valueResult['date'] === todayDateString && Object.keys(dateInfoObj)
                    .includes(valueResult['date']) ? t('birthday_calendar.today') : Object.keys(dateInfoObj)
                    .includes(valueResult['date']) ? '' : ''
        )
        setCheckedMonth(bdMonth)
        setCheckedDate(valueResult['day'])
        setCheckedName(clickedName)
        setIsTodayBirthday(checkBirthday)
    }

    const onActiveStartDateChange = (value) => {
        let res = reArrangeDate({'date': value['activeStartDate']})
        let currentYear = res['year']
        let arrangeYearDates = [];

        setCurrentMonth(res['month'])

        birthDates.forEach(bDay => {
            let bDayItem = bDay.split('-')

            if (bDayItem[0] !== currentYear) {
                bDayItem[0] = currentYear
                bDay = bDayItem.join('-')

                arrangeYearDates.push(bDay);
            }
        })

        let oldDates = Object.keys(dateInfoObj)
        arrangeYearDates.forEach((item, index) => {
            dateInfoObj[item] = dateInfoObj[oldDates[index]]
        })

        setBirthDates(arrangeYearDates)
        setDateInfoObj(dateInfoObj)

        // set highlight for all months around the view frame
        getBirthDateHighlights({'date': value['activeStartDate'], 'view': 'month'})
    }

    const getBirthDateHighlights = (date) => {
        if (date['view'] === 'month') {
            let result = reArrangeDate(date)

            if((birthDates || []).includes(result['date']) && result['date'] === todayDateString){
                return "react-calendar__tile--now-nobirthday";
            }else if((birthDates || []).includes(result['date'])){
                return "react-calendar__tile--active";
            }else if (result['date'] === todayDateString &&
                (birthDates && !birthDates.includes(todayDateString))){
                return "react-calendar__tile--now-nobirthday";
            }else if (result['month'] !== currentMonth) {
                return "react-calendar__tile--anotherMonth";
            }else {
                return "react-calendar__tile--inactive";
            }
        }
    }

    const getDisabledDate = (date) => {
        if (date['view'] === 'month') {
            let result = reArrangeDate(date)

            return birthDates && !birthDates.includes(result['date'])
        }
    }

    const formatShortWeekday = (locale, date) => {
        return [
            t('birthday_calendar.weekday_SUNDAY'),
            t('birthday_calendar.weekday_MONDAY'),
            t('birthday_calendar.weekday_TUESDAY'),
            t('birthday_calendar.weekday_WEDNESDAY'),
            t('birthday_calendar.weekday_THURSDAY'),
            t('birthday_calendar.weekday_FRIDAY'),
            t('birthday_calendar.weekday_SATURDAY')][date.getDay()]
    }

    const navigationLabel = (value) => {
        let monthRes = value['label'].split(' ')
        monthRes[0] = t('birthday_calendar.month_' + monthRes[0].toUpperCase())

        return monthRes.join(' ')
    }

    const openModal = () => {
        setModalVisible(true)
    }

    const closeModal = () => {
        setModalVisible(false)
    }

    const noBirthdayCake = <FontAwesomeIcon icon={faBirthdayCake} className={'no-birthday-cake'} size={'3x'}/>
    const birthdayCake = <FontAwesomeIcon icon={faBirthdayCake} className={'birthday-cake'} size={'4x'}/>
    return (
        <BirthdayBoxWrapper>
            {isTodayBirthday ? birthdayCake : ''}
            <div className={'calendar-title'}>{t('birthday_calendar.title')}</div>

            {birthDates && birthDates.length !== 0 ?
                <ReactTooltip id='instructions' type='info' effect='solid' clickable={true} aria-haspopup={true} delayHide={100} backgroundColor='#A43A89' multiline={true} place='left'>
                    <span className={"tooltip-message"} onClick={openModal}>
                        <span className={'birthday-name'}>{t('birthday_calendar.tooltip_message_first')}<br />{t('birthday_calendar.tooltip_message_second')}</span>
                    </span>
                </ReactTooltip> : '' }

            <BirthdayTextWrapper data-tip data-for="instructions">
                <span className={'birthday-title'}>{birthDates && birthDates.length !== 0 ?
                    birthDayHeader : noBirthdayCake}</span>
                <span data-t={"next-birthday-month"} className={'birthday-month'}>{birthDates && birthDates.length !== 0 ? checkedMonth : ''}</span>
                <span className={'no-birthday-text'}>{birthDates && birthDates.length === 0 ?
                    t('birthday_calendar.long_msg') : ''}</span>
                <span data-t={"next-birthday-day-of-month"} className={'birthday-date'}>{birthDates && birthDates.length !== 0? checkedDate : ''}</span>
                {checkedName ? checkedName.map((name,idx) =>(<span key={idx} className={'birthday-name'}>{name}</span>)) :
                    <span className={'birthday-name'} onClick={openModal}><u>{t('birthday_calendar.short_msg')}</u></span>}
            </BirthdayTextWrapper>
            <Calendar
                onChange={onChange}
                onActiveStartDateChange={onActiveStartDateChange}
                prevLabel={''}
                nextLabel={''}
                prev2Label={null}
                next2Label={null}
                tileClassName={getBirthDateHighlights}
                tileDisabled={getDisabledDate}
                minDetail="month"
                maxDetail="month"
                view={'month'}
                className={'react-calendar'}
                selectRange={false}
                formatShortWeekday={formatShortWeekday}
                navigationLabel={navigationLabel}
            />

            <BirthdayInstructionsModal modalVisible={modalVisible} closeModal={closeModal} />
        </BirthdayBoxWrapper>
    );
}