import moment from "moment";
import {rrulestr} from "rrule";
import {momentToRRuleDate, rruleDateToMoment} from "./rruleUtil";

export default function dateMatchRRule(date, rruleStrValue) {
    if (!rruleStrValue) {
        return true;
    }

    const dtstart = momentToRRuleDate(moment(new Date()).startOf('day'));
    const rule = rrulestr(rruleStrValue, {
        dtstart,
        // IMPORTANT: looks like there is a bug in [rrulestr],
        // it stops respecting [dtstart] option above
        // when [forceset] is true
        forceset: false
    });

    const startOfDay = (date ? moment(date) : moment()).startOf('day');
    const nextOccurrence = rule.after(momentToRRuleDate(startOfDay), true); // next rule date including today
     // check if 'DAY' is same
    return rruleDateToMoment(nextOccurrence).isSame(startOfDay, 'day');
}