import {GOOGLE_API_KEY} from "../../../constants";
import GoogleMap from "google-map-react";
import React from "react";
import {Icon} from "../../atoms/style";
import useTrans from "../../hooks/useTrans";

const width = 32;
const height = 48;
const markerStyle = {
    position: 'absolute',
    width: width,
    height: height,
    top: -height,
    left: -(width * 0.5)
};

const Marker = () => (
    <div>
        <Icon color={'red'} style={markerStyle} icon={'map-marker-alt'}/>
    </div>
);

const Map = ({point}) => {
    const {i18n} = useTrans(true);
    return (
        <div className="map-responsive-custom bg-gray-custom w-100" style={{height: 250}}>
            <GoogleMap
                defaultZoom={15}
                bootstrapURLKeys={{
                    key: GOOGLE_API_KEY,
                }}
                language={i18n.lng}
                region={i18n.lng}
                center={point ? point : [50, 6]}
            >
                {point ? <Marker {...point} /> : null}
            </GoogleMap>
        </div>
    );
};
export default Map;