import {Table} from "reactstrap";
import React from "react";
import ComponentList from "../../atoms/ComponentList";

const TableRow = ({user, text, answer}) => (
    <tr>
        <td className="w-40-custom">{user.full_name}:</td>
        <td className="w-60-custom">{text || answer}</td>
    </tr>
);

export default function({answers, component, empty = null, ...props}) {
    return answers && answers.length > 0 ? (
        <Table size={"sm"} className="table-custom text-left mb-5">
            <tbody>
                <ComponentList component={component ? component : TableRow} items={answers} {...props}/>
            </tbody>
        </Table>
    ) : empty;
}