import React from "react";
import BootstrapField from "../../molecules/BootstrapField";
import GenericPollsField from "./GenericPollsField";
import getIn from "lodash/get";
import {Field} from "formik";
import InputErrorFeedback from "../../molecules/InputErrorFeedback";


const MultipleChoiceInput = ({t, index, prefix, disabled}) => {
    return (
        <>
            <BootstrapField name={`${prefix}.question`} disabled={disabled} help_text={t('event_form.poll_multiple_choice_help_text')}/>
            <Field render={({form: {errors}}) => {
                const error = getIn(errors, `${prefix}.options`);
                return (
                    <>
                        <BootstrapField disabled={disabled} inline_row name={`${prefix}.options[0].text`} label={`${t('event_form.poll_option_title')} 1`}/>
                        <BootstrapField disabled={disabled} inline_row name={`${prefix}.options[1].text`} label={`${t('event_form.poll_option_title')} 2`}/>
                        <BootstrapField disabled={disabled} inline_row name={`${prefix}.options[2].text`} label={`${t('event_form.poll_option_title')} 3`}/>
                        <BootstrapField disabled={disabled} inline_row name={`${prefix}.options[3].text`} label={`${t('event_form.poll_option_title')} 4`}/>
                        <BootstrapField disabled={disabled} inline_row name={`${prefix}.options[4].text`} label={`${t('event_form.poll_option_title')} 5`} help_text={t('event_form.poll_options_limit')}/>
                        {error ? <InputErrorFeedback error={error}/> : null}
                    </>
                );
            }}/>
        </>
    );
};


export default (props) => (
    <GenericPollsField {...props} name={'multiple_choices'} inputComponent={MultipleChoiceInput}/>
);