import useTrans from "../../hooks/useTrans";
import React from "react";
import SingerSelect from "./SingerSelect";
import uniq from 'lodash/uniq';

export const isInactiveMember = (member) => member && ((member.active_member === false) || (member.is_active === false));
export const activeMembers = (members) => members ? members.filter(member => !isInactiveMember(member)) : [];

export default ({field: {value, name}, form: {setFieldValue}, userSelect, singers, managers, ...props}) => {
    if (!singers) {
        throw Error("singers must be specified");
    }
    singers = userSelect ? singers.map(singer => ({...singer, id: singer.user.id, scp_id: singer.id})) : singers;
    managers = userSelect && managers ? managers.map(manager => ({...manager, id: manager.user.id, ccp_id: manager.id})) : managers;
    singers = activeMembers(singers);
    managers = activeMembers(managers);
    const selected = value || [];
    const applySelection = (includeOp, selection) => {
        let newValue = selected;
        if (includeOp) {
            newValue = uniq([...selected, ...selection]);
        } else {
            newValue = newValue.filter(value => selection && selection.indexOf(value) === -1);
        }
        setFieldValue(name, newValue);
    };

    return (
        <SingerSelect t={useTrans()}
                      selected={selected}
                      applySelection={applySelection}
                      singers={singers}
                      managers={managers}
                      {...props}/>
    );
};