import React from "react";
import FormikCheckboxMultiSelect from "../EventFilter/FormikCheckboxMultiSelect";
import {Label} from "reactstrap";
import useTrans from "../../hooks/useTrans";

const MultipleChoice = (props) => {
    const {idx, options, question} = props;
    const prefix = `multiple_choice_answers.${idx}`;
    const t = useTrans();
    const inputOptions = options.map(option => ({title: option.text, value: option.id}));
    return (
        <>
            <Label><strong>{question}</strong></Label>
            <FormikCheckboxMultiSelect t={t} name={`${prefix}.options`} options={inputOptions}/>
        </>
    );
};

export default MultipleChoice;