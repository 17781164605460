import React, {useEffect, useRef, useState} from "react";
import {Form, Formik, useField, yupToFormErrors} from "formik";
import {RRule, RRuleSet, rrulestr} from "rrule";
import usePrompt from "../../hooks/usePrompt";
import {Container, FormGroup, Label, ModalBody, ModalFooter} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import BootstrapField from "../BootstrapField";
import moment from "moment";
import Select from "react-select";
import {array, mixed, number, object, string} from "yup";
import {castToMoment} from "../../validation/utils";
import InputErrorFeedback from "../InputErrorFeedback";
import {GreyButton} from "../../pages/EventEditPage/style";
import {momentToRRuleDate, rruleDateToMoment} from "../../../utils/rruleUtil";

const CUSTOM_OCCURRENCE = 'custom';
const NEVER_OCCURRENCE = 'never';

const MONTHLY = 1;
const WEEKLY = 2;

const weeklySchema = array().of(number().min(0, 'validation.must_be_positive').integer('validation.must_be_integer')).nullable();
const monthlySchema = array().of(number().positive('validation.must_be_positive').integer('validation.must_be_integer')).nullable();

const repeatInputSchema = object({
    interval: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
    freq: number().oneOf([MONTHLY, WEEKLY]).positive('validation.must_be_positive').integer('validation.must_be_integer'),
    byweekday: weeklySchema.when(['freq', '$forDate'], (freq, forDate, schema) => {
            if (freq !== WEEKLY) {
                return schema;
            }
            const newSchema = schema.min(1, 'validation.at_least_1_item')
            if (forDate) {
                const requiredWeekday = (forDate.getDay() - 1 + 7) % 7
                return newSchema.test('requiredDayMissing', 'validation.weekday_must_match_event_start_date', (value) => {
                    return value && value.length > 0 && value.indexOf(requiredWeekday) !== -1;
                });
            } else {
                return newSchema;
            }
        }),
    bymonthday: monthlySchema.when('freq', {
        is: value => value === MONTHLY,
        then: monthlySchema.min(1, 'validation.at_least_1_item'),
        otherwise: monthlySchema
    }),
    ends: string().oneOf(['never', 'on', 'after']),
    until: mixed().transform(castToMoment).nullable().when(['ends', '$forDate' ], (ends, forDate, schema) => {
        if (ends === 'on' && forDate) {
            const thresholdDate = moment(forDate);
            return schema.test('after_a_week', 'validation.recurrence_should_end_after_start_date', value => {
                return value ? value.isAfter(thresholdDate) : false;
            });
        }
        return schema;
    }),
    count: number().typeError('validation.must_be_positive')
        .when(['ends'], (ends, schema) => {
            if (ends === 'after') {
                return schema.positive('validation.must_be_positive').integer('validation.must_be_positive');
            } else {
                return schema.nullable();
            }
        })
        .transform(value => (value === "" || isNaN(value) || !value) ? null : value)
});

function newRRule(opts) {
    return new RRule(opts);
    // const retval = new RRuleSet();
    // retval.rrule(new RRule(opts));
    // return retval;
}

function findRRule(value, valueList) {
    const idx = valueList.findIndex((v) => rruleEqual(v, value));
    return idx !== -1;
}

function addIfDoesntExist(values, value) {
    if (value && !findRRule(value, values)) {
        values.push(value);
    }
}

function getOptionsForDate(forDate, defaultValue, value) {
    const weekly = newRRule({
        freq: RRule.WEEKLY,
        interval: 1,
        byweekday: (forDate.getDay() - 1 + 7) % 7
    });
    const monthly = newRRule({
        freq: RRule.MONTHLY,
        interval: 1,
        bymonthday: forDate.getDate()
    });
    const values = [
        NEVER_OCCURRENCE
    ];
    addIfDoesntExist(values, defaultValue);
    addIfDoesntExist(values, value);
    addIfDoesntExist(values, weekly);
    addIfDoesntExist(values, monthly);
    values.push(CUSTOM_OCCURRENCE);
    return values;
}

function getRRuleTitle(t, option) {
    if (option === NEVER_OCCURRENCE || !option) {
        return t('occurence.never')
    } else if (option === CUSTOM_OCCURRENCE) {
        return t('occurence.custom')
    } else {
        const language = {
            dayNames: [
                t('rrule.Sunday'), t('rrule.Monday'), t('rrule.Tuesday'), t('rrule.Wednesday'),
                t('rrule.Thursday'), t('rrule.Friday'), t('rrule.Saturday')
            ],
            monthNames: [
                t('rrule.January'), t('rrule.February'), t('rrule.March'), t('rrule.April'), t('rrule.May'),
                t('rrule.June'), t('rrule.July'), t('rrule.August'), t('rrule.September'), t('rrule.October'),
                t('rrule.November'), t('rrule.December')
            ],
            tokens: {}
        };
        return option.toText((token) => t(`rrule.${token.replace(/\s+/g, '-')}`), language);
    }
}
function simplifyRRule(rrule) {
    // return rrule.toString();
    return rrule.toString().replace('INTERVAL=1;', '').replace(/DTSTART:\d+T\d+Z/gi, '').trim();
}

function rruleEqual(value, value2) {
    if (!value && !value2) {
        return true;
    }
    if (value && value2) {
        if (value === value2) {
            return true;
        } else {
            if (simplifyRRule(value) === simplifyRRule(value2)) {
                return true;
            }
        }
    } else {
        return false;
    }

    return false;
}

function getSelectOptions(options, t) {
    return options.map(option => ({
        value: option,
        label: getRRuleTitle(t, option)
    }));
}

const RepeatDisplay = ({value, forDate, disabled = false, onChange, defaultValue, ...props}) => {
    forDate = forDate || new Date();
    const t = useTrans();
    const promptModal = usePrompt();
    const selectRef = useRef(null);
    value = value || NEVER_OCCURRENCE;
    const curValue = {
        label: getRRuleTitle(t, value),
        value
    }
    const values = getOptionsForDate(forDate, defaultValue, value);
    const options = getSelectOptions(values, t);

    const onSelectChange = (option) => {
      if (option && option.value === CUSTOM_OCCURRENCE) {
          promptModal({
              title: t('occurence.custom_modal_title'),
              component: (opts) => (
                  <RepeatInput defaultValue={value} forDate={forDate} onChange={onChange} {...opts} />
              ),
              onClose: () => {
                  // giving some time to render
                  setTimeout(() => {
                      if (selectRef.current && selectRef.current.select) {
                          selectRef.current.select.blur();
                      }
                  }, 50);
              }
          });
      } else if (option && option.value === NEVER_OCCURRENCE){
          onChange(null);
      } else if (option && option.value) {
          onChange(option.value);
      }
    };
    return (
        <Select value={curValue}
                isDisabled={disabled}
                onChange={onSelectChange}
                isOptionSelected={(option, selectedOptions) => {
                    return selectedOptions.find(curItem => rruleEqual(curItem.value, option.value));
                }}
                options={options}
                isMulti={false}
                openAfterFocus={true}
                ref={selectRef}
                isSearchable={false}
        />
    );
};

const RadioInputField = ({curValue, value, children, ...props}) => {
    return (
        <FormGroup>
            <BootstrapField md={3} type={'radio'} checked={curValue === value} value={value} {...props}/>
            {children ? children(curValue !== value) : null}
        </FormGroup>
    );
};

const SelectField = ({name, options, isMulti = false, ...props}) => {
    const [field, meta, helpers] = useField(name);
    let curValue = null;
    if (isMulti) {
        curValue = field.value ? field.value.map(v => (options.find(x => x.value === v))) : [];
    } else {
        curValue = field.value ? options.find(x => x.value === field.value) : null;
    }
    const onChange = (selected) => {
        if (isMulti) {
            helpers.setValue(selected ? selected.map((option) => option.value) : []);
        } else {
            helpers.setValue(selected ? selected.value : null);
        }
    };
    return (
        <FormGroup>
            <Select
                value={curValue}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                {...props}/>
            <InputErrorFeedback error={meta.error}/>
        </FormGroup>
    );
};

const WeekdaysSelect = () => {
    const t = useTrans();
    const options = [
        {value: 0, label: t('rrule.Monday')},
        {value: 1, label: t('rrule.Tuesday')},
        {value: 2, label: t('rrule.Wednesday')},
        {value: 3, label: t('rrule.Thursday')},
        {value: 4, label: t('rrule.Friday')},
        {value: 5, label: t('rrule.Saturday')},
        {value: 6, label: t('rrule.Sunday')},
    ];
    return (
        <SelectField
            name={'byweekday'}
            options={options}
            isMulti={true}
            isSearchable={true}
            autoFocus={false}/>
    );
};


const MonthlySelect = ({forDate}) => {
    forDate = forDate ? forDate : new Date();
    const options = [
        {value: forDate.getDate(), label: '' + forDate.getDate()},
    ];
    return (
        <SelectField
            name={'bymonthday'}
            options={options}
            isMulti={true}
            isSearchable={true}
            autoFocus={false}/>
    );
};

const formValuesToRRule = (values) => {
    const freq = values.freq ? parseInt(values.freq) : WEEKLY;
    const opts = {
        freq,
        interval: parseInt(values.interval)
    };
    if (freq === MONTHLY) {
        opts["bymonthday"] = values.bymonthday;
    }
    if (freq === WEEKLY && values.byweekday) {
        opts["byweekday"] = values.byweekday
    }
    if (values.ends === 'on' && values.until) {
        opts["until"] = momentToRRuleDate(values.until);
    }
    if (values.ends === 'after') {
        opts["count"] = parseInt(values.count);
    }
    return new RRule(opts);
};

function rruleToValues(rrule) {
    let ends = 'never';
    if (rrule && rrule !== NEVER_OCCURRENCE) {
        if (rrule.options.until) {
            ends = 'on';
        }
        if (rrule.options.count) {
            ends = 'after';
        }
        return {
            interval: rrule.options.interval,
            count: rrule.options.count,
            byweekday: rrule.options.byweekday,
            bymonthday: rrule.options.bymonthday,
            freq: rrule.options.freq,
            until: rrule.options.until ? rruleDateToMoment(rrule.options.until) : null,
            ends
        };
    }
    return {
        interval: 1,
        freq: RRule.WEEKLY,
        count: 1,
        ends
    }
}

const CustomRepeatForm = ({formProps: {values, errors, isValid, setValues}, forDate, close}) => {
    const t = useTrans();
    const ends = values.ends || 'never';
    const freq = values.freq || WEEKLY;

    const options = [
        {value: WEEKLY, label: t('rrule.weekly')},
        {value: MONTHLY, label: t('rrule.monthly')},
    ];
    return (
        <Container>
        <Form>
            <ModalBody>
                <Label>{t('rrule.repeat_every')}</Label>
                <FormGroup>
                    <BootstrapField type={'number'} value={values.interval} min={1} name={'interval'}/>
                    <SelectField name={'freq'} options={options} isSearchable={false}/>
                </FormGroup>
                <Label>{t('rrule.repeat_on')}</Label>
                {freq === WEEKLY ? (
                    <WeekdaysSelect />
                ) : (
                    <MonthlySelect forDate={forDate}/>
                )}
                <Label>{t('rrule.ends')}</Label>
                <RadioInputField name={'ends'} curValue={ends} value={'never'} label={t('rrule.ends_never')}/>
                <RadioInputField name={'ends'} curValue={ends} value={'on'} label={t('rrule.ends_on')}>
                    {(disabled) => (
                        <BootstrapField md={6} type='date' disabled={disabled} name={'until'}/>
                    )}
                </RadioInputField>
                <RadioInputField name={'ends'} curValue={ends} value={'after'} label={t('rrule.ends_after')}>
                    {(disabled) => (
                        <BootstrapField md={6} type='number' defaultValue={1} min={1} disabled={disabled} name={'count'} append={t('rrule.count')}/>
                    )}
                </RadioInputField>
            </ModalBody>
            <ModalFooter>
                <GreyButton type={'submit'} disabled={!isValid} data-t={"button.save_recurrence"}>{t('action.save')}</GreyButton>
                <GreyButton onClick={close} type={'button'} data-t={"button.close"} data-dismiss="modal">{t('action.close')}</GreyButton>
            </ModalFooter>
        </Form>
        </Container>
    );
};

const RepeatInput = ({onChange, defaultValue, close, forDate}) => {
    const onSubmit = (values) => {
        onChange(formValuesToRRule(values));
        close();
    }
    const initialValues = rruleToValues(defaultValue);
    const validate = (values) => repeatInputSchema
        .validate(values, { abortEarly: false, context: {forDate}})
        .then(value => {})
        .catch(err => yupToFormErrors(err));

    return (
        <Formik
            validate={validate}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {(formProps) => (
                <CustomRepeatForm formProps={formProps} forDate={forDate} close={close}/>
            )}
        </Formik>
    );
};

function copyRuleSet(ruleSet, rule) {
    if (!rule) {
        return null;
    }
    const retval = ruleSet ? ruleSet.clone() : new RRuleSet();
    // TODO: must be a better way
    retval._rrule = [rule];
    return retval;

}

const firstRule = (rruleSet) => rruleSet ? rruleSet._rrule[0] : null;

const Repeat = ({value, forDate, onChange, disabled, initialValue, ...props}) => {
    const [objValue, setObjValue] = useState(null);
    const [defaultRRuleValue, setDefaultRRuleValue] = useState(null);
    useEffect(() => {
        const rruleObj = value ? rrulestr(value, {forceset: true}) : null;
        setObjValue(rruleObj);
    }, [value]);

    useEffect(() => {
        const rruleObj = initialValue ? rrulestr(initialValue, {forceset: true}) : null;
        setDefaultRRuleValue(rruleObj);
    }, [initialValue]);

    const wrappedOnChange = (newValue) => {
        if (newValue) {
            const newObjValue = copyRuleSet(objValue, newValue);
            setObjValue(newObjValue);
            onChange(newObjValue.toString())
        } else {
            setObjValue(null);
            onChange(null);
        }
    };
    const rrule = firstRule(objValue);
    return (
        <RepeatDisplay
            value={rrule}
            forDate={forDate}
            defaultValue={firstRule(defaultRRuleValue)}
            disabled={disabled}
            onChange={wrappedOnChange}
        />
    )
};

const RRuleInput = ({field, form, ...props}) => {
  return (
      <Repeat value={field.value} {...props} onChange={(rrule) => {
          console.log(`RRule changed, now it's ${rrule}`);
          form.setFieldValue(field.name, rrule);
      }} />
  );
};

export default RRuleInput;