import useTrans from "../hooks/useTrans";
import React from "react";

const AttachmentVirusStatus = ({attachment}) => {
    const t = useTrans();
    return attachment.virus_scan_status && attachment.virus_scan_status !== "clean" ? (
        <small className={`virus_status ${attachment.virus_scan_status}`}>{t('virus.attachment_status_label')}: {t(`virus.status_${attachment.virus_scan_status}`)}</small>
    ) : null;
}

export default AttachmentVirusStatus;