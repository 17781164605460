import history from "../setup/history";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import goTo from "../components/utils/goTo";
import {isDevDeployment, isProdDeployment, OKTA_OAUTH_NONCE, OKTA_OAUTH_STATE} from "../constants";

export const onAuthRequired = (options, a, b, c) => {
    console.log("onAuthRequired", options, a, b, c);
    history.push('/logout');
};

export const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
};

//TODO: prod config will be different from staging
export const oktaDomainConfig = isProdDeployment() ? ({
    clientId: "0oawpq2y0pAeZShaj416",
    domain: 'sso-chm.gl-systemhaus.de',
    issuer: 'https://sso-chm.gl-systemhaus.de/oauth2/auswpq8i3DUejOB6l416',
    mfaGroupId: '00gwpq2redGJZ9FzR416'
}) : ({
    clientId: "0oaiczgipYvBNcrEi416",
    domain: 'sso-chm.gl-systemhaus.de',
    issuer: 'https://sso-chm.gl-systemhaus.de/oauth2/aushfvyh8kKrh5k22416',
    mfaGroupId: '00gictc2haKNMyUQU416'
})
export const OKTA_DOMAIN = oktaDomainConfig.domain;

function getUrlLanguage() {
    return window.location.href.indexOf('/en/') === -1 ? 'de' : 'en';
}

export const oktaConfig = {
    issuer: oktaDomainConfig.issuer,
    clientId: oktaDomainConfig.clientId,
    devMode: !isProdDeployment(),
    redirectUri: window.location.origin + '/implicit/callback',
    postLogoutRedirectUri: window.location.origin + '/login',
    onAuthRequired,
    responseType: ['id_token', 'token'],
    scopes: ['openid', 'profile', 'email'],
    ignoreSignature: !isProdDeployment(),
    cookies: {
        sameSite: 'strict'
    },
    headers: {
        'Accept-Language': getUrlLanguage()
    },
    pkce: true,
    extraParams: isDevDeployment() ? {
        state: OKTA_OAUTH_STATE,
        nonce: OKTA_OAUTH_NONCE
    } : {},
    tokenManager: {
        autoRenew: true,
        expireEarlySeconds: 120
    }
};

export const oktaAuth = new OktaAuth(oktaConfig);

oktaAuth.tokenManager.on('expired', () => {
    goTo('logout');
});

