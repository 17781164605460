import useTrans from "../../hooks/useTrans";
import PageFeedback from "../PageFeedback";
import {CenteringRow, LightColumn} from "../../pages/LoginPage/style";
import {CheckIcon, Div, FailureIcon, H2, ILink} from "../../atoms/style";
import React from "react";

const SimpleFeedback = ({title, links, noIcon, success = true, children}) => {
    links = links || [{to: 'login', key: 'link.back_to_login'}];

    const icon = noIcon ? null : (success ? <CheckIcon/> : <FailureIcon/>);
    const t = useTrans();
    return (
        <PageFeedback>
            <CenteringRow>
                <LightColumn data-t={"feedback.success"}>
                    <H2 className="text-center" spacing={{my: 2, mb: 4}}>{title}</H2>
                    {icon}
                    {children}
                    {links.map(({to, key}, idx) => (
                        <Div key={idx} className="text-center" spacing={{pt: 4}}>
                            <ILink to={to} data-t={key} title="">{t(key)}</ILink>
                        </Div>
                    ))}
                </LightColumn>
            </CenteringRow>
        </PageFeedback>
    )
};

export default SimpleFeedback;