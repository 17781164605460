import React, {useContext} from "react";
import {StyledBadge} from "./style";
import NotificationsContext from "../../contexts/NotificationsContext";

const MessageCounterBadge = ({onOpen, count, isLoading}) => (
    <StyledBadge num={count} onClick={onOpen} data-t={'badge.notifications_mobile'}/>
);


export default () => {
    const {notifications, isLoading, open} = useContext(NotificationsContext);
    return notifications ? (
        <MessageCounterBadge isLoading={isLoading} count={notifications ? notifications.length : 0} onOpen={open}/>
    ) : null;
};