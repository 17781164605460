import {TinyEmitter} from "tiny-emitter";

const BE_AUTH_KEY = "be_auth_storage"

class BeAuthService {
    constructor(config) {
        this.emitter = new TinyEmitter();
        this.getAccessToken = this.getAccessToken.bind(this);
        this.clearAuthState = this.clearAuthState.bind(this);
        this.emitAuthState = this.emitAuthState.bind(this);
        this.getAuthState = this.getAuthState.bind(this);
        this.emit = this.emit.bind(this);
        this.on = this.on.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        this.setAccessToken = this.setAccessToken.bind(this);
        this.loadFromStorage = this.loadFromStorage.bind(this);
        this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
        this.clearLocalStorage = this.clearLocalStorage.bind(this);

        this.clearAuthState(this.loadFromStorage())
    }

    loadFromStorage() {
        try {
            const item = localStorage.getItem(BE_AUTH_KEY);
            if (item) {
                return JSON.parse(item);
            }
        } catch (e) {
            console.warn(`Failed parsing ${BE_AUTH_KEY} from localStorage: ${e}`);
        }
        return null;
    }

    clearLocalStorage() {
        localStorage.removeItem(BE_AUTH_KEY);
    }

    saveToLocalStorage() {
        localStorage.setItem(BE_AUTH_KEY, JSON.stringify(this.getAuthState()));
    }

    async setAccessToken(accessToken) {
        this.emitAuthState({
            ...this.getAuthState(),
            isPending: false,
            isAuthenticated: true,
            accessToken
        })
    }

    async getAccessToken() {
        return this.getAuthState().accessToken;
    }

    async login({token}) {
        console.log(`Login ${token}`);
        this.emitAuthState({
            isPending: false,
            isAuthenticated: true,
            accessToken: token
        });
    }

    clearAuthState(state={}) {
        this.emitAuthState({ ...BeAuthService.DEFAULT_STATE, ...state });
    }

    emitAuthState(state) {
        this._authState = this._authState || BeAuthService.DEFAULT_STATE;
        this._authState = {
            ...this._authState,
            ...state
        };
        this.saveToLocalStorage()
        this.emit('authStateChange', this.getAuthState());
    }

    getAuthState() {
        return this._authState;
    }

    async logout(options={}) {
        this.clearAuthState();
    }

    emit(event, message) {
        this.emitter.emit(event, message);
    }

    on(event, callback)  {
        this.emitter.on(event, callback, this.emitter);
        return () => {
            this.emitter.off(event, callback);
        };
    }
}

BeAuthService.DEFAULT_STATE = {
    isPending: true,
    isAuthenticated: null,
    idToken: null,
    accessToken: null,
};

export const beAuthService = new BeAuthService();