import React from "react";

const ComponentList = ({items, component, ...props}) => {
    const Component = component;
    return (items && items.length > 0) ? (
        items.map((item, idx) => <Component key={idx} idx={idx} {...item} {...props}/>)
    ) : null;
};

export default ComponentList;
