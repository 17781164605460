import withAttrs from "../../utils/withAttrs";
import {ListGroup, ListGroupItem} from "reactstrap";
import React from "react";
import {BigIcon, Div, NavLinkUpcase} from "../../atoms/style";
import styled from "styled-components";
import {maxWidth, minWidth} from "../../utils/media";

export const StyledListGroup = withAttrs(ListGroup, {
    spacing: {mb: 2}
});

export const StyledListGroupItem = withAttrs(ListGroupItem, {
    tag: "span",
    className: "d-flex rounded-0",
    spacing: {px: 2}
});

export const BigBlueIcon = withAttrs(BigIcon, {
    className: "text-blue-custom"
});

export const NotificationItem = ({children}) => (
    <StyledListGroupItem>
        <Div spacing={{mr: 2}}><BigBlueIcon icon="info-circle"/></Div>
        <div>{children}</div>
    </StyledListGroupItem>
);

export const Counter = styled.span.attrs({
    className: "scale-custom bg-red-custom"
})`
display: inline-block;
min-width: 2.05em;
height: 2.05em;
margin: -.5em -.375em -50% 0;
padding: 0 .5em 0 .5em;
vertical-align: top;
border-radius: 3em;
line-height: .15em;
text-align: center;

line-height: 2;
color: #fff !important;

${maxWidth.tablet`margin: .05rem -.625rem 0 -.2rem; font-size: 75%;`}
${minWidth.xs_desktop`.nav-link:hover & {transform: scale(1.15);}`}
`;

export const Text = withAttrs("span", {
    spacing: {md: {d: 'none'}, lg: {'d': 'inline'}, pl: 3}
});

export const Badge = ({num, ...props}) => num === 0 ? null : <Counter {...props}>{num}</Counter>;

export const StyledBadge = styled(Badge).attrs({
    className: "d-md-none"
})`
margin: .175rem 1.25rem 0 0;
font-size: 95%;
`;

export const StyledNavLinkUpcase = withAttrs(NavLinkUpcase, {
    className: "nav-link-custom transition-custom"
});