import PageFeedback from "../../molecules/PageFeedback";
import React from "react";
import {GreenBox, GreyHeader, GreySubmitButton, H4, HelpText, NoteBox, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import useTrans from "../../hooks/useTrans";
import {Col, FormGroup, Row} from "reactstrap";
import Markdown from "markdown-to-jsx";
import BootstrapField from "../../molecules/BootstrapField";
import {CHOIR_SIZES, CHOIR_VOICINGS, LANGUAGE_CHOICES} from "../../../constants";
import {boolean, object, ref, string} from "yup";
import {EventFormDottedSpacer, EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import moment from "moment-timezone";
import withAttrs from "../../utils/withAttrs";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import useToggle from "../../hooks/useToggle";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import {passwordSchema} from "../../validation/passwordSchema";

const timezoneNames = moment.tz.names();

const signupSchema = object({
    choir: object({
        name: string().required('validation.this_field_is_required'),
        slug: string().required('validation.this_field_is_required')
            .matches(/^[a-z0-9]+(?:[-_]+[a-z0-9]+)*$/, "validation.must_be_valid_slug"),
        voice_type: string().oneOf(CHOIR_VOICINGS).required('validation.this_field_is_required'),
        size: string().oneOf(CHOIR_SIZES).required('validation.this_field_is_required'),
    }),

    first_name: string().required('validation.this_field_is_required'),
    last_name: string().required('validation.this_field_is_required'),
    username: string().required('validation.this_field_is_required'),

    password: passwordSchema,
    re_password: string().required('validation.this_field_is_required')
        .oneOf([ref('password'), null], 'validation.passwords_should_match'),
    terms_agree: boolean().required('validation.this_field_is_required')
        .oneOf([true], 'validation.terms_must_be_accepted'),

    language: string().nullable().oneOf(LANGUAGE_CHOICES),
    timezone: string().nullable().oneOf(timezoneNames),
});

const onSubmit = ({t, onSuccess}) => (values, {setSubmitting, setErrors, setStatus}) => {
    const api = getApi("user");

    const email = values['username']
    const signup_data = {
        "email": email,
        "source": "signup"
    }
    const emailValidPromise = api.checkEmailValidity(email, signup_data);

    emailValidPromise.then(value => {
        let checkInvalid = Object.values(value).filter((email) => {
            return email['result']['verdict'] === 'Invalid'
        })
        let invalidEmails = checkInvalid.map(item => {
            return item['result']['email']
        }).join(',')

        if (checkInvalid.length === 0){
            console.log("Email valid", value['result']);
            return api.signupUser(values);
        }else {
            console.log("Email invalid", value['result']);
            setSubmitting(false)
            setStatus({error: t('form.email_validation') + ': ' + invalidEmails});
            setErrors(value['result'])
        }
    }).then(value => {
        console.log("second promise result", value)
        if (value === undefined){
            setSubmitting(false)
        }else {
            setSubmitting(false);
            onSuccess();
        }
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
};

const Num = withAttrs("span", {
    className: "bg-green-custom text-white",
    spacing: {mr: 2},
    style: {
        borderRadius: '50%',
        minWidth: '2.05em',
        minHeight: '2.05em',
        display: 'inline-block',
        lineHeight: '2.05em',
        textAlign: 'center'
    }
});

const FormLegend = ({num = 1, children}) => (
    <React.Fragment>
        <h5><strong><Num>{num}</Num> {children}</strong></h5>
        <EventFormDottedSpacer/>
    </React.Fragment>
);

const TheForm = ({t, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    return (
        <Form data-t={'form'}>

            <GreyHeader spacing={{py: 1}}><H4 spacing={{my: 2}}>{t('signup_form.header')}</H4></GreyHeader>

            <WhiteBox spacing={{px: 3, pt: 3, pb: 4}}>

                <FormStatus status={status}/>

                <FormLegend>{t('signup_form.choir_legend')}</FormLegend>

                <BootstrapField name={'choir.name'} label={<strong>{t('choir_form.name')}</strong>} required={true}/>
                <BootstrapField name={'choir.slug'}
                                label={<strong>{t('choir_form.slug')}</strong>}
                                required={true}
                                help_text={<HelpText>{t('choir_form.slug_help_text')}</HelpText>}/>

                <FormGroup row>
                    <BootstrapField col={6} fg={false} name={'choir.size'} label={<strong>{t('choir_form.size')}</strong>}
                                    type={'select'} required={true}
                                    help_text={<HelpText>{t('choir_form.size_help_text')}</HelpText>}>
                        {CHOIR_SIZES.map(size => <option key={size} value={size}>{t(`choir.size_${size}`)}</option>)}
                    </BootstrapField>

                    <BootstrapField col={6} fg={false} name={'choir.voice_type'} label={<strong>{t('choir_form.voice_type')}</strong>}
                                    type={'select'} required={true}
                                    help_text={<HelpText>{t('choir_form.voice_type_help_text')}</HelpText>}>
                        {CHOIR_VOICINGS.map(voiceType => <option key={voiceType} value={voiceType}>{voiceType}</option>)}
                    </BootstrapField>
                </FormGroup>

                <EventFormSpacer spacing={{mb: 3}}/>
                <FormLegend num={2}>{t('signup_form.user_legend')}</FormLegend>

                <FormGroup row>
                    <BootstrapField col={6} fg={false} required={true} name={'first_name'} label={<strong>{t('profile_form.first_name')}</strong>}/>
                    <BootstrapField col={6} fg={false} required={true} name={'last_name'} label={<strong>{t('profile_form.last_name')}</strong>}/>
                </FormGroup>
                <FormGroup row>
                    <BootstrapField col={6} fg={false} required={true} name={'username'} label={<strong>{t('profile_form.email')}</strong>}/>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField col={6} fg={false} required={true} name={'password'}
                                    label={<strong>{t('profile_form.password')}</strong>}
                                    type={'password'}/>
                    <BootstrapField col={6} fg={false} required={true} name={'re_password'}
                                    label={<strong>{t('profile_form.re_password')}</strong>}
                                    type={'password'}/>
                    <Col>
                        <HelpText>{t('choir_form.password_help_text')}</HelpText>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <BootstrapField col={6} fg={false} name={'language'} label={<strong>{t('profile_form.language')}</strong>} type={'select'}>
                        {LANGUAGE_CHOICES.map(lng => <option key={lng} value={lng}>{t(`language.${lng}`)}</option>)}
                    </BootstrapField>
                    <BootstrapField col={6} fg={false} name={'timezone'} label={<strong>{t('profile_form.timezone')}</strong>} type={'select'}>
                        {timezoneNames.map(tz => <option key={tz} value={tz}>{tz}</option>)}
                    </BootstrapField>
                </FormGroup>

                <GreenBox>
                    <BootstrapField type={'checkbox'} name={'terms_agree'} label={<Markdown>{t('signup_form.terms_agree_md?')}</Markdown>}/>
                </GreenBox>

                <NoteBox>
                    <Markdown>{t("signup_form.note_md")}</Markdown>
                </NoteBox>

                <EventFormSpacer/>

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'} disabled={isSubmitting}>{t('signup_form.register')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton data-t={'signup_form.cancel'} to={"login"}>{t('signup_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>
            </WhiteBox>

        </Form>
    );
};

const SignupForm = (props) => {
    const initialValues = {
        timezone: "Europe/Berlin",
        language: "de",
        choir: {
            size: CHOIR_SIZES[0],
            voice_type: CHOIR_VOICINGS[0]
        }
    };
    return (
        <Formik validationSchema={signupSchema} initialValues={initialValues} onSubmit={onSubmit(props)}>
            {(formProps) => (
                <TheForm {...props} formProps={formProps} />
            )}
        </Formik>
    );
};

export default function (props) {
    const [isRequested, toggleRequested] = useToggle();
    const t = useTrans();

    if (isRequested){
        return (
            <SimpleFeedback title={t('signup.signup_success')}>
                <Markdown data-t={"success-note"}>{t('signup.signup_success_note')}</Markdown>
            </SimpleFeedback>
        );
    }

    return (
        <PageFeedback>
            <Row className="justify-content-md-center">

                <Col lg={9}>
                    <Markdown>{t('signup.greeting_md')}</Markdown>

                    <SignupForm t={t} onSuccess={toggleRequested} />

                </Col>

            </Row>
        </PageFeedback>
    );
}