import {FieldArray} from "formik";
import ReminderInput from "./ReminderInput";
import {IconedLink} from "../../atoms/style";
import React from "react";
import {EventFormGreyBox} from "./style";

const RemindersField = ({name = 'reminders', disabled = false, setFieldValue, value, t, from_date}) => {
    return (
        <FieldArray name={name} render={arrayHelpers => {
            return (
                <div>
                    <EventFormGreyBox>
                        {t('event_form.automatic_reminders_note')}
                    </EventFormGreyBox>
                    {value && value.length > 0 ? value.map((reminder, index) => (
                        <ReminderInput
                            index={index + 1}
                            key={`${name}.${reminder.id || reminder._id}`}
                            prefix={`${name}.${index}`}
                            value={reminder}
                            setFieldValue={setFieldValue}
                            disabled={disabled}
                            t={t}
                            from_date={from_date}
                            onDelete={() => {
                                if (window.confirm(t('confirm.delete_reminder?'))) {
                                    arrayHelpers.remove(index)
                                }
                            }}/>
                    )) : null}
                    {!disabled && (!value || value.length < 10) ? (
                        <IconedLink icon={'plus-square'} onClick={() => arrayHelpers.push({_id: Math.round(Math.random() * 10000), _selection: "reminder_days"})}>
                            {t('event_form.add_automatic_reminder')}
                        </IconedLink>
                    ) : null}
                </div>
            );
        }}/>
    );
};

export default RemindersField;