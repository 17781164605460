import React, {useContext} from 'react';
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import useTrans from "../../hooks/useTrans";
import {NoItemsFound} from "../../atoms/style";
import WithPagination from "../../molecules/WithPagination";
import PaginationContext from "../../contexts/PaginationContext";
import ManagerOptOutRequestFetcher from "../../fetchers/ManagerOptOutRequestFetcher";
import Pagination from "../../molecules/Pagination/Pagination";
import OptOutRequestBox from "./OptOutRequestBox";

const OptOutRequests = ({t, onEventSelect}) => {
    const {pagination, updatePagination} = useContext(PaginationContext);
    const response = ManagerOptOutRequestFetcher.read({page: pagination.cur_page});
    const invalidate = ManagerOptOutRequestFetcher.invalidator({page: pagination.cur_page});
    React.useEffect(() => {
        updatePagination(response);
    }, [response, updatePagination]);
    const items = response.results;

    return items && items.length ? (
        <React.Fragment>

            {items.map((optout, idx) =>
                <OptOutRequestBox key={idx} t={t} optout={optout} onSuccess={invalidate}/>
            )}

            <Pagination t={t} />

        </React.Fragment>
    ) : (
        <NoItemsFound>{t("optout.no_optout_requests")}</NoItemsFound>
    );
};

const OptOutRequestListPage = (props) => {
    const t = useTrans();
    return (
        <PageWithSidebar title={t('optout.list_title')} sidebar={<SideBar />}>
            <WithPagination>
                <OptOutRequests t={t} {...props}/>
            </WithPagination>
        </PageWithSidebar>
    );
};
export default OptOutRequestListPage;