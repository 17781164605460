import React from 'react'
import getApi from "../../services/getApi";

export default function DownloadLink ({ url, children, ...props }) {
    const link = React.useRef()
    const altFilename = props.alt ? props.alt : (props.title ? props.title : "download");
    const handleAction = React.useCallback(async (e) => {
        if (link.current.attributes.lasturl === url){
            return
        }
        link.current.attributes.lasturl = url;
        e.preventDefault();
        const api = getApi("files");
        try {
            const [filename, blob] = await api.request(url, {blob: true});
            const href = window.URL.createObjectURL(blob);

            if (filename) {
                link.current.download = filename;
            } else {
                link.current.download = altFilename;
            }
            link.current.href = href;

            link.current.click();
        } catch (err) {
            console.log(err);
            window.alert("Could not download file");
        }
    }, [url, link, altFilename]);

    return (
        <a {...props} role='button' ref={link} onClick={handleAction}>{children}</a>
    )
}