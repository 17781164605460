import {Form, Formik} from "formik";
import {
    GreenBox,
    GreySubmitButton,
    IconedNoteBox,
    RedSubmitButton,
    SubmitButton,
    WhiteBox,
    YellowBox
} from "../../atoms/style";
import React, {useContext, useEffect, useState} from "react";
import BootstrapField from "../../molecules/BootstrapField";
import {FormGroup, Row} from "reactstrap";
import {EventFormGreyBox, EventFormSpacer, FormSubmitCol} from "./style";
import goTo from "../../utils/goTo";
import getApi from "../../../services/getApi";
import EventFormCollapse from "./EventFormCollapse";
import RemindersField from "./RemindersField";
import CollapseMap, {LocationAutocomplete} from "./CollapseMap";
import EventFetcher from "../../fetchers/EventFetcher";
import isAppError from "../../../utils/isAppError";
import AttachmentsField from "./AttachmentsField";
import OpenQuestionsField from "./OpenQuestionsField";
import MultipleChoicesField from "./MultipleChoicesField";
import validateExtension from "../../../utils/validateExtension";
import MemberChoicesField from "./MemberChoicesField";
import {array, boolean, mixed, number, object, ref, string} from 'yup';
import {castMomentToDateStr, castToMoment} from "../../validation/utils";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import ToastContext from "../../contexts/ToastContext";
import EventListFetcher from "../../fetchers/EventListFetcher";
import 'react-rrule-generator/build/styles.css';
import RRuleInput from "../../molecules/RRuleInput/RRuleInput";
import usePrompt from "../../hooks/usePrompt";
import changed from "../../utils/changed";
import dateMatchRRule from "../../../utils/dateMatchRRule";
import validateFileSize from "../../../utils/validateFileSize";

const reminderSchema = object({
    id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
    reminder_date: mixed().transform(castToMoment).nullable(),
    reminder_days: number('validation.must_be_integer')
        .positive('validation.must_be_positive')
        .integer('validation.must_be_integer')
        .nullable().transform(value => (value === "" || isNaN(value) || !value) ? null : value)
}).transform(value => ({...value, _selection: value._selection || (value.reminder_days ? "reminder_days" : "reminder_date") }));

const castToRemindersString = value => {
    if (!value) {
        return ""
    }
    const result = [...new Set(
        value
            .map(reminder => reminder ? reminder.reminder_days : null)
            .filter(it => it && it > 0)
    )];
    result.sort()
    return result.map(it => "" + it).join(",")
};

const castToRemindersList = value => {
    if (!value) {
        return [];
    }
    let count = 0;
    return value.split(",").map(it => ({
        id: ++count,
        reminder_days: parseInt(it)
    }));
};

const removeSeconds = (value) => {
    const parts = value.split(":");
    if (parts.length === 3) {
        return `${parts[0]}:${parts[1]}`
    } else {
        return value;
    }
};

const eventSchema = (eventTypeKeys) => object({
    id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
    lat: number().nullable(),
    lng: number().nullable(),
    from_date: mixed().transform(castToMoment).required('validation.this_field_is_required'),
    to_date: mixed().transform(castToMoment).nullable().default(ref('from_date')),
    from_hour: string().nullable().transform(value => value ? removeSeconds(value) : null),
    to_hour: string().nullable().transform(value => value ? removeSeconds(value) : null),
    recurrences: string().nullable().when(['from_date', 'id'], (from_date, id, schema) => {
        if (from_date && !id) {
            return schema.test('start_date_match_recurrence', 'validation.from_date_should_be_within_recurrence_rule', value => dateMatchRRule(from_date, value));
        } else {
            return schema;
        }
    }),
    notes: string().nullable().ensure(),
    location: string().nullable().notRequired().ensure(),
    title: string().required('validation.this_field_is_required'),
    choir_id: number().required('validation.this_field_is_required').integer('validation.must_be_integer').positive('validation.must_be_positive'),
    rehearsal_time: string().nullable(),
    scp_id: number().positive('validation.must_be_positive').integer('validation.must_be_integer').nullable(),
    ccp_id: number().positive('validation.must_be_positive').integer('validation.must_be_integer').nullable(),
    user_commitment_url: string().nullable(),
    opt_out_event: boolean(),
    default_yes: boolean(),
    public_allowed: boolean().default(false),
    event_type: string().required('validation.this_field_is_required').oneOf(eventTypeKeys).default(eventTypeKeys[0]), //.nullable(),
    notify_on_create: boolean(),
    notification_message: string().nullable(),
    notify_on_edit: boolean(),
    change_reason: string().nullable(),
    show_map: boolean().default(false),
    attachments: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        name: string().required('validation.this_field_is_required').nullable(),
        description: string().ensure(),
        file: string().nullable().when(['_file'], (file, schema) => {
            if (file && file.name) {
                return schema
                    .test('invalid_file_extension', 'validation.invalid_file_extension', _ => validateExtension(file.name))
                    .test('file_size_too_big', 'validation.file_size_too_big', _ => validateFileSize(file))
            } else {
                return schema;
            }
        })
    })).compact(value => !value || (!!!value.name && !!!value.description && !!!value.file && !!!value._file)),
    multiple_choices: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        question: string().required('validation.is_required'),
        options: array().of(object({
            id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
            text: string().nullable()
        })).min(2, 'validation.at_least_2_items').max(5).compact(value => !value || !value.text || value.text.trim().length === 0)
    })),
    open_questions: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        text: string().required('validation.this_field_is_required')
    })).compact(value => !value || !value.text || value.text.trim().length === 0),
    member_choices: array().of(object({
        id: number().positive('validation.must_be_positive').integer('validation.must_be_integer'),
        question: string().required('validation.this_field_is_required'),
        choice: string().oneOf(['voice', 'multiple']).default('multiple')
    })).compact(value => !value || !value.question || value.question.trim().length === 0),
    reminders: array().of(reminderSchema).compact(value => !value || (!!!value.reminder_date && !!!value.reminder_days)),
});

const deserializeEventSchema = (eventTypeKeys) => eventSchema(eventTypeKeys).shape({
    reminders: mixed().transform(castToRemindersList)
})

const newEvent = {
    from_date: null,
    title: "",
    choir_id: null,
    recurrences: null,
    from_hour: "",
    attachments: [],
    reminders: [],
    multiple_choices: [],
    open_questions: [],
    member_choices: []
};

const serializeEventSchema = (eventTypeKeys) => eventSchema(eventTypeKeys).shape({
    from_date: mixed().transform(castMomentToDateStr),
    to_date: mixed().transform(castMomentToDateStr),
    reminders: mixed().transform(castToRemindersString)
});

function checkChange(initialValues, values) {
    const changedValues = changed(values, initialValues);
    const isRelatedChanged = changedValues.attachments || changedValues.member_choices || changedValues.open_questions || changedValues.multiple_choices;
    const isStartChanged = changedValues.from_date || changedValues.from_hour;
    const isRecurrenceChanged = !!changedValues.recurrences;
    const isIllegal = isRecurrenceChanged && isRelatedChanged;
    return {
        isRelatedChanged,
        isRecurrenceChanged,
        isIllegal,
        isStartChanged,
        changedValues
    };
}
const getChoirById = (choirs, selectedChoirId) => {
    if (!selectedChoirId) {
        return null;
    }
    selectedChoirId = parseInt(selectedChoirId)
    return choirs.find((choir) => selectedChoirId === choir.id);
}

const eventOptions = (choirEventTypeState, fixedSortedEvents, t) => {
    return (
        <>
        {choirEventTypeState.eventTypeData.indexOf(fixedSortedEvents[0]) !== -1 ? <option key={fixedSortedEvents[0]} value={fixedSortedEvents[0]}>{t(`event_type.${fixedSortedEvents[0]}`)}</option> : ""}
        {choirEventTypeState.eventTypeData.indexOf(fixedSortedEvents[1]) !== -1 ? <option key={fixedSortedEvents[1]} value={fixedSortedEvents[1]}>{t(`event_type.${fixedSortedEvents[1]}`)}</option> : ""}
        {choirEventTypeState.eventTypeData?.map((event_type, idx) =>
            (fixedSortedEvents.indexOf(event_type)) === -1 ? <option key={event_type} value={event_type}>{t(`event_type.${event_type}`)}</option> : "")
        }
        {choirEventTypeState.eventTypeData.indexOf(fixedSortedEvents[2]) !== -1 ? <option key={fixedSortedEvents[2]} value={fixedSortedEvents[2]}>{t(`event_type.${fixedSortedEvents[2]}`)}</option> : ""}
        </>
    )
}

const TheEventForm = ({t, isEdit, event, choirs, eventTypeKeys, choirEventTypes, promptModal, formProps: {isSubmitting, submitCount, setStatus, initialValues, touched, errors, status, values, submitForm, setFieldValue}}) => {
    const selectedChoir = getChoirById(choirs, values.choir_id);

    useEffect(() => {
        if (selectedChoir && !isEdit) {
            setFieldValue('notify_on_create', selectedChoir.event_notifications)
        }
    }, [setFieldValue, selectedChoir, isEdit]);

    const toaster = useContext(ToastContext);

    const forDate = values.from_date ? values.from_date.toDate() : null;

    const deleteConfirmed = (deleteFunction) => {
        deleteFunction(event.id).then(() => {
            EventListFetcher.invalidateAll();
            goTo('event_list');
            toaster.onPush({msg: t('success.event_deleted'), type: 'success'});
        }).catch(error => {
            toaster.onPush({msg: t('error.failed_deleting_event'), type: 'error'});
        });
    };

    const onDelete = (e) => {
        e.preventDefault();
        const api = getApi("event");
        if (event && event.id) {
            if (event.recurrences && event.recurrences !== '') {
                promptModal({
                    title: t('confirm.delete_event?'),
                    body: t('confirm.delete_recurring_event_body'),
                    actions: [
                        {
                            text: t('event_action.all'),
                            "data-t": "button.all_events",
                            onClick: () => deleteConfirmed(api.deleteEventAllRecurring)
                        },
                        {
                            text: t('event_action.this_and_next'),
                            "data-t": "button.this_and_next_events",
                            onClick: () => deleteConfirmed(api.deleteEventThisAndNext)
                        },
                        {
                            text: t('event_action.this_only'),
                            "data-t": "button.this_only_event",
                            onClick: () => deleteConfirmed(api.deleteEvent)
                        },
                    ]
                });
            } else {
                promptModal({
                    title: t('confirm.delete_event?'),
                    actions: [
                        {
                            text: t('confirm.yes'),
                            onClick: () => deleteConfirmed(api.deleteEvent)
                        },
                    ]
                });
            }
        }
        return false;
    };
    const {isIllegal, isRelatedChanged} = checkChange(initialValues, values);
    const recurrenceDoesntMatchEventStart = values.id && values.from_date && values.recurrences ? !dateMatchRRule(values.from_date, values.recurrences) : false;

    // sort eventtypes:
    choirs.map((choir) => {
        choirEventTypes[choir.id].sort((a, b) => {return t(`event_type.${a}`).localeCompare(t(`event_type.${b}`))});
        return false
    });
    // if form is called via ChoirEdit.js the choir is fixed:
    const initialChoirId = event ? event.choir_id : choirs[0].id
    const initialChoirEventTypeState = {
        choirid: initialChoirId,
        eventTypeData: choirEventTypes[initialChoirId]
    }
    const [choirEventTypeState, setChoirEventTypeState] = useState(initialChoirEventTypeState);

    
    // event types that are supposed to appear in a specific order if existing
    const fixedSortedEvents = ["Concert","Rehearsal","Other"]
    useEffect(() => {
        // initialize event_type field to Concert on page load
        if (!isEdit) {
            setFieldValue("event_type", fixedSortedEvents[0]);
        }
    },[]);
    
    const changeChoir = (e) => {
        if (e.target.value === ""){
            setChoirEventTypeState({
                choirid: null,
                eventTypeData: []
            });
            return false
        } else{
            const upd = {choirid: e.target.value, eventTypeData: choirEventTypes[e.target.value]}
            setChoirEventTypeState(upd);
            setFieldValue("choir_id", upd.choirid);
            // initialize event_type field to Concert on every choir change
            setFieldValue("event_type", fixedSortedEvents[0]);
        }
    }


    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <Form>
                <FormStatus status={status} error_title={isIllegal ? t('event_form.recurring_event_and_related_objects') : null} />

                <BootstrapField onChange={e=>changeChoir(e)}
                    name={'choir_id'} disabled={isEdit} label={<strong>{t('event_form.choir')}</strong>} type={'select'} required={true}>
                    {choirs.map(choir => <option key={choir.id} value={choir.id}>{choir.name}</option>)}
                </BootstrapField>

                <BootstrapField name={'event_type'} label={<strong>{t('event_form.event_type')}</strong>} type={'select'} required={true}>
                    {eventOptions(choirEventTypeState, fixedSortedEvents, t)}
                </BootstrapField>

                <FormGroup row>
                    <BootstrapField type={'date'} name={'from_date'} label={<strong>{t('event_form.from_date')}</strong>} md={6} fg={false} required={true}/>
                    <BootstrapField type={'date'} name={'to_date'} label={<strong>{t('event_form.to_date')}</strong>} md={6} fg={false}/>
                </FormGroup>


                <FormGroup row>
                    <BootstrapField type={'time'} name={'from_hour'} label={<strong>{t('event_form.from_hour')}</strong>} md={3} required={false} fg={false}/>
                    <BootstrapField type={'time'} name={'to_hour'} label={<strong>{t('event_form.to_hour')}</strong>} md={3} fg={false}/>
                </FormGroup>

                <FormGroup>
                    <BootstrapField name={'recurrences'}
                                    forDate={forDate}
                                    input={RRuleInput}
                                    showError
                                    initialValue={initialValues ? initialValues.recurrences : null}
                                    required={false}
                                    label={<strong>{t('event_form.recurrence')}</strong>}
                    />

                    {recurrenceDoesntMatchEventStart ? <IconedNoteBox>
                        {t('note.from_date_does_not_match_recurrence_rule')}
                    </IconedNoteBox> : null}
                </FormGroup>

                <FormGroup row>
                    <BootstrapField type={'time'} name={'rehearsal_time'} label={<strong>{t('event_form.rehearsal_time')}</strong>} md={3} fg={false}/>
                </FormGroup>

                <BootstrapField name={'title'} label={<strong>{t('event_form.title')}</strong>} required={true}/>

                <BootstrapField name={'location'} type={'text'} input={LocationAutocomplete} label={<strong>{t('event_form.location')}</strong>} groupContent={
                    <CollapseMap value={(values.lat && values.lng) ? [values.lat, values.lng] : null} t={t} defaultValue={values['show_map']} onChange={(value) => setFieldValue('show_map', value)}/>
                }/>

                <BootstrapField name={'notes'} label={<strong>{t('event_form.notes')}</strong>} type={'textarea'} rows={5}/>

                <EventFormSpacer/>
                <EventFormCollapse title={t('event_form.automatic_reminders')}>
                    <RemindersField t={t} value={values.reminders} setFieldValue={setFieldValue} from_date={values.from_date}/>
                </EventFormCollapse>
                <EventFormSpacer/>
                <EventFormCollapse title={t('event_form.attachments')}>
                    <AttachmentsField t={t} value={values.attachments} setFieldValue={setFieldValue}/>
                </EventFormCollapse>
                <EventFormSpacer />
                <EventFormCollapse title={t('event_form.opt_out_title')}>
                    <BootstrapField type={'checkbox'} name={'opt_out_event'} checked={values['opt_out_event']} label={t('event_form.opt_out_event_label')}/>
                </EventFormCollapse>
                <EventFormSpacer />
                <EventFormCollapse title={t('event_form.default_yes_title')}>
                    <BootstrapField type={'checkbox'} name={'default_yes'} checked={values['default_yes']} label={t('event_form.default_yes_label')}/>
                </EventFormCollapse>
                <EventFormSpacer />
                <EventFormCollapse title={t('event_form.poll_title')}>
                    <EventFormGreyBox>
                        {t('event_form.polls_note')}
                    </EventFormGreyBox>
                    <OpenQuestionsField t={t} value={values.open_questions} setFieldValue={setFieldValue}/>
                    <MultipleChoicesField t={t} value={values.multiple_choices} setFieldValue={setFieldValue}/>
                    <MemberChoicesField t={t} value={values.member_choices} limit={1} setFieldValue={setFieldValue}/>
                    <EventFormGreyBox>
                        {t('event_form.polls_limit_note')}
                    </EventFormGreyBox>
                </EventFormCollapse>
                <EventFormSpacer/>
                {isEdit ? (
                    <YellowBox>
                        <BootstrapField type={'checkbox'} name={'notify_on_edit'} checked={values['notify_on_edit']} label={<strong>{t('event_form.notify_on_edit?')}</strong>} groupContent={
                            <BootstrapField name={'change_reason'} spacing={{mt: 2}} placeholder={t('event_form.change_reason')} type={'textarea'} rows={3}/>
                        } />
                    </YellowBox>
                ) : (
                    <YellowBox>
                        <BootstrapField type={'checkbox'} name={'notify_on_create'} checked={values['notify_on_create']} label={<strong>{t('event_form.notify_on_create?')}</strong>} groupContent={
                            <BootstrapField name={'notification_message'} spacing={{mt: 2}} placeholder={t('event_form.notify_on_create')} type={'textarea'} rows={3}/>
                        } />
                    </YellowBox>
                )}
                <GreenBox>
                    <h4>{t('event_form.publish?')}</h4>
                    <BootstrapField type={'checkbox'} name={'public_allowed'} checked={values['public_allowed']} label={t('event_form.publish_label')}/>
                </GreenBox>

                {values.recurrences && isRelatedChanged ? (
                    <IconedNoteBox>
                        {t('event_form.related_objects_applied_to_this_event_only')}
                    </IconedNoteBox>
                ) : null}

                <EventFormSpacer spacing={{mb: 3}}/>

                <FormStatus status={status} error_title={isIllegal ? t('event_form.recurring_event_and_related_objects') : null} />

                <Row>

                    <FormSubmitCol>
                        <SubmitButton type={'submit'} data-t={"button.save"} disabled={isIllegal || isSubmitting}>{t('event_form.save')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        {isEdit ? (
                            <RedSubmitButton data-t={'button.delete_event'} onClick={onDelete} disabled={isSubmitting}>{t('event_form.delete_event')}</RedSubmitButton>
                        ) : (
                            <SubmitButton type={'button'} onClick={(e) => {
                                e.preventDefault();
                                if (!isSubmitting) {
                                    setFieldValue('_submitAndNew', true);
                                    setTimeout(submitForm, 50);
                                }
                                return false;
                            }} disabled={isSubmitting || isIllegal}>{t('event_form.save_and_new')}</SubmitButton>
                        )}
                    </FormSubmitCol>
                    <FormSubmitCol>
                        <GreySubmitButton data-t={'button.cancel'} to={event ? ['event_detail', {id: event.id}] : 'event_list'} disabled={isSubmitting}>{t('event_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

const extractNewAttachments = (data) => ({
    newAttachments: (data && data.attachments ? data.attachments.filter(attachment => !attachment.id) : []),
    newData: {
        ...data,
        attachments: (data && data.attachments ? data.attachments.filter(attachment => !!attachment.id) : [])
    },
});

const onSubmit = ({t, isEdit, promptModal, initialValues, toaster, eventTypeKeys, onSuccess, event}) => (values, {isSubmitting, setSubmitting, setErrors, setStatus}) => {
    const {_submitAndNew, ...data} = values;
    if (isSubmitting) {
        return;
    }
    const api = getApi("event");
    const {newAttachments, newData} = extractNewAttachments(serializeEventSchema(eventTypeKeys).cast(data));

    const saveAction = (theAction) => {
        const promise = isEdit ? theAction(event.id, newData) : api.createEvent(newData);
        promise.then(event => {
            toaster.onPush({msg: t('success.event_saved'), type: 'success'});
            return event;
        }).then((event) => {
            if (newAttachments && newAttachments.length > 0) {
                toaster.onPush({msg: t('warning.uploading_attachments'), type: 'warning'});
                return Promise.all(newAttachments.map(({_file, file, ...attachment}) => {
                    return api.createEventAttachment(event.id, attachment, _file);
                })).then(() => {
                    toaster.onPush({msg: t('success.attachments_uploaded'), type: 'success'});
                    return api.getEvent({event_id: event.id});
                }).catch((error) => {
                    toaster.onPush({msg: t('error.attachment_upload_failed'), type: 'error'});
                    return event;
                });
            } else {
                return event;
            }
        }).then((event)=>{
            EventFetcher.update({event_id: event.id}, event);
            onSuccess();
            setSubmitting(false);
            if (_submitAndNew) {
                goTo('event_create');
            } else {
                goTo('event_detail', {id: event.id});
            }
        }).catch(error => {
            console.error("FAILED", error);
            setSubmitting(false);
            if (isAppError(error)) {
                setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
                setErrors(error.body)
            }
        });
    };

    const {isRelatedChanged, isRecurrenceChanged, isStartChanged} = checkChange(initialValues, values);
    if (isEdit) {
        if (event.recurrences && event.recurrences !== '') {
            const recurrenceDoesntMatchEventStart = !dateMatchRRule(values.from_date, values.recurrences);
            promptModal({
                title: t('confirm.save_recurring_event'),
                body: t('confirm.save_recurring_event_body'),
                actions: [
                    {
                        text: t('event_action.all'),
                        "data-t": "button.all_events",
                        disabled: isRelatedChanged || isStartChanged || recurrenceDoesntMatchEventStart,
                        onClick: () => saveAction(api.updateEventAllRecurring)
                    },
                    {
                        text: t('event_action.this_and_next'),
                        "data-t": "button.this_and_next_events",
                        disabled: isRelatedChanged || recurrenceDoesntMatchEventStart,
                        onClick: () => saveAction(api.updateEventThisAndNext)
                    },
                    {
                        text: t('event_action.this_only'),
                        "data-t": "button.this_only_event",
                        disabled: isRecurrenceChanged,
                        onClick: () => saveAction(api.updateEvent)
                    },
                ],
                onClose: () => {
                    setSubmitting(false);
                }
            });
        } else {
            saveAction(api.updateEvent);
        }
    } else {
        saveAction();
    }
};

export const EventForm = (props) => {
    const {isEdit, event, choirs} = props;
    let eventTypes = {}
    let choirEventTypes = {}
    choirs.map(choir => {
        choirEventTypes[choir.id] = []
        choir.event_types.map(data_eventtype => {
            eventTypes[data_eventtype.slug]="";
            choirEventTypes[choir.id].push(data_eventtype.slug);
            return false
        });
        return false
    });
    let eventTypeKeys = Object.keys(eventTypes);

    const toaster = useContext(ToastContext);
    const promptModal = usePrompt();
    const initialValues = isEdit ? deserializeEventSchema(eventTypeKeys).cast(event) : {
        ...newEvent,
        event_type: eventTypeKeys[0],
        choir_id: (choirs && choirs.length > 0) ? choirs[0].id : null
    };
    return (
        <Formik validationSchema={eventSchema(eventTypeKeys)}
                initialValues={initialValues}
                onSubmit={onSubmit({...props, initialValues, promptModal, toaster, eventTypeKeys, onSuccess: EventListFetcher.invalidateAll})}>
            {(formProps) => (
                <TheEventForm {...props} promptModal={promptModal} formProps={formProps} choirs={choirs} eventTypeKeys={eventTypeKeys} choirEventTypes={choirEventTypes}/>
            )}
        </Formik>
    );
};

export default EventForm;