import {useEffect, useState} from "react";
import {throttle} from "lodash";

function useScrollDistance(threshold = 50) {
    const [value, setValue] = useState(window.pageYOffset || document.documentElement.scrollTop);
    useEffect(() => {
        const updateScrollDistance = throttle(() => {
            const value = window.pageYOffset || document.documentElement.scrollTop;
            setValue(value > threshold);
        }, 200);
        window.addEventListener('scroll', updateScrollDistance);
        return () => {
            window.removeEventListener("scroll", updateScrollDistance);
        }
    }, [setValue, threshold]);
    return value;
}

export default useScrollDistance;