import {Div, RedButton, WrappedCol, WrappedRow} from "../../atoms/style";
import React, {useContext} from "react";
import useTrans from "../../hooks/useTrans";
import ChoirContext from "../../contexts/ChoirContext";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import ChoirListFetcher from "../../fetchers/ChoirListFetcher";
import DangerBox from "../DangerBox/DangerBox";


export default function (props) {
    const {choir} = useContext(ChoirContext);
    if (!choir.scp_id) {
        return null;
    }
    const t = useTrans();
    const onLeave = () => {
        if (window.confirm(t('choir.leave_prompt?'))) {
            getApi("choir_detail").leaveChoir({choir_id: choir.id}).then(() => {
                goTo('choir_list');
                ChoirListFetcher.invalidate();
            })
        }
    };
    return (
        <DangerBox icon={'sign-out-alt'} title={t('choir.leave')}>
            <Div spacing={{p: 3, mb: 3}} className="small bg-red-custom-light">
                {t('choir.leave_warning')}
            </Div>
            <WrappedRow>
                <WrappedCol md={4} spacing={{md: {mb: 0}, mb: 4}}>
                    <RedButton onClick={onLeave} size={'md'} spacing={{w: 100}} title="">{t('choir.action_leave')}</RedButton>
                </WrappedCol>
            </WrappedRow>
        </DangerBox>
    );
}