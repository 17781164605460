import moment from "moment";

export const momentToRRuleDate = (mDate) => new Date(Date.UTC(
    mDate.get('year'),
    mDate.get('month'),
    mDate.get('date'),
    mDate.get('hour'),
    mDate.get('minute'),
    mDate.get('second'),
    mDate.get('millisecond')
));

export const rruleDateToMoment = (rDate) => moment({
    years: rDate.getUTCFullYear(),
    months: rDate.getUTCMonth(),
    date: rDate.getUTCDate(),
    hours: rDate.getUTCHours(),
    minutes: rDate.getUTCMinutes(),
    seconds: rDate.getUTCSeconds(),
    milliseconds: rDate.getUTCMilliseconds()
});