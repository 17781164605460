import React, {useContext} from "react";

import {Row} from "reactstrap";
import VoiceMember from "./VoiceMember";
import groupBy from 'lodash/groupBy';
import {AttendanceBoxWrapper, Title, VoiceBoxWrapper, VoiceMembersWrapper} from "./style";
import {getLeafVoices} from "../../../utils/choirUtils";
import EventContext from "../../contexts/EventContext";

const VoiceBox = ({title, choir_id, commitments}) => {
    const {isManager, event} = useContext(EventContext);
    return (
        <VoiceBoxWrapper>
            <Title as={'div'}>
                <strong>{title}</strong>
            </Title>
            <VoiceMembersWrapper>
                {commitments ? commitments.map((commitment, idx) => (
                    <VoiceMember key={idx}
                                 choir_id={event.choir_id}
                                 canChange={isManager || (event.scp_id && event.scp_id === commitment.scp_id)}
                                 {...commitment}/>
                )) : null}
            </VoiceMembersWrapper>
        </VoiceBoxWrapper>
    );
}

const AttendanceBoxGrid = ({voices, t, commitments, ...props}) => {
    const groupedCommitments = groupBy(commitments, 'voice.id');
    const leafVoices = getLeafVoices(voices);
    return (leafVoices && leafVoices.length > 0) ? (
        <AttendanceBoxWrapper>
            <Row className="no-gutters">
                {leafVoices.map((voice, idx) => {
                    const commitments = groupedCommitments[voice.id];
                    return (
                        <VoiceBox key={idx} title={t(`voice.name_${voice.abbr || 'MS'}`)} commitments={commitments}/>
                    );
                })}
            </Row>
        </AttendanceBoxWrapper>
    ) : null;
};

export default AttendanceBoxGrid;