import React from 'react';
import PageFeedback from "../../molecules/PageFeedback";
import withTrans from "../../atoms/withTrans";

const NotFoundPage = ({t}) => (
    <PageFeedback>
        <h1>{t('common.page_not_found')}</h1>
    </PageFeedback>
);

export default withTrans(NotFoundPage);