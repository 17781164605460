import {WrappedCol, WrappedRow} from "../../atoms/style";
import React from "react";


export const RegRow = ({left, right, children, hide, leftSize = 3, ...props}) => hide || (!children && !right) ? null : (
    <WrappedRow noGutters spacing={{pt: 3}} {...props}>
        <WrappedCol md={leftSize}>
            <strong>{left}</strong>
        </WrappedCol>
        <WrappedCol md={12 - leftSize} spacing={{md: {pl: 3}}}>
            {children ? children : right}
        </WrappedCol>
    </WrappedRow>
);