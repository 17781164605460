import {useState} from "react";

const isBool = (value) => {
    return value === false || value === true;
};

class KeyToggle {
    constructor(state, setState, keys, defaultValue = true) {
        this.state = state;
        this.setState = setState;
        let $allTrue = {};
        let $allFalse = {};
        keys.forEach(key => {
            $allTrue[key] = true;
            $allFalse[key] = false;
        });
        this.allTrue = $allTrue;
        this.allFalse = $allFalse;
        this.defaultValue = defaultValue;
    }

    getToggleValue(key) {
        if (isBool(this.state[key])) {
            return this.state[key];
        }

        return this.defaultValue;
    }

    toggleValue(key) {
        this.setState({...this.state, [key]: !this.getToggleValue(key)});
    }

    setAllTrue = () => {
        this.setState(this.allTrue);
    };

    setAllFalse = () => {
      this.setState(this.allFalse);
    };

}

export default function useKeyToggle(keys, defaultValue = true) {
    keys = keys || [];
    const initialState = {};
    keys.forEach(key => {
        initialState[key] = defaultValue
    });
    const [state, setState] = useState(initialState);
    return new KeyToggle(state, setState, keys, defaultValue);
}