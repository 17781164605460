import {Div, Icon} from "../../atoms/style";
import React from "react";
import {Alert} from "reactstrap";
import './style.css';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import toPairs from 'lodash/toPairs';
import withTrans from "../../atoms/withTrans";

const FormAlert = withTrans(({t, error_title, errors, color}) => {
    error_title = isArray(error_title) ? error_title.join("\n") : error_title;
    error_title = error_title || "validation.something_went_wrong";
    return (
        <Alert color={color ? color : 'danger'} className="alert-danger-custom" data-t={'form.alert'}>
            <div className="d-flex">
                <Div spacing={{mr: 2}}>
                    <h4 className="alert-heading text-red-custom">
                        <Icon icon="exclamation-triangle" size={'lg'}/>
                    </h4>
                </Div>
                <div className="align-self-center">
                    <h4 className="alert-heading text-red-custom">
                        {error_title && error_title.startsWith("validation.") ? t(error_title) : error_title}
                    </h4>
                </div>
            </div>
            {isArray(errors) ? errors.map((error, idx) => (
                <>
                    {idx !== 0 ? <hr/> : null}
                    <p key={idx}>{error.startsWith("validation.") ? t(error) : error}</p>
                </>
            )) : null}

            {isObject(errors) ? toPairs(errors).map(([key, error], idx) => null) : null}
        </Alert>
    )
});

export default FormAlert;