import withAttrs from "../../utils/withAttrs";
import {Col, PopoverBody} from "reactstrap";
import {ILink, SmallUpper, WhiteBox} from "../../atoms/style";
import React from "react";

export const VoicePopoverBody = withAttrs(PopoverBody, {
    className: "popover-content-optins"
});

export const VoiceMemberDecisionWrapper = withAttrs('div', {
    className: "bg-white text-left font-weight-normal",
    spacing: {mx: 'auto', px: 1, py: 2}
});

export const FlexLink = withAttrs(ILink, {
    className: "d-flex link-container-custom"
});

export const ButtonsWrapper = withAttrs('div', {
    className: 'box-cta-custom text-nowrap',
    spacing: {w: 100}
});

export const UserTitle = ({name, title}) => title ? (
    <div className="small">
        <span className="text-uppercase"><strong>{title}</strong></span>
        <br/>
        <u>{name}</u>
    </div>
) : (
    <div className="align-self-center small">{name}</div>
);

export const AttendanceBoxWrapper = withAttrs(WhiteBox, {
    className: "container-var04-custom",
    spacing: {px: 3, pt: 3, pb: 0, mb: 4}
});

export const Title = withAttrs(SmallUpper, {
    className: "bg-gray-custom",
    spacing: {py: 1, px: 2}
});

export const VoiceBoxWrapper = withAttrs(Col, {
    className: "col-6 text-center",
    md: 3,
    spacing: {pb:4, pr:1}
});

export const VoiceMembersWrapper = withAttrs('div', {
    className: "mt-3 h3"
});