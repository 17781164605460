import { transform, isEqual, isObject, isEmpty, size } from 'lodash';
import {isMoment} from "moment";

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object that represent changed fields
 */
export default function changed(object, base) {
    return transform(object, (result, value, key) => {
        const baseValue = base ? base[key] : undefined;
        if (!isEqual(value, baseValue)) {
            if (isMoment(value) && isMoment(baseValue)) {
                result[key] = !value.isSame(baseValue);
            } else if (isObject(value) && isObject(baseValue)) {
                const objChange = changed(value, baseValue);
                if (objChange && !isEmpty(objChange)) {
                    result[key] = objChange;
                } else if (size(value) !== size(baseValue)) {
                    result[key] = true;
                }
            } else {
                const valEql = isEqual('' + value, '' + baseValue);
                if (!valEql) {
                    result[key] = true
                }
            }
        }
    });
}