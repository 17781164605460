import React from "react";
import {AvatarImg, ContainerLink, SquareImageContainer} from "../atoms/style";
import {getAvatarSrc} from "../atoms/misc";

const AvatarBoxLink = ({id, to, name, src, children}) => (
    <ContainerLink to={to}>
        <SquareImageContainer spacing={{mx: 'auto', mb: 3}}>
            {children ? children : <AvatarImg src={getAvatarSrc(src)} alt={'Logo'}/>}
        </SquareImageContainer>
        <p><strong><u>{name}</u></strong></p>
    </ContainerLink>
);

export default AvatarBoxLink;