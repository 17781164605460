import React from 'react';
import {Route, Switch} from 'react-router-dom';

import urls from './setup/urls'
import NotFoundPage from "./components/pages/NotFoundPage";
import Toasts from "./components/molecules/Toasts/Toasts";
import PromptContextProvider from "./components/molecules/PromptComponent/PromptContextProvider";
import {LoginCallback} from "@okta/okta-react";
import WithNotifications from "./components/molecules/WithNotifications/WithNotifications";

const App = (props) => (
    <WithNotifications>
        <Toasts>
            <PromptContextProvider>
                <Switch>
                    {urls.map((url) =>
                        <Route key={url.name} exact={true} path={url.path} component={url.component}/>
                    )}
                    <Route path='/implicit/callback' component={LoginCallback} />
                    <Route component={NotFoundPage}/>
                </Switch>
            </PromptContextProvider>
        </Toasts>
    </WithNotifications>
);

export default App;