import {Spinner} from "reactstrap";
import React from "react";

const PageLoading = ({height = window.innerHeight}) => {
    return (
        <div className={"d-flex justify-content-center w-100"} style={{height}}>
            <div className={"align-self-center"}>
                <Spinner/>
            </div>
        </div>

    );
};

export default PageLoading;