import {Form, Formik} from "formik";
import React from "react";
import {H2, SubmitButton, YellowBox} from "../atoms/style";
import BootstrapField from "./BootstrapField";
import useTrans from "../hooks/useTrans";
import {object, string} from "yup";
import FormStatus from "./FormStatus/FormStatus";
import {login} from "../../services/authn";
import Loading from "../atoms/Loading";
import useCounter from "../hooks/useCounter";
import getApi from "../../services/getApi";

const api = getApi("loginForm");

const loginSchema = object({
    username: string().required('validation.this_field_is_required'),
    password: string().required('validation.this_field_is_required'),
});

const LoginForm = ({loginHandler}) => {
    const t = useTrans();
    const [failsCount, incCounter] = useCounter();

    const onSubmit = React.useCallback((values, {setSubmitting, setStatus}) => {
        setStatus(null);
        const {username, password} = values;
        setSubmitting(true);
        login(username, password)
            .then(loginHandler)
            .catch(err => {
                console.log(err);
                incCounter(1);
                setSubmitting(false);
                setStatus({error: "validation.login_failed"});
                api.notifyFailedLogin(username)
                    .then(
                        res => {
                            if (res.fixAvailable === "yes") {
                                console.log("an internal error occured - try again");
                                setStatus({error: "validation.require_new_attempt"});
                            }
                        }
                    )
                    .catch(err => {console.log("notify failed err: ",err);});
            });
    }, [loginHandler, incCounter]);

    const renderForm = ({isSubmitting, status}) => isSubmitting ? (
        <Loading/>
    ) : (
        <Form>
            <H2 className="text-center" spacing={{my: 2, mb: 4}}>{t('login.title')}</H2>
            <FormStatus/>
            <BootstrapField name={'username'}
                            label={<strong>{t('login.email')}</strong>}
                            required={true}/>
            <BootstrapField name={'password'}
                            label={<strong>{t('login.password')}</strong>}
                            type={'password'}
                            required={true}/>
            {failsCount > 4 ? (
                <YellowBox spacing={{mt: 4, px: 2, pt: 2}}>
                    {t("login.lockout_warning", {failsCount})}
                </YellowBox>
            ) : null}
            <SubmitButton data-t={'button.login'} disabled={isSubmitting}>{t('login.login')}</SubmitButton>
        </Form>
    );

    return (
        <Formik validationSchema={loginSchema} onSubmit={onSubmit} initialValues={{username: "", password: ""}}>
            {renderForm}
        </Formik>
    );
};

export default LoginForm;