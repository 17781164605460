import React from 'react';
import {A, BigIcon, Div} from "../../atoms/style";
import {Label, UncontrolledTooltip} from "reactstrap";
import useKeyToggle from "../../hooks/useKeyToggle";
import {DECISION_COLOR} from "../../../constants";
import {uniq} from "lodash/array";
import {sortBy, padStart} from "lodash";


const buildSelected = (root) => {
  if (root.nodes) {
      const reduction = root.nodes.map(node => buildSelected(node)).reduce((acc, value) => ({
          selected: acc.selected && value.selected,
          selection: [...acc.selection, ...value.selection],
      }), {selected: true, selection: []});
      root.selected = reduction.selected;
      root.selection = reduction.selection;
  }
  return {
      selected: root.selected,
      selection: root.selection
  };
};

const getVoicesTree = (t, voices, singers, managers, selected) => {
    if (!voices || voices.length === 0 || !singers) {
        return null;
    }
    let mapping = {};
    let root = null;
    voices.map(voice => {
        mapping[voice.id] = {
            ...voice,
            name: t(`voice.name_${voice.abbr || "MS"}`),
            nodes: []
        };
        if (voice.parent_id === null) {
            root = mapping[voice.id];
        }
        return mapping[voice.id];
    }).forEach(voice => {
        if (voice.parent_id && mapping[voice.parent_id]) {
            let parent_voice = mapping[voice.parent_id];
            parent_voice.nodes = parent_voice.nodes || [];
            parent_voice.nodes.push(voice);
        }
    });

    singers.forEach((singer) => {
        const node = mapping[singer.voice.id];
        node.nodes = node.nodes || [];
        node.last_node = true;
        node.nodes.push({
            id: `singer-${singer.id}`,
            name: singer.user.full_name,
            nodes: null,
            selection: [singer.id],
            selected: selected && selected.indexOf(singer.id) !== -1
        });
    });

    if (managers && managers.length > 0) {
        let managersNode = {
            id: -1,
            name: t('singer_select.choirmanagers'),
            nodes: [],
        };
        mapping[-1] = managersNode;
        managers.forEach((manager) => {
            const node = mapping[-1];
            node.nodes = node.nodes || [];
            node.last_node = true;
            node.nodes.push({
                id: `manager-${manager.id}`,
                name: manager.user.full_name,
                nodes: null,
                selection: [manager.id],
                selected: selected && selected.indexOf(manager.id) !== -1
            });
        });
        root = {
            id: null,
            name: null,
            nodes: [managersNode, root]
        };
    }

    buildSelected(root);
    return root;
};


const CollapseIcon = ({collapsed, onClick}) => (
    <Div className={'btn btn-green-custom link-collapse-custom'}>
        <BigIcon icon={collapsed ? 'angle-right' : 'angle-down'} onClick={onClick}/>
    </Div>
);

const TreeNode = ({root, toggle, applySelection, hideEmpty, depth = 0}) => {
    if (!root) {
        return null;
    }
    if (hideEmpty && (!root.selection || root.selection.length === 0)) {
        return null;
    }
    const collapsed = toggle.getToggleValue(root.id);
    const onToggle = () => toggle.toggleValue(root.id);
    const hasChildren = !!root.nodes;
    if (!root.name && !root.id) {
        return root.nodes.map((node, idx) => <TreeNode root={node}
                                                       key={idx}
                                                       toggle={toggle}
                                                       hideEmpty={hideEmpty}
                                                       applySelection={applySelection}
                                                       depth={depth + 1} />);
    }

    const sortedNodes = sortBy(root.nodes, (node) => {
        if (typeof node.position === "undefined") {
            return padStart(node.id, 6, '0');
        } else {
            return padStart(node.position, 3, '0') + '-' + padStart(node.id, 6, '0');
        }
    });

    return (
        <li data-depth={depth}>
            {hasChildren ? <CollapseIcon collapsed={collapsed} onClick={onToggle}/> : null}
            <Div className={'form-check d-inline-block'}>
                <input className={'form-check-input'} type={'checkbox'} checked={root.selected} onChange={(e) => {
                    applySelection(e.currentTarget.checked, root.selection);
                }} />
                <Label className={'form-check-label'}>{root.name}</Label>
            </Div>
            {hasChildren ? (
                <ul className={root.last_node ? 'last-ul-custom': ''} style={{display: (collapsed ? 'none' : 'block')}}>
                    {sortedNodes.map((node, idx) => <TreeNode root={node}
                                                             key={idx}
                                                             toggle={toggle}
                                                             hideEmpty={hideEmpty}
                                                             applySelection={applySelection}
                                                             depth={depth + 1} />)}
                </ul>
            ) : null}
        </li>
    );
};

const SelectByCommitments = ({t, withCommitments, selected, applySelection}) => withCommitments ? (
    <Div spacing={{py: 1}}>
        {withCommitments.map(pair => {
            const [decision_code, selection] = pair;
            const groupSelected = selection.filter(itemId => selected.indexOf(itemId) !== -1).length === selection.length;
            const isDisabled = selection.length === 0;
            const iconId = `user_select_decision_${decision_code}`;
            return (
                <Div key={decision_code} className={'d-inline-flex'} spacing={{mr: 2}}>
                    <BigIcon id={iconId} icon={'user'} className={`text-${DECISION_COLOR[decision_code]}-custom`}
                             spacing={{mr: 1}}/>
                    <UncontrolledTooltip trigger="hover" placement="top" target={iconId}>
                        {t(`singer_select.commitment_${decision_code}`)}
                    </UncontrolledTooltip>
                    <input type={'checkbox'} disabled={isDisabled} checked={groupSelected && !isDisabled} onChange={(e) => {
                        applySelection(e.currentTarget.checked, selection);
                    }} />
                </Div>
            )
        })}
    </Div>
) : null;

const getSelectedNames = (selected, singers) => singers && singers.length > 0 ? singers.filter(singer => selected && selected.indexOf(singer.id) !== -1).map(singer => singer.user ? singer.user.full_name : "" + singer.id) : [];

const SingerSelect = ({voices, t, singers, managers, selected, hideEmpty, withCommitments, applySelection}) => {
    const root = getVoicesTree(t, voices, singers, managers, selected);
    const keys = voices.map(voice => voice.id);
    keys.push(-1);
    const toggle = useKeyToggle(keys);
    const selectedSingers = uniq(getSelectedNames(selected, singers).concat(getSelectedNames(selected, managers)));
    return root ? (
        <Div data-t={'input.singer_select'}>
            <Div spacing={{mb: 1}}>{t('singer_select.selected')}: {selectedSingers.length > 0 ? selectedSingers.join(", ") : t('singer_select.none_selected')}</Div>
            <Div spacing={{mb: 3}}>
                <A onClick={toggle.setAllFalse}>{t('singer_select.expand_all')}</A> | <A onClick={toggle.setAllTrue}>{t('singer_select.collapse_all')}</A>
            </Div>
            <SelectByCommitments t={t} withCommitments={withCommitments} selected={selected} applySelection={applySelection}/>
            <ul className="tree-custom">
                <TreeNode root={root} toggle={toggle} hideEmpty={hideEmpty} applySelection={applySelection} />
            </ul>
        </Div>
    ) : null;
};

export default SingerSelect;