import withAttrs from "../../utils/withAttrs";
import React from "react";
import {Div, ILink, ImageContainer, SquareImageContainer} from "../../atoms/style";
import placeholderChoirPath from "../../../img/pic_placeholder_choir2.jpg";
import placeholderLogoPath from "../../../img/pic_placeholder_logo.jpg";
import {Col, Row} from "reactstrap";

export const ChoirWrapper = withAttrs(Div, {
    className: "container-var02-custom bg-white border border-light-custom",
    spacing: {px: 3, pt: 3, pb: 4, mb: 4}
});

export const LogoContainer = withAttrs(Div, {
    className: "img-container-custom square-md-custom transition-custom border border-light-custom",
    spacing: {mb: 4, sm: {mb: 0, mx: 0}, mx: "auto"}
});

export const FlexMis = withAttrs(ILink, {
    className: "d-sm-flex link-container-custom"
});

export const Title = ({children}) => (
    <Div spacing={{mr: "auto", pr: 2}}>
        <h3 className="h2 text-center text-sm-left">{children}</h3>
    </Div>
);

export const ChoirHeadline = (props) => {
    const {title, logo, to} = props;
    return (
        <FlexMis to={to} data-t={"link.choir_detail"}>
            <SquareImageContainer className={"order-2 mx-auto mb-4 mb-sm-0 mx-sm-0"}>
                <img src={logo ? logo : placeholderLogoPath} alt={'Logo'}/>
            </SquareImageContainer>
            <Title className="order-1">
                {title}
            </Title>
        </FlexMis>
    );
};

export const ChoirPicture = ({picture}) => (
    <ImageContainer className="img-container-custom rectangle-lg-custom transition-custom h-100 w-100">
        <img src={picture ? picture : placeholderChoirPath} alt=""/>
    </ImageContainer>
);

export const ChoirInfo = ({t, location, member_count, managers}) => (
    <Div>
        <h4 className="h5 text-uppercase">{t("choir.location")}</h4>
        <p>{location}</p>

        <h4 className="h5 text-uppercase">{t("choir.members_count")}</h4>
        <p>{member_count}</p>

        <h4 className="h5 text-uppercase">{t("choir.choir_managers")}</h4>
        <ul>
            {managers ? managers.filter(manager => manager.active_member).map(manager => (
                <li><ILink to={['choir_member', {choir_id: manager.choir_id, id: manager.user.id}]}>{manager.user.full_name}</ILink></li>
            )) : null}
        </ul>
    </Div>
);

export const ChoirDetailButton = withAttrs(ILink, {
  className: "btn btn-green-custom btn-md-custom btn-block"
});

export const ChoirBoxBody = ({t, location, member_count, picture, managers, to}) => (
    <Row noGutters className={'pt-3'}>
        <Col md={5} className="bg-gray-custom px-3 pt-3">
            <ChoirInfo t={t} location={location} member_count={member_count} managers={managers}/>
        </Col>

        <Col md={7} className="link-container-custom mt-2 mt-md-0 pl-0 pl-md-1">
            {to ? (
                <div className="d-flex align-items-start flex-column h-100">
                    <ChoirPicture picture={picture}/>
                    <Div spacing={{pt: 2, md: {mt: 1}, w: 100}}>
                        <ChoirDetailButton to={to}>{t('choir.detail')}</ChoirDetailButton>
                    </Div>
                </div>
            ) : (
                <ChoirPicture picture={picture}/>
            )}
        </Col>


    </Row>
);