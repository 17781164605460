import PageFeedback from "../../molecules/PageFeedback";
import React from 'react';
import useTrans from "../../hooks/useTrans";
import {CenteringRow, LightColumn} from "../LoginPage/style";
import PasswordResetConfirmForm from "./PasswordResetConfirmForm";
import Markdown from "markdown-to-jsx";
import useToggle from "../../hooks/useToggle";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import LoginFormContainer from "../../molecules/LoginFormContainer/LoginFormContainer";


export default ({match: {params}}) => {
    const [isRequested, toggleRequested] = useToggle();
    const t = useTrans();

    if (isRequested){
        return (
            <SimpleFeedback title={t('password_reset.password_changed')}>
                <Markdown data-t={"success-note"}>{t('password_reset.password_reset_success')}</Markdown>
            </SimpleFeedback>
        );
    }

    return (
        <PageFeedback>
            <CenteringRow>
                <LightColumn>
                    <LoginFormContainer>
                        <PasswordResetConfirmForm uid={params.uid}
                                                       token={params.token}
                                                       onSuccess={toggleRequested}
                        />
                    </LoginFormContainer>
                </LightColumn>
            </CenteringRow>
        </PageFeedback>
    );
}