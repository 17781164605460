import React, {useState} from "react";
import PromptContext from "../../contexts/PromptContext";


export default function PromptContextProvider(props) {
    const [prompts, setPrompts] = useState([]);
    return (
        <PromptContext.Provider value={[prompts, setPrompts]}>
            {props.children}
        </PromptContext.Provider>
    )

}