import useTrans from "../../hooks/useTrans";
import React, {useContext, useEffect} from "react";
import LetterListFetcher from "../../fetchers/LetterListFetcher";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import WithPagination from "../../molecules/WithPagination";
import PaginationContext from "../../contexts/PaginationContext";


import {Arrow, BigIcon, Div, EventFormDottedSpacer, WhiteBox} from "../../atoms/style";
import useToggle from "../../hooks/useToggle";
import {Collapse} from "reactstrap";
import moment from "moment";
import Pagination from "../../molecules/Pagination/Pagination";
import withTrans from "../../atoms/withTrans";

const CollapseTitle = ({toggle, isOpen, children}) => (
    <Div spacing={{py: 2}} onClick={toggle}
         className="d-flex align-items-start link-collapse-custom no-textdecoration-custom"
         role="button">
        <Div spacing={{mr: "auto"}}>
            {children}
        </Div>
        <Arrow isOpen={isOpen} data-t={"button.collapse"}/>
    </Div>
);

const LetterAttachment = withTrans(({t, attachment}) => (
    <span  className="d-flex mb-2" title="">
        <div><BigIcon icon={'paperclip'} spacing={{mr: 2}} /></div>
        <div><strong>{attachment.name}</strong></div>
    </span>
));

const LetterAttachments = ({attachments}) => attachments && attachments.length > 0 ? (
    <React.Fragment>
        <EventFormDottedSpacer spacing={{mb: 2, my: 0, mt: 2}}/>
        {attachments.map(attachment => (
            <LetterAttachment attachment={attachment}/>
        ))}
    </React.Fragment>
) : null;

const Letter = ({letter, t}) => {
    const [isOpen, toggle] = useToggle(false);
    const recipients = letter && letter.recipients ? (letter.recipients.map(user => user.full_name).join(", ")) : null;
    const create_at = moment(letter.created_at);
    return (
        <WhiteBox spacing={{px: 3, pt: 1, pb: 1, mb: 4}} data-t={"letter"}>

            <CollapseTitle isOpen={isOpen} toggle={toggle}>
                {create_at.format("LLL")}<br/>
                <h3 className="my-1">{letter.subject}</h3>
                <span className="small" data-t={"letter.recipients"}>{t('letter.recipients')}: {recipients}</span>
            </CollapseTitle>

            <Collapse isOpen={isOpen} className="pt-2 pb-3" data-t={"letter.body"}>
                <EventFormDottedSpacer spacing={{mb: 2, my: 0}}/>
                {letter.text}
                <LetterAttachments attachments={letter.attachments}/>
            </Collapse>

        </WhiteBox>
    );
};

const LettersPage = ({t}) => {
    const {pagination, updatePagination} = useContext(PaginationContext);
    const response = LetterListFetcher.read({page: pagination.cur_page});
    useEffect(() => {
        updatePagination(response);
    }, [response, updatePagination]);
    const letters = response.results;

    return letters ? (
        <div data-t="page.letters">
            {letters.map(letter => <Letter letter={letter} t={t}/>)}

            <Pagination t={t}/>
        </div>
    ) : null;
};

export default () => {
    const t = useTrans();
    return (
        <PageWithSidebar title={t('letters.page_title')} sidebar={<SideBar />} breadcrumbs={[{title: t('nav.events'), to: '/'}]} >
            <WithPagination>
                <LettersPage t={t} />
            </WithPagination>
        </PageWithSidebar>
    ) ;
};