import React from "react";
import useTrans from "../../hooks/useTrans";
import {GrayButton, RedButton} from "../../atoms/style";
import FactorContext from "./FactorContext";

const ResetFactor = ({cancel}) => {
    const t = useTrans();
    const {resetFactor, factorStatus, resetSubmitting} = React.useContext(FactorContext);
    return factorStatus ? (cancel ? (
            <GrayButton data-t={'action.cancel'} disabled={resetSubmitting}
                       onClick={resetFactor}>{t('factor.cancel')}</GrayButton>

        ) : (
            <RedButton data-t={'action.reset'} disabled={resetSubmitting}
                       onClick={resetFactor}>{t('factor.reset_factor')}</RedButton>
        )
    ) : null;
};

export default ResetFactor;