import React from "react";
import {IconedText, Spacer} from "../atoms/style";
import withTrans from "../atoms/withTrans";


const EventsBottomText = ({t}) => (
    <React.Fragment>
        <Spacer/>
        <IconedText icon={'exclamation-triangle'}>
            {t('event.exclamation_note')}
        </IconedText>
        <IconedText icon={'download'}>
            {t('event.download_note')}
        </IconedText>
    </React.Fragment>
);


export default withTrans(EventsBottomText);