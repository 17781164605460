import React, {Suspense} from 'react';
import useTrans from "../../hooks/useTrans";
import Markdown from "markdown-to-jsx";
import Loading from "../../atoms/Loading";
import OptOutDetailFetcher from "../../fetchers/OptOutDetailFetcher";
import isAppError from "../../../utils/isAppError";
import SimpleFeedback from "../../molecules/SimpleFeedback/SimpleFeedback";
import useToggle from "../../hooks/useToggle";
import OptOutRequestBox from "./OptOutRequestBox";
import PageFeedback from "../../molecules/PageFeedback";
import {CenteringRow} from "../LoginPage/style";
import {WrappedCol} from "../../atoms/style";


const OptoutRequestDetail = ({t, token}) => {
    const optout = OptOutDetailFetcher.read({token});
    const [isSuccess, onSuccess] = useToggle(false);
    if (isAppError(optout)) {
        return (
            <SimpleFeedback success={false} title={t('optout_detail.error_title')}>
                <Markdown data-t={"failure-note"}>{t('optout_detail.token_invalid')}</Markdown>
            </SimpleFeedback>
        )
    }
    if (isSuccess) {
        return (
            <SimpleFeedback title={t('optout_detail.success_title')}>
                <Markdown data-t={"success-note"}>{t('optout_detail.success_note')}</Markdown>
            </SimpleFeedback>
        )
    }
    return (
        <PageFeedback>
            <CenteringRow>
                <WrappedCol md={9}>
                    <OptOutRequestBox t={t} optout={optout} onSuccess={onSuccess}/>
                </WrappedCol>
            </CenteringRow>
        </PageFeedback>
    );
};


export default ({match: {params}}) => {
    const t = useTrans();
    return (
        <div data-t={"page.optout_detail"}>
            <Suspense fallback={<SimpleFeedback noIcon title={t('optout_detail.page_title')}><Loading/></SimpleFeedback>}>
                <OptoutRequestDetail t={t} token={params.token}/>
            </Suspense>
        </div>
    );
}