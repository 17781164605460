import React, {useCallback} from "react";
import getApi from "../../../services/getApi";

const useResendPasscode = (factorStatus, startCountdown) => {
    const [resendSubmitting, setResendSubmitting] = React.useState(false);
    const resend = useCallback(() => {
        if (factorStatus) {
            setResendSubmitting(true);
            getApi("factor").factorResend(factorStatus?.id).then(result => {
                startCountdown();
                console.log("RESEND success")
            }).finally(() => {
                setResendSubmitting(false);
            });
        }
    }, [startCountdown, factorStatus, setResendSubmitting]);
    return [resend, resendSubmitting];
};

export default useResendPasscode;