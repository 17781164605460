import withAttrs from "../../utils/withAttrs";
import {Col, Collapse, Row} from "reactstrap";

export const StyledCollapse = withAttrs(Collapse, {
    className: "filter-container-custom bg-white border border-light-custom",
    lg: 12,
    spacing: {px: 3, py: 4}
});


export const NoGuttersRow = withAttrs(Row, {
    className: "no-gutters",
    spacing: {mb: 4}
});


export const CheckBoxColumn = withAttrs(Col, {
    className: "small",
    spacing: {pb: 4, md: {pb: 0}}
});