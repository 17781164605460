import {array, mixed, number, object, string} from 'yup';
import {castMomentToDateStr, castToMoment} from "./utils";
import {DECISION_TYPES} from "../../constants";
import moment from "moment";

const toArray = (_, originalValue) => {
    if (!originalValue || (typeof originalValue === "string" && originalValue === "")) {
        return undefined;
    }
    return originalValue && !Array.isArray(originalValue) ? [originalValue] : originalValue;
};

export const eventFilterSchema = (eventTypeKeys) => object({
    from_date: mixed().transform(castToMoment).transform(value => value ? value : moment()),
    to_date: mixed().transform(castToMoment).transform(value => value ? value : moment().add({days: 30})),
    choir: array().of(number().positive().integer()).transform(toArray).notRequired(),
    event_type: array().of(string().oneOf(eventTypeKeys)).transform(toArray).notRequired(),
    commitment_type: array().of(number().integer().oneOf(DECISION_TYPES)).transform(toArray).notRequired(),
});

export const eventFilterSerializer = (eventtypeKeys) => eventFilterSchema(eventtypeKeys).shape({
    from_date: mixed().transform(castMomentToDateStr),
    to_date: mixed().transform(castMomentToDateStr),
});
