import React from 'react';
import {Field} from "formik";
import DatePicker from "../DatePicker";

export const DatePickerInput = ({
    // { name, value, onChange, onBlur }
    field,
    isInvalid,
    // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form: {setFieldValue, setFieldTouched},
    label,
    col,
    ...props
}) => (
    <DatePicker id={`id_${field.name}`} {...props} onBlur={() => setFieldTouched(field.name, true)} name={field.name} selected={field.value} onChange={(value) => setFieldValue(field.name, value)}/>
);

const ChoirDatePicker = (props) => (
    <Field type="text" component={DatePickerInput} {...props}/>
);

export default ChoirDatePicker;