import React from 'react';
import { CenterFlex } from '../../atoms/style';
import YesNo from '../YesNo';
import * as PropTypes from 'prop-types';
import { updateAttendance } from '../AttendanceBox/attendanceUpdateUtil';

const InlineOptOutForm = (props) => {
  const { decision } = props;

  return (
    <React.Fragment>
      <CenterFlex>
        <YesNo size={'sm'} decision={decision} {...props} />
      </CenterFlex>
    </React.Fragment>
  );
};

function EventAttendanceBox({
  inline,
  formComponent,
  displayComponent,
  onSubmit,
  event_id,
  scp_id,
  ...props
}) {
  const wrappedOnSubmit = updateAttendance(event_id, scp_id);

  const FormComponent =
    formComponent || (inline === true ? InlineOptOutForm : '');

  return (
    <FormComponent
      onSubmit={wrappedOnSubmit}
      event_id={event_id}
      scp_id={scp_id}
      {...props}
    />
  );
}

EventAttendanceBox.propTypes = {
  decision: PropTypes.number,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default EventAttendanceBox;
