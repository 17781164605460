import {Arrow, BigIcon, Div, WhiteBox} from "../../atoms/style";
import useToggle from "../../hooks/useToggle";
import React from "react";
import {Collapse} from "reactstrap";

const CollapseTitle = ({toggle, icon, isOpen, dataT, children}) => (
    <Div spacing={{py: 2}} onClick={toggle} data-t={dataT} className="d-flex align-items-center link-collapse-custom no-textdecoration-custom"
         role="button" data-toggle="collapse" aria-expanded="false" aria-controls="collapse01">
        <Div spacing={{mr: "auto"}}>
            <div className="d-flex">
                <div><BigIcon icon={icon} className="fa-width-custom text-red-custom mr-2"/></div>
                <div><strong>{children}</strong></div>
            </div>
        </Div>
        <Arrow isOpen={isOpen} />
    </Div>
);


export default ({title, icon, dataT, children}) => {
    const [isOpen, toggle] = useToggle(false);
    return (
        <WhiteBox spacing={{px: 3, pt: 1, pb: 1, mt: 5, mb: 4}}>

            <CollapseTitle dataT={dataT} icon={icon} isOpen={isOpen} toggle={toggle}>{title}</CollapseTitle>

            <Collapse isOpen={isOpen} className="pt-2 pb-3">
                {children}
            </Collapse>

        </WhiteBox>
    );
};