import {Toast, ToastBody, ToastHeader} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import useTrans from "../../hooks/useTrans";
import ToastContext from '../../contexts/ToastContext';
import uuidv4 from 'uuid/v4';

const TOAST_COLOR_MAPPING = {
    "error": "danger",
    "warning": "warning",
    "info": "info",
    "success": "success"
};

const ClosableToast = ({t, toast, onClose}) => {
    const {msg, type} = toast;
    useEffect(() => {
        if (toast.isOpen) {
            setTimeout(() => onClose(toast), toast.timeout + Math.random() * 10);
        }
    }, [onClose, toast]);
    return (
        <Toast transition={{
            timeout: {
                appear: 0,
                enter: 300,
                exit: 0,
            },
            unmountOnExit: true
        }} data-t={`toast.${type}`} isOpen={toast.isOpen} style={{minWidth: '20em'}}>
            <ToastHeader icon={TOAST_COLOR_MAPPING[type]} toggle={() => onClose(toast)}>
                {t(`toast.${type}`)}
            </ToastHeader>
            <ToastBody>
                {msg}
            </ToastBody>
        </Toast>
    );
};

const Toasts = () => {
    const t = useTrans();
    const {toasts, onDelete, onClose} = useContext(ToastContext);
    return toasts.map(toast => <ClosableToast key={toast.id} t={t} toast={toast} onClose={onClose} onDelete={onDelete}/>);
};

function removeOldToasts(toasts) {
    const currentTime = (new Date().getTime());
    return toasts.filter(toast => toast.ts + toast.timeout > currentTime);

}
export default function({children}) {
    const [toasts, setToasts] = useState([]);
    const onPush = ({msg, id, type = 'info', timeout = 5000}) => {
        id = id ? id : uuidv4();
        setToasts((currentToasts) => ([...removeOldToasts(currentToasts), {
            id,
            msg,
            type,
            timeout,
            ts: (new Date().getTime()),
            isOpen: true
        }]));
    };
    const onClose = ({id}) => {
        setToasts((currentToasts) => currentToasts.map(toast => {
            if (toast.id === id) {
                return {
                    ...toast,
                    isOpen: false
                };
            } else {
                return toast;
            }
        }));
    };
    return (
        <ToastContext.Provider value={{toasts, onPush, onClose}}>
            <div style={{
                zIndex: 10040,
                position: 'fixed',
                right: 0,
                top: 0,
            }} className={"d-flex p-2 justify-content-end"}>
                <div>
                    <Toasts/>
                </div>
            </div>
            {children}
        </ToastContext.Provider>
    )
}