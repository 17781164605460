const formErrorFormat = (value) => {
    // if (isPlainObject(value)) {
    //     return mapValues(value, formErrorFormat)
    // }
    // if (isArray(value)) {
    //     return value.join(". ")
    // }
    if (value === null) {
        return undefined // THIS SHOULD BE UNDEFINED
    }
    return value
};

const parseAPIResponse = response => response.text()
    .catch(err => Promise.reject({
        error_type: 'NetworkError',
        status: response.status,
        statusText: response.statusText,
        message: err,
    })).then((responseBody) => {
        // Attempt to parse JSON
        try {
            const parsedJSON = responseBody ? JSON.parse(responseBody) : null;
            if (response.ok) return Promise.resolve(parsedJSON);
            if (response.status >= 500) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    error_type: 'ServerError',
                    status: response.status,
                    statusText: response.statusText,
                    body: parsedJSON,
                });
            }
            if (response.status <= 501) {
                const body = (response.status === 400) ? formErrorFormat(parsedJSON) : parsedJSON;
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    error_type: 'ApplicationError',
                    status: response.status,
                    statusText: response.statusText,
                    body,
                });
            }
        } catch (e) {
            // We should never get these unless response is mangled
            // Or API is not properly implemented
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({
                error_type: 'InvalidJSON',
                status: response.status,
                statusText: response.statusText,
                body: responseBody,
            });
        }
    });


export default parseAPIResponse;