import {IconedLink} from "../../atoms/style";
import {EventFormDottedSpacer} from "./style";
import React from "react";
import FieldArrayWrapper from "../../molecules/FieldArrayWrapper";

const randomId = () => Math.round(Math.random() * 10000);

const GenericPollInputWrapper = ({t, index, prefix, disabled = false, poll_type, children, setFieldValue, value, onDelete}) => {
    return (
        <>
            <strong>{t(`event_form.poll_type_${poll_type}`) + ' ' + index}</strong>
            {children}
            {!disabled ? (<IconedLink className="float-right" color={'red'} icon={'minus-square'} onClick={onDelete}>{t(`poll.delete_poll_${poll_type}`)}</IconedLink>) : null}
            <EventFormDottedSpacer/>
        </>

    );
};

const GenericPollListField = ({t, name, value, arrayHelpers, disabled = false, setFieldValue, inputComponent, poll_type,  limit = 3}) => {
    const InputComponent = inputComponent;
    return (
        <div>
            {value && value.length > 0 ? value.map((item, index) => {
                const isDisabled = !(item && item.answer_count ? item.answer_count === 0 : true) || disabled;
                return (
                    <GenericPollInputWrapper index={index + 1}
                                             key={`${name}.${item.id || item._id}`}
                                             t={t}
                                             poll_type={poll_type}
                                             disabled={isDisabled}
                                             onDelete={() => {
                                                 if (window.confirm(t(`confirm.delete_${poll_type}?`))) {
                                                     arrayHelpers.remove(index)
                                                 }}}>
                        <InputComponent index={index + 1}
                                        prefix={`${name}.${index}`}
                                        value={item}
                                        setFieldValue={setFieldValue}
                                        disabled={isDisabled}
                                        t={t}
                        />
                    </GenericPollInputWrapper>
                );
            }) : null}
            {!disabled && (!value || value.length < limit) ? (
                <IconedLink icon={'plus-square'} onClick={() => arrayHelpers.push({_id: randomId()})}>
                    {t(`event_form.add_${poll_type}`)}
                </IconedLink>
            ) : null}
        </div>
    );
};

export default (props) => (
    <FieldArrayWrapper {...props} poll_type={props.name} forComponent={GenericPollListField}/>
);