import ChoirFetcher from "../../fetchers/ChoirFetcher";
import isNotFound from "../../../utils/isNotFound";
import NotFoundPage from "../NotFoundPage";
import WithChoir from "../../molecules/WithChoir";
import useTrans from "../../hooks/useTrans";
import React, {useContext} from "react";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import InviteForm from "./InviteForm";
import goTo from "../../utils/goTo";
import ChoirContext from "../../contexts/ChoirContext";

const AddMembers = (props) => (
    <InviteForm {...props}/>
);

const AddMembersPage = (props) => {
    const {t} = props;
    const {choir, isManager, voices} = useContext(ChoirContext);
    if (!isManager) {
        goTo('choir_detail', {id: choir.id})
        return null;
    }
    return (
        <PageWithSidebar title={t('choir.add_choir_members_title')}
                         sidebar={<SideBar />}
                         breadcrumbs={[{title: choir.name, to: ['choir_detail', {id: choir.id}]}]}
                         page={'add_members'}>
            <AddMembers {...props} choir={choir} voices={voices}/>
        </PageWithSidebar>
    );
};
export default (props) => {
    const {match: {params}} = props;
    // const choir = useChoir(parseInt(params.id));
    const choir = ChoirFetcher.read({choir_id: params.id});
    if (isNotFound(choir)) {
        return <NotFoundPage/>;
    }
    return (
        <WithChoir choir={choir}>
            <AddMembersPage {...props} t={useTrans()} />
        </WithChoir>
    ) ;
};