import isNotFound from "../../../utils/isNotFound";
import NotFoundPage from "../NotFoundPage";
import WithChoir from "../../molecules/WithChoir";
import useTrans from "../../hooks/useTrans";
import React, {Suspense, useContext, useEffect} from "react";
import useChoir from "../../hooks/useChoir";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import ChoirContext from "../../contexts/ChoirContext";
import ChoirMemberFetcher from "../../fetchers/ChoirMemberFetcher";
import useToggle from "../../hooks/useToggle";
import ProfileEdit from "../ProfileEditPage/ProfileEdit";
import MembershipProfile from "./MembershipProfile";
import MemberProfile from "./MemberProfile";
import EditMembershipProfile from "./EditMembershipProfile";
import useAuthUser from "../../hooks/useAuthUser";
import DownloadBox from "../../molecules/DownloadBox";
import getApi from "../../../services/getApi";
import {BigEditButton} from "../../atoms/style";
import isAppError from "../../../utils/isAppError";
import StatusComponent from "./StatusComponent";
import Loading from "../../atoms/Loading";
import RemoveMemberBox from "../../molecules/RemoveMemberBox/RemoveMemberBox";

const getOffsetTop = element => {
    let offsetTop = 0;
    while(element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
    }
    return offsetTop;
};

const MemberPage = (props) => {
    const {t, member, onUpdate, args, isEditMembership = false} = props;
    const {choir, isManager} = useContext(ChoirContext);
    const currentUser = useAuthUser();
    const header_offset = 120;
    const isSelf = member.id === currentUser.id;
    const [showProfile, onProfileToggle] = useToggle(true);
    const [showMembership, onMembershipToggle] = useToggle(!isEditMembership);
    const canEdit = isSelf ? isSelf : isManager;
    const canEditProfile = isSelf ? isSelf : isManager && (member.membership && member.membership.allow_profile_edit);
    const editRef = React.createRef();

    const memberVcfUrl = React.useMemo(() => getApi("choir").getMemberVCFUrl(choir.id, member.id), [choir, member]);

    useEffect(() => {
        if (editRef.current && isEditMembership) {
            window.scrollTo(0, getOffsetTop(editRef.current) - header_offset);
        }
    }, [editRef, isEditMembership]);
    return (
        <PageWithSidebar title={t('choir_member.profile_title')}
                         sidebar={<SideBar />}
                         breadcrumbs={[{title: choir.name, to: ['choir_detail', {id: choir.id}]}]}>

            {canEditProfile && showProfile ? <BigEditButton onClick={onProfileToggle} data-t={"choir_member.edit_profile"}>
                {t('choir_member.edit_profile')}
            </BigEditButton> : null}

            <DownloadBox links={[{
                icon: 'download',
                title: t("choir_member.download_vcf"),
                url: memberVcfUrl
            }]}/>

            {showProfile ? (
                <MemberProfile {...props} isSelf={isSelf} isManager={isManager} />
            ) : <ProfileEdit t={t} user={member} onUpdate={() => {
                onProfileToggle();
                onUpdate();
            }} onCancel={onProfileToggle}/>}

            <div ref={editRef}>
                {canEdit ? <BigEditButton ref={editRef} id={'edit_membership'} data-t={'choir_member.edit_membership'} onClick={onMembershipToggle}>
                    {t('choir_member.edit_membership')}
                </BigEditButton> : null}

                {showMembership ? (
                    <MembershipProfile {...props} isManager={isManager} isSelf={isSelf} canEdit={canEdit} onEdit={canEdit ? onMembershipToggle : null}/>
                ) : <EditMembershipProfile {...props} onUpdate={() => {
                    onMembershipToggle();
                    onUpdate();
                }} isManager={isManager} isSelf={isSelf} onCancel={onMembershipToggle}/>}
            </div>

            <Suspense fallback={<Loading/>}>
                {isManager || canEdit ? <StatusComponent args={args}  /> : null}
            </Suspense>

            {isManager ? (
                <RemoveMemberBox choir_id={choir.id} user_id={member.id}/>
            ) : null}
        </PageWithSidebar>
    );
};

export default (props) => {
    const {match: {params}} = props;
    const choir = useChoir(parseInt(params.choir_id));
    const args = {choir_id: parseInt(params.choir_id), user_id: parseInt(params.id)};
    const member = ChoirMemberFetcher.read(args);
    const invalidator = ChoirMemberFetcher.invalidator(args);

    if (isNotFound(choir) || isAppError(member)) {
        return <NotFoundPage/>;
    }
    return (
        <WithChoir choir={choir}>
            <MemberPage {...props} args={args} isEditMembership={params.action === 'edit-membership'} member={member} onUpdate={() => {
                invalidator();
            }} t={useTrans()} />
        </WithChoir>
    ) ;
};