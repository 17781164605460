import uuidv4 from 'uuid/v4';
import parseAPIResponse from "./parseApiResponse";
import {removeNils} from "./formatUrl";
import fetch from 'cross-fetch';
import {BACKEND_API_BASE} from "../constants";

const AbortController = window.AbortController;

let REQUESTS = {};
export function abortForCaller (callerId) {
    const promises = REQUESTS[callerId] || {};
    Object.keys(promises).forEach(requestId => {
        promises[requestId].abort();
        delete promises[requestId];
    });
}

function cleanUp() {
    // console.info(`Cleaning up request ${this.requestId}`);
    let requests = REQUESTS[this.callerId];
    if (requests[this.requestId]) {
        delete requests[this.requestId];
    }
}

export default function abortableFetch(url, {controller, headers, data, ...opts}, callerId) {
    controller = controller || new AbortController();
    const signal = controller.signal;
    const requestId = uuidv4();
    let requests = REQUESTS[callerId] || (REQUESTS[callerId] = {});
    if (!url.startsWith("http")) {
        url = BACKEND_API_BASE + url;
    }
    const endParams = {
        credentials: 'omit',
        body: (data ? JSON.stringify(data) : undefined),
        signal,
        ...opts,
        headers: removeNils({
            "Content-Type": "application/json",
            ...headers,
            'X-Request-Id': requestId
        })
    };
    const requestPromise = fetch(url, endParams);
    requestPromise.abort = () => controller.abort();
    requestPromise.requestId = requestId;
    requestPromise.callerId = callerId;
    requests[requestId] = requestPromise;

    return requestPromise.finally(cleanUp.bind(requestPromise));
}

const DISP_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

export function apiCall(url, {blob, ...params}, callerId) {
    const promise = abortableFetch(url, params, callerId);
    if (blob) {
        return promise.then(response => {
            const content_disposition = response.headers.get('content-disposition');
            const matches = DISP_REGEX.exec(content_disposition);
            const filename = matches !== null && matches[1] ? matches[1].replace(/['"]/g, '') : null;
            return response.blob().then(blobData => {
                return [filename, blobData];
                // const a = document.createElement('a');
                // const url = window.URL.createObjectURL(blobData);
                // a.href = url;
                // a.style.display = 'none';
                // if (filename) {
                //     a.download = filename;
                // }
                // window.document.body.appendChild(a);
                // a.click();
                // setTimeout(() => {
                //     window.document.body.removeChild(a);
                //     window.URL.revokeObjectURL(url);
                // }, 500);
            });
        });
    } else {
        return promise.then(parseAPIResponse);
    }
}