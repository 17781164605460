import React from "react";
import {BigIcon, StyledWidgetLink} from "../atoms/style";

const WidgetLink = ({to, onClick, icon, title, ...props}) => {
    return (
        <StyledWidgetLink to={to} onClick={onClick} {...props}>
            <BigIcon icon={icon} spacing={{mr: 2}} /> {title}
        </StyledWidgetLink>
    );
};

export default WidgetLink;