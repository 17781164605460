import ChoirBox from "../ChoirBox";
import React, {useContext} from "react";
import {ILink, SidebarBoxWrapper} from "../../atoms/style";
import {ChoirListHeader} from "./style";
import UserChoirsContext from "../../../state/UserChoirsContext";
import useTrans from "../../hooks/useTrans";

const UserChoirs = ({choirs}) => choirs.map((choir, idx) => (
    <ChoirBox key={idx} {...choir}/>
));

export const UserChoirList = ({t, choirs}) => {
    return choirs && choirs.length > 0 ? (
        <SidebarBoxWrapper data-t={'div.my_choirs'}>
            <ChoirListHeader>{t('choir.my_choirs')}</ChoirListHeader>
            <UserChoirs choirs={choirs.slice(0, 5)} />
            {choirs.length > 5 ? <ILink to={'choir_list'}>{t('user_choirs.more_link')}</ILink> : null}
        </SidebarBoxWrapper>
    ) : null;
};

export default (props) => {
    const choirs = useContext(UserChoirsContext);
    const t = useTrans();
    return <UserChoirList choirs={choirs} t={t} {...props}/>;
};