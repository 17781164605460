import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {object} from "yup";
import getApi from "../../../services/getApi";
import {GreyHeader, GreySubmitButton, H5, NoteBox, SubmitButton, WhiteBox} from "../../atoms/style";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormSpacer, FormSubmitCol} from "../EventEditPage/style";
import {Row} from "reactstrap";
import ToastContext from "../../contexts/ToastContext";
import {emailSchema} from "../../validation/emailSchema";
import SelfUserFetcher from "../../fetchers/SelfUserFetcher";
import EmailChangeRequestFetcher from "../../fetchers/EmailChangeRequestFetcher";
import isAppError from "../../../utils/isAppError";

const newUsernameSchema = (currentEmail) => object({
    new_username: emailSchema
        .notOneOf([currentEmail], "validation.email_must_be_changed"),
    old_username: emailSchema
        .oneOf([currentEmail], "validation.email_must_be_changed"),
});

const changeUsername = async (currentEmail, email) => {
    const api = getApi("username");
    return api.createEmailChangeRequest({
        from_email: currentEmail,
        to_email: email
    });
}

const onSubmit = ({t, toaster, enrollEmailFactor, user}) => (values, {
    setSubmitting,
    setErrors,
    setStatus
}) => {
    setSubmitting(true);
    setStatus(null);

    const email = values['new_username']
    const changeUsernameData = {
        "email": email,
        "source": "username"
    }
    const emailValidPromise = getApi("username").checkEmailValidity(email, changeUsernameData);

    emailValidPromise.then(value => {
        let checkInvalid = Object.values(value).filter((email) => {
            return email['result']['verdict'] === 'Invalid'
        })
        let invalidEmails = checkInvalid.map(item => {
            return item['result']['email']
        }).join(',')

        if (checkInvalid.length === 0){
            console.log("Email valid", value['result']);
            return changeUsername(user?.email, values?.new_username);
        }else {
            console.log("Email invalid", value['result']);
            setSubmitting(false)
            setStatus({error: t('form.email_validation') + ': ' + invalidEmails});
            setErrors(value['result'])
        }
    }).then(value => {
        console.log("second promise result", value)
        if (value === undefined){
            setSubmitting(false)
        }else {
            toaster.onPush({msg: t('success.email_changed'), type: 'success'});
            window.location.reload();
        }
    }).catch(error => {
        setStatus({error: error})
    }).finally(() => {
        setSubmitting(false);
    });
};

const TheForm = ({t, changeRequest, cancelRequest, formProps: {isSubmitting, setStatus, touched, errors, status}}) => {

    return (
        <WhiteBox spacing={{px: 3, pt: 3, pb: 4, mb: 4}}>
            <Form>
                <FormStatus status={status}/>
                {changeRequest ? (
                    <NoteBox>
                        {t("change_email_form.verify_new_email_note")}
                    </NoteBox>
                ) : null}
                <BootstrapField name={'old_username'} label={<strong>{t('change_email_form.old_email')}</strong>}
                                required={true} disabled={true}/>
                <BootstrapField name={'new_username'} label={<strong>{t('change_email_form.new_email')}</strong>}
                                required={true} disabled={!!changeRequest}/>

                <EventFormSpacer/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'}
                                      disabled={isSubmitting || !!changeRequest}>{t('change_email_form.save')}</SubmitButton>
                    </FormSubmitCol>
                    <FormSubmitCol>
                        {changeRequest ? (
                            <GreySubmitButton onClick={cancelRequest}>{t('change_email_form.cancel')}</GreySubmitButton>
                        ) : (
                            <GreySubmitButton to={'profile'}>{t('change_email_form.cancel')}</GreySubmitButton>
                        )}

                    </FormSubmitCol>
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function (props) {
    const t = props.t;
    const user = SelfUserFetcher.read();
    const userId = user?.id;
    const changeRequestResult = EmailChangeRequestFetcher.read(userId);
    const changeRequest = isAppError(changeRequestResult) ? null : changeRequestResult;
    const initialValues = {
        old_username: changeRequest?.from_email || user.email,
        new_username: changeRequest?.to_email || user.secondEmail,
    };
    const toaster = useContext(ToastContext);
    const validationSchema = React.useMemo(() => newUsernameSchema(user.email), [user.email]);

    const cancelRequest = React.useCallback(() => {
        getApi("cancel").deleteEmailChangeRequest(userId).then(() => {
            window.location.reload();
        })
    }, [userId]);
    return (
        <>
            <GreyHeader>
                <H5 spacing={{my: 1}}>{t('account.change_email')}</H5>
            </GreyHeader>
            <Formik validationSchema={validationSchema} initialValues={initialValues}
                    onSubmit={onSubmit({toaster, user, ...props})}>
                {(formProps) => (
                    <TheForm {...props}
                             changeRequest={changeRequest}
                             formProps={formProps}
                             cancelRequest={cancelRequest}
                    />
                )}
            </Formik>
        </>
    );
}