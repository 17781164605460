import {date_range, time_range} from "../../molecules/EventBox/EventHeadline";
import React, {useState} from "react";
import {PipeBox, RedSubmitButton, SubmitButton, WhiteBox} from "../../atoms/style";
import {LightGreyBox} from "./style";
import {Col, FormGroup, Row} from "reactstrap";
import moment from "moment";
import getApi from "../../../services/getApi";

const OptOutRequestBox = ({t, optout, onSuccess}) => {
    const event = optout.event;
    const dateRange = date_range(t, event.from_date, event.to_date);
    const timeRange = time_range(t, event.from_hour, event.to_hour);
    const user_name = optout.singer && optout.singer.user ? optout.singer.user.full_name : null;
    const [comment, setComment] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const onAccept = (optout_id, manager_comment) => {
        setSubmitting(true);
        getApi("optout").acceptOptOutRequest(optout_id, manager_comment).then(result => {
            onSuccess();
            setSubmitting(false);
        }).catch(error => {
            setSubmitting(false);
        });
    };

    const onReject = (optout_id, manager_comment) => {
        setSubmitting(true);
        getApi("optout").rejectOptOutRequest(optout_id, manager_comment).then(result => {
            onSuccess();
            setSubmitting(false);
        }).catch(error => {
            setSubmitting(false);
        });
    };

    return (
        <WhiteBox spacing={{px: 3, pt: 3, pb: 4, mb: 4}}>
            <LightGreyBox spacing={{p: 3, mb: 3}}>
                <h3>
                    <PipeBox name={`${event.choir_name}: ${event.title}`} value={t(`event_type.${event.event_type}`)}/>
                </h3>
                <div className="d-inline-block">{dateRange} {timeRange ? (
                    <span
                        className="text-nowrap d-block d-md-inline mr-auto mr-sm-0"><span
                        className="d-none d-md-inline font-weight-normal">|</span>  {timeRange}</span>
                ) : null}</div>
            </LightGreyBox>

            <Row noGutters>
                <Col md={3}>
                    <strong>{t('optout.requester_name')}</strong>
                </Col>
                <Col md={9} className="pl-md-3">
                    {user_name}
                </Col>
            </Row>

            <Row noGutters>
                <Col md={3}>
                    <strong>{t('optout.request_time')}</strong>
                </Col>
                <Col md={9} className="pl-md-3">
                    {moment(optout.created_at).format("LLL")}
                </Col>
            </Row>

            <Row noGutters>
                <Col md={3}>
                    <strong>{t('optout.reason')}</strong>
                </Col>
                <Col md={9} className="pl-md-3">
                    {optout.reason}

                    <Row className="mt-4">
                        <Col>
                            <FormGroup>
                                <textarea rows={4} className="form-control" placeholder={t('optout.manager_comment')}
                                          name={'manager_comment'}
                                          onChange={(e) => setComment(e.target.value)}>
                                    {comment}
                                </textarea>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={4} className="mb-4 mb-md-0">
                            <SubmitButton data-t={"action.accept"} disabled={isSubmitting} onClick={() => onAccept(optout.activation_key, comment)}>{t("optout.accept")}</SubmitButton>
                        </Col>
                        <Col md={4} className="mb-4 mb-md-0">
                            <RedSubmitButton data-t={"action.reject"} disabled={isSubmitting} onClick={() => onReject(optout.activation_key, comment)}>{t("optout.reject")}</RedSubmitButton>
                        </Col>
                    </Row>

                </Col>
            </Row>

        </WhiteBox>
    );
};

export default OptOutRequestBox;