import React from 'react';
import NavBar from './NavBar/NavBar';
import NotificationsModal from "./Notifications/NotificationsModal";
import {HeaderWrapper} from "../atoms/style";
import PromptContainer from "./PromptComponent/PromptContainer";

const Header = (props) => (
    <HeaderWrapper>
        <NotificationsModal />
        <PromptContainer/>
        <NavBar {...props}/>
    </HeaderWrapper>
);

export default Header;

