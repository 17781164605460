import React, {useContext} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import useTrans from "../../hooks/useTrans";
import {NotificationItem, StyledListGroup} from "./style";
import {GreyButton} from "../../pages/EventEditPage/style";
import Loading from "../../atoms/Loading";
import NotificationsContext from "../../contexts/NotificationsContext";

const NotificationList = ({notifications}) => notifications ? (
    <StyledListGroup>
        {notifications.map((notification, idx) => (
            <NotificationItem key={idx}>
                {notification.description}
            </NotificationItem>
        ))}
    </StyledListGroup>
) : null;

const NotificationsModal = ({t, className, isOpen, close, isLoading, notifications}) => (
    <Modal isOpen={isOpen} toggle={close} className={className} data-t={"modal.notifications"}>
        <ModalHeader toggle={close}>
            {t('nav.notifications')}
        </ModalHeader>
        <ModalBody>
            {isLoading ? <Loading/> : <NotificationList notifications={notifications}/>}
        </ModalBody>
        <ModalFooter>
            <GreyButton onClick={close} data-t={"button.close"} data-dismiss="modal">{t('action.close')}</GreyButton>
        </ModalFooter>
    </Modal>
);

export default (props) => {
    const context = useContext(NotificationsContext);
    return (
        <NotificationsModal t={useTrans()} {...context} {...props}/>
    );
};