import useTrans from "../../hooks/useTrans";
import React from "react";
import {Form, Formik, useFormikContext} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import getApi from "../../../services/getApi";
import {GrayButton, GreenButton} from "../../atoms/style";
import ResetFactor from "./ResetFactor";
import FactorContext from "./FactorContext";
import {ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import factorId from "./factorId";
import useCountdown from "../../hooks/useCountdown";
import {passwordSchema} from "../../validation/passwordSchema";
import {object, string} from "yup";


const submitWrapper = (submit) => (values, {setSubmitting, setStatus}) => {
    setSubmitting(true);
    submit(values).catch(error => {
        setStatus({error});
    }).finally(() => {
        setSubmitting(false);
    })
}

export const resolveWrapper = (resolve) => (values, {setSubmitting}) => {
    setSubmitting(true);
    resolve(values);
}

const AutoSubmitToken = () => {
    // Grab values and submitForm from context
    const {values, submitForm} = useFormikContext();
    React.useEffect(() => {
        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
        if (values.factorId && values.token.length === 6) {
            submitForm();
        }
    }, [values, submitForm]);
    return null;
};

const AutoResetFactor = () => {
    const {resetFactor} = React.useContext(FactorContext);
    React.useEffect(() => {
        resetFactor();
    }, [resetFactor]);
    return null;
};


export function isTotp(factorStatus) {
    return factorStatus?.factorType?.indexOf("totp") !== -1;
}

const FactorVerifyInfo = (props) => {
    if (!isTotp(props?.factorStatus)) {
        return null;
    }
    const imgSrc = props?.factorStatus?._embedded?.activation?._links?.qrcode?.href;
    // const sharedSecret = factorStatus?._embedded?.activation?.sharedSecret;
    return imgSrc ? (
        <div>
            <img alt={"QR code"} src={imgSrc}/>
        </div>
    ) : <AutoResetFactor {...props}/>;
}

export const ResendButton = ({disabled, resend}) => {
    const t = useTrans();
    const [timeLeft, startCountdown] = useCountdown(30);
    React.useEffect(() => {
        startCountdown();
    }, [startCountdown]);
    return (
        <GrayButton data-t={'action.resend'}
                    disabled={disabled || timeLeft > 0}
                    onClick={() => {
                        resend();
                        startCountdown();
                    }}>
            {t('factor.resend')} {timeLeft > 0 ? timeLeft : ''}
        </GrayButton>
    )
};

const ResendPasscode = () => {
    const factorContext = React.useContext(FactorContext);
    const {factorStatus, timeLeft, resend, resendSubmitting} = factorContext;
    return factorStatus && !isTotp(factorStatus) ? (
        <ResendButton timeLeft={timeLeft} disabled={resendSubmitting} resend={resend}/>
    ) : null;
}

export const PasscodeInput = ({submit, resolve, ...props}) => {
    const handleSubmit = React.useMemo(() => {
        if (submit) {
            return submitWrapper(submit);
        }
        if (resolve) {
            return resolveWrapper(resolve);
        }
    }, [submit, resolve]);
    return (
        <Formik
            initialValues={{token: ''}}
            validate={validateToken}
            onSubmit={handleSubmit}
        >
            <TheForm {...props}/>
        </Formik>
    )
}

const ChangePasswordForm = ({children, close, extraActions}) => {
    const t = useTrans()
    const {status, isSubmitting, submitForm} = useFormikContext();
    return (
        <Form>
            <ModalHeader toggle={close}>
                {t('factor.changePassword')}
            </ModalHeader>
            <ModalBody>
                <FormStatus status={status}/>

                {children}

                <BootstrapField disabled={isSubmitting} name={'oldPassword'}
                                type={'password'}
                                label={<strong>{t('factor.oldPassword')}</strong>}
                                required={true}/>
                <BootstrapField disabled={isSubmitting} name={'newPassword'}
                                type={'password'}
                                label={<strong>{t('factor.newPassword')}</strong>}
                                required={true}/>
            </ModalBody>
            <ModalFooter>
                <GreenButton
                    data-t={'action.changePassword'}
                    disabled={isSubmitting}
                    onClick={submitForm}>{t('factor.changePassword')}</GreenButton>
                {extraActions}
            </ModalFooter>
        </Form>
    )
};

const changePasswordSchema = object({
    newPassword: passwordSchema,
    oldPassword: string().required('validation.this_field_is_required')
});

export const ChangePassword = ({submit, resolve, ...props}) => {
    const handleSubmit = React.useMemo(() => {
        if (submit) {
            return submitWrapper(submit);
        }
        if (resolve) {
            return resolveWrapper(resolve);
        }
    }, [submit, resolve]);
    return (
        <Formik
            initialValues={{oldPassword: '', newPassword: ''}}
            validationSchema={changePasswordSchema}
            onSubmit={handleSubmit}
        >
            <ChangePasswordForm {...props}/>
        </Formik>
    )
}

const TheForm = ({children, close, factor, extraActions, factorUseCase = ''}) => {
    const t = useTrans()
    const {status, isSubmitting, errors, submitForm} = useFormikContext();
    return (
        <Form>
            <ModalHeader toggle={close}>
                {t(`factor.${factorUseCase}${factorUseCase ? '.' : ''}${factorId(factor)}.prompt_passcode`)}
            </ModalHeader>
            <ModalBody>
                <FormStatus status={status}/>

                {children}

                <BootstrapField disabled={isSubmitting} name={'token'} label={<strong>{t('factor.token')}</strong>}
                                required={true}/>
                <AutoSubmitToken/>
            </ModalBody>
            <ModalFooter>
                <GreenButton
                    data-t={'action.verify'}
                    disabled={isSubmitting || errors.token}
                    onClick={submitForm}>{t('factor.verify')}</GreenButton>
                {extraActions}
            </ModalFooter>
        </Form>
    )
};

const validateToken = (values) => {
    let errors = {};
    if (values?.token) {
        if (values.token.length < 6) {
            errors.token = 'validation.token_too_short';
        } else if (values.token.length > 6) {
            errors.token = 'validation.token_too_long';
        }
    } else {
        errors.token = 'validation.token_too_short';
    }
    return errors;
};

const VerifyFactor = ({resolve, enableReset, factorUseCase}) => {
    const factorContext = React.useContext(FactorContext);
    const {factorStatus, setFactorStatus} = factorContext;
    const factorId = factorStatus?.id;
    const submit = React.useCallback((values) => {
        const api = getApi("factor");
        return api.factorActivate(factorId, values.token).then(result => {
            setFactorStatus(result)
            if (resolve) {
                resolve(result);
            }
        });
    }, [setFactorStatus, resolve, factorId])

    if (factorStatus?.status === "ACTIVE") {
        return <ResetFactor {...factorContext}/>;
    }
    return factorId ? (
        <PasscodeInput factor={factorStatus} submit={submit} factorUseCase={factorUseCase} extraActions={[
            <ResendPasscode/>,
            enableReset ? <ResetFactor/> : null
        ]}>
            <FactorVerifyInfo factorStatus={factorStatus}/>
        </PasscodeInput>
    ) : null;
};

export default VerifyFactor;