import useTrans from "../../hooks/useTrans";
import React from "react";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import {GreenButton, GreyHeader, H5, RedButton, WhiteBox} from "../../atoms/style";
import EnrolledFactors from "../../fetchers/EnrolledFactors";
import FactorDetail from "./FactorDetail";
import Markdown from "markdown-to-jsx";
import FactorContextProvider from "./FactorContextProvider";
import {UI_SUPPORTED_FACTORS} from "./constants";
import getApi from "../../../services/getApi";
import Loading from "../../atoms/Loading";
import OktaUserGroupsFetcher from "../../fetchers/OktaUserGroupsFetcher";
import useIsOktaAuthenticated from "../../hooks/useIsOktaAuthenticated";
import PageNotAvailableNote from "../../atoms/PageNotAvailableNote";
import {oktaDomainConfig} from "../../../services/okta";

const matchFactors = (allFactors, enrolledFactors) => {
    const supportedFactors = UI_SUPPORTED_FACTORS.map((filterMap) => {
        return allFactors.find(factor => factor.factorType === filterMap.factorType && factor.provider === filterMap.provider);
    });

    return supportedFactors.map(factor => {
        return [
            factor,
            enrolledFactors.find(enrolledFactor => enrolledFactor.factorType === factor.factorType && enrolledFactor.provider === factor.provider)
        ]
    });
}

const MFA_GROUP_ID = oktaDomainConfig.mfaGroupId;
const OptInMFAOnSignIn = ({enrolled}) => {
    const t = useTrans();
    const disabled = enrolled.length === 0;
    const [oktaUserGroups, updateOktaUserGroups] = React.useState(OktaUserGroupsFetcher.read());
    const [isLoading, setLoading] = React.useState(false);
    const curValue = React.useMemo(() => {
        const result = oktaUserGroups.find(group => group?.id === MFA_GROUP_ID);
        return !!result;
    }, [oktaUserGroups]);
    const toggleMFAUse = React.useCallback(() => {
        setLoading(true);
        const api = getApi("okta");
        api.addUserToGroup(MFA_GROUP_ID, !curValue).then(() => api.getOktaUserGroups()).then(result => {
            updateOktaUserGroups(result);
        }).finally(() => {
            setLoading(false);
        });
    }, [setLoading, curValue, updateOktaUserGroups]);

    React.useEffect(() => {
        if (enrolled.length === 0 && curValue) {
            toggleMFAUse()
        }
    }, [curValue, enrolled, toggleMFAUse])

    if (isLoading) {
        return <Loading/>;
    }
    return curValue ? (
        <RedButton disabled={disabled} onClick={toggleMFAUse}>{t("action.disable_mfa_on_signin")}</RedButton>
    ) : (
        <GreenButton disabled={disabled} onClick={toggleMFAUse}>{t("action.use_mfa_on_signin")}</GreenButton>
    )
};


const Multifactor = () => {
    const t = useTrans();
    const enrolledFactors = EnrolledFactors.read();
    const matchedFactors = React.useMemo(() => matchFactors(UI_SUPPORTED_FACTORS, enrolledFactors), [enrolledFactors]);
    const [enrolled, setEnrolled] = React.useState([]);
    const updateEnrolled = React.useCallback((factorId, isCancel = false) => {
        setEnrolled(curEnrolled => {
            if (isCancel) {
                return curEnrolled.filter(curFactorId => curFactorId !== factorId)
            } else {
                return [...curEnrolled.filter(curFactorId => curFactorId !== factorId), factorId];
            }
        });
    }, [setEnrolled])
    return (
        <>
            <GreyHeader>
                <H5 spacing={{my: 1}}>{t('account.multifactor_page')}</H5>
            </GreyHeader>

            <WhiteBox spacing={{px: 3, pt: 3, pb: 4, mb: 4}}>
                <p>
                    <Markdown>{t('factors.description_md')}</Markdown>
                </p>
                <div>
                    <OptInMFAOnSignIn enrolled={enrolled}/>
                </div>
            </WhiteBox>

            {matchedFactors.map((factorPair) => {
                const [factor, factorStatus] = factorPair;
                return (
                    <FactorContextProvider key={factor.id} factor={factor} updateEnrolled={updateEnrolled}
                                           initialFactorStatus={factorStatus}>
                        <FactorDetail/>
                    </FactorContextProvider>
                )
            })}
        </>
    );
}

export default function ({match: {params}}) {
    const t = useTrans();
    const isOktaAuth = useIsOktaAuthenticated();
    return (
        <PageWithSidebar title={t('account.multifactor_page')} sidebar={<SideBar/>}>
            {isOktaAuth ? (
                <React.Suspense fallback={<Loading/>}>
                    <Multifactor/>
                </React.Suspense>
            ) : (
                <PageNotAvailableNote/>
            )}

        </PageWithSidebar>
    )
}