import React from 'react';
import {merge} from "lodash/object";

function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export function mergeValue(key, prop, attr) {
    if (key === "className") {
        return attr && prop ? (attr + ' ' + prop) : (attr || prop);
    } else if (key === "style" || key === "spacing") {
        return merge(attr, prop);
    } else {
        return prop || attr;
    }
}

export function buildAttrs(compoundAttrs, props) {
    let finalAttrs = {};
    compoundAttrs.forEach(attrs => {
        attrs = isFunction(attrs) ? attrs(props) : attrs;
        Object.keys(attrs).forEach(key => {
            const value = attrs[key];
            finalAttrs[key] = mergeValue(key, (isFunction(value) ? value(props) : value), finalAttrs[key]);
        });
    });
    return finalAttrs;
}

export const mergeProps = (props, compoundAttrs) => {
    let newProps = {...props};
    const finalAttrs = buildAttrs(compoundAttrs, props);
    for (let key of Object.keys(finalAttrs)) {
        let attr = finalAttrs[key];
        const prop = newProps[key];
        newProps[key] = (typeof prop === "undefined") ? attr : mergeValue(key, prop, attr);
    }
    return newProps;
};
const sizeNames = ['xs', 'sm', 'md', 'lg', 'xl'];
const propNames = {'mt': true, 'mb': true, 'ml': true, 'mr': true, 'mx': true, 'my': true, 'm': true, 'pt': true, 'pb': true, 'pl': true, 'pr': true, 'px': true, 'py': true, 'p': true, 'h': true, 'w': true, 'd': true, 'clearfix': true};

export const propsToClasses = (props) => {
    const {className, spacing, ...restProps} = props;
    return (spacing ? {
        className: `${spacingToClasses(spacing)}${className ? ' ' + className : ''}`,
        ...restProps
    } : props);
};
const populateTuple = (collection, props, prefix) => {
    const keys = Object.keys(props);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (propNames[key] === true) {
            collection.push([key, props[key], prefix]);
        }
    }
};
const spacingToClasses = (props) => {
    if (!props) {
        return null;
    }
    let tuples = [];
    sizeNames.forEach(size => {
        const value = props[size];
        if (value != null && typeof value === "object") {
            populateTuple(tuples, value, size);
        }
    });
    populateTuple(tuples, props, null);
    return tuples.map((tuple) => {
        const [key, value, prefix] = tuple;
        const type = typeof value;
        if (type === "number" || type === "string") {
            return prefix ? `${key}-${prefix}-${value}` : `${key}-${value}`;
        }
        if (type === "boolean" && value) {
            return prefix ? `${prefix}-${value}` : value;
        }
        return null;
    }).filter((v)=>(v != null && v !== undefined && v !== 'undefined')).join(" ");
};

const withAttrs = (Component, attrs) => {
    const RootComponent = Component.rootComponent || Component;
    const inheritedAttrs = Array.isArray(Component.attrs) ? Component.attrs : [];
    const compoundAttrs = attrs ? [...inheritedAttrs, attrs] : inheritedAttrs;
    const compWithAttrs = ({wc, ...props}) => {
        const RenderComponent = wc ? wc : RootComponent;
        return <RenderComponent {...propsToClasses(mergeProps(props, compoundAttrs))}/>;
    };
    compWithAttrs.displayName = `withAttrs(${RootComponent.displayName || RootComponent.name || RootComponent})`;
    compWithAttrs.rootComponent = RootComponent;
    compWithAttrs.attrs = compoundAttrs;
    return compWithAttrs;
};

export default withAttrs;