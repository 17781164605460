class Resolver {
    registry = {};

    register(name, value) {
        this.registry[name] = value;
    }

    unregister(name) {
        delete this.registry[name];
    }

    resolve(name) {
        const result = this.registry[name];
        if (result == null) {
            throw new Error(`Couldn't resolve ${name}`);
        }
        return result;
    }
}

export default Resolver;