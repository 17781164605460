import history from "../../setup/history";
import resolver from '../../resolver';

const goTo = (name, params, extra) => {
    if (name.startsWith("/")) {
        history.push(name);
        return;
    }
    const reverse = resolver.resolve('reverse');
    history.push({
        ...extra,
        pathname: reverse(name, params)
    });
};

export default goTo;