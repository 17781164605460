import React from "react";
import {AvatarImg, WhiteBox, WrappedCol, WrappedRow} from "../../atoms/style";
import {getAvatarSrc} from "../../atoms/misc";
import {RegRow} from "./style";
import {MEMBERSHIP_NAMES} from "../../../constants";
import {formatDate} from "../../utils/formatDate";

const PhoneTitle = ({t, dataT, title, phone}) => phone ? (
    <span data-t={dataT}>{title}: {phone}</span>
) : null;

const Address = ({t, data : {street, city, zipcode, country}}) => street && city && country ? (
    <span>
        <span>{street}, </span>
        <span>{city}, </span><span>{country}</span>
        <span>, {zipcode}</span>
    </span>
) : t("N/A");

const MainBoxRow = ({left, right}) => (
    <WrappedRow noGutters spacing={{mt: 4}}>
        <WrappedCol md={5} spacing={{pr: 2}}>{left}:</WrappedCol>
        <WrappedCol md={7} spacing={{pr: 2}}><strong>{right}</strong></WrappedCol>
    </WrappedRow>
);

const ProfileAvatar = ({src}) => {
    src = getAvatarSrc(src);
    return (
        <div
            className="order-2 img-container-custom square-lg-custom border border-light-custom mb-4 mb-md-0 mx-auto mx-md-0 ml-md-2">
            <AvatarImg src={src} spacing={{w: 100}} />
        </div>
    );
};

const MemberProfile = ({t, member, isSelf = false, isManager = false}) => {
    const {
        numbers_visible_to_singers,
        address_visible_to_singers,
        birthdate_visible_to_singers,
        email_visible_to_singers,
        voice,
        active_member,
        membership_type,
        is_manager
    } = member.membership;
    const showAll = isManager || isSelf || is_manager;

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>

            <div className="d-md-flex mb-3 mb-3">
                <ProfileAvatar src={member}/>
                <div className="order-1 mr-auto w-100 bg-gray-custom p-3">
                    <h3 className="h2">{member.full_name} <span
                        className="text-nowrap d-block d-md-inline mr-auto mr-sm-0"><span
                        className="d-none d-md-inline font-weight-normal">| </span>{voice ? voice.abbr : t('choir_member.a_manager')}</span></h3>
                    <MainBoxRow left={t('choir_member.status')}
                                right={active_member ? t('choir_member.active_member') : t('choir_member.inactive_member')} />
                    <MainBoxRow left={t('choir_member.membership_type')} right={t(`membership_type. a_${MEMBERSHIP_NAMES[membership_type ? membership_type : 0]}`)}/>
                </div>
            </div>

            <div>
                <RegRow data-t={'choir_member.birthday'} left={t('choir_member.birthday')} hide={!birthdate_visible_to_singers && !showAll}>
                    {member.birthday ? formatDate(member.birthday) : t('N/A')}
                </RegRow>
                <RegRow data-t={"choir_member.email"} left={t('choir_member.email')} hide={!email_visible_to_singers && !showAll}>
                    {member.email}
                </RegRow>
                <RegRow data-t={"choir_member.address"} left={t('choir_member.address')} hide={!address_visible_to_singers && !showAll}>
                    <Address t={t} data={member}/>
                </RegRow>
                <RegRow left={t('choir_member.phone')} hide={!numbers_visible_to_singers && !showAll}>
                    <PhoneTitle data-t={'phone.mobile'} t={t} title={t('phone.mobile')} phone={member.phone_mobile} /><br/>
                    <PhoneTitle data-t={'phone.home'}  t={t} title={t('phone.home')} phone={member.phone_home} /><br/>
                    <PhoneTitle data-t={'phone.fax'}  t={t} title={t('phone.fax')} phone={member.phone_fax} /><br/>
                </RegRow>
                <RegRow left={t('choir_member.about')}>
                    {member.about}
                </RegRow>
            </div>

        </WhiteBox>
    );
};


export default MemberProfile;