import {FieldArray} from "formik";
import {IconedLink} from "../../atoms/style";
import React from "react";
import {EventFormGreyBox} from "./style";
import AttachmentInput from "./AttachmentInput";

const AttachmentsField = ({name = 'attachments', attachmentType = "event", disabled = false, maxLimit = 4, setFieldValue, value, t}) => {
    return (
        <FieldArray name={name} render={arrayHelpers => {
            return (
                <div>
                    <EventFormGreyBox>
                        {t(`${attachmentType}_form.attachments_note`)}
                    </EventFormGreyBox>
                    {value && value.length > 0 ? value.map((attachment, index) => (
                        <AttachmentInput
                            index={index + 1}
                            prefix={`${name}.${index}`}
                            value={attachment}
                            attachmentType={attachmentType}
                            key={`${name}.${attachment.id || attachment._id}`}
                            setFieldValue={setFieldValue}
                            disabled={disabled}
                            t={t}
                            onDelete={() => {
                                if (window.confirm(t('confirm.delete_attachment?'))) {
                                    arrayHelpers.remove(index)
                                }
                            }}/>
                    )) : null}
                    <EventFormGreyBox>
                        {t(`${attachmentType}_form.attachments_limit_note`)}
                    </EventFormGreyBox>
                    {!disabled && (!value || value.length < maxLimit) ? (
                        <IconedLink icon={'plus-square'} onClick={() => arrayHelpers.push({_id: Math.round(Math.random() * 10000)})}>
                            {t(`${attachmentType}_form.add_attachment`)}
                        </IconedLink>
                    ) : null}
                </div>
            );
        }}/>
    );
};

export default AttachmentsField;