import React, {useContext} from 'react';
import {PageEditButton} from "../../atoms/style";
import DownloadBox from "../../molecules/DownloadBox";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import EventBox from "../../molecules/EventBox/EventBox";
import SideBar from "../../molecules/SideBar";
import {Col, Row} from "reactstrap";
import AttendanceBox from "../../molecules/AttendanceBox/AttendanceBox";
import useTrans from "../../hooks/useTrans";
import EventFetcher from '../../fetchers/EventFetcher';
import EventContext from "../../contexts/EventContext";
import NotFoundPage from "../NotFoundPage";
import EventAttachmentBox from "../../molecules/EventAttachmentBox";
import isNotFound from "../../../utils/isNotFound";
import EventPollBox from "../../molecules/EventPollBox";
import WithEvent from "../../molecules/WithEvent";
import EventPollStatsBox from "../../molecules/EventPollStatsBox";
import getApi from "../../../services/getApi";

const EventDetail = (props) => {
    const {t} = props;
    const {event, canEdit} = useContext(EventContext);
    const downloadUrl = React.useMemo(() => {
        const api = getApi("events");
        return api.getEventDetailExcelUrl(event.id);
    }, [event]);

    return (
        <React.Fragment>
            {canEdit ? (
                <Row>
                    <Col md={5}>
                        <PageEditButton data-t={'button.event_edit'} to={['event_edit', {id: event.id}]}>{t('event.edit')}</PageEditButton>
                    </Col>
                </Row>
            ) : null}

            {canEdit ? <DownloadBox spacing={{mt: 4}} links={[
                {icon: 'file-excel', alt: `event_${event.id}_details`, title: t('event.download_details'), url: downloadUrl},
                {icon: 'envelope', title: t('event.send_message'), to: ['event_send_letter', {id: event.id}]}
            ]}/>: null}

            <EventBox showLocation={true}/>

            <EventPollStatsBox/>

            <EventPollBox/>

            <EventAttachmentBox attachments={event.attachments}/>

            <AttendanceBox />
        </React.Fragment>
    );
};


const EventDetailPage = (props) => {
    const {t} = props;
    return (
        <PageWithSidebar title={t('event.detail_title')}
                         sidebar={<SideBar />} breadcrumbs={[{title: t('nav.events'), to: 'event_list'}]}
                         page={'event_detail'} >
            <EventDetail {...props}/>
        </PageWithSidebar>
    );
};

export default (props) => {
    const {match: {params}} = props;
    const event = EventFetcher.read({event_id: params.id});
    if (isNotFound(event)) {
        return <NotFoundPage/>;
    }
    return (
        <WithEvent event={event}>
            <EventDetailPage {...props} t={useTrans()} />
        </WithEvent>
    ) ;
};