import React, {createContext} from "react";
import lodashget from "lodash/get"

function useCacheReducer() {
    const [cache, dispatch] = React.useReducer((state, {type, key, value}) => {
        if (type === "CLEAR") {
            return {};
        } else if (type === "SET") {
            return {
                ...state,
                [key]: value
            }
        } else if (type === "REMOVE") {
            return {
                ...state,
                [key]: undefined
            }
        }
    }, {});

    const set = React.useCallback((key, value) => dispatch({
        type: "SET",
        key,
        value
    }), [dispatch])
    const remove = React.useCallback((key) => dispatch({
        type: "SET",
        key
    }), [dispatch])
    const clear = React.useCallback(() => dispatch({
        type: "CLEAR"
    }), [dispatch])
    const get = React.useCallback((key, defaultValue) => lodashget(cache, key, defaultValue), [cache])
    return {
        cache,
        set,
        get,
        remove,
        clear
    };
}

const GlobalCache = createContext({});

function GlobalCacheRender({children}) {
    const value = useCacheReducer();
    GlobalCache.singleton_value = value
    return (
        <GlobalCache.Provider value={value}>
            {children}
        </GlobalCache.Provider>
    )
}

GlobalCache.Render = GlobalCacheRender;

export default GlobalCache;
