import React from "react";
import {Col, Container, Row} from "reactstrap";
import {SideBarWrapper} from "../atoms/style";
import Header from "./Header";
import Footer from "./Footer/Footer";
import BreadCrumbs from "./BreadCrumbs";
import LoginRequired from "../atoms/LoginRequired";
import ErrorBoundary from "../atoms/ErrorBoundary";

const PageWithSidebar = (props) => {
    const {title, children, sidebar, breadcrumbs, login_required, page} = props;
    const res = (
        <React.Fragment>
            <Header {...props}/>
            <section className="py-3 py-md-5" data-t={`page.${page}`}>

                <Container className="pb-3">

                    {breadcrumbs ? <BreadCrumbs {...breadcrumbs[0]}/> : null}

                    <h1 className="py-4">{title}</h1>

                    <Row>
                        <Col lg={9}>
                            <ErrorBoundary>
                                {children}
                            </ErrorBoundary>
                        </Col>
                        <SideBarWrapper>
                            <ErrorBoundary>
                                {sidebar}
                            </ErrorBoundary>
                        </SideBarWrapper>
                    </Row>
                </Container>
            </section>

            <Footer {...props}/>

        </React.Fragment>
    );
    if (login_required) {
        return (
            <LoginRequired>
                {res}
            </LoginRequired>
        );
    } else {
        return res;
    }
};

export default PageWithSidebar;
