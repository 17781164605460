import {VoiceMemberIcon} from "../../atoms/style";
import {UncontrolledPopover} from "reactstrap";
import VoiceMemberDecisionForm from "./VoiceMemberDecisionForm";
import React from "react";
import {updateCommitment} from "./util";
import {VoicePopoverBody} from "./style";
import {DECISION_CODES} from "../../../constants";

const VoiceMember = (props) => {
    const {commitment, event_id, scp_id, canChange} = props;
    const decision = (commitment && commitment.decision != null) ? commitment.decision : DECISION_CODES.later;
    const iconId = `voice-member-${scp_id}`;
    const onSubmit = updateCommitment(event_id, scp_id);
    return (
        <React.Fragment>
            <VoiceMemberIcon size={'sm'} spacing={{ml: 1}} decision={decision} id={iconId} data-t={'event.member_icon'}/>
            <UncontrolledPopover target={iconId} trigger={'legacy'} placement="top">
                <VoicePopoverBody>
                    <VoiceMemberDecisionForm canChange={canChange} onSubmit={onSubmit} {...props}/>
                </VoicePopoverBody>
            </UncontrolledPopover>
        </React.Fragment>
    );
};

export default VoiceMember;