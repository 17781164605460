import {Icon} from "../atoms/style";
import React from "react";
import {FormFeedback} from "reactstrap";
import isArray from "lodash/isArray";
import useTrans from "../hooks/useTrans";

const ErrorFeedback = ({errorText}) => (
    <FormFeedback data-t={"msg.input_error"} valid={false} className="text-red-custom" style={{display: 'block'}}>
        <Icon icon={'exclamation-triangle'} size={'2x'} spacing={{pr: 2, pt: 2}} />
        <strong>{errorText}</strong>
    </FormFeedback>
);

const InputErrorFeedback = ({error, children}) => {
    if (error) {
        error = isArray(error) ? error : [error];
    }
    const t = useTrans();
    return error && error.length > 0 ? (error.map((errorText, idx) => (
        <ErrorFeedback key={idx} errorText={errorText.startsWith("validation.") ? t(errorText) : errorText} />
    ))) : (children ? (
        <ErrorFeedback errorText={children}/>
    ) : null);
};

export default InputErrorFeedback;