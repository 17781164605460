import {useState, useCallback} from "react";

export default (initialPagination) => {
    const [pagination, setPagination] = useState({
        cur_page: 1,
        num_pages: 0,
        ...initialPagination
    });
    const onPageChange = useCallback((page) => setPagination((curPagination) => ({
        ...curPagination,
        cur_page: page,
    })), [setPagination]);
    const updatePagination = useCallback((listResponse) => {
        setPagination((curPagination) => {
            if (curPagination !== listResponse.pagination && listResponse.pagination !== null) {
                return ({
                    ...curPagination,
                    ...listResponse.pagination
                });
            } else {
                return curPagination;
            }
        });
    }, [setPagination]);
    return {
        pagination,
        onPageChange,
        updatePagination
    };
};