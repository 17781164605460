import Loading from "./Loading";
import React from 'react';
import useAuth from "../hooks/useAuth";
import goTo from "../utils/goTo";

const LoginRequired = ({children}) => {
    const { isPending, isAuthenticated } = useAuth();
    React.useEffect(() => {
        if (!isPending && !isAuthenticated) {
            goTo("login");
        }
    }, [isAuthenticated, isPending]);
    if (isPending) {
        return <Loading/>;
    } else if (isAuthenticated) {
        return children;
    } else {
        return null;
    }
};

export default LoginRequired;