import React from "react";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import EventEdit from "./EventEdit";

const EventEditPage = (props) => {
    const {t, isEdit} = props;
    return (
        <PageWithSidebar title={isEdit ? t('event.edit_title') : t('event.create_title')}
                         sidebar={<SideBar />} page={isEdit ? 'event_edit' : 'event_create'}>
            <EventEdit {...props} />
        </PageWithSidebar>
    );
};

export default EventEditPage;