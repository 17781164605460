import {mixed, number, object, string} from "yup";
import getApi from "../../../services/getApi";
import isAppError from "../../../utils/isAppError";
import {GreySubmitButton, HelpText, SubmitButton, WhiteBox} from "../../atoms/style";
import {Form, Formik} from "formik";
import FormStatus from "../../molecules/FormStatus/FormStatus";
import BootstrapField from "../../molecules/BootstrapField";
import {EventFormGreyBox, FormSubmitCol} from "../EventEditPage/style";
import React, {useContext, useEffect} from "react";
import {FormGroup, Row} from "reactstrap";
import useTrans from "../../hooks/useTrans";
import {MEMBERSHIP_CODES} from "../../../constants";
import VoiceSelect from "../../molecules/VoiceSelect/VoiceSelect";
import ChoirContext from "../../contexts/ChoirContext";
import {castMomentToDateStr, castToMoment} from "../../validation/utils";

const inviteSchema = object({
    membership_type: number().required('validation.this_field_is_required'),
    voice_id: mixed().when('membership_type', (value) => {
        if (parseInt(value) === MEMBERSHIP_CODES.manager) {
            return number().nullable();
        } else {
            return number().required('validation.this_field_is_required');
        }
    }),
    joined_choir: mixed().transform(castToMoment).nullable(),
    resigned_choir: mixed().transform(castToMoment).nullable(),
    notes: string().nullable(),
    resignation_reason: string().nullable(),
});

const serializeInviteSchema = inviteSchema.shape({
    joined_choir: mixed().nullable().transform(castMomentToDateStr),
    resigned_choir: mixed().nullable().transform(castMomentToDateStr),
});

const onSubmit = ({t, member, choir, onUpdate}) => (values, {setSubmitting, setErrors, setStatus}) => {
    setSubmitting(true);
    const api = getApi("membership");
    const promise = api.updateMembershipProfile(choir.id, member.id, serializeInviteSchema.cast(values));
    promise.then((result) => {
        setSubmitting(false);
        onUpdate(result);
    }).catch(error => {
        console.error("FAILED", error);
        setSubmitting(false);
        if (isAppError(error)) {
            setStatus({error: error.non_field_errors || t('form.some_fields_failed_validation')});
            setErrors(error.body)
        }
    });
    // window.location.reload();
};

const TheForm = ({t, onCancel, voices, isManager, isSelf, formProps: {isSubmitting, setStatus, touched, errors, status, values, submitForm, setFieldValue}}) => {

    useEffect(() => {
        if (!values.membership_type) {
            setFieldValue('membership_type', MEMBERSHIP_CODES.singer);
        }
    }, [values.membership_type, setFieldValue]);

    const managerOnly = parseInt(values.membership_type) === MEMBERSHIP_CODES.manager;
    const singerOnly = parseInt(values.membership_type) === MEMBERSHIP_CODES.singer;

    useEffect(() => {
        if (managerOnly) {
            setFieldValue('voice_id', undefined);
        }
    }, [managerOnly, setFieldValue]);

    return (
        <WhiteBox spacing={{px:3, pt:3, pb:4, mb:4}}>
            <EventFormGreyBox>
                {t('choir_member.edit_membership_note')}
            </EventFormGreyBox>
            <Form>
                <FormStatus status={status} />

                <BootstrapField name={'membership_type'} disabled={!isManager} label={<strong>{t('membership_form.membership_type')}</strong>} type={'select'}>
                    {Object.entries(MEMBERSHIP_CODES).map((entry) => {
                        const [membership_type, code] = entry;
                        return (
                            <option value={code}>{t(`membership_type.${membership_type}`)}</option>
                        );
                    })}
                </BootstrapField>

                <VoiceSelect name={'voice_id'} t={t} voices={voices} disabled={managerOnly || !isManager} label={<strong>{t('membership_form.voice')}</strong>}/>

                {!managerOnly && isManager ? (
                    <div>
                        <FormGroup row>
                            <BootstrapField type={'date'}
                                            name={'joined_choir'}
                                            md={6}
                                            label={<strong>{t('membership_form.joined_choir_label')}</strong>}/>
                            <BootstrapField type={'date'}
                                            name={'resigned_choir'}
                                            md={6}
                                            label={<strong>{t('membership_form.resigned_choir_label')}</strong>}/>
                        </FormGroup>
                        <BootstrapField type={'textarea'}
                                        name={'notes'}
                                        label={<strong>{t('membership_form.notes_label')}</strong>}/>
                        <BootstrapField type={'textarea'}
                                        name={'resignation_reason'}
                                        label={<strong>{t('membership_form.resignation_reason_label')}</strong>}/>
                    </div>
                ) : null}

                {isSelf && singerOnly ? (
                    <div>
                        <BootstrapField type={'checkbox'}
                                        name={'allow_profile_edit'}
                                        label={<strong>{t('membership_form.allow_profile_edit_label')}</strong>}
                                        help_text={<HelpText>{t('membership_form.allow_profile_edit_help')}</HelpText>}/>

                        <BootstrapField type={'checkbox'}
                                        name={'numbers_visible_to_singers'}
                                        label={<strong>{t('membership_form.numbers_visible_to_singers_label')}</strong>}
                                        help_text={<HelpText>{t('membership_form.numbers_visible_to_singers_help')}</HelpText>}/>

                        <BootstrapField type={'checkbox'}
                                        name={'email_visible_to_singers'}
                                        label={<strong>{t('membership_form.email_visible_to_singers_label')}</strong>}
                                        help_text={<HelpText>{t('membership_form.email_visible_to_singers_help')}</HelpText>}/>

                        <BootstrapField type={'checkbox'}
                                        name={'address_visible_to_singers'}
                                        label={<strong>{t('membership_form.address_visible_to_singers_label')}</strong>}
                                        help_text={<HelpText>{t('membership_form.address_visible_to_singers_help')}</HelpText>}/>

                        <BootstrapField type={'checkbox'}
                                        name={'birthdate_visible_to_singers'}
                                        label={<strong>{t('membership_form.birthdate_visible_to_singers_label')}</strong>}
                                        help_text={<HelpText>{t('membership_form.birthdate_visible_to_singers_help')}</HelpText>}/>
                    </div>
                ) : null}

                <FormStatus status={status}/>

                <Row>
                    <FormSubmitCol>
                        <SubmitButton type={'submit'}
                                      disabled={isSubmitting}
                                      data-t={"membership_form.save"}>{t('membership_form.save')}</SubmitButton>
                    </FormSubmitCol>

                    {onCancel ? <FormSubmitCol>
                        <GreySubmitButton onClick={onCancel} data-t={"membership_form.cancel"}>{t('membership_form.cancel')}</GreySubmitButton>
                    </FormSubmitCol> : null}
                </Row>

            </Form>
        </WhiteBox>
    )
};

export default function(props) {
    const {member} = props;
    const initialValues = inviteSchema.cast({
        ...member.membership,
        voice_id: member.membership.voice ? member.membership.voice.id : undefined
    });
    const {choir, voices} = useContext(ChoirContext);
    const t = useTrans();
    return (
        <Formik validationSchema={inviteSchema} initialValues={initialValues} onSubmit={onSubmit({choir, ...props})}>
            {(formProps) => (
                <TheForm t={t} {...props} voices={voices} formProps={formProps} />
            )}
        </Formik>
    );
}