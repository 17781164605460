import {oktaAuth} from "./okta";
import {beAuthService} from "./beAuth";
import GlobalCache from "../components/atoms/GlobalCache";
import i18n from "../setup/i18n";


export const login = (username, password) => {
    return oktaAuth.signInWithCredentials({username, password});
}

export const clearSession = async () => {
    await oktaAuth.closeSession();
}

export const logout = async () => {
    if (beAuthService.getAuthState().isAuthenticated) {
        await beAuthService.logout();
    }
    const cache = GlobalCache?.singleton_value;
    if (cache) {
        cache.clear();
    }
    const isAuth = await oktaAuth.isAuthenticated();
    if (isAuth) {
        await oktaAuth.signOut({postLogoutRedirectUri: window.location.origin + `/${i18n.language}/login`});
    }
    return true;
}
export const clearAuthState = async () => {
    await oktaAuth.closeSession();
    oktaAuth.tokenManager.clear()
    beAuthService.clearLocalStorage();
}