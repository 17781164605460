import AvatarBoxLink from "../AvatarBoxLink";
import WidgetLink from "../WidgetLink";
import React from "react";
import {SidebarBoxWrapper} from "../../atoms/style";

export const PureUserBoxContent = ({user, t, showOptOut = true}) => (
    <SidebarBoxWrapper spacing={{p: 3}}>
        <AvatarBoxLink to={'profile'} name={user.full_name} src={user} />

        <WidgetLink to={'profile'} title={t('user.my_profile')} icon={'user'} />
        <WidgetLink to={'change_password'} title={t('user.change_password')} icon={'key'} />
        <WidgetLink to={'change_email'} title={t('user.change_email')} icon={'at'} />
        <WidgetLink to={'factors'} title={t('user.factors')} icon={'user-shield'} />
        {showOptOut ? <WidgetLink to={'optout_requests'} title={t('user.permissions')} icon={'question-circle'} /> : null}
        <WidgetLink to={'letters'} title={t('user.letters')} icon={'envelope'} />
    </SidebarBoxWrapper>
);