import {Field, getIn} from "formik";
import React from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {A, Div} from "../../atoms/style";

const notInValue = (valueArray, value) => {
    if (!valueArray || !Array.isArray(valueArray) || valueArray.length === 0) {
        return true;
    }
    if (valueArray.indexOf("" + value) !== -1) {
        return false;
    }
    if (valueArray.indexOf(value) !== -1) {
        return false;
    }
    if (!isNaN(value) && valueArray.indexOf(parseInt(value)) !== -1) {
        return false;
    }
    return true;
};

const isEqual = (a, b) => {
    return a === b || ("" + a) === ("" + b);
};

class CheckboxMultiSelect extends React.Component {
    constructor(props){
        super(props);
        this.selectAll = this.selectAll.bind(this);
        this.selectNone = this.selectNone.bind(this);
        this.eventHandleChange = this.eventHandleChange.bind(this);
    }

    selectAll() {
        this.setValue(this.props.options.map(v => v.value));
    }

    selectNone() {
        this.setValue(undefined);
    }

    setValue(value) {
        this.props.form.setFieldValue(this.props.field.name, value);
    }

    getValue() {
        return this.props.field.value;
    }

    eventHandleChange(e) {
        const value = this.getValue() || [];
        const checkboxValue = e.currentTarget.value;
        if (notInValue(value, checkboxValue)) {
            this.setValue([...value, checkboxValue]);
        } else {
            this.setValue(value.filter((v) => !isEqual(v, checkboxValue)));
        }
    }

    render(){
        const {options, t, field, form} = this.props;
        const {name} = field;
        const value = getIn(form.values, field.name);
        return (
            <Div spacing={{mb: 3}}>
                <Div sapcing={{pb: 2}}>
                    {t('event.choose')}: <A onClick={this.selectAll}>{t('event.all')}</A> | <A onClick={this.selectNone}>{t('event.none')}</A>
                </Div>
                {options.map((option, idx) => {
                    const inputId = `id_${name}_${option.value}`;
                    const isChecked = !notInValue(value, option.value);
                    return (
                        <FormGroup check key={idx}>
                            <Input className="form-check-input" type="checkbox" onChange={this.eventHandleChange} checked={isChecked} name={name} value={option.value} id={inputId}/>
                            <Label for={inputId} className={'form-check-label'}>{option.title}</Label>
                        </FormGroup>
                    );
                })}
            </Div>
        );
    }
}


const FormikCheckboxMultiSelect = ({name, ...props}) => (
    <Field name={name} render={(formikProps) => (
        <CheckboxMultiSelect {...formikProps} {...props}/>
    )}/>
);

export default FormikCheckboxMultiSelect;