import EventPollBox from "./EventPollBox";
import useTrans from "../../hooks/useTrans";
import React, {useContext} from "react";
import EventContext from "../../contexts/EventContext";

export default function (props) {
    const {event, voices, isSinger} = useContext(EventContext);

    return isSinger ? (
        <EventPollBox t={useTrans()} event={event}  voices={voices} {...props}/>
    ) : null;
}