import React, {useEffect, useLayoutEffect} from 'react';
import goTo from "../../utils/goTo";
import Loading from "../../atoms/Loading";
import {logout} from "../../../services/authn";
import useAuth from "../../hooks/useAuth";

/**
 * @return {null}
 */
function LogoutPage() {
    const auth = useAuth();
    useLayoutEffect(() => {
        if (auth.isAuthenticated) {
            logout();
        }
    }, [auth.isAuthenticated]);
    useEffect(() => {
        if (!auth.isAuthenticated && !auth.isPending) {
            goTo("login");
        }
    }, [auth.isAuthenticated, auth.isPending])
    return <Loading/>;
}

export default LogoutPage;