import React from "react";
import BootstrapField from "../../molecules/BootstrapField";
import GenericPollsField from "./GenericPollsField";


const OpenQuestionInput = ({t, index, prefix, disabled}) => {
    return (
        <BootstrapField name={`${prefix}.text`}
                        disabled={disabled}
                        help_text={t('event_form.poll_question_help_text')}/>
    );
};

export default (props) => (
    <GenericPollsField {...props} name={'open_questions'} inputComponent={OpenQuestionInput}/>
);