import {useEffect} from "react";
import goTo from "../utils/goTo";
import * as queryString from "querystring";
import {beAuthService} from "../../services/beAuth";
import {logout} from "../../services/authn";


export default () => {
    const token = queryString.parse(window.location.search.replace(/^\?/, '')).hijack_jsession;
    useEffect(() => {
        if (token) {
            logout().then(() => {
                beAuthService.login({token}).then(() => {
                    goTo('event_list')
                });
            });
        }
    }, [token]);

}