import isNotFound from "../../../utils/isNotFound";
import useTrans from "../../hooks/useTrans";
import React from "react";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import SideBar from "../../molecules/SideBar";
import EventFetcher from "../../fetchers/EventFetcher";
import EventOptOutRequestFetcher from "../../fetchers/EventOptOutRequestFetcher";
import {NoteBox, WhiteBox} from "../../atoms/style";
import OptOutForm from "./OptOutForm";
import goTo from "../../utils/goTo";

const Layout = ({children, t, event}) => (
    <PageWithSidebar title={t('optout.requrest_opt_out_title')} sidebar={<SideBar />} breadcrumbs={[{title: event.title, to: ['event_detail', {id: event.id}]}]} >
        {children}
    </PageWithSidebar>
);

const isEmptyResponse = (response) => response && response.results && response.results.length === 0;

export default (props) => {
    const {match: {params}} = props;
    const event = EventFetcher.read({event_id: params.event_id});
    const optout = EventOptOutRequestFetcher.read({event_id: params.event_id});
    const invalidate = EventOptOutRequestFetcher.invalidator({event_id: params.event_id});
    const t = useTrans();
    const redirectToDetail = () => {
        goTo('event_detail', {id: event.id});
    };
    const onSuccess = () => {
        redirectToDetail();
        invalidate();
    };
    if (isNotFound(optout) || isEmptyResponse(optout)) {
        return (
            <Layout event={event} t={t}>
                <WhiteBox spacing={{p: 3}}>
                    <OptOutForm t={t} event={event} onSuccess={onSuccess} onCancel={redirectToDetail}/>
                </WhiteBox>
            </Layout>
        )
    } else {
        return (
            <Layout event={event} t={t}>
                <WhiteBox spacing={{p: 3}}>
                    <NoteBox spacing={{mb: 1}} data-t={"optout.already_requested"}>
                        {t('optout.already_requested')}
                    </NoteBox>
                </WhiteBox>
            </Layout>
        )
    }
};