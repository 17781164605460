import AvatarBoxLink from "../AvatarBoxLink";
import WidgetLink from "../WidgetLink";
import React from "react";
import {ChoirBoxWrapper} from "./style";
import getApi from "../../../services/getApi";
import {getChoirLogoSrc} from "../../atoms/misc";
import {ChoirLogoImg} from "../../atoms/style";
import useAuthUser from "../../hooks/useAuthUser";

export const ChoirBox = (props) => {
    const {id, name, t} = props;
    const api = getApi("choir");
    const user = useAuthUser();

    return (
        <ChoirBoxWrapper>

            <AvatarBoxLink id={id} name={name} to={['choir_detail', {id}]}>
                <ChoirLogoImg src={getChoirLogoSrc(props)} />
            </AvatarBoxLink>

            <WidgetLink to={['choir_member', {choir_id: id, id: user.id}]} title={t('choir.membership_profile')} />
            <WidgetLink url={api.getChoirVCFUrl(id)} alt={`choir_${id}_addresses`} icon="download" title={t('choir.address_vcf')}/>
            <WidgetLink url={api.getChoirPDFUrl(id)} alt={`choir_${id}_addresses`} icon="download" title={t('choir.address_pdf')} />
            <WidgetLink to={['choir_download_ical', {id}]} icon="calendar-alt" title={t('choir.subscribe_to_events')} />
            <WidgetLink data-t={'link.choir_send_letter'} to={['choir_send_letter', {id}]} icon="envelope" title={t('choir.send_letter')} />

        </ChoirBoxWrapper>
    );
};