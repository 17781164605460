import React from "react";
import UserBox from "./UserBox";
import useTrans from "../../hooks/useTrans";
import useManagerStatus from "../../hooks/useManagerStatus";
import useAuthUser from "../../hooks/useAuthUser";

export default (props) => {
    const user = useAuthUser();
    const t = useTrans();
    const managerStatus = useManagerStatus();
    return (
        <UserBox {...props} showOptOut={managerStatus} t={t} user={user}/>
    )
};