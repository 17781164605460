import React, {useState} from "react";


const initialState = {
    timeLeft: 0,
    interval: null
};

const useCountdown = (totalTime) => {
    const [state, setState] = useState(initialState);

    const startCountdown = React.useCallback(() => {
        setState(curState => {
            const interval = curState?.interval;
            if (interval == null) {
                const intervalId = setInterval(() => {
                    setState(curState => {
                        const timeLeft = Math.max(0, curState?.timeLeft - 1);
                        if (timeLeft === 0) {
                            clearInterval(curState?.interval);
                            return initialState;
                        } else {
                            return ({
                                ...curState,
                                timeLeft
                            });
                        }
                    });
                }, 1000);
                return {
                    timeLeft: totalTime,
                    interval: intervalId
                };
            } else {
                return curState;
            }
        });
    }, [setState, totalTime]);
    return [state?.timeLeft, startCountdown];
}

export default useCountdown;