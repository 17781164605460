import {Div, RedButton, WrappedCol, WrappedRow} from "../../atoms/style";
import DangerBox from "../../molecules/DangerBox/DangerBox";
import React from "react";
import getApi from "../../../services/getApi";
import goTo from "../../utils/goTo";
import Markdown from 'markdown-to-jsx';
import {clearAuthState, logout} from "../../../services/authn";


export default function ({t, user_id}) {
    const onDelete = () => {
        if (window.confirm(t('user.delete_prompt?'))) {
            getApi("user").deleteUser(user_id).then(() => {
                logout().then(() => {
                    clearAuthState();
                    goTo('login');
                }).catch(() => {
                    clearAuthState();
                    goTo('login');
                });
            })

        }
    };
    return (
        <DangerBox icon={'trash'} dataT={'user.delete'} title={t('user.delete')}>
            <Div spacing={{p: 3, mb: 3}} className="small bg-red-custom-light">
                <Markdown>
                    {t('user.delete_warning')}
                </Markdown>
            </Div>
            <WrappedRow>
                <WrappedCol md={4} spacing={{md: {mb: 0}, mb: 4}}>
                    <RedButton onClick={onDelete} size={'md'} spacing={{w: 100}} title="" data-t={'user.action_delete'}>{t('user.action_delete')}</RedButton>
                </WrappedCol>
            </WrappedRow>
        </DangerBox>
    );
}