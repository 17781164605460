export const isProdDeployment = () => {
    return process.env.NODE_ENV === 'production' && window.location.hostname === 'app.choirmanager.com';
};
export const isDevDeployment = () => {
    return window.location.hostname.indexOf("localhost") !== -1;
};
export const GOOGLE_API_KEY = 'AIzaSyD_bMI4mhp8JvI_ymM5MH3IfogBijgqV94';
export const DECISION_CODES = {
    yes: 2,
    no: 1,
    maybe: 0,
    later: -1
};
export const DECISION_NAMES = {
    2: "yes",
    1: "no",
    0: "maybe",
    [-1]: "later"
};
export const DECISION_COLOR = {
    yes: 'green',
    no: 'red',
    maybe: 'yellow',
    later: 'gray'
};
export const DECISION_TYPES = [
    DECISION_CODES.yes, DECISION_CODES.no, DECISION_CODES.maybe, DECISION_CODES.later
];

export const CHOIR_VOICINGS = ["SSAATTBB",
    "SATB",
    "SSAA",
    "TTBB",
    "SA",
    "TB",
    "SSA",
    "TTB",
    "SAT",
    "SAB",
    "Unisono",
    "SMA"
];

export const MEMBERSHIP_CODES = {
    singer: 0,
    manager: 1,
    singer_and_manager: 2
};

export const MEMBERSHIP_NAMES = {
    0: 'singer',
    1: 'manager',
    2: 'singer_and_manager'
};

export const STATUS_VALUES = [0, 1];
export const STATUS_NAMES = {
    0: 'active',
    1: 'inactive'
};

export const GENDER_CHOICES = {
    none: 0,
    mr: 1,
    ms: 2
};

export const ACADEMIC_TITLES = {
    none: 0,
    doctor: 1,
    professor: 2
};

export const LANGUAGE_CHOICES = ["en", "de"];

export const CHOIR_SIZES = ['s', 'm', 'l'];
export const BACKEND_API_BASE = isDevDeployment() ? "http://localhost:8000" : (isProdDeployment() ? "https://api.choirmanager.com" : "https://api-staging.choirmanager.com")
export const OKTA_OAUTH_STATE = 'Wi6IqJQuLGFpB5K1ex8ufIhUdoi0GPniutRvA5A2c02ZsxUP1yWdkQg4AHRStYiD';
export const OKTA_OAUTH_NONCE = '91wHKuOGefvaGXJcGPVeayPZApV1AkzZQjPNIqEBNRdF3GqvEr6BQOajTPreV1PG';
