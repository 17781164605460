import React, {useEffect} from "react";
import BootstrapField from "../../molecules/BootstrapField";
import {BigIcon, Div, IconedLink} from "../../atoms/style";
import {FormGroup, Input} from "reactstrap";
import {EventFormDottedSpacer} from "./style";
import {dirname} from "../../utils/tiny";
import InputErrorFeedback from "../../molecules/InputErrorFeedback";
import {useField} from "formik";
import NullableLink from "../../molecules/NullableLink";
import AttachmentVirusStatus from "../../molecules/AttachmentVirusStatus";

const AttachmentInput = ({t, index, prefix, disabled = false, attachmentType, setFieldValue, value, onDelete}) => {
    const isCreated = value ? !!value.id : false;
    const filename = value && value.file ? dirname(value.file) : null;
    const [, meta] = useField(`${prefix}.file`);

    useEffect(() => {
        if (filename && !value.name) {
            setFieldValue(`${prefix}.name`, filename);
        }
    }, [filename, value, setFieldValue, prefix]);

    const isVirusClean = !value.virus_scan_status || value.virus_scan_status === "clean";

    return (
        <div>
            <BootstrapField name={`${prefix}.name`}
                            label={t(`${attachmentType}_form.attachment_name_label`)}
                            disabled={disabled}
                            required={true}/>
            {attachmentType !== 'letter' ? <BootstrapField name={`${prefix}.description`}
                                                           disabled={disabled}
                                                           label={t(`${attachmentType}_form.attachment_description_label`)}/> : null}
            {isCreated ? (value && value.file ? (
                <Div spacing={{mb: 3}}>
                    {isVirusClean ? (
                        <>
                            <BigIcon icon={'file-download'}/><NullableLink href={isVirusClean ? value.file : null}>{t('attachment.download_link')}</NullableLink>
                            {filename ? <small> ({filename})</small> : null}
                        </>
                    ) : (
                        <>
                            <small>{filename}</small>
                            <br/>
                            <AttachmentVirusStatus attachment={value} />
                        </>
                    )}
                </Div>
            ) : null) : (
                <FormGroup>
                    <Input type={'file'} required={true} disabled={disabled} onChange={(e) => {
                        const theFile = e.currentTarget.files[0];
                        setTimeout(() => setFieldValue(`${prefix}._file`, theFile), 1);
                        if (!value.name) {
                            setFieldValue(`${prefix}.name`, theFile.name);
                        }
                        // setFieldValue(`${prefix}.file`, e.target.value);
                        return false;
                    }}/>
                    <InputErrorFeedback error={meta.error}/>
                </FormGroup>
            )}

            {!disabled ? (
                <IconedLink className={"float-right"} color={"red"} icon={'minus-square'} onClick={onDelete}>
                    {t('attachment.delete')}
                </IconedLink>
            ) : null}
            <EventFormDottedSpacer/>
        </div>
    );
};

export default AttachmentInput;