import {Field} from "formik";
import {
    Col,
    FormGroup,
    FormText,
    Input, InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    UncontrolledTooltip
} from "reactstrap";
import DatePickerInput from "./ChoirDatePicker";
import withAttrs from "../utils/withAttrs";
import React from 'react';
import useTrans from "../hooks/useTrans";
import getIn from 'lodash/get';
import InputErrorFeedback from "./InputErrorFeedback";
import TimeInput from "./TimeInput";
import classNames from 'classnames'
import {Div} from "../atoms/style";

const BootstrapInput = ({
    isInvalid,
    type,
    // { name, value, onChange, onBlur }
    field,
    defaultValue = "",
    // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    form,
    label,
    col,
    afterChange,
    ...props
}) => {

    type = type || "text";
    const InputComponent = type === 'time' ? TimeInput : Input;
    const curValue = field.value || defaultValue;

    return (type === "checkbox" || type === "radio") ? (
        <Input type={type} invalid={isInvalid} checked={curValue} {...field} {...props}  />
    ) : (
        <InputComponent type={type} invalid={isInvalid} {...field} {...props} value={curValue}/>
    );
};

const AttrFormGroup = withAttrs(FormGroup);

const MyFormGroup = ({fg = true, check, ...props}) => fg ? (
    <AttrFormGroup {...props} check={check}/>
): (
    <Div {...props} />
);

const FormGroupInput = withAttrs(({col, md, check, hasError, showError, error, label, t, fg = true, required, inline, inline_row, help_text, input, groupContent, spacing, append, ...props}) => {
    const {field, type} = props;
    required = !!required;
    showError = showError || hasError;
    const isTickbox = (type === 'checkbox' || type === 'radio');
    const InputComponent = input || (type === "date" ? DatePickerInput : BootstrapInput);
    const reqId = 'req__' +  field.name.replace(/[.[\]]+/g, "__");
    const labelComp = label ? <Label for={field.name} check={!!isTickbox}>{label} {required ? (
        <>
            <strong className="text-blue-custom" aria-hidden="true" id={reqId}>*</strong>
            <UncontrolledTooltip placement="top" target={reqId}>{t('form.this_field_is_required')}</UncontrolledTooltip>
        </>
    ) :  null}</Label> : null;
    let inputComp = <InputComponent isInvalid={!!error} {...props}/>;
    if (append) {
        inputComp = (
            <InputGroup>
                {inputComp}
                <InputGroupAddon addonType="append">
                    <InputGroupText>{t('rrule.count')}</InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        )
    }
    let renderComp = isTickbox ? (
        <>
            {inputComp}
            {labelComp}
        </>
    ) : (
        <>
            {labelComp}
            {inputComp}
        </>
    );

    renderComp = inline ? <FormGroup check inline>{renderComp}</FormGroup> : renderComp;
    if (inline_row) {
        return <MyFormGroup fg={fg} data-t={`input.${field.name}`} spacing={spacing}>
            <Row>
                <Col md={inline_row && inline_row.length > 0 ? inline_row[0] : 'auto'}>
                    {labelComp}
                </Col>
                <Col md={inline_row && inline_row.length > 0  ? inline_row[1] : 4}>
                    {inputComp}
                    {help_text ? <FormText>{help_text}</FormText> : null}
                    <InputErrorFeedback error={error}/>
                    {groupContent}
                </Col>
            </Row>
        </MyFormGroup>
    }
    const className = classNames({
        [`col-${col}`]: col,
        [`col-md-${md}`]: md
    });
    return (
        <MyFormGroup fg={fg} data-t={`input.${field.name}`} className={className} spacing={spacing} check={!!isTickbox}>
            {renderComp}
            {help_text ? <FormText>{help_text}</FormText> : null}
            <InputErrorFeedback error={showError ? error : null}/>
            {groupContent}
        </MyFormGroup>
    );
});


const EMPTY_VALUES = {
    "text": "",
    "date": undefined,
    "select": undefined,
    "checkbox": undefined,
    "time": "",
};

const MyField = ({name, defaultValue, ...props}) => <Field name={name}>{({field, form, otherProps}) => {
    defaultValue = defaultValue ? defaultValue : EMPTY_VALUES[field.type];
    const error = getIn(form.errors, name);
    const touch = getIn(form.touched, name);
    const value = field.value || getIn(form.values, name);
    // 1. Fix touch === undefined when submit
    // const hasError = (touch || form.submitCount > 0) && !!error;
    const hasError = (touch || form.submitCount > 0) && !!error;
    const newField = {
        ...field,
        value: typeof value === 'undefined' ? defaultValue : value
    };
    return (
        <FormGroupInput {...props} {...otherProps} hasError={hasError} error={error} field={newField} form={form}/>
    );
}}</Field>;

const BootstrapField = ({slow, ...props}) => {
    const t = useTrans();
    return (
        <MyField t={t} {...props}/>
    );
};

export default BootstrapField;