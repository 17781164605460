import React from "react";
import {EventNotesTitle, EventNotesWrapper} from "./style";
import withTrans from "../../atoms/withTrans";


const textToParagraphs = (text) => text.split("\n").map((part) => `<p>${part}</p>`).join("");

const EventNotes = ({t, notes}) => notes ? (
    <EventNotesWrapper>
        <EventNotesTitle>{t('event.notes')}</EventNotesTitle>
        <div dangerouslySetInnerHTML={{__html: textToParagraphs(notes)}} />
    </EventNotesWrapper>
) : null ;


export default withTrans(EventNotes);