import withAttrs from "../../utils/withAttrs";

export const BirthdayBoxWrapper = withAttrs('div', {
    className: "text-center",
    // spacing: {pt: 1, pb: 1},
    style: {maxWidth: '100%'}
});

export const BirthdayTextWrapper = withAttrs('div', {
    className: "text-center",
    spacing: {pt: 2, pb: 4},
    style: {maxWidth: '100%', backgroundColor: '#A43A89', maxHeight: '100%'}
});