import getApi from "../../../services/getApi";
import EventAttendanceFetcher from "../../fetchers/EventAttendanceFetcher";
import AttendanceDecisionFetcher from "../../fetchers/AttendanceDecisionFetcher";
import goTo from "../../utils/goTo";
import isAppError from "../../../utils/isAppError";

const OPT_OUT_ERRORS = ['validation.optout_required', 'validation.optout_pending'];

const isOptOutError = (error) => isAppError(error) && (OPT_OUT_ERRORS.indexOf(error.body[0]) !== -1);

export const updateAttendance = (event_id, scp_id, callback) => (decision) => {
    const promise = getApi("decision").updateAttendanceDecision(event_id, scp_id, decision).then((result) => {
        AttendanceDecisionFetcher.update({event_id, scp_id}, result);
        return EventAttendanceFetcher.preload({event_id});
    }).catch(error => {
        if (isOptOutError(error)) {
            goTo("event_optout", {event_id});
        }
        return null;
    })
    return callback ? promise.then(callback) : promise;
};
