import {css} from 'styled-components';

const minWidthSizes = {
    l_desktop: 1700,
    desktop: 1200,
    m_desktop: 1200,
    s_desktop: 1140,
    xs_desktop: 992,
    tablet: 768,
    phone: 577,
};


const maxWidthSizes = {
    desktop: 991,
    tablet: 767,
    s_phone: 420,
    phone: 480,
};


export const minWidth = Object.keys(minWidthSizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (min-width: ${minWidthSizes[label]/16}em) {
      ${css(...args)}
    }
  `;
   return acc;
}, {});

export const maxWidth = Object.keys(maxWidthSizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${maxWidthSizes[label]/16}em) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});
