import React, {useContext} from "react";
import useToggle from "../../hooks/useToggle";
import UserChoirsContext from "../../../state/UserChoirsContext";
import PureEventFilter from "./PureEventFilter";
import EventFilterContext from "../../contexts/EventFilterContext";
import useTrans from "../../hooks/useTrans";


export default (props) => {
    const [isOpen, toggle] = useToggle();
    const choirs = useContext(UserChoirsContext);
    const {filter, isFiltered, setFilter} = useContext(EventFilterContext);
    const t = useTrans();
    return (
        <PureEventFilter
            {...props}
            choirs={choirs}
            t={t}
            isOpen={isOpen}
            onToggle={toggle}
            filter={filter}
            isFiltered={isFiltered}
            onSubmit={setFilter}
        />
    )
};