import React from 'react';
import LoginRequired from "../atoms/LoginRequired";


const loginRequired = (Component) => (props) => (
    <LoginRequired>
        <Component {...props}/>
    </LoginRequired>
);

export default loginRequired;