import React from "react";
import EventEditPage from "./EventEditPage";
import useTrans from "../../hooks/useTrans";
import EventFetcher from '../../fetchers/EventFetcher';
import EventContext from "../../contexts/EventContext";
import NotFoundPage from "../NotFoundPage";
import useEditableChoirs from "../../hooks/useEditableChoirs";
import isNotFound from "../../../utils/isNotFound";

export default (props) => {
    const {match: {params}, isEdit = true} = props;
    const t = useTrans();
    const choirs = useEditableChoirs();
    const event = isEdit ? EventFetcher.read({event_id: params.id}) : null;
    if (isEdit && isNotFound(event)) {
        return <NotFoundPage/>;
    }
    return (
        <EventContext.Provider value={event}>
            <EventEditPage {...props} isEdit={isEdit} event={event} t={t} choirs={choirs}/>
        </EventContext.Provider>
    );

};