import useTrans from "../../hooks/useTrans";
import isNotFound from "../../../utils/isNotFound";
import NotFoundPage from "../NotFoundPage";
import React from "react";
import SideBar from "../../molecules/SideBar";
import PageWithSidebar from "../../molecules/PageWithSidebar";
import {GreyHeader, H5} from "../../atoms/style";
import UserFetcher from "../../fetchers/UserFetcher";
import ProfileEdit from "./ProfileEdit";
import useAuthUser from "../../hooks/useAuthUser";
import DeleteProfileBox from "./DeleteProfileBox";
import DownloadBox from "../../molecules/DownloadBox";

export default function ({match, id}) {
    id = id || (match && match.params && match.params.id);
    const t = useTrans();
    const onUpdate = UserFetcher.updator({user_id: id});
    const user = UserFetcher.read({user_id: id});
    const currentUser = useAuthUser();
    if (isNotFound(user)) {
        return <NotFoundPage/>;
    }

    return (
        <PageWithSidebar title={t('profile.edit_title')} sidebar={<SideBar/>}>
            <DownloadBox spacing={{mb: 4}} links={[
                {to: 'change_password', title: t('user.change_password'), icon: 'key'},
                {to: 'change_email', title: t('user.change_email'), icon: 'at'},
                {to: 'factors', title: t('user.factors'), icon: 'user-shield'}
            ]}/>

            <GreyHeader>
                <H5 spacing={{my: 1}}>{t('profile.edit_profile')}</H5>
            </GreyHeader>

            <ProfileEdit t={t} user={user} onUpdate={onUpdate}/>

            {currentUser && currentUser.id === user.id ? <DeleteProfileBox t={t} user_id={user.id}/> : null}
        </PageWithSidebar>
    )
}