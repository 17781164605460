import * as queryString from "querystring";
import {eventFilterSerializer} from "../validation/eventFilter";
import pick from "lodash/pick";
import React,{useContext} from "react";
import {useLocation, useHistory} from "react-router-dom";
import UserChoirsContext from "../../state/UserChoirsContext";

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

function computeInitialState(location, eventtypeKeys) {
    let initialState = queryString.parse(location.search.substring(1));
    initialState = pick(initialState, [
        'from_date',
        'to_date',
        'choir',
        'event_type',
        'commitment_type',
    ]);

    initialState = eventFilterSerializer(eventtypeKeys).cast({
        ...initialState
    });
    if (initialState.from_date === "" || initialState.from_date === null || !initialState.from_date) {
        delete initialState.from_date;
    }
    if (initialState.to_date === "" || initialState.to_date === null || !initialState.to_date) {
        delete initialState.to_date;
    }
    return initialState;
}

//TODO: move it from hooks since it is not using state anymore
export default function useEventFilter() {
    const choirs = useContext(UserChoirsContext);
    let eventTypes = {}
    choirs.map(choir => {
        choir.event_types.map(data_eventtype => {
            eventTypes[data_eventtype.slug]="";
            return false
        });
        return false
    });
    const eventtypeKeys = Object.keys(eventTypes);

    const location = useLocation();
    const filter = React.useMemo(() => computeInitialState(location, eventtypeKeys), [location, eventtypeKeys]);
    const isFiltered = React.useMemo(() => !isEmpty(filter), [filter]);
    const history = useHistory();
    const setFilter = React.useCallback((newFilter) => {
        history.push({
            search: queryString.stringify(newFilter)
        });
    }, [history])
    return {
        filter,
        isFiltered,
        setFilter
    };
}