import React, {useEffect, useState} from "react";
import {beAuthService} from "../../services/beAuth";

export const BeAuthContext = React.createContext();

export const BeAuthProvider = (props) => {

    const [beAuthState, setAuthState] = useState(beAuthService.getAuthState());

    useEffect( () => {
        const unsubscribe = beAuthService.on('authStateChange', () => {
            setAuthState(beAuthService.getAuthState());
        });

        return unsubscribe;
    }, []);

    return (
        <BeAuthContext.Provider value={ { beAuthService, beAuthState } }>
            {props.children}
        </BeAuthContext.Provider>
    );
};